export const colorKeys = [
  "black",
  "white",
  "gray50",
  "gray100",
  "gray200",
  "gray300",
  "gray400",
  "gray500",
  "gray600",
  "gray700",
  "gray800",
  "gray900",
  "green50",
  "green100",
  "green200",
  "green300",
  "green400",
  "green500",
  "green600",
  "green700",
  "green800",
  "green900",
  "primary50",
  "primary100",
  "primary200",
  "primary300",
  "primary400",
  "primary500",
  "primary600",
  "primary700",
  "primary800",
  "primary900",
  "alpha05",
  "alpha10",
  "alpha15",
  "alpha20",
  "alpha25",
  "alpha30",
  "alpha35",
  "alpha40",
  "alpha50",
  "alpha60",
  "red50",
  "red100",
  "red200",
  "red300",
  "red400",
  "red500",
  "red600",
  "red700",
  "red800",
  "red900",
  "orange50",
  "orange100",
  "orange200",
  "orange300",
  "orange400",
  "orange500",
  "orange600",
  "orange700",
  "orange800",
  "orange900",
] as const;

export type ColorKey = typeof colorKeys[number];

export const colorName = {
  black: "black",
  white: "white",
  gray50: "gray.50",
  gray100: "gray.100",
  gray200: "gray.200",
  gray300: "gray.300",
  gray400: "gray.400",
  gray500: "gray.500",
  gray600: "gray.600",
  gray700: "gray.700",
  gray800: "gray.800",
  gray900: "gray.900",
  green50: "green.50",
  green100: "green.100",
  green200: "green.200",
  green300: "green.300",
  green400: "green.400",
  green500: "green.500",
  green600: "green.600",
  green700: "green.700",
  green800: "green.800",
  green900: "green.900",
  primary50: "primary.50",
  primary100: "primary.100",
  primary200: "primary.200",
  primary300: "primary.300",
  primary400: "primary.400",
  primary500: "primary.500",
  primary600: "primary.600",
  primary700: "primary.700",
  primary800: "primary.800",
  primary900: "primary.900",
  alpha05: "alpha.05",
  alpha10: "alpha.10",
  alpha15: "alpha.15",
  alpha20: "alpha.20",
  alpha25: "alpha.25",
  alpha30: "alpha.30",
  alpha35: "alpha.35",
  alpha40: "alpha.40",
  alpha50: "alpha.50",
  alpha60: "alpha.60",
  red50: "red.50",
  red100: "red.100",
  red200: "red.200",
  red300: "red.300",
  red400: "red.400",
  red500: "red.500",
  red600: "red.600",
  red700: "red.700",
  red800: "red.800",
  red900: "red.900",
  orange50: "orange.50",
  orange100: "orange.100",
  orange200: "orange.200",
  orange300: "orange.300",
  orange400: "orange.400",
  orange500: "orange.500",
  orange600: "orange.600",
  orange700: "orange.700",
  orange800: "orange.800",
  orange900: "orange.900",
} as const satisfies Record<ColorKey, string>;

export type ColorName = typeof colorName[keyof typeof colorName];

export const colorHex = {
  black: "#000000",
  white: "#ffffff",
  gray50: "#F1F2F4",
  gray100: "#D4D9DD",
  gray200: "#B8BFC7",
  gray300: "#9BA6B0",
  gray400: "#7F8C9A",
  gray500: "#657380",
  gray600: "#4F5964",
  gray700: "#384047",
  gray800: "#22262B",
  gray900: "#0B0D0E",
  green50: "#EDF7F3",
  green100: "#CAE8DA",
  green200: "#A6D8C1",
  green300: "#83C8A8",
  green400: "#60B98F",
  green500: "#469F75",
  green600: "#377C5B",
  green700: "#275941",
  green800: "#173527",
  green900: "#08120D",
  primary50: "#E9E8FC",
  primary100: "#BCBBF6",
  primary200: "#908EF1",
  primary300: "#6461EB",
  primary400: "#3733E5",
  primary500: "#1E1ACC",
  primary600: "#17149E",
  primary700: "#110E71",
  primary800: "#0A0944",
  primary900: "#030317",
  alpha05: "#0B0D0E0D",
  alpha10: "#0B0D0E1A",
  alpha15: "#0B0D0E26",
  alpha20: "#0B0D0E33",
  alpha25: "#0B0D0E40",
  alpha30: "#0B0D0E4D",
  alpha35: "#0B0D0E59",
  alpha40: "#0B0D0E66",
  alpha50: "#0B0D0E80",
  alpha60: "#0B0D0E99",
  red50: "#FBEBE9",
  red100: "#F3C2BE",
  red200: "#EB9993",
  red300: "#E47068",
  red400: "#E16259",
  red500: "#C22E23",
  red600: "#97241B",
  red700: "#6C1914",
  red800: "#410F0C",
  red900: "#160504",
  orange50: "#FDF3E8",
  orange100: "#F8DBBA",
  orange200: "#F3C48C",
  orange300: "#EEAC5E",
  orange400: "#E99430",
  orange500: "#CF7B16",
  orange600: "#A16011",
  orange700: "#73440C",
  orange800: "#452907",
  orange900: "#170E02",
} as const satisfies Record<ColorKey, string>;

export const brandColors = {
  instagram: "#DA0961",
  twitter: "#1DA1F2",
} as const satisfies Record<string, string>;
