import {isTime, Operation, Time} from "@workspace/models";
import {objectTypeGuard} from "@workspace/type-utils";
import {isMessagesDailyQuota, MessagesDailyQuota} from "./MessagesDailyQuota";
import {isPricingPlanKey, PricingPlanKey} from "./PricingPlan";

export const TRIAL_PERIOD_IN_DAYS = 14;
const TRIAL_STATE = ["notStarted", "started", "expired", "hadBefore"] as const;
export type TrialState = (typeof TRIAL_STATE)[number];

export type Subscription = PricingPlanKey &
  MessagesDailyQuota & {
    //https://stripe.com/docs/api/subscriptions/object#subscription_object-cancel_at
    /**
     * A date in the future at which the subscription will automatically get canceled.
     */
    cancelAt?: Time;
    /**
     * If the subscription has been canceled, the date of that cancellation.
     */
    canceledAt?: Time;
    /**
     * If the subscription has a trial, the end of that trial.
     */
    trialEnd?: Time | null;
    /**
     * If true, subscription will only be able to access features that are available for the plan
     */
    isV2Subscription?: boolean;

    /**
     * Id of the Stripe subscription. May be optional, since it is missing in the old subscription documents
     */
    subscriptionId?: string;
  };

export const isSubscription = objectTypeGuard<Subscription>(
  ({pricingPlan, maxDailyMessages, trialEnd, cancelAt, canceledAt}) =>
    isPricingPlanKey({pricingPlan}) &&
    isMessagesDailyQuota({maxDailyMessages}) &&
    (cancelAt === undefined ? true : isTime(cancelAt)) &&
    (canceledAt === undefined ? true : isTime(canceledAt)) &&
    (trialEnd === undefined ? true : trialEnd === null ? true : isTime(trialEnd)),
);

export type ReadSubscription = Operation<void, Subscription | null>;

export const isReadSubscriptionOutput =
  objectTypeGuard<Exclude<ReadSubscription["out"], null>>(isSubscription);
