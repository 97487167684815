import React, {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {Box, chakra, Flex, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr} from "@chakra-ui/react";
import {Button, Checkbox, Icon, Skeleton, Tab, TabList, Tabs} from "@workspace/ui";
import {useMutation, useQuery} from "@tanstack/react-query";
import {firebaseFunctions as functions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {useParams} from "react-router";
import {FetchUserLeadsFilters, isCloudFunctionOutput, isListCampaign} from "@workspace/firebase-datamodel";
import {Lead, LEADS_PROCESSED_AT_ONCE, List, ListCampaign} from "@workspace/models";
import LeadsFilters from "../../components/leads/leads-filters";
import {ReactComponent as ListEmptyIcon} from "../../assets/icons/lists-empty.svg";
import {add} from "date-fns";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import useLocalStorage from "use-local-storage";
import {filterLeads} from "../../utils/leads";

const StyledTh = chakra(Th, {
  baseStyle: {
    letterSpacing: "0px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0B0D0E80",
    px: 2
  }
})

const StyledTd = chakra(Td, {
  baseStyle: {
    borderBottom: "none",
    py: 2,
    px: 2
  }
})

const StyledTr = chakra(Tr, {
  baseStyle: {
    cursor: "pointer",
    fontSize: "14px",
    borderBottom: "none",
    transitionDuration: "250ms",
    _hover: {
      backgroundColor: "#E9E8FC"
    }
  },
})

const UserAvatar = chakra(Image, {
  baseStyle: {
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    objectFit: "cover"
  },
})

type Props = {
  setCreatedCampaign: Dispatch<SetStateAction<ListCampaign | undefined>>
}


/**
 * Calculates the amount of time it will take to process the remaining leads by calculating the number of batches needed left to process leads * the interval between processing
 * Then converts this time to readable distance
 * */
const calculateEstimatedTime = (count: number) => {
  const numberOfRuns = Math.ceil(count / LEADS_PROCESSED_AT_ONCE);

  const minutes = numberOfRuns * 20;

  const completedAt = add(new Date(), {
    minutes: minutes + 20 //Add 20 minutes buffer
  })

  return formatDistanceToNow(completedAt);
}

export const ViewLeadsTable = ({ setCreatedCampaign }: Props) => {
  const [selectedLeads, setSelectedLeads] = React.useState<string[]>([])
  const [queryFilters, setQueryFilters] = useState<FetchUserLeadsFilters>({
    lastPostDate: undefined,
    keyword: ""
  })

  const params = useParams<{ slug: string }>();

  const [activeLeadsTab, setActiveLeadsTab] = useState<number>(0)
  const [_removedLeads, setRemovedLeads] = useLocalStorage<Array<Lead>>(`list-${params.slug}-removed-leads`, [])

  const isOnRemovedTab = activeLeadsTab === 1;

  const removedLeads = useMemo(() => Array.isArray(_removedLeads) ? _removedLeads : [], [_removedLeads]);

  const lQuery = useQuery({
    queryKey: ["leads", params.slug],
    queryFn: async () => {
      const response = await functions.httpsCallable(cloudFunctionName.fetchUserLeads)({
        slug: params.slug
      });

      if (isCloudFunctionOutput.fetchUserLeads(response.data)) {
        return response.data
      }

      return {
        list: {} as List,
        leads: []
      }
    },
    retry: false,
    staleTime: 3 * 60 * 1000
  })

  const createCampaign = useMutation({
    mutationFn: async () => {
      const response = await functions.httpsCallable(cloudFunctionName.createCampaignFromLeads)({
        slug: params.slug,
        leads: visibleLeads
      });


      if(isListCampaign(response.data)){
        setCreatedCampaign(response.data)
      }
    }
  })

  const visibleLeads = useMemo(() => {
    if (isOnRemovedTab) return removedLeads;

    let fetchedLeads = lQuery.data?.leads || [];

    const removedLeadsSet = new Set(removedLeads.map(l => l.id));

    const leadsWithoutRemoved = fetchedLeads.filter(s => !removedLeadsSet.has(s.id))

    return filterLeads(leadsWithoutRemoved, queryFilters)
  }, [isOnRemovedTab, removedLeads, lQuery.data?.leads, queryFilters]);

  const isInitialLoading = lQuery.isFetching;

  const allLeadSelected = selectedLeads.length === visibleLeads.length

  const onSelectLead = (leadId: string) => {
    if(isOnRemovedTab) return;

    if (selectedLeads.includes(leadId)) {
      setSelectedLeads(selectedLeads.filter(id => id !== leadId))
    } else {
      setSelectedLeads([...selectedLeads, leadId])
    }
  }

  const onSelectAllLeads = () => {
    if (selectedLeads.length === visibleLeads.length) {
      setSelectedLeads([])
    } else {
      setSelectedLeads(visibleLeads.map(lead => lead.id) || [])
    }
  }

  const handleApplyFilters = (newFilters: FetchUserLeadsFilters) => {
    setQueryFilters(newFilters)
  }

  const onRemoveLeads = () => {
    setRemovedLeads(currentRemoved => {
      const selectedLeadsInfo = visibleLeads.filter(lead => selectedLeads.includes(lead.id));

      if(!currentRemoved) return selectedLeadsInfo;

      return currentRemoved.concat(selectedLeadsInfo)
    })

    setSelectedLeads([])
  }

  const renderCount = () => {
    if (selectedLeads.length === 0) {
      return `${visibleLeads.length} leads`
    }

    return `${selectedLeads.length}/${visibleLeads.length} selected`
  }

  const renderButton = () => {
    if(isOnRemovedTab) return null

    if (selectedLeads.length > 0) {
      return (
        <Button variant="bordered" disabled={visibleLeads.length === 0} color='red.600' onClick={onRemoveLeads}>
          Remove
        </Button>
      )
    }

    return (
      <Button
        variant="primary"
        disabled={(visibleLeads.length === 0) || isOnRemovedTab}
        onClick={() => createCampaign.mutate()}
        isLoading={createCampaign.isPending}
      >
        Write messages
      </Button>
    )
  }


  const listIsIncomplete = useMemo(() => {
    if(!lQuery.data) return false;

    const list = lQuery.data?.list;

    if(!list) return false;

    return list.incompleteLeads > 0
  }, [lQuery.data])

  const listDetails = useMemo(() => {
    if(!lQuery.data) return;

    const list = lQuery.data?.list;

    if(!list) return;

    return {
      progress: ((list.totalLeads - list.incompleteLeads) * 100) / (list.totalLeads),
      list
    }
  }, [lQuery.data])


  return (
    <Flex mt={8} px='10%' alignItems='stretch'>
      <LeadsFilters
        activeFilters={queryFilters}
        handleApplyFilters={handleApplyFilters}
        isFetchingNextPage={lQuery.isFetching}
        listIsIncomplete={listIsIncomplete}
      />
      <Box bg='#0000000D' flexShrink={0} width='1px' mx={12}/>
      <Box flexBasis='70.5%'>
        <Flex align="center" justify="space-between" mb={6}>
          <Tabs variant="outlined" onChange={setActiveLeadsTab}>
            <TabList>
              <Tab width='105px'>Added</Tab>
              <Tab width='105px'>Removed {removedLeads.length > 0 ? `(${removedLeads.length})` : ''}</Tab>
            </TabList>
          </Tabs>
          {renderButton()}
        </Flex>
        {
          (listDetails && listIsIncomplete) && (
            <Box mb={6}>
              <Flex w='100%' h='3px' borderRadius={99} bg='#F1F2F4'>
                <Flex h='3px' w={`${listDetails.progress}%`} bg='#60B98F' borderRadius={99}/>
              </Flex>
              <Flex mt={3} align='center'>
                <Text fontSize='13px' lineHeight='20px' color='gray.900' mr='6px'>
                  Processed {listDetails.list.totalLeads - listDetails.list.incompleteLeads} of {listDetails.list.totalLeads} leads
                </Text>
                <Icon name='info' />
                <Text fontSize='13px' lineHeight='20px' color='gray.900' ml='auto' opacity={0.5}>
                  Estimated time: {calculateEstimatedTime(listDetails.list.incompleteLeads)}
                </Text>
              </Flex>
            </Box>
          )
        }
        <TableContainer>
          <Table variant='simple' color="gray.900" fontFamily="Inter" mb={6}>
            <Thead>
              {
                isInitialLoading ? (
                  <Tr>
                    <Th width='20px' px={3}>
                      <Skeleton width={5} height={5} borderRadius="4px"/>
                    </Th>
                    <StyledTh width="55%" textTransform="none">
                      <Skeleton width={40} height={3} borderRadius="4px"/>
                    </StyledTh>
                    {
                      Array.from({length: 3}).map((_, index) => (
                        <StyledTh key={index}>
                          <Skeleton width={14} height={3} borderRadius="4px"/>
                        </StyledTh>
                      ))
                    }
                  </Tr>
                ) : (
                  <Tr>
                    <Th width='20px' px={3} display={isOnRemovedTab ? 'none' : undefined}>
                      <Checkbox
                        checked={allLeadSelected}
                        onChange={onSelectAllLeads}
                      />
                    </Th>
                    <StyledTh width="55%" textTransform="none">
                      {renderCount()}
                    </StyledTh>
                    <StyledTh>
                      Posts
                    </StyledTh>
                    <StyledTh>
                      Followers
                    </StyledTh>
                    <StyledTh>
                      Following
                    </StyledTh>
                  </Tr>
                )
              }
            </Thead>
            <Tbody>
              {
                isInitialLoading ? (
                  <LoadingRows/>
                ) : (
                  visibleLeads.map(lead => (
                    <StyledTr
                      key={lead.id}
                      backgroundColor={selectedLeads.includes(lead.id) ? "#E9E8FC" : "transparent"}
                      onClick={() => onSelectLead(lead.id)}
                    >
                      <StyledTd
                        px={3}
                        onClick={(e: any) => {
                          e.stopPropagation()
                        }}
                        display={isOnRemovedTab ? 'none' : undefined}
                      >
                        <Checkbox
                          isChecked={selectedLeads.includes(lead.id)}
                          onChange={() => {
                            onSelectLead(lead.id)
                          }}
                        />
                      </StyledTd>
                      <StyledTd>
                        <Flex gap={3}>
                          {lead.avatar ? <UserAvatar src={lead.avatar} alt={lead.handle}/> : <Icon size={36} name='avatar' /> }
                          <Box>
                            <Text fontSize="14px" fontWeight="500">
                              {lead.name}
                            </Text>
                            <Text fontSize="12px" lineHeight="16px" color="rgb(133, 133, 134)">
                              @{lead.handle}
                            </Text>
                          </Box>
                        </Flex>
                      </StyledTd>
                      <StyledTd>
                        {lead.posts}
                      </StyledTd>
                      <StyledTd>
                        {lead.followers}
                      </StyledTd>
                      <StyledTd>
                        {lead.following}
                      </StyledTd>
                    </StyledTr>
                  ))
                )
              }
            </Tbody>
          </Table>
        </TableContainer>
        {
          (!lQuery.isFetching && visibleLeads.length === 0) && <EmptyView isRemoved={isOnRemovedTab}/>
        }
      </Box>
    </Flex>
  );
};

const LoadingRows = () => (
  <>
    {
      Array.from({length: 5}).map((_, index) => (
        <StyledTr key={index}>
          <StyledTd px={3}>
            <Skeleton width={5} height={5} borderRadius="4px"/>
          </StyledTd>
          <StyledTd>
            <Flex gap={3}>
              <Skeleton width={9} height={9} borderRadius="18px"/>
              <Box>
                <Skeleton width={32} height={4} borderRadius="4px"/>
                <Skeleton width={20} height={4} borderRadius="4px" mt="4px"/>
              </Box>
            </Flex>
          </StyledTd>
          <StyledTd>
            <Skeleton width={8} height={4} borderRadius="4px" mt="4px"/>
          </StyledTd>
          <StyledTd>
            <Skeleton width={8} height={4} borderRadius="4px" mt="4px"/>
          </StyledTd>
          <StyledTd>
            <Skeleton width={8} height={4} borderRadius="4px" mt="4px"/>
          </StyledTd>
        </StyledTr>
      ))
    }
  </>
)

const EmptyView = ({ isRemoved = false }: { isRemoved: boolean }) => (
  <Flex direction="column" align="center" pt={6}>
    <ListEmptyIcon/>
    <Text fontSize={14} fontWeight={600} mb={2} color="gray.900">
      {isRemoved ? "No one removed yet" : "Your saved leads will show up here"}
    </Text>
    <Text fontSize={14} color="gray.600" maxW={303} textAlign="center">
      {isRemoved ? "Removed leads will show up here" : "Start adding Instagram leads to this list using Howdy Chrome extension"}
    </Text>
  </Flex>
)

