import {Box} from "@chakra-ui/react";
import {Textarea} from "@workspace/ui";
import {FC} from "react";
import {StepSectionSubtext, StepSectionTitle, StepTitle} from "../styled";
import {ExtraStepProps} from "./types";

export const ExtraStep: FC<ExtraStepProps> = ({extraPreferences, onChange}) => {
  return (
    <Box>
      <StepTitle mb="1">
        Would you like to include anything else?
      </StepTitle>
      <StepSectionTitle mb="10">Add any type of information you think is necessary to get the most accurate leads every day.</StepSectionTitle>
      <StepSectionSubtext mb="3">
        Describe in detail
      </StepSectionSubtext>
      <Textarea
        minH="184px"
        maxH="250px"
        placeholder="Add as much information as you think is needed..."
        p="4"
        fontSize="h6"
        color="gray.900"
        background="alpha.05"
        borderRadius="12px"
        resize="vertical"
        value={extraPreferences}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  );
};
