import * as Sentry from "@sentry/browser";
import {firebaseFunctions} from "@workspace/firebase-app";
import {HttpsCallableResult} from "../../services/firebase";

import {CampaignMemberData} from "./types";

export const addMembersToCampaign = async (data: {
  template: string;
  members: CampaignMemberData[];
}): Promise<HttpsCallableResult> => {
  try {
    // needs to be replaced with a real value
    const name = new Date().toString();

    return await firebaseFunctions.httpsCallable(
      "addMembersToCampaignForTwitterUsersOnCall",
    )({...data, name});
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
