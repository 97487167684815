import {Box, Container, Grid, Text, Link} from '@chakra-ui/react';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {ReactComponent as FailureImage} from '../../../assets/images/blocked-conversation-empty.svg';
import routes from '../../../router/routes';
import Layout from '../components/layout';

const InstagramIntegrationFailure: React.FC = () => {
  return (
    <Layout>
      <Grid mx="auto" h="100%" alignItems="center">
        <Box>
          <Container maxW="266px">
            <FailureImage />
          </Container>
          <Text mt="64px" textAlign="center" fontSize="xl">
            Could not integrate accounts.
            <br />
            <Link
              as={RouterLink}
              to={routes.Integrations.route}
              textDecor="underline"
            >
              Try again
            </Link>
          </Text>
        </Box>
      </Grid>
    </Layout>
  );
};

export default InstagramIntegrationFailure;
