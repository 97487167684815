import {Audience, Selling} from "@workspace/models";

// export const DISCOVERY_STEPS = { // TODO uncomment when it's time
//   selling: 0,
//   audience: 1,
//   location: 2,
//   interests: 3,
//   topUsers: 4,
//   hashtags: 5,
//   icp: 6,
//   pricing: 7,
//   extraPreferences: 8,
// } as const satisfies Record<string, number>;
export const DISCOVERY_STEPS = {
  selling: 0,
  businessType: 1,
  audience: 2,
  location: 3,
  interests: 4,
  topUsers: 5,
  hashtags: 6,
  icp: 7,
  pricing: 8,
  extraPreferences: 9,
  instagramSession: 10,
} as const satisfies Record<string, number>;
export const DISCOVERY_STEPS_COUNT = Object.keys(DISCOVERY_STEPS).length;

export const DISCOVERY_CUSTOMER_DESCRIPTIONS = {
  Coaching: {
    Professionals:
      "My services are designed to support individuals such as executives, managers, entrepreneurs, and professionals in areas like finance, marketing, and technology",
    Businesses:
      "My customers are businesses of all sizes, from small startups to large corporations across various industries, including finance, marketing, and technology, who are looking to enhance their skills, overcome challenges, and achieve their business goals.",
    "General consumers":
      "My services are meant for everyday people who are looking to improve their personal lives, gain clarity, and achieve their goals.",
    "Content creators":
      "My services are designed to support bloggers, vloggers, writers, and social media influencers looking to enhance creativity, develop effective content strategies, or improve online presence.",
  },
  "Real estate": {
    Professionals:
      "My services are designed to support professionals who are interested in real estate su as doctors, lawyers, engineers, architects, and business owners, looking to buy a new home, invest in rental properties, or sell existing property",
    Businesses:
      "My customers are businesses looking for real estate opportunities. I’m focused on small startups, established companies, or even larger corporations looking for office spaces, retail locations, or industrial properties",
    "General consumers":
      "My customers are individuals like first-time homebuyers, families looking for a new home, investors seeking rental properties, or individuals interested in buying commercial spaces. Basically, anyone who is in the market for real estate and wants to find the perfect property for their needs.",
    "Content creators":
      "My customers are content creators like YouTubers, photographers, or podcasters looking for modern apartments with beautiful views to spacious studios and unique locations.",
  },
  "Financial services": {
    Professionals:
      "My services are designed to support professionals such as doctors, lawyers, engineers, consultants, and business owners interested in investment planning, retirement strategies, tax optimization, and wealth management.",
    Businesses:
      "My customers are businesses seeking reliable financial services. I work with small startups, mid-sized companies, or a large corporations, providing bookkeeping and accounting to financial planning and analysis.",
    "General consumers":
      "My customers are everyday individuals lik students, professionals, retirees, and families looking for assistance with budgeting, saving, investing, or planning for major life events.",
    "Content creators":
      "My customers are content creators like YouTubers, photographers, or podcasters looking for budgeting and tax planning to investment guidance and revenue optimization",
  },
  Consulting: {
    Professionals:
      "My services are designed to support professionals such as executives, managers, entrepreneurs, and professionals interested in strategic advice, problem-solving support, or guidance in their specific industry",
    Businesses:
      "My customers are businesses of all sizes and industries who are seeking valuable insights and guidance to help them navigate challenges, streamline processes, and drive growth.",
    "General consumers":
      "My customers are everyday individuals like students, professionals, parents who are looking for guidance in personal finance, career development, relationship advice, or personal growth.",
    "Content creators":
      "My customers are content creators like blogger, vlogger, writer, or social media influencer looking for help with content strategy and audience engagement to monetization and brand partnerships",
  },
  "Personal training": {
    Professionals:
      "My services are designed to support professionals such as executive, manager, entrepreneur interested in strength training and cardio workouts to customized nutrition plans.",
    Businesses:
      "My customers are businesses of all sizes and industries who are seeking personalized training programs to help keep their employees fit, healthy, and motivated. I offer group and individual training options.",
    "General consumers":
      "My customers are everyday individuals like a stay-at-home parent, a student, or a retiree, who are looking for ways to lose weight, build strength, improve flexibility, or simply lead a healthier lifestyle.",
    "Content creators":
      "My customers are content creators like blogger, vlogger, writer, or social media influencer looking for strength training and cardio workouts, to improve flexibility and mindfulness exercises.",
  },
  Legal: {
    Professionals:
      "My services are designed to support professionals such as executives, managers, entrepreneurs looking for legal advice on contracts, intellectual property, employment issues, or business regulations.",
    Businesses:
      "My customers are businesses of all sizes who are seeking legal services like contract drafting and review to intellectual property protection and business compliance.",
    "General consumers":
      "My customers are everyday individuals like students, homeowners, entrepreneurs, or retirees dealing with a legal dispute, need assistance with legal documents, or simply want to understand their rights.",
    "Content creators":
      "My customers are content creators like blogger, vlogger, writer, or social media influencer looking for help with copyright and trademark registration to contract review and negotiation.",
  },
  Other: {
    Professionals:
      "My services are designed to support professionals such as executives, managers, entrepreneurs looking for legal advice on contracts, intellectual property, employment issues, or business regulations.",
    Businesses:
      "My customers are businesses of all sizes who are seeking legal services like contract drafting and review to intellectual property protection and business compliance.",
    "General consumers":
      "My customers are everyday individuals like students, homeowners, entrepreneurs, or retirees dealing with a legal dispute, need assistance with legal documents, or simply want to understand their rights.",
    "Content creators":
      "My customers are content creators like blogger, vlogger, writer, or social media influencer looking for help with copyright and trademark registration to contract review and negotiation.",
  },
} as const satisfies Record<Selling, Record<Audience, string>>;
