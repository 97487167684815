import {TWITTER_DEFAULT_PROFILE_IMG_URL} from './constants';

const defaultProfileImgUrls = new Set([TWITTER_DEFAULT_PROFILE_IMG_URL]);

/**
 * This is used for detecting an absent or a default avatar on user`s profile
 * and showing the same default avatar from Chakra UI for users from different platforms.
 */
const getAvatarSrc = (avatarUrl?: string): string | undefined => {
  if (avatarUrl && !defaultProfileImgUrls.has(avatarUrl)) {
    return avatarUrl;
  }
};

export default getAvatarSrc;
