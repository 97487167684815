import {isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import { Operation } from "./operation";
import {
  SocialNetwork,
  isSocialNetwork,
  SocialNetworkHandle,
  isSocialNetworkHandle,
} from "./SocialNetwork";
import {isTime, Time} from "./time";

export type BlockedTarget = {
  id: string;
  handle: SocialNetworkHandle;
  username: string;
  socialNetwork: SocialNetwork;
  whenCreated: Time;
  avatar?: string;
};

export type BlockTargetOperation = Operation<Omit<BlockedTarget, "whenCreated">, void>;

export const isBlockedTarget = objectTypeGuard<BlockedTarget>(
  ({id, handle, username, socialNetwork, whenCreated}) => {
    return (
      isNonEmptyString(id) &&
      isNonEmptyString(username) &&
      isSocialNetworkHandle(handle) &&
      isSocialNetwork(socialNetwork) &&
      isTime(whenCreated)
    );
  },
);

