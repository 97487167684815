import {FC} from "react";
import {Redirect} from "react-router-dom";

import {useDsmChromeExtension} from "src/hooks";
import {routes} from "src/router";

export const HomePage: FC = () => {
  const {extensionIsInstalled} = useDsmChromeExtension();

  if (extensionIsInstalled === false) return <Redirect to={routes.GetStarted.route} />;

  if (extensionIsInstalled) return <Redirect to={routes.Integrations.route} />;

  return null;
};
