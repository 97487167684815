import {chakra, IconButton, IconButtonProps, Link, LinkProps} from "@chakra-ui/react";
import React from "react";
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom";

import DsmTooltip from "./dsm-tooltip";

export const DsmInternalSolidLink: React.FC<LinkProps & RouterLinkProps> = ({
  children,
  ...props
}) => (
  <Link
    as={RouterLink}
    display="inline-block"
    py="10.5px"
    px="4"
    fontSize="baseMajor"
    fontWeight="bold"
    color="white"
    bg="primary.500"
    borderRadius="8px"
    _hover={{bg: "primary.400"}}
    {...props}
  >
    {children}
  </Link>
);

export const DsmExternalSolidLink: React.FC<LinkProps> = ({children, ...props}) => (
  <Link
    p="10.5px 16px"
    fontSize="md"
    fontWeight="medium"
    color="white"
    bg="primary.500"
    borderRadius="6px"
    _hover={{bg: "primary.400"}}
    {...props}
  >
    {children}
  </Link>
);

interface TooltipIconInternalLinkProps {
  label: string;
}

export const TooltipIconInternalLink: React.FC<
  TooltipIconInternalLinkProps & IconButtonProps & RouterLinkProps
> = ({to, label, icon, ...props}) => (
  <DsmTooltip label={label}>
    <chakra.span display="inline-block">
      <IconButton
        minW={8}
        p={0}
        w={8}
        h={8}
        to={to}
        icon={icon}
        as={RouterLink}
        bgColor="transparent"
        color="gray.500"
        _hover={{bgColor: "gray.100"}}
        _active={{bgColor: "gray.200"}}
        _focus={{
          borderColor: "gray.300",
          borderWidth: "2px",
          bgColor: "gray.200",
        }}
        {...props}
      />
    </chakra.span>
  </DsmTooltip>
);
