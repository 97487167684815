import {createSlice} from '@reduxjs/toolkit';

import {Friend} from '../friend/types';

import {MAX_SELECTED_NUMBER} from './helpers/helpers-variables';
import {bulkSendMessage} from './thunks';

export interface BulkSendState {
  selectedRecipients: Friend[];
  currentRecipient?: Friend;
  templatesById: Record<string, string>;
  template: string;
  isSending: boolean;
  isSuccessfullySent: boolean;
}

export const initialState: BulkSendState = {
  selectedRecipients: [],
  templatesById: {},
  template: '',
  isSending: false,
  isSuccessfullySent: false,
};

const bulkSendSlice = createSlice({
  name: 'bulkSend',
  initialState,
  reducers: {
    selectRecipient: (state, action) => {
      state.selectedRecipients.length < MAX_SELECTED_NUMBER
        ? state.selectedRecipients.push(action.payload)
        : alert(
            `To help keep your account in good health, you cannot select more than ${MAX_SELECTED_NUMBER} recipients to send messages to at once.`
          );
    },
    deselectRecipient: (state, action) => {
      const {id, twitterId} = action.payload;

      const index = state.selectedRecipients.findIndex(
        (recipient) => recipient.id === id
      );

      if (index !== -1) {
        state.selectedRecipients.splice(index, 1);
      }
      if (twitterId in state.templatesById) {
        delete state.templatesById[twitterId];
      }
    },
    selectMultipleRecipients: (state, action) => {
      state.selectedRecipients = action.payload;
    },
    deselectAllRecipients: (state) => {
      state.selectedRecipients = [];
    },
    setCurrentRecipient: (state, action) => {
      state.currentRecipient = action.payload;
    },
    resetCurrentRecipient: (state) => {
      delete state.currentRecipient;
    },
    updateTemplate: (state, action) => {
      state.template = action.payload;
    },
    updateTemplateById: (state, action) => {
      const {twitterUserId, template} = action.payload;

      state.templatesById[twitterUserId] = template;
    },
    removeTemplateById: (state, action) => {
      delete state.templatesById[action.payload];
    },
    resetTemplatesById: (state) => {
      state.templatesById = {};
    },
    clearBulkSendData: () => ({
      selectedRecipients: [],
      templatesById: {},
      template: '',
      isSending: false,
      isSuccessfullySent: false,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(bulkSendMessage.pending, (state) => {
        state.isSending = true;
      })
      .addCase(bulkSendMessage.fulfilled, (state) => {
        state.isSending = false;
        state.isSuccessfullySent = true;
      })
      .addCase(bulkSendMessage.rejected, (state) => {
        state.isSending = false;
      });
  },
});

export const {
  selectRecipient,
  deselectRecipient,
  selectMultipleRecipients,
  deselectAllRecipients,
  setCurrentRecipient,
  resetCurrentRecipient,
  updateTemplate,
  updateTemplateById,
  resetTemplatesById,
  clearBulkSendData,
  removeTemplateById,
} = bulkSendSlice.actions;

export const reducer = bulkSendSlice.reducer;
