import {Center, Link} from '@chakra-ui/react';
import {Heading} from "@workspace/ui";

import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import routes from '../../../router/routes';
import LogoutButton from '../../auth/components/logout-button';
import Layout from '../components/layout';

import {OutlinedBox} from 'src/components';

const FacebookIntegrationFailure: React.FC = () => {
  return (
    <Layout>
      <OutlinedBox>
        <Heading as="h1" variant="h3" color={'gray.900'} mb={6}>
          Integration failed
        </Heading>
        <Link as={RouterLink} mb={10} to={routes.Integrations.route}>
          Try again
        </Link>
      </OutlinedBox>

      <Center mt={8}>
        <LogoutButton />
      </Center>
    </Layout>
  );
};

export default FacebookIntegrationFailure;
