import {Box, chakra, Flex, Grid, GridItem} from "@chakra-ui/react";
import {colorName, Heading, HeadingProps} from "@workspace/ui";
import {FC, PropsWithChildren} from "react";
import {AuthBanner} from "src/components/AuthBanner";
import {ReactComponent as Logo} from "../../../assets/icons/logo.svg";

const StyledLogo = chakra(Logo);

/**
 * The wrapper has a minimum height of 100% to prevent vertical content overflow.
 * This should not usually happen, but at some low resolutions, when the form
 * has an error warning, the submit button can move down and be partially off-screen.
 * This wrapper is not used for the inside pages of the application.
 */

export const AuthWrapper: FC<PropsWithChildren> = ({children}) => (
  <Grid
    px={6}
    pt={18}
    py={5}
    minHeight="100%"
    justifyItems="center"
    alignItems="start"
    gridTemplateColumns="1fr minmax(auto, 450px) 1fr"
    gridTemplateRows="2fr 7fr"
    bg="#F4F4F9"
  >
    <StyledLogo gridRow="1" gridColumn="2" alignSelf="center" />
    <GridItem gridColumn="2" gridRow="2" w="100%">
      {children}
    </GridItem>
  </Grid>
);

export const AuthFormWrapper: FC<PropsWithChildren> = ({children}) => (
  <Box
    py="8"
    px="12"
    bg="white"
    borderRadius="16px"
    boxShadow="0px 30px 84px rgba(10, 9, 68, 0.08), 0px 8px 32px rgba(10, 9, 68, 0.07), 0px 3px 14px rgba(10, 9, 68, 0.03), 0px 1px 3px rgba(10, 9, 68, 0.13)"
  >
    {children}
  </Box>
);

export const NewAuthFormWrapper: FC<PropsWithChildren> = ({children}) => (
  <Flex justifyContent="center" pt="96px" bg="white" h="100%">
    <Box maxWidth="370px" flexGrow="1">
      {children}
    </Box>
  </Flex>
);

export const AuthTitle: FC<{title: string} & HeadingProps> = ({title, ...props}) => (
  <Heading mb="2" variant="h4" textAlign="center" color={colorName.gray900} {...props}>
    {title}
  </Heading>
);

export const NewAuthTitle: FC<{title: string} & HeadingProps> = ({title, ...props}) => (
  <Heading mb="2" variant="h1" fontSize="h1" color={colorName.gray900} {...props}>
    {title}
  </Heading>
);

export const StyledLabel: FC<PropsWithChildren<{htmlFor: string}>> = ({
  htmlFor,
  children,
  ...props
}) => (
  <chakra.label
    mb={2}
    htmlFor={htmlFor}
    lineHeight={1.5}
    color="gray.900"
    display="block"
    fontSize="baseMinor"
    fontWeight={600}
    {...props}
  >
    {children}
  </chakra.label>
);

export const AuthWrapperWithBanner: FC<PropsWithChildren> = ({children}) => (
  <Flex h="100%">
    <Box
      w="45%"
      h="100%"
      display={{
        base: "none",
        md: "block",
      }}
    >
      <AuthBanner />
    </Box>
    <Box flexGrow="1">
      <NewAuthFormWrapper>{children}</NewAuthFormWrapper>
    </Box>
  </Flex>
);
