import {createSlice} from '@reduxjs/toolkit';

import {
  deleteScheduledMessage,
  scheduleMessageToTwitterUser,
  setScheduledMessage,
} from './thunks';
import {ScheduledMessage} from './types';

export interface ScheduledMessagesState {
  // TODO evict for memory
  scheduledMessagesByConversationId: Record<string, ScheduledMessage[]>;
  isSchedulingMessageByConversationId: Record<string, boolean>;
  isSchedulingMessageByTwitterId: Record<string, boolean>;
}

export const initialState: ScheduledMessagesState = {
  scheduledMessagesByConversationId: {},
  isSchedulingMessageByConversationId: {},
  isSchedulingMessageByTwitterId: {},
};

const ScheduledMessages = createSlice({
  name: 'scheduledMessages',
  initialState,
  reducers: {
    addScheduledMessages: (state, action) => {
      const {conversationId, messages} = action.payload;
      state.scheduledMessagesByConversationId[conversationId] = messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setScheduledMessage.pending, (state, action) => {
        state.isSchedulingMessageByConversationId[
          action.meta.arg.conversationId
        ] = true;
      })
      .addCase(setScheduledMessage.fulfilled, (state, action) => {
        state.isSchedulingMessageByConversationId[
          action.meta.arg.conversationId
        ] = false;
      })
      .addCase(setScheduledMessage.rejected, (state, action) => {
        state.isSchedulingMessageByConversationId[
          action.meta.arg.conversationId
        ] = false;
      })
      .addCase(deleteScheduledMessage.pending, (state, action) => {
        const {conversationId, scheduledMessage} = action.meta.arg;

        state.scheduledMessagesByConversationId[conversationId] =
          state.scheduledMessagesByConversationId[conversationId].filter(
            (message) => message.id !== scheduledMessage.id
          );
      })
      .addCase(deleteScheduledMessage.rejected, (state, action) => {
        const {conversationId, scheduledMessage} = action.meta.arg;

        state.scheduledMessagesByConversationId[conversationId].push(
          scheduledMessage
        );
        state.scheduledMessagesByConversationId[conversationId].sort(
          (a: ScheduledMessage, b: ScheduledMessage) => a.sendAt - b.sendAt
        );
      })
      .addCase(scheduleMessageToTwitterUser.pending, (state, action) => {
        state.isSchedulingMessageByTwitterId[action.meta.arg.twitterUserId] =
          true;
      })
      .addCase(scheduleMessageToTwitterUser.fulfilled, (state, action) => {
        delete state.isSchedulingMessageByTwitterId[
          action.meta.arg.twitterUserId
        ];
      })
      .addCase(scheduleMessageToTwitterUser.rejected, (state, action) => {
        delete state.isSchedulingMessageByTwitterId[
          action.meta.arg.twitterUserId
        ];
      });
  },
});

export const {addScheduledMessages} = ScheduledMessages.actions;

export const reducer = ScheduledMessages.reducer;
