import * as Sentry from "@sentry/browser";
import {firebaseFunctions} from "@workspace/firebase-app";
import {Friend} from "../friend/types";
import {Message} from "../message/types";

import {
  FiltersValues,
  SearchType,
  TwitterUserWithCanDm,
  MessageSearchFilters,
  MessageSearchSortOrder,
} from "./types";

export const searchElasticsearchOnCall = async ({
  filters,
  sortOrder,
  actingUserId,
}: {
  filters: MessageSearchFilters;
  sortOrder: MessageSearchSortOrder;
  actingUserId?: string;
}): Promise<{messages: Message[]}> => {
  try {
    const response = await firebaseFunctions.httpsCallable("searchElasticsearchOnCall")({
      type: SearchType.MESSAGES,
      filters,
      sortOrder,
      actingUserId,
    });
    const messages = response.data.data.items;

    return {messages};
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const searchFriendsOnCall = async (data: {
  query: string;
  filters?: FiltersValues;
}): Promise<{
  followers: (Omit<Friend, "createdAt"> & {createdAt: string})[];
}> => {
  try {
    const response = await firebaseFunctions.httpsCallable("searchFriendsOnCall")({
      ...data,
      type: SearchType.FOLLOWERS,
    });
    const followers = response.data.data.items;

    return {followers};
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const searchTwitterUsersOnCall = async (
  username: string,
): Promise<{users: TwitterUserWithCanDm[]}> => {
  try {
    const response = await firebaseFunctions.httpsCallable("searchTwitterUsersOnCall")({
      query: username,
    });
    const users = response.data.twitterUsers;

    return {users};
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
