"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.isTestingChromeStorage = exports.isTestingCampaignMap = exports.isTestingCampaign = exports.isNewTestingCampaign = exports.isMinMaxObject = exports.isTargetType = exports.isMessageContent = exports.TARGET_TYPE = exports.MESSAGE_CONTENT = void 0;
var type_utils_1 = require("@workspace/type-utils");
var time_1 = require("./time");
exports.MESSAGE_CONTENT = ["SAME", "RANDOM"];
exports.TARGET_TYPE = ["NEW", "EXISTING", "MIX"];
var CAMPAIGN_STATUS = ["RUNNING", "PAUSED", "FINISHED", "CANCELLED"];
exports.isMessageContent = (0, type_utils_1.isLiteralType)(exports.MESSAGE_CONTENT);
exports.isTargetType = (0, type_utils_1.isLiteralType)(exports.TARGET_TYPE);
exports.isMinMaxObject = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var min = _a.min, max = _a.max;
    return (0, type_utils_1.isNonNegativeInteger)(min) && (0, type_utils_1.isNonNegativeInteger)(max) && min < max;
});
exports.isNewTestingCampaign = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var totalDms = _a.totalDms, groupSize = _a.groupSize, messageContent = _a.messageContent, targetType = _a.targetType, individualInterval = _a.individualInterval, groupInterval = _a.groupInterval;
    return ((0, type_utils_1.isNonNegativeInteger)(totalDms) &&
        (0, type_utils_1.isNonNegativeInteger)(groupSize) &&
        (0, exports.isMessageContent)(messageContent) &&
        (0, exports.isTargetType)(targetType) &&
        (0, exports.isMinMaxObject)(individualInterval) &&
        (0, exports.isMinMaxObject)(groupInterval));
});
exports.isTestingCampaign = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, whenCreated = _a.whenCreated, newCampaignFields = __rest(_a, ["id", "whenCreated"]);
    return ((0, exports.isNewTestingCampaign)(newCampaignFields) &&
        (0, type_utils_1.isNonEmptyString)(id) &&
        (0, time_1.isTime)(whenCreated));
});
exports.isTestingCampaignMap = (0, type_utils_1.objectTypeGuard)(function (obj) {
    return Object.keys(obj).every(function (testingCampaignId) {
        return (0, exports.isTestingCampaign)(obj[testingCampaignId]);
    });
});
exports.isTestingChromeStorage = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var activeCampaign = _a.activeCampaign, campaigns = _a.campaigns;
    return ((0, exports.isTestingCampaignMap)(campaigns) &&
        (activeCampaign === null ||
            activeCampaign === undefined ||
            (0, type_utils_1.isNonEmptyString)(activeCampaign)));
});
