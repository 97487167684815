import {Integrations} from '@sentry/tracing';
import {Breadcrumb, Integration} from '@sentry/types';

export const sentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()] as Integration[],
  // It is recommended to use a low value for `tracesSampleRate`,
  // see also https://docs.sentry.io/product/sentry-basics/tracing/distributed-tracing/#data-sampling
  tracesSampleRate: 0.1,
  environment: process.env.REACT_APP_ENVIRONMENT,
  /* We ignore this error in order not to exceed quotas.
   * According to this sources it can be safely ignored:
   * https://github.com/DevExpress/testcafe/issues/4857#issuecomment-598775956
   * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
   */
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  beforeBreadcrumb: (breadcrumb: Breadcrumb): Breadcrumb | null => {
    return breadcrumb.category === 'console' && breadcrumb.level === 'log'
      ? null
      : breadcrumb;
  },
};
