import {defineStyleConfig, Textarea as ChakraTextarea, TextareaProps as ChakraTextareaProps,} from "@chakra-ui/react";
import {forwardRef} from "react";
import ReactTextAreaAutosize, {TextareaAutosizeProps} from "react-textarea-autosize";

export const textareaStyleConfig = defineStyleConfig({
  baseStyle: {
    field: {
      _disabled: {
        backgroundColor: "gray.50",
        border: "1px solid rgba(11, 13, 14, 0.1)",
      },
      _placeholder: {
        fontSize: "14px",
        color: "gray.400",
      },
    },
  },
});

export type TextareaProps = ChakraTextareaProps & TextareaAutosizeProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    return (
      <ChakraTextarea
        ref={ref}
        as={ReactTextAreaAutosize}
        // Explain: To prevent Platform-wide native keyboard shortcuts from triggering on typing.
        onKeyDown={(evt) => evt.stopPropagation()}
        onKeyUp={(evt) => evt.stopPropagation()}
        onKeyPress={(evt) => evt.stopPropagation()}
        focusBorderColor="primary.300"
        errorBorderColor="red.400"
        borderRadius="8px"
        fontSize="14px"
        resize="none"
        {...props}
      />
    );
  },
);

Textarea.displayName = "TextArea";
