import {useContext, useEffect, useState} from "react";
import {DiscoveryDataContext} from "@workspace/react";
import {DiscoveryUIStages} from "@workspace/models";

import {GeneralLoading} from "../../components";
import {Redirect} from "react-router";
import {CampaignStarted} from "./CampaignStarted";
import {WelcomeSteps} from "./WelcomeSteps";
import {NextStep} from "./NextStep";
import {PendingScreenAnimation} from "./PendingScreenAnimation";
import InnerAppLayout from "src/components/inner-app-layout";
import LoggedOut from "./LoggedOut";

export const Discovery = () => {
  const [showCampaignStarted, setShowCampaignStarted] = useState(false);
  const [showIdleScreen, setShowIdleScreen] = useState(false);
  const [showPendingScreen, setShowPendingScreen] = useState(false);

  const {isFetchingDiscoveryData, discoveryData, fetchDiscoveryData} =
    useContext(DiscoveryDataContext);

  useEffect(() => {
    if (!discoveryData) {
      fetchDiscoveryData();
    }
  }, [discoveryData, fetchDiscoveryData]);

  if (isFetchingDiscoveryData) {
    return (
      <InnerAppLayout hasBasicHeader showTrialInfo>
        <GeneralLoading />
      </InnerAppLayout>
    );
  }

  if (!discoveryData) return <Redirect to="/" />;

  if (showCampaignStarted) {
    return <CampaignStarted />;
  }

  if (showIdleScreen || showPendingScreen) {
    return <PendingScreenAnimation isIdle={showIdleScreen} />;
  }

  switch (discoveryData.status) {
    case DiscoveryUIStages.NOT_CREATED:
      return <WelcomeSteps onComplete={() => setShowIdleScreen(true)} />;

    case DiscoveryUIStages.COLLECTING:
    case DiscoveryUIStages.CREATED_FOR_TODAY:
      return <PendingScreenAnimation />;

    case DiscoveryUIStages.IDLE:
      return <PendingScreenAnimation isIdle />;

    case DiscoveryUIStages.IS_LOGGED_OUT:
      return <LoggedOut onRefreshSession={() => setShowPendingScreen(true)} />;

    case DiscoveryUIStages.CAN_CREATE_CAMPAIGN:
      return (
        <NextStep
          discoveryData={discoveryData}
          onCampaignStarted={() => setShowCampaignStarted(true)}
        />
      );
  }

  return (
    <div>
      <h1>Discovery</h1>
    </div>
  );
};
