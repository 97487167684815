"use strict";
exports.__esModule = true;
exports.isBlockTargetInput = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isBlockTargetInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, socialNetwork = _a.socialNetwork, username = _a.username, handle = _a.handle;
    return ((0, type_utils_1.isNonEmptyString)(id) &&
        (0, type_utils_1.isFiniteString)(username) &&
        (0, models_1.isSocialNetworkHandle)(handle) &&
        (0, models_1.isSocialNetwork)(socialNetwork));
});
