import {extendTheme} from "@chakra-ui/react";
import {theme as baseTheme} from "@workspace/ui";

const theme = extendTheme({
  colors: {
    ...baseTheme["colors"],
    yellow: {
      200: "#FFE8A6",
      300: "#FFD04E",
      500: "#D69E2E",
    },
    secondary: {
      500: "#FF5050",
    },
    teal: {
      400: "#38B2AC",
    },
    lightBlue: "#F1F3FF",
    highlight: "#D69E2E", // yellow
  },
  fonts: {
    heading: "Circular, Arial",
    body: "Circular, Arial",
  },
  fontSizes: {
    ...baseTheme["fontSizes"],
    xxs: "10px",
    xs: "12px",
    sm: "13px",
    baseMinor: "14px",
    base: "15px",
    baseMajor: "16px",
    md: "18px",
    lg: "20px",
    xl: "24px",
    xxl: "32px",
    xxxl: "36px",
    "4xl": "40px",
  },
  lineHeights: baseTheme["lineHeights"],
  letterSpacings: baseTheme["letterSpacings"],
  styles: {
    global: {
      "html, body": {
        height: "100%",
      },
      "#root": {
        height: "100%",
        WebkitFontSmoothing: "antialiased",
      },

      // Styles for Tagify's dropdown.
      // Added globally because Tagify creates dropdown directly on body
      ".tagify__dropdown__wrapper": {
        border: "1px solid var(--chakra-colors-gray-200) !important",
        borderRadius: "var(--chakra-radii-md) !important",
        boxShadow: "var(--chakra-shadows-sm)",
      },
      ".tagify__dropdown__item": {
        color: "var(--chakra-colors-gray-500)",
        fontSize: "var(--chakra-fontSizes-sm)",
        padding: "10px 0.8rem",
        fontWeight: "400",
      },
      ".tagify__dropdown__item--active": {
        background: "var(--chakra-colors-gray-50)",
        color: "var(--chakra-colors-gray-600)",
      },
    },
  },
  components: baseTheme["components"],
});

export default theme;
