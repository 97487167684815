import {Box, Button, Text} from '@chakra-ui/react';
import React from 'react';

interface AutoresponderOnToastProps {
  disabled: boolean;
  turnAutoresponderOffHandler: () => void;
}

const AutoresponderOnToast: React.FC<AutoresponderOnToastProps> = ({
  disabled,
  turnAutoresponderOffHandler,
}) => (
  <Box
    px={4}
    py={3}
    mx={3}
    w="340px"
    border="1px"
    borderColor="gray.200"
    color="gray.700"
    textAlign="center"
    borderRadius={4}
    fontSize="baseMinor"
    fontWeight="medium"
    bgColor="orange.500"
  >
    <Text fontSize="baseMajor" fontWeight="bold">
      Autoresponder ON
    </Text>
    <Button
      fontSize="baseMinor"
      height="auto"
      bgColor="transparent"
      disabled={disabled}
      _hover={{bgColor: 'transparent'}}
      _focus={{outline: 'none'}}
      onClick={turnAutoresponderOffHandler}
    >
      Click to turn off
    </Button>
  </Box>
);

export default AutoresponderOnToast;
