"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.isMonitorResponses = exports.isMonitorResponse = exports.monitoringEndpoint = exports.monitoringEndpoints = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.monitoringEndpoints = [
    'FOLLOWING',
    'FOLLOWERS',
    'LIKERS',
    'COMMENTERS',
    'HASHTAG',
    'LOCATION'
];
exports.monitoringEndpoint = exports.monitoringEndpoints.reduce(function (endpoints, endpoint) {
    var _a;
    return __assign(__assign({}, endpoints), (_a = {}, _a[endpoint] = endpoint, _a));
}, {});
exports.isMonitorResponse = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var status = _a.status, errorMessage = _a.errorMessage, endpointType = _a.endpointType;
    return (['SUCCESS', 'ERROR', 'INVALID_TYPE'].includes(status) &&
        (status === 'ERROR' ? errorMessage !== undefined : true) &&
        exports.monitoringEndpoints.includes(endpointType));
});
exports.isMonitorResponses = (0, type_utils_1.arrayTypeGuard)(exports.isMonitorResponse);
