import * as Sentry from "@sentry/browser";
import {firebaseFunctions} from "@workspace/firebase-app";
import {HttpsCallableResult} from "../../services/firebase";
import {Bucket} from "../conversation/types";

export const markFriendBucketOnCall = ({
  follower,
  bucket,
}: {
  follower: string;
  bucket: Bucket;
}): Promise<string> => {
  return new Promise((res) => {
    setTimeout(() => res(`Successfully marked ${follower} as ${bucket}`), 2000);
  });
};

export const addTagsToFriendOnCall = async (data: {
  friendId: string;
  tags: string[];
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("addTagsToFriendOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const removeTagsFromFriendOnCall = async (data: {
  friendId: string;
  tags: string[];
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("removeTagsFromFriendOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
