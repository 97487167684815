import {NonEmptyString, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {v4 as uuidv4} from "uuid";

export type Item = {
  id: NonEmptyString;
};
export const isItem = objectTypeGuard<Item>(({id}) => isNonEmptyString(id));

export const createId = uuidv4;

export const newItem = (): Item => ({
  id: createId(),
});
