import {chakra, HStack, Tag} from '@chakra-ui/react';
import React from 'react';
import {Link} from 'react-router-dom';

import routes from '../../router/routes';

export enum NavbarRoute {
  Inbox,
  Followers,
  Analytics,
}

interface NavMenuProps {
  activeRoute?: NavbarRoute;
}

interface MenuItemProps {
  isActive: boolean;
  to: string;
  title: string | React.ReactNode;
}

const StyledLink = chakra(Link);

const MenuItem: React.FC<MenuItemProps> = ({isActive, to, title}) => (
  <chakra.li fontSize="baseMinor" fontWeight="400">
    <StyledLink
      to={to}
      pb="2px"
      borderBottomWidth="1px"
      borderBottomColor={isActive ? 'black' : 'transparent'}
      _hover={{borderBottomColor: 'black'}}
    >
      {title}
    </StyledLink>
  </chakra.li>
);

const NavMenu: React.FC<NavMenuProps> = ({activeRoute}) => (
  <HStack as="ul" spacing={7} listStyleType="none">
    <MenuItem
      to={routes.Conversations.route}
      isActive={activeRoute === NavbarRoute.Inbox}
      title="Inbox"
    />
    <MenuItem
      to={routes.Followers.route}
      isActive={activeRoute === NavbarRoute.Followers}
      title="Community"
    />
    <MenuItem
      to={routes.Analytics.route}
      isActive={activeRoute === NavbarRoute.Analytics}
      title={
        <span>
          Analytics
          <Tag
            size="sm"
            ml={1}
            borderRadius="full"
            variant="solid"
            colorScheme="yellow"
          >
            New
          </Tag>
        </span>
      }
    />
  </HStack>
);

export default NavMenu;
