import React, {useState} from 'react';
import {Box, Flex, Text, Tooltip} from "@chakra-ui/react";
import {Button, Field, Input, Select} from "@workspace/ui";
import {RangeInput} from "./range-input";
import {FetchUserLeadsFilters, ListRangeFilter} from "@workspace/firebase-functions/@workspace/firebase-datamodel";
import {lastPostDate} from "@workspace/firebase-functions/@workspace/models";
import {isEqual} from "lodash-es";

const lastPostDateOptions = [
  {
    label: "Last 14 days",
    value: lastPostDate.last14
  },
  {
    label: "Last 30 days",
    value: lastPostDate.last30
  },
  {
    label: "Last 90 days",
    value: lastPostDate.last90
  },
  {
    label: "Last 1 year",
    value: lastPostDate["1year"]
  },
  {
    label: "Last 5 years",
    value: lastPostDate["5years"]
  },
  {
    label: "Unlimited",
    value: lastPostDate["unlimited"]
  },
]

const initialFilterData = {
  lastPostDate: undefined,
  keyword: ""
};

type Props = {
  isFetchingNextPage: boolean;
  handleApplyFilters: (filters: FetchUserLeadsFilters) => void;
  activeFilters: FetchUserLeadsFilters;
  listIsIncomplete: boolean;
}

const LeadsFilters = ({isFetchingNextPage, handleApplyFilters, activeFilters, listIsIncomplete}: Props) => {
  const [filters, setFilters] = useState<FetchUserLeadsFilters>(initialFilterData);

  const onChangeRangeInput = (parent: 'followers' | 'following' | 'posts') => (key: 'min' | 'max', value: string) => {
    const currentValue = filters[parent] || {};

    setFilters({
      ...filters,
      [parent]: {
        ...(currentValue as ListRangeFilter),
        [key]: isNaN(parseInt(value)) ? "" : parseInt(value)
      }
    })
  }

  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      keyword: e.target.value
    })
  }


  const filtersApplied = isEqual(activeFilters, filters);

  const onClickReset = () => {
    setFilters(initialFilterData)
    handleApplyFilters(initialFilterData)
  }

  return (
    <Box flexBasis='21%' flexShrink={0} pb={10}>
      <Flex align="center" mb={6} justify="space-between">
        <Text fontSize="20px" fontWeight="600" color="gray.900">
          Filters
        </Text>
        <Button variant="ghost" onClick={onClickReset}>
          Reset
        </Button>
      </Flex>
      <Box display="grid" rowGap={6}>
        <Tooltip hasArrow isDisabled={!listIsIncomplete} label='Keywords are inactive during list processing. Please try again when the list is fully processed.'>
          <div>
            <Field label='Keywords' smallLabel>
              <Input
                placeholder='e.g. Personal development'
                value={filters.keyword}
                onChange={onChangeKeyword}
                isDisabled={listIsIncomplete}
              />
            </Field>
          </div>
        </Tooltip>
        <RangeInput
          title="Followers"
          range={filters.followers}
          onChange={onChangeRangeInput('followers')}
        />
        <RangeInput
          title="Following"
          range={filters.following}
          onChange={onChangeRangeInput('following')}
        />
        <RangeInput
          title="Posts"
          range={filters.posts}
          onChange={onChangeRangeInput('posts')}
        />
        <Tooltip hasArrow isDisabled={!listIsIncomplete} label='Last post date is inactive during list processing. Please try again when the list is fully processed.'>
          <div>
            <Field label='Last post date' smallLabel>
              <Select
                value={filters.lastPostDate || ""}
                options={lastPostDateOptions}
                // @ts-ignore
                onChange={newValue => setFilters({...filters, lastPostDate: newValue.value})}
                size='md'
                isDisabled={listIsIncomplete}
              />
            </Field>
          </div>
        </Tooltip>
        <Button
          variant="primary"
          onClick={() => handleApplyFilters(filters)}
          disabled={isFetchingNextPage || filtersApplied}
        >
          {filtersApplied ? 'Applied' : 'Apply'}
        </Button>
      </Box>
    </Box>
  );
};

export default LeadsFilters;
