import {Avatar, Flex, Link} from '@chakra-ui/react';
import React from 'react';

import DsmCheckbox from '../../../components/dsm-checkbox';
import getAvatarSrc from '../../conversation/helpers/get-avatar-src';
import {buildTwitterProfileLink} from '../../conversation/helpers/profile-link-helpers';
import {CanDm, Friend} from '../types';

import FollowerInfo from './follower-info';
import FollowerToolbar from './toolbar/follower-toolbar';

interface FollowerRowProps {
  follower: Friend;
  isSelectable?: boolean;
  isSelected?: boolean;
  showMessageButton?: boolean;
  isBlocked?: boolean;
  selectionHandler?: (checked: boolean, follower: Friend) => void;
}

const FollowerRow: React.FC<FollowerRowProps> = ({
  follower,
  isSelectable,
  isSelected,
  showMessageButton,
  selectionHandler,
}) => {
  const twitterProfileLink = follower.twitterId
    ? buildTwitterProfileLink(follower.twitterId)
    : '';

  const checkboxChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    selectionHandler && selectionHandler(e.target.checked, follower);

  return (
    <Flex
      py={4}
      px={{base: 2, md: 4}}
      mb={2}
      borderWidth="1px"
      borderBottom="1px"
      borderColor="gray.200"
      borderRadius="8px"
      flexWrap={{base: 'wrap', md: 'unset'}}
      justify="space-between"
    >
      <Flex>
        <Flex
          h="100%"
          mr={2}
          alignSelf="flex-start"
          flexDir="column"
          alignItems="center"
        >
          <Link
            mb={{base: '85px', md: 4}}
            target="_blank"
            href={twitterProfileLink}
            title="Go to Twitter profile"
            _focus={{
              outline: 'none',
            }}
          >
            <Avatar size="md" src={getAvatarSrc(follower.profileImageUrl)} />
          </Link>
          {isSelectable && (
            <Flex>
              <DsmCheckbox
                colorScheme="primary"
                isDisabled={follower.canDm !== CanDm.TRUE}
                isChecked={isSelected}
                onChange={checkboxChangeHandler}
              />
            </Flex>
          )}
        </Flex>
        <FollowerInfo
          showMessageButton={showMessageButton}
          follower={follower}
        />
      </Flex>
      <Flex display={{base: 'none', md: 'flex'}}>
        <FollowerToolbar
          follower={follower}
          showMessageButton={showMessageButton}
        />
      </Flex>
    </Flex>
  );
};

export default FollowerRow;
