import {FirebaseUserInfo} from "@workspace/firebase-datamodel";
import {Time} from "./time";
import {NonEmptyString, NonNegativeInteger} from "@workspace/type-utils";

export type List = {
  userId: FirebaseUserInfo["uid"];
  whenCreated: Time;
  name: NonEmptyString;
  slug: NonEmptyString;
  id: NonEmptyString;
  totalLeads: NonNegativeInteger;
  incompleteLeads: NonNegativeInteger;
}

export type NewLead = {
  posts: NonNegativeInteger;
  followers: NonNegativeInteger;
  following: NonNegativeInteger;
  avatar: string;
  bio: string;
  id: NonEmptyString;
  handle: NonEmptyString;
  name: string;
}

export type Lead = NewLead & {
  lastPostDate: Time | null;
  whenUpdated: Time;
  keywordString: string;
  isProcessed: boolean;
}

export const lastPostDates = [
  'last14',
  'last30',
  'last90',
  '1year',
  '5years',
  'unlimited'
] as const;

export type LastPostDate = typeof lastPostDates[number];

export const lastPostDate = lastPostDates.reduce((acc, key) => ({...acc, [key]: key}), {} as Record<LastPostDate, LastPostDate>);

export type ListCampaign = {
  targets: Lead[];
  listId: string;
  userId: NonEmptyString;
  id: NonEmptyString;
}

/**
 * Number of leads processed per processing run
 * */
export const LEADS_PROCESSED_AT_ONCE = 20;

/**
 * Time between processing runs
 * */
export const LEAD_PROCESSING_INTERVAL_IN_MINUTES = 20;
