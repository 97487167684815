"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.isDeleteDiscoveryTargetOutput = exports.isDeleteDiscoveryTargetInput = exports.isSaveDiscoveryReportInput = exports.isUpdateDiscoverySessionInput = exports.isDescribeInterestFromOpenAIInput = exports.isDiscoveryData = exports.isCreateCampaignFromDiscoveryInput = exports.isSaveActiveDiscoveryTabInput = exports.isDiscoveryQueue = exports.isSaveDiscoveryTargetsInput = exports.isDiscoveryTargets = exports.isDiscoveryTarget = exports.isSaveDiscoveryPostsInput = exports.isNextDiscoveryActionOutput = exports.isDiscovery = exports.isCreateDiscoveryInput = exports.isDiscoveryPost = exports.isDiscoveryStage = exports.isBusinessType = exports.isPricingLiteral = exports.isAudienceLiteral = exports.isSellingLiteral = exports.isArrayOfStrings = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isArrayOfStrings = (0, type_utils_1.arrayTypeGuard)(type_utils_1.isNonEmptyString);
exports.isSellingLiteral = (0, type_utils_1.isLiteralType)(models_1.SELLINGS);
exports.isAudienceLiteral = (0, type_utils_1.isLiteralType)(models_1.AUDIENCES);
exports.isPricingLiteral = (0, type_utils_1.isLiteralType)(models_1.PRICINGS);
exports.isBusinessType = (0, type_utils_1.isLiteralType)(models_1.BUSINESS_TYPE);
exports.isDiscoveryStage = (0, type_utils_1.isLiteralObjectType)(models_1.DiscoveryStages);
exports.isDiscoveryPost = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var link = _a.link;
    return (0, type_utils_1.isNonEmptyString)(link);
});
var isDiscoveryPosts = (0, type_utils_1.arrayTypeGuard)(exports.isDiscoveryPost);
exports.isCreateDiscoveryInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var selling = _a.selling, descriptionOfMainClients = _a.descriptionOfMainClients, clientInterests = _a.clientInterests, clientLocation = _a.clientLocation, ICPExamples = _a.ICPExamples, audience = _a.audience, topInstagramPages = _a.topInstagramPages, pricing = _a.pricing, instagramSession = _a.instagramSession;
    return (0, exports.isSellingLiteral)(selling) &&
        (0, exports.isAudienceLiteral)(audience) &&
        (0, type_utils_1.isNonEmptyString)(descriptionOfMainClients) &&
        (0, type_utils_1.isNonEmptyString)(clientInterests) &&
        (0, type_utils_1.isNonEmptyString)(instagramSession) &&
        (0, exports.isArrayOfStrings)(clientLocation) &&
        (0, exports.isPricingLiteral)(pricing) &&
        (0, exports.isArrayOfStrings)(ICPExamples) &&
        (0, exports.isArrayOfStrings)(topInstagramPages);
});
exports.isDiscovery = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var userId = _a.userId, selling = _a.selling, audience = _a.audience, descriptionOfMainClients = _a.descriptionOfMainClients, clientLocation = _a.clientLocation, clientInterests = _a.clientInterests, ICPExamples = _a.ICPExamples, topInstagramPages = _a.topInstagramPages, pricing = _a.pricing, generatedPosts = _a.generatedPosts, activePost = _a.activePost, activePage = _a.activePage, currentStage = _a.currentStage;
    return (0, type_utils_1.isNonEmptyString)(userId) &&
        (0, exports.isSellingLiteral)(selling) &&
        (0, exports.isAudienceLiteral)(audience) &&
        (0, type_utils_1.isNonEmptyString)(descriptionOfMainClients) &&
        (0, exports.isArrayOfStrings)(clientLocation) &&
        (0, type_utils_1.isNonEmptyString)(clientInterests) &&
        (0, exports.isArrayOfStrings)(ICPExamples) &&
        (0, exports.isArrayOfStrings)(topInstagramPages) &&
        (0, exports.isPricingLiteral)(pricing) &&
        isDiscoveryPosts(generatedPosts) &&
        typeof activePost === "number" &&
        typeof activePage === "number" &&
        (0, exports.isDiscoveryStage)(currentStage);
});
exports.isNextDiscoveryActionOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var link = _a.link, stage = _a.stage, discoveryId = _a.discoveryId;
    return (0, type_utils_1.isNonEmptyString)(link) && (0, type_utils_1.isNonEmptyString)(stage) && (0, type_utils_1.isNonEmptyString)(discoveryId);
});
exports.isSaveDiscoveryPostsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var posts = _a.posts;
    return isDiscoveryPosts(posts);
});
exports.isDiscoveryTarget = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var followers = _a.followers, following = _a.following, posts = _a.posts, comment = _a.comment, id = _a.id, rest = __rest(_a, ["followers", "following", "posts", "comment", "id"]);
    return (0, models_1.isSocialNetworkUser)(rest) &&
        (0, type_utils_1.isNonEmptyString)(followers) &&
        (0, type_utils_1.isNonEmptyString)(following) &&
        (0, type_utils_1.isNonEmptyString)(posts) &&
        (0, type_utils_1.isNonEmptyString)(comment) &&
        (0, type_utils_1.isNonEmptyString)(id);
});
exports.isDiscoveryTargets = (0, type_utils_1.arrayTypeGuard)(exports.isDiscoveryTarget);
exports.isSaveDiscoveryTargetsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var targets = _a.targets, postUrl = _a.postUrl, activePost = _a.activePost;
    return ((0, type_utils_1.isNonEmptyString)(postUrl) &&
        (0, exports.isDiscoveryTargets)(targets) &&
        (0, type_utils_1.isNonNegativeInteger)(activePost));
});
exports.isDiscoveryQueue = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var activeSchedulerId = _a.activeSchedulerId, whenUpdated = _a.whenUpdated;
    return (0, models_1.isUpdateTime)({ whenUpdated: whenUpdated }) &&
        (activeSchedulerId === undefined ? true : (0, type_utils_1.isNonEmptyString)(activeSchedulerId));
});
exports.isSaveActiveDiscoveryTabInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var schedulerId = _a.schedulerId;
    return (0, type_utils_1.isNonEmptyString)(schedulerId);
});
exports.isCreateCampaignFromDiscoveryInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var messages = _a.messages, instagramHandle = _a.instagramHandle, targetIds = _a.targetIds, discoveryId = _a.discoveryId;
    return (0, models_1.isCampaignMessages)(messages) &&
        (0, type_utils_1.isNonEmptyString)(instagramHandle) &&
        (0, exports.isArrayOfStrings)(targetIds) &&
        (0, type_utils_1.isNonEmptyString)(discoveryId);
});
exports.isDiscoveryData = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var status = _a.status, targets = _a.targets, discoveryId = _a.discoveryId;
    return (targets === undefined || (0, exports.isDiscoveryTargets)(targets)) &&
        (0, type_utils_1.isNonEmptyString)(status) &&
        (discoveryId === undefined || (0, type_utils_1.isNonEmptyString)(discoveryId));
});
exports.isDescribeInterestFromOpenAIInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var selling = _a.selling, audience = _a.audience;
    return (0, exports.isSellingLiteral)(selling) && (0, exports.isAudienceLiteral)(audience);
});
exports.isUpdateDiscoverySessionInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var instagramSession = _a.instagramSession;
    return (0, type_utils_1.isNonEmptyString)(instagramSession);
});
exports.isSaveDiscoveryReportInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var currentCollectedCount = _a.currentCollectedCount, timestamp = _a.timestamp, errors = _a.errors, targetsCollected = _a.targetsCollected, action = _a.action;
    return ((0, type_utils_1.isNonNegativeInteger)(currentCollectedCount) &&
        (0, type_utils_1.isNonNegativeInteger)(timestamp) &&
        (0, exports.isArrayOfStrings)(errors) &&
        (0, type_utils_1.isNonNegativeInteger)(targetsCollected) &&
        (0, exports.isDiscoveryStage)(action));
});
exports.isDeleteDiscoveryTargetInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var discoveryId = _a.discoveryId, targetId = _a.targetId, startAfter = _a.startAfter;
    return ((0, type_utils_1.isNonEmptyString)(discoveryId) &&
        (0, type_utils_1.isNonEmptyString)(targetId) &&
        (0, type_utils_1.isNonEmptyString)(startAfter));
});
exports.isDeleteDiscoveryTargetOutput = exports.isDiscoveryTarget;
