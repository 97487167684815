import {Box, Flex} from "@chakra-ui/react";
import {PRICINGS} from "@workspace/models";
import {FC} from "react";
import {StepTitle} from "../styled";
import {PRICING_LABELS, PricingStepProps} from "./types";
import {CircularButton} from "../CircularButton";

export const PricingStep: FC<PricingStepProps> = ({selectedPricing, onChange}) => {
  return (
    <Box>
      <StepTitle mb="10">What is your current offer?</StepTitle>
      <Flex gap="3" flexWrap="wrap">
        {PRICINGS.map((offer) => {
          const isSelected = selectedPricing === offer;

          return (
            <CircularButton
              key={offer}
              isSelected={isSelected}
              onClick={() => onChange(isSelected ? null : offer)}
            >
              {PRICING_LABELS[offer]}
            </CircularButton>
          );
        })}
      </Flex>
    </Box>
  );
};
