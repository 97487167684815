import {Box, Flex, Text} from "@chakra-ui/react";
import {Icon, Input} from "@workspace/ui";
import {ChangeEvent, FC, KeyboardEvent, useCallback, useState} from "react";
import {StepSectionSubtext, StepTitle} from "../styled";
import {HashtagsStepProps} from "./types";

export const HashtagsStep: FC<HashtagsStepProps> = ({selectedHashtags, onChange}) => {
  const [inputValue, setInputValue] = useState("");

  const handleRemoveHashtag = (hashtag: string) => {
    onChange(selectedHashtags.filter((hash) => hash !== hashtag));
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code !== "Enter" || !inputValue) return;

      const newHashTag = inputValue.startsWith("#") ? inputValue : `#${inputValue}`;

      if(selectedHashtags.includes(newHashTag)) return;

      onChange([
        ...selectedHashtags,
        newHashTag
        ,
      ]);

      setInputValue('');
    },
    [inputValue, selectedHashtags, onChange],
  );

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.trim());
  }, []);

  return (
    <Box>
      <StepTitle mb="10">What hashtags do your prospects engage with?</StepTitle>
      <StepSectionSubtext>
        Add hashtags
      </StepSectionSubtext>
      <Box mb="4">
        <Input
          h="44px"
          placeholder="e.g. #personaltrainer"
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
        />
      </Box>
      <Flex direction="column" gap="2" flexWrap="wrap">
        {selectedHashtags.map((hashtag) => (
          <Flex bg="alpha.05" px="4" py="2.5" key={hashtag} alignItems="center" gap="2" fontWeight="400" color="gray.900">
            <Icon name="hashtag" size={20}/>
            <Text mr={3} fontSize="14px" fontWeight="500" color="gray.900">
              {hashtag.replaceAll("#", '')}
            </Text>
            <Box
              color="alpha.50"
              cursor="pointer"
              onClick={() => handleRemoveHashtag(hashtag)}
              ml="auto"
            >
              <Icon name="x" size="16" />
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
