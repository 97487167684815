import {createSlice} from '@reduxjs/toolkit';

import {User} from '../user/types';

export interface AdminState {
  usersList: User[];
}

export const initialState: AdminState = {
  usersList: [],
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
  },
});

export const {setUsersList} = adminSlice.actions;

export const reducer = adminSlice.reducer;
