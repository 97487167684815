import {useMemo} from "react";
import {
  allPricingPlans,
  BillingFrequency,
  isMonthlyPricingPlan,
  isPricingPlanName,
  isYearlyPricingPlan,
  monthlyPricingPlans,
  monthlyToYearlyPlans,
  PricingPlanDetails,
  PricingPlanName,
  yearlyPricingPlans,
  yearlyToMonthlyPlans
} from "@workspace/firebase-datamodel";

type UsePlanDetailsProps = {
  currentPlanName: PricingPlanName | null | undefined;
  selectedPlanName: PricingPlanName | undefined;
  billingFrequency: BillingFrequency;
}

export const usePlanDetails = (
  {currentPlanName, selectedPlanName, billingFrequency}: UsePlanDetailsProps
) => {
  const currentPlanDetails = useMemo<PricingPlanDetails | undefined>(() => {
    if (!isPricingPlanName(currentPlanName)) return;
    return allPricingPlans[currentPlanName];
  }, [currentPlanName]);

  const monthlyPlan = useMemo<PricingPlanDetails | undefined>(() => {
    if (isMonthlyPricingPlan(selectedPlanName)) {
      return monthlyPricingPlans[selectedPlanName];
    }
    if (isYearlyPricingPlan(selectedPlanName)) {
      const planName = yearlyToMonthlyPlans[selectedPlanName];
      if (planName) return monthlyPricingPlans[planName];
    }
  }, [selectedPlanName]);

  const yearlyPlan = useMemo<PricingPlanDetails | undefined>(() => {
    if (isYearlyPricingPlan(selectedPlanName)) {
      return yearlyPricingPlans[selectedPlanName];
    }
    if (isMonthlyPricingPlan(selectedPlanName)) {
      const planName = monthlyToYearlyPlans[selectedPlanName];
      if (planName) return yearlyPricingPlans[planName];
    }
  }, [selectedPlanName]);

  const selectedPlanDetails = useMemo<PricingPlanDetails | undefined>(() => {
    if (billingFrequency === "monthly" && monthlyPlan) return monthlyPlan;
    if (billingFrequency === "yearly" && yearlyPlan) return yearlyPlan;
  }, [monthlyPlan, yearlyPlan, billingFrequency]);

  return {
    currentPlanDetails,
    selectedPlanDetails,
    monthlyPlan,
    yearlyPlan,
  }
}
