"use strict";
exports.__esModule = true;
exports.isBlockedTarget = void 0;
var type_utils_1 = require("@workspace/type-utils");
var SocialNetwork_1 = require("./SocialNetwork");
var time_1 = require("./time");
exports.isBlockedTarget = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, handle = _a.handle, username = _a.username, socialNetwork = _a.socialNetwork, whenCreated = _a.whenCreated;
    return ((0, type_utils_1.isNonEmptyString)(id) &&
        (0, type_utils_1.isNonEmptyString)(username) &&
        (0, SocialNetwork_1.isSocialNetworkHandle)(handle) &&
        (0, SocialNetwork_1.isSocialNetwork)(socialNetwork) &&
        (0, time_1.isTime)(whenCreated));
});
