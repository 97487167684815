import {NonEmptyString, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";

// TODO data in users collection
// need to add some fields, email should be always defined.
export type User = {
  email?: NonEmptyString;
  firstName?: string;
  lastName?: string;
};

export const isUser = objectTypeGuard<User>(({email}) =>
  email === undefined ? true : isNonEmptyString(email),
);
