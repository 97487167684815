import {Operation, SocialNetworkUser} from "@workspace/models";
import {isNonNegativeInteger, objectTypeGuard} from "@workspace/type-utils";

export type GenerateTestingTargets = Operation<{count: number}, Array<SocialNetworkUser>>;

export const isGenerateTestingTargetsInput = objectTypeGuard<
  GenerateTestingTargets["in"]
>(({count}) => isNonNegativeInteger(count));

export type GenerateTestingMessagesOperation = Operation<{count: number}, Array<string>>;

export const isGenerateTestingMessagesInput = objectTypeGuard<
  GenerateTestingMessagesOperation["in"]
>(({count}) => isNonNegativeInteger(count));
