import {Friend} from '../../friend/types';
import {CampaignMemberData} from '../types';

// this function should be updated for using it with Instagram integration
const prepareMembersDataForBulkSend = (
  selectedRecipients: Friend[],
  templatesById: Record<string, string>
): CampaignMemberData[] =>
  selectedRecipients.map((recipient) => {
    const member: CampaignMemberData = {
      twitterUserId: recipient.twitterId as string,
    };

    if ((recipient.twitterId as string) in templatesById) {
      member.template = templatesById[recipient.twitterId as string];
    }

    return member;
  });

export default prepareMembersDataForBulkSend;
