import {useCallback, useEffect, useMemo, useRef} from "react";
import {useLocation} from "react-router";

const LANDING_FLOW_FREQUENCY_STORAGE_KEY = "landingFlowFrequency";
const LANDING_FLOW_MESSAGES_PER_DAY_STORAGE_KEY = "landingFlowMessagesPerDay";

export const useLandingFlowData = () => {
  const location = useLocation();
  const isRemovedRef = useRef<boolean>(false);

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const messagesPerDayQueryParam = useMemo(() => queryParams.get("plan"), [queryParams]);

  const billingFrequencyQueryParam = useMemo(
    () => queryParams.get("interval"),
    [queryParams],
  );

  const messagesPerDay = useMemo(() => {
    if (isRemovedRef.current) return null;

    return (
      messagesPerDayQueryParam ??
      localStorage.getItem(LANDING_FLOW_MESSAGES_PER_DAY_STORAGE_KEY)
    );
  }, [messagesPerDayQueryParam]);

  const billingFrequency = useMemo(() => {
    if (isRemovedRef.current) return null;

    return (
      billingFrequencyQueryParam ??
      localStorage.getItem(LANDING_FLOW_FREQUENCY_STORAGE_KEY)
    );
  }, [billingFrequencyQueryParam]);

  const handleClear = useCallback(() => {
    localStorage.removeItem(LANDING_FLOW_FREQUENCY_STORAGE_KEY);
    localStorage.removeItem(LANDING_FLOW_MESSAGES_PER_DAY_STORAGE_KEY);
  }, []);

  useEffect(() => {
    if (!messagesPerDayQueryParam || !billingFrequencyQueryParam || isRemovedRef.current)
      return;

    localStorage.setItem(LANDING_FLOW_FREQUENCY_STORAGE_KEY, billingFrequencyQueryParam);
    localStorage.setItem(
      LANDING_FLOW_MESSAGES_PER_DAY_STORAGE_KEY,
      messagesPerDayQueryParam,
    );
  }, [messagesPerDayQueryParam, billingFrequencyQueryParam]);

  return {
    messagesPerDay,
    billingFrequency,
    clear: handleClear,
  };
};
