import * as Sentry from "@sentry/browser";
import {firebaseFirestore, firebaseFunctions} from "@workspace/firebase-app";
import {HttpsCallableResult} from "../../services/firebase";
import {AppDispatch, ReduxState} from "../../store/store";

import generateHashKey from "./helpers/generate-hash-key";
import {addMessages, dedupeInflightMessage} from "./message-slice";
import {Listener, Message, MessageType} from "./types";

const allowedMessagesTypes = [
  MessageType.TWITTER_DIRECT_MESSAGE,
  MessageType.TWITTER_TWEET_CREATE,
  MessageType.TWITTER_RETWEET,
  MessageType.TWITTER_TWEET_LIKE,
  MessageType.TWITTER_TWEET_QUOTE,
  MessageType.TWITTER_TWEET_REPLY,
  MessageType.INSTAGRAM_DIRECT_MESSAGE,
  MessageType.INSTAGRAM_STORY_MENTION,
  MessageType.INSTAGRAM_DIRECT_MESSAGE_WITH_MEDIA,
];

let listener: Listener | undefined = undefined;

export const releaseListener = (): void => {
  if (listener) {
    listener();
    listener = undefined;
  }
};

export const fetchMessages = async (
  conversationId: string,
  userId: string,
  dispatch: AppDispatch,
  getState: () => ReduxState,
): Promise<Message[]> => {
  releaseListener();

  return new Promise(
    (resolve) =>
      (listener = firebaseFirestore
        .collection("users")
        .doc(userId)
        .collection("conversations")
        .doc(conversationId)
        .collection("messages")
        .where("type", "in", allowedMessagesTypes)
        .orderBy("createdAt", "asc")
        .onSnapshot(
          (querySnapshot) => {
            const messagesData: Message[] = [];

            querySnapshot.forEach((doc) => {
              const data = doc.data();

              const message = {
                ...data,
                createdAt: data.createdAt?.toMillis(),
              };

              messagesData.push(message as Message);
            });

            querySnapshot.docChanges().forEach((messageSnapshot) => {
              if (messageSnapshot.type !== "added") return;

              const {text, recipientId} = messageSnapshot.doc.data();
              const inflightMessages =
                getState().messages.inflightMessagesByRecipientId[recipientId] || [];

              if (!inflightMessages.length) return;

              const hashKey = generateHashKey(recipientId, text);
              dispatch(dedupeInflightMessage({recipientId, hashKey}));
            });

            dispatch(addMessages({conversationId, messages: messagesData}));
            resolve(messagesData);
          },
          (e) => {
            console.log(e);
            Sentry.captureException(e);
          },
        )),
  );
};

export const sendDirectMessageOnCall = async (data: {
  text: string;
  conversationId: string;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("sendDirectMessageOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const sendDirectMessageToTwitterUserOnCall = async (data: {
  twitterUserId: string;
  messageText: string;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("sendDirectMessageToTwitterUserOnCall")(
      data,
    );
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const markMessageReadOnCall = async (
  messageId: string,
): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("markMessageReadOnCall")({
      messageId,
    });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
