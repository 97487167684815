import { normalize_casing, normalize, remove_all_chars_after_pipe_from, strip_emojis, remove_filler_prefixes, } from "./cleaners";
import { pipe } from "./pipe";
/**
 * - Removes all chars after "|",<br>
 * - Capitalizes the first letter of each word.
 * - Strips all emojis from a username string,<br>
 * - Normalizes it,<br>
 *
 * @example
 *  "Vasili | 🍊MM Fitness Coach" | "i'm going for a work-out".
 * @exampleOutput
 *  "Vasili" | "I'm Going For A Work-out" .
 * @param string - The string to strip.
 * @param devprint - A function to print to the console in dev mode.
 * @isPure Yes.
 */
export function process_display_name(string) {
    try {
        const result = pipe(string).thru(remove_all_chars_after_pipe_from, remove_filler_prefixes, normalize_casing, strip_emojis, normalize);
        return result;
        // Explain: We cannot possibly account for all the weirdness in usernames -
        // so if any of our cleaners is unable to process a username - just return it raw.
    }
    catch (err) {
        return string;
    }
}
