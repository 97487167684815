import {InstagramUser, isTime, Operation, SocialNetworkHandle, Time} from "@workspace/models";
import {isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";

export type InstagramConversation = {
  receiver: InstagramUser["pk"];
  responseTime: Time;
  responseText: string;
}

export type InstagramConversations = {
  sender:  SocialNetworkHandle;
  conversations: Array<InstagramConversation>
}

export type ReadInstagramConversation = Operation<SocialNetworkHandle, InstagramConversation | null>;

export type ReadInstagramConversations = Operation<SocialNetworkHandle, Array<InstagramConversation>>;

export type WriteInstagramConversation = Operation<InstagramConversation & { sender: string }, void>;

export const isInstagramConversation = objectTypeGuard<InstagramConversation>(
  ({receiver, responseTime, responseText}) =>
    isNonEmptyString(receiver) && isTime(responseTime) && isNonEmptyString(responseText),
);

export const isReadInstagramConversationOutput = objectTypeGuard<Exclude<ReadInstagramConversation["out"], null>>(
  (instagramConversation) => isInstagramConversation(instagramConversation),
);
