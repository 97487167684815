import {
  defineStyleConfig,
  SystemStyleInterpolation,
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps,
} from "@chakra-ui/react";
import {FC} from "react";
import {colorName} from "../colors";

export type BadgeVariant = "variable" | "success" | "warning" | "danger" | "passive";

export const badgeStyleConfig = defineStyleConfig({
  defaultProps: {
    variant: "variable",
    size: "sm",
  },
  baseStyle: {
    fontWeight: 600,
    borderRadius: "6px",
    cursor: "pointer",
    textTransform: "none",
  },
  sizes: {
    sm: {
      fontSize: "12px",
      lineHeight: "16px",
      px: 2,
      py: 1,
    },
    md: {
      fontSize: "13px",
      lineHeight: "20px",
      px: 3,
      py: 1.5,
    },
  },
  variants: {
    variable: {
      color: colorName.primary500,
      bg: colorName.primary50,
      _hover: {
        color: colorName.primary600,
        bg: colorName.primary100,
      },
    },
    success: {
      color: colorName.green700,
      bg: colorName.green50,
      _hover: {
        color: colorName.green800,
        bg: colorName.green100,
      },
    },
    warning: {
      color: colorName.orange600,
      bg: colorName.orange50,
      _hover: {
        color: colorName.orange700,
        bg: colorName.orange100,
      },
    },
    danger: {
      color: colorName.red500,
      bg: colorName.red50,
      _hover: {
        color: colorName.red600,
        bg: colorName.red100,
      },
    },
    passive: {
      color: colorName.gray700,
      bg: colorName.gray50,
      _hover: {
        color: colorName.gray800,
        bg: colorName.gray100,
      },
    },
  } satisfies Record<BadgeVariant, SystemStyleInterpolation>,
});

export type BadgeProps = Omit<ChakraBadgeProps, "variant" | "size"> & {
  size?: "sm" | "md";
  variant?: BadgeVariant;
};

export const Badge: FC<BadgeProps> = (props) => <ChakraBadge {...props} />;
