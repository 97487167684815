import {chakra} from '@chakra-ui/react';
import parse from 'html-react-parser';
import React from 'react';

import convertToHighlightedText from '../modules/search/helpers/convert-to-highlighted-text';

import LinkifyWrapper from './linkify-wrapper';

interface HighlightingWrapperProps {
  text: string;
  withLinks?: boolean;
}

const HighlightingWrapper: React.FC<HighlightingWrapperProps> = ({
  text,
  withLinks,
}) => (
  <chakra.span
    sx={{
      'span.highlighted': {
        color: 'white',
        bgColor: 'highlight',
        borderRadius: '4px',
      },
    }}
  >
    {withLinks ? (
      <LinkifyWrapper>{parse(convertToHighlightedText(text))}</LinkifyWrapper>
    ) : (
      parse(convertToHighlightedText(text))
    )}
  </chakra.span>
);

export default HighlightingWrapper;
