import { jsx as _jsx } from "react/jsx-runtime";
import { fetchAndActivate, firebaseRemoteConfig, getValue } from "@workspace/firebase-app";
import { isAdmin } from "@workspace/client-utils";
import { createContext, useCallback, useEffect } from "react";
const SURPASS_FEATURE_FLAG_LOCAL_STORAGE_KEY = "SURPASS_FEATURE_FLAG";
export const RemoteConfigContext = createContext({
    isFirebaseFeatureEnabledForUser: () => false,
});
export const RemoteConfigProvider = ({ children }) => {
    useEffect(() => {
        fetchAndActivate(firebaseRemoteConfig);
    }, []);
    const isFirebaseFeatureEnabledForUser = useCallback((featureName, userEmail) => {
        try {
            if (!userEmail)
                return false;
            const surpassFeature = localStorage.getItem(SURPASS_FEATURE_FLAG_LOCAL_STORAGE_KEY);
            if (surpassFeature) {
                const parsedSurpassFeature = JSON.parse(surpassFeature);
                if (parsedSurpassFeature && isAdmin(userEmail))
                    return true;
            }
            const feature = getValue(firebaseRemoteConfig, featureName);
            if (!feature)
                return false;
            const featureParsed = JSON.parse(feature.asString());
            if (Array.isArray(featureParsed)) {
                return !!featureParsed.find((item) => item && typeof item === "object" && item.email === userEmail);
            }
            else if (typeof featureParsed === "boolean") {
                return featureParsed;
            }
            return false;
        }
        catch {
            return false;
        }
    }, []);
    return (_jsx(RemoteConfigContext.Provider, { value: { isFirebaseFeatureEnabledForUser }, children: children }));
};
