import {Container, Text, chakra} from '@chakra-ui/react';
import React from 'react';
import {Link} from 'react-router-dom';

import {ReactComponent as EmptyStateImage} from '../../../assets/images/bulk-send-empty.svg';
import routes from '../../../router/routes';

const StyledEmptyStateImage = chakra(EmptyStateImage);
const StyledLink = chakra(Link);

const EmptyState: React.FC = () => (
  <Container display="flex" maxW="500px" flexDir="column" alignItems="center">
    <StyledEmptyStateImage my={24} maxW="465px" />
    <Text
      maxW="355px"
      fontSize={{base: 'lg', md: 'xl'}}
      textAlign="center"
      color="black"
    >
      No recipients are selected.
      <br /> Select recipients from Community Search Results{' '}
      <StyledLink to={routes.Followers.route} textDecor="underline">
        here
      </StyledLink>
      .
    </Text>
  </Container>
);

export default EmptyState;
