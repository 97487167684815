import {Global} from '@emotion/react';
import * as React from 'react';

const Fonts: React.FC = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Circular';
      font-style: normal;
      font-weight: 300;
      src: url(/fonts/CircularXXWeb-Regular.woff2) format('woff2');
    }
    
    @font-face {
      font-family: 'Circular';
      font-style: italic;
      font-weight: 300;
      src: url(/fonts/CircularXXWeb-Italic.woff2) format('woff2');
    }

    @font-face {
      font-family: 'Circular';
      font-style: normal;
      font-weight: 400;
      src: url(/fonts/CircularXXWeb-Book.woff2) format('woff2');
    }
    
    @font-face {
      font-family: 'Circular';
      font-style: italic;
      font-weight: 400;
      src: url(/fonts/CircularXXWeb-BookItalic.woff2) format('woff2');
    }

    @font-face {
      font-family: 'Circular';
      font-style: normal;
      font-weight: 500;
      src: url(/fonts/CircularXXWeb-Medium.woff2) format('woff2');
    }
    
    @font-face {
      font-family: 'Circular';
      font-style: italic;
      font-weight: 500;
      src: url(/fonts/CircularXXWeb-MediumItalic.woff2) format('woff2');
    }
    
    @font-face {
      font-family: 'Circular';
      font-style: normal;
      font-weight: 700;
      src: url(/fonts/CircularXXWeb-Bold.woff2) format('woff2');
    }
    
    @font-face {
      font-family: 'Circular';
      font-style: italic;
      font-weight: 700;
      src: url(/fonts/CircularXXWeb-BoldItalic.woff2) format('woff2');
    }
    
     @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 300;
      src: url(/fonts/Inter-Light.ttf);
    }

    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      src: url(/fonts/Inter-Regular.ttf);
    }
    
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      src: url(/fonts/Inter-Medium.ttf);
    }
    
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      src: url(/fonts/Inter-SemiBold.ttf);
    }
    
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      src: url(/fonts/Inter-Bold.ttf);
    }
    
    @font-face {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 800;
      src: url(/fonts/Inter-ExtraBold.ttf);
    }
    
    @font-face {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 800;
      src: url(/fonts/CabinetGrotesk-Extrabold.otf);
    }
    
    @font-face {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 700;
      src: url(/fonts/CabinetGrotesk-Bold.otf);
    }
    
    @font-face {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 300;
      src: url(/fonts/CabinetGrotesk-Light.otf);
    }
    
    @font-face {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 500;
      src: url(/fonts/CabinetGrotesk-Medium.otf);
    }
    
    @font-face {
      font-family: 'Cabinet Grotesk';
      font-style: normal;
      font-weight: 400;
      src: url(/fonts/CabinetGrotesk-Regular.otf);
    }
  `}
  />
);

export default Fonts;
