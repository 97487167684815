import {isLiteralObjectType} from "@workspace/type-utils";
import {TIME} from "./time";

export const FOLLOW_UP_MESSAGE_STATUS = {
  AWAITING_RESPONSE: 'AWAITING_RESPONSE',
  HAS_RESPONDED: 'HAS_RESPONDED',
  HAS_SENT_FOLLOW_UP: 'HAS_SENT_FOLLOW_UP',
  FOLLOW_UP_FAILED: 'FOLLOW_UP_FAILED',
} as const satisfies Record<string, string>

export type FollowUpMessageStatus = typeof FOLLOW_UP_MESSAGE_STATUS[keyof typeof FOLLOW_UP_MESSAGE_STATUS];

export const isFollowUpMessageStatus = isLiteralObjectType<FollowUpMessageStatus>(FOLLOW_UP_MESSAGE_STATUS);

export type FollowUpMessage = {
  id: string;
  message: string;
  username: string;
  campaignId: string;
  initialMessageId: string;
  initialMessageThreadId: string;
  followUpTime: number; //Date in milliseconds
  status: FollowUpMessageStatus;
  userId: string;
  userInstagramId: string;
}

export const FOLLOW_UP_INTERVAL_OPTION = {
  ONE_DAY: 'ONE_DAY',
  THREE_DAYS: 'THREE_DAYS',
  SEVEN_DAYS: 'SEVEN_DAYS',
} as const satisfies Record<string, string>

export type FollowUpIntervalOption = typeof FOLLOW_UP_INTERVAL_OPTION[keyof typeof FOLLOW_UP_INTERVAL_OPTION];

export const followUpIntervalToTime = (interval: FollowUpIntervalOption) => {
  switch (interval) {
    case FOLLOW_UP_INTERVAL_OPTION.ONE_DAY:
      return TIME.ONE_DAY;
    case FOLLOW_UP_INTERVAL_OPTION.THREE_DAYS:
      return TIME.ONE_DAY * 3;
    case FOLLOW_UP_INTERVAL_OPTION.SEVEN_DAYS:
      return TIME.ONE_DAY * 7;
  }
}
