import {Operation} from "@workspace/models";
import {NonEmptyString, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {FirebaseUserInfo} from "./FirebaseUserInfo";
import {PricingPlanKey, isPricingPlanKey} from "./PricingPlan";

export type CheckoutSessionStripeMetadata = Pick<FirebaseUserInfo, "uid"> &
  PricingPlanKey;

export const isCheckoutSessionStripeMetadata =
  objectTypeGuard<CheckoutSessionStripeMetadata>(
    ({uid, pricingPlan}) => isNonEmptyString(uid) && isPricingPlanKey({pricingPlan}),
  );

export type CreateCheckoutSession = Operation<
  PricingPlanKey,
  {
    /**
     * Redirect client to this URL to perform checkout.
     */
    url: NonEmptyString;
  }
>;

export const isCreateCheckoutSessionInput = objectTypeGuard<CreateCheckoutSession["in"]>(
  ({pricingPlan}) => isPricingPlanKey({pricingPlan}),
);

export const isCreateCheckoutSessionOutput = objectTypeGuard<
  CreateCheckoutSession["out"]
>(({url}) => isNonEmptyString(url));
