export interface AnalyticsData {
  newConversationsStarted: number;
  newConversationsInbound: number;
  existingConversationsEngaged: number;
  messagesSent: number;
  messagesReceived: number;
}

export const defaultAnalyticsData: AnalyticsData = {
  newConversationsStarted: 0,
  newConversationsInbound: 0,
  existingConversationsEngaged: 0,
  messagesSent: 0,
  messagesReceived: 0,
};

export enum AnalyticsCadence {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}
