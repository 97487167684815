import {SocialNetwork} from "@workspace/models";

export const subcollectionKeys = [
  "conversations",
  "instagramCampaigns",
  "instagramPendingThreads",
  "instagramFirstResponseThreads",
  "twitterCampaigns",
  "pendingMessages",
  "repliedMessages",
  "ignoredMessages",
  "discoveryTargets",
  "discoveries",
  "messages",
  "userLogs",
  "discoveryUsers",
  "leads"
] as const;

export type SubcollectionKey = (typeof subcollectionKeys)[number];

export const subcollectionName: Record<SubcollectionKey, string> = {
  conversations: "conversations",
  instagramCampaigns: "instagramCampaigns",
  instagramPendingThreads: "instagramPendingThreads",
  instagramFirstResponseThreads: "instagramFirstResponseThreads",
  twitterCampaigns: "twitterCampaigns",
  pendingMessages: "pendingMessages",
  repliedMessages: "repliedMessages",
  ignoredMessages: "ignoredMessages",
  userLogs: "userLogs",
  discoveryTargets: "discoveryTargets",
  discoveries: "discoveries",
  messages: "messages",
  discoveryUsers: "discoveryUsers",
  leads: "leads",
};

export const socialNetworkCampaigns = (socialNetwork: SocialNetwork): string => {
  const subcollectionRecord: Record<SocialNetwork, string> = {
    Instagram: subcollectionName.instagramCampaigns,
    Twitter: subcollectionName.twitterCampaigns,
  };
  return subcollectionRecord[socialNetwork];
};
