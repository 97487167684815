import {Box} from "@chakra-ui/react";
import {SubscriptionContext} from "@workspace/react";
import {FC, useContext} from "react";
import {dayToText} from "src/helpers/date";

export const TrialInfo: FC = () => {
  const {remainingTrialDays} = useContext(SubscriptionContext);

  if (typeof remainingTrialDays !== "number") return null;

  return (
    <Box rounded="full" px="3.5" py="2" bg="alpha.05" color="gray.900" fontSize="h6">
      {dayToText(remainingTrialDays)} left in trial
    </Box>
  );
};
