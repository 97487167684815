const generateBulkSendBtnLabel = (
  dmsCount: number,
  personalizationsCount: number
): string =>
  `${dmsCount} DMs ${
    personalizationsCount > 0
      ? `, including ${personalizationsCount} ${
          personalizationsCount === 1 ? 'personalization' : 'personalizations'
        }`
      : ''
  }`;
export default generateBulkSendBtnLabel;
