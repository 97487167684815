import {Box, BoxProps} from "@chakra-ui/react";
import {FC, PropsWithChildren} from "react";

export const OutlinedBox: FC<PropsWithChildren<BoxProps>> = (props) => (
  <Box
    p="16"
    textAlign="center"
    borderRadius="2xl"
    bg="white"
    boxShadow="0px 30px 84px rgba(10, 9, 68, 0.08), 0px 8px 32px rgba(10, 9, 68, 0.07), 0px 3px 14px rgba(10, 9, 68, 0.03), 0px 1px 3px rgba(10, 9, 68, 0.13)"
    {...props}
  />
);
