import {Button, ButtonProps, chakra, IconButton, IconButtonProps} from "@chakra-ui/react";
import React from "react";

import DsmTooltip from "./dsm-tooltip";

export const DsmButton: React.FC<ButtonProps> = ({children, ...props}) => (
  <Button
    p="10.5px 16px"
    color="primary.500"
    fontSize="baseMajor"
    fontWeight="bold"
    bg="transparent"
    {...props}
  >
    {children}
  </Button>
);

export const DsmSolidButton: React.FC<ButtonProps> = ({children, disabled, ...props}) => (
  <DsmButton
    color="white"
    bg="primary.500"
    disabled={disabled}
    _hover={disabled ? {} : {bg: "primary.400"}}
    {...props}
  >
    {children}
  </DsmButton>
);

export const DsmOutlinedButton: React.FC<ButtonProps> = ({children, ...props}) => (
  <DsmButton
    color="primary.500"
    bg="transparent"
    borderWidth="1px"
    borderColor="primary.500"
    _hover={{bg: "primary.500", color: "white"}}
    {...props}
  >
    {children}
  </DsmButton>
);

export const DsmLightButton: React.FC<ButtonProps> = ({children, ...props}) => (
  <DsmButton
    color="gray.500"
    _hover={{bgColor: "gray.50"}}
    _active={{bgColor: "gray.100", color: "gray.500"}}
    _focus={{
      bgColor: "gray.100",
      color: "gray.500",
      borderWidth: "2px",
      borderColor: "gray.200",
    }}
    {...props}
  >
    {children}
  </DsmButton>
);

export const DsmIconButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    p={0}
    minW={8}
    bgColor="transparent"
    color="primary.500"
    borderWidth="1px"
    borderColor="transparent"
    _hover={{bgColor: "gray.50"}}
    _active={{outline: "none", borderColor: "primary.500"}}
    _focus={{outline: "none", borderColor: "primary.500"}}
    {...props}
  />
);

export interface TooltipIconButtonProps {
  label: string;
  icon: React.ReactElement;
  onClick?: () => void;
}

export const TooltipIconButton: React.FC<TooltipIconButtonProps & IconButtonProps> = ({
  label,
  icon,
  onClick,
  ...props
}) => (
  <DsmTooltip label={label}>
    <chakra.span display="inline-block">
      <DsmIconButton
        h={8}
        w={8}
        icon={icon}
        bgColor="transparent"
        color="gray.500"
        onClick={onClick}
        {...props}
      />
    </chakra.span>
  </DsmTooltip>
);
