import {Bucket, TwitterFriendshipConnection} from '../conversation/types';

export enum CanDm {
  PENDING = 'pending',
  TRUE = 'true',
  FALSE = 'false',
}

export interface Friend {
  id: string;
  userId: string;
  twitterId?: string;
  instagramId?: string;
  url?: string;
  bucket?: Bucket;
  tags: Record<string, boolean>;
  username?: string;
  name?: string;
  location?: string;
  description?: string;
  verified: boolean;
  createdAt: number;
  publicMetrics?: {
    followersCount: number;
    followingCount: number;
    tweetCount: number;
    listedCount: number;
  };
  profileImageUrl: string;
  userTwitterId?: string;
  conversationId: string;
  connections?: TwitterFriendshipConnection[];
  isProtected?: boolean;
  canDm: CanDm;
}
