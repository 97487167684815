import {configureStore} from "@reduxjs/toolkit";
import {Action, combineReducers} from "redux";
import type {TypedUseSelectorHook} from "react-redux";
import * as reduxLogger from "redux-logger";
import {ThunkAction} from "redux-thunk";

import {reducer as actingUserReducer} from "../modules/acting-user/acting-user-slice";
import {reducer as adminReducer} from "../modules/admin/admin-slice";
import {reducer as analyticsReducer} from "../modules/analytics/analytics-slice";
import {reducer as bulkSendReducer} from "../modules/bulk-send/bulk-send-slice";
import {reducer as conversationsReducer} from "../modules/conversation/conversations-slice";
import {reducer as draftsReducer} from "../modules/drafts/drafts-slice";
import {reducer as messagesReducer} from "../modules/message/message-slice";
import {reducer as scheduledMessagesReducer} from "../modules/scheduled-messages/scheduled-messages-slice";
import {reducer as searchReducer} from "../modules/search/search-slice";
import {reducer as userReducer} from "../modules/user/user-slice";

import {sentryMiddleware} from "./middlewares/sentry-middleware";

const rootReducer = combineReducers({
  actingUser: actingUserReducer,
  admin: adminReducer,
  analytics: analyticsReducer,
  bulkSend: bulkSendReducer,
  conversations: conversationsReducer,
  drafts: draftsReducer,
  messages: messagesReducer,
  scheduledMessages: scheduledMessagesReducer,
  search: searchReducer,
  user: userReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.REACT_APP_ENVIRONMENT === "development") {
      return [...getDefaultMiddleware(), sentryMiddleware, reduxLogger.logger];
    } else if (process.env.REACT_APP_ENVIRONMENT === "local") {
      return [...getDefaultMiddleware(), reduxLogger.logger];
    } else {
      return [...getDefaultMiddleware(), sentryMiddleware];
    }
  },
});

export type AppDispatch = typeof store.dispatch;

export type ReduxState = ReturnType<typeof rootReducer>;

export type AppSelector = TypedUseSelectorHook<ReduxState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  Action<string>
>;
export default store;
