import {CHROME_EXTENSION_ID} from "../modules/integration/helpers/constants";
import {SocialNetworkUserWithPostDetails, TIME} from "@workspace/models";
import {firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {LRUCache} from "@workspace/client-utils";

const cache = new LRUCache<SocialNetworkUserWithPostDetails>("SEARCH_BY_USERNAME", TIME.ONE_DAY);
export const searchInstagramByUsername = (searchQuery: string): Promise<SocialNetworkUserWithPostDetails | null> => {
  return new Promise( (resolve, reject) => {
    const cachedResults = cache.get(searchQuery);
    if(cachedResults) resolve(cachedResults);

    const rejectTimeout = setTimeout(() => reject("Request timed out"), TIME.ONE_MINUTE * 2);

    let port = chrome.runtime.connect(CHROME_EXTENSION_ID, {name: "SEARCH_USERNAME"});

    port.onMessage.addListener(async(msg) => {
      if (msg.command === "RETURN_SEARCH_RESULTS") {
        const user = msg.payload as SocialNetworkUserWithPostDetails;

        clearTimeout(rejectTimeout);

        if(user.id){
          const avatarResponse = await firebaseFunctions.httpsCallable(
            cloudFunctionName.uploadInstagramUserAvatar,
          )({
            avatarUrl: user.avatar,
            instagramId: user.id,
          });

          const userWithAvatar = {
            ...user,
            avatar: avatarResponse.data
          }
          cache.set(searchQuery, userWithAvatar)

          resolve(userWithAvatar)
        }else{
          resolve(null)
        }

        port.disconnect()
      }
    })

    port.postMessage({
      payload: searchQuery
    })
  })
}
