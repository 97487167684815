import {
  Box,
  chakra,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from "@chakra-ui/react";
import {useFirebaseUser} from "@workspace/react";
import {colorHex, Icon} from "@workspace/ui";
import {FC, useCallback, useMemo} from "react";
import {Link, useHistory} from "react-router-dom";

import {useAppDispatch, useDsmChromeExtension} from "src/hooks";
import {routes} from "src/router";
import useMixpanel from "../../helpers/hooks/use-mixpanel";
import {logout} from "../../modules/auth/auth-service";
import useWindowWidth from "../../helpers/hooks/use-window-width";
import {MAX_MOBILE_RESOLUTION} from "../../helpers/constants";

const StyledLink = chakra(Link, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "base",
  },
});

type Props = {
  isFullWidth?: boolean;
};

export const AccountInfo: FC<Props> = ({isFullWidth}) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MAX_MOBILE_RESOLUTION;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const mixpanel = useMixpanel();
  const {extensionSignOut} = useDsmChromeExtension();

  const {isAuthenticated, userInfo} = useFirebaseUser();

  const email = useMemo(() => (userInfo ? userInfo.email ?? "" : ""), [userInfo]);

  const logoutHandler = useCallback(async () => {
    try {
      await logout(dispatch);
      history.push(routes.Login.route);
      extensionSignOut();
      mixpanel?.track("web.general.auth.logout");
      mixpanel?.reset();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, mixpanel, extensionSignOut, history]);

  return (
    <Box
      w={isFullWidth ? "100%" : "auto"}
      pl={{base: 0, md: 4}}
      display="flex"
      _hover={{cursor: "pointer"}}
      data-cy="account-info"
      fontFamily="Inter"
    >
      {isAuthenticated && (
        <Menu placement="bottom-end">
          <MenuButton w={isFullWidth ? "100%" : "auto"} display="block" textAlign="left">
            <Stack color="gray.900" isInline>
              <Text fontWeight={500} fontSize="h6" lineHeight="1.1">
                {email ?? ""}
              </Text>
              <Icon name="chevronDown" />
            </Stack>
          </MenuButton>

          <MenuList fontSize="sm" minWidth="180px" p={1} zIndex={10}>
            <MenuGroup>
              {!isMobile && (
                <>
                  <MenuItem as={Box} fontSize="h6" px="3" py="2">
                    <StyledLink
                      to={routes.PaymentPlans.route}
                      display="flex"
                      alignItems="center"
                      width="100%"
                    >
                      <Box mr="2">
                        <Icon name="cardOutline" size="20px" color={colorHex.gray300} />
                      </Box>
                      Billing
                    </StyledLink>
                  </MenuItem>
                  <MenuItem as={Box} fontSize="h6" px="3" py="2">
                    <StyledLink
                      to={routes.Settings.route}
                      display="flex"
                      alignItems="center"
                      width="100%"
                    >
                      <Box mr="2">
                        <Icon name="settings" size="20px" color={colorHex.gray300} />
                      </Box>
                      Settings
                    </StyledLink>
                  </MenuItem>
                </>
              )}
              <MenuItem px="3" py="2" fontSize="h6" onClick={logoutHandler}>
                <Box mr="2">
                  <Icon name="logout" size="20px" color={colorHex.gray300} />
                </Box>
                Log out
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};
