import {Platform} from "../integration/types";

export enum EventCode {
  Enter = "Enter",
  NumpadEnter = "NumpadEnter",
}

export enum MessageType {
  TWITTER_DIRECT_MESSAGE = "twitter_direct_message",
  TWITTER_TWEET_LIKE = "twitter_tweet_like",
  TWITTER_BLOCK = "twitter_block",
  TWITTER_UNBLOCK = "twitter_unblock",
  TWITTER_FOLLOW = "twitter_follow",
  TWITTER_UNFOLLOW = "twitter_unfollow",
  TWITTER_MUTE = "twitter_mute",
  TWITTER_UNMUTE = "twitter_unmute",
  TWITTER_TWEET_CREATE = "twitter_tweet_create",
  TWITTER_RETWEET = "twitter_retweet",
  TWITTER_TWEET_QUOTE = "twitter_tweet_quote",
  TWITTER_TWEET_REPLY = "twitter_tweet_reply",
  INSTAGRAM_DIRECT_MESSAGE = "instagram_direct_message",
  INSTAGRAM_STORY_MENTION = "instagram_story_mention",
  INSTAGRAM_DIRECT_MESSAGE_WITH_MEDIA = "instagram_direct_message_with_media",
}

export enum MessageUrlType {
  TWEET = "tweet",
  GENERAL = "general",
}

export interface MessageUrl {
  type: MessageUrlType;
  url: string;
  tweetId?: string;
}

export interface MessageMediaAttachment {
  id: string;
  height: number;
  width: number;
  mediaUrl: string;
  type: string;
  videoInfo: {
    aspectRatio: [number, number];
    variants: {
      bitrate: number;
      contentType: string;
      url: string;
    }[];
  };
}

export interface Message {
  conversationId: string;
  createdAt: number;
  id: string;
  integrationId: string;
  platform: Platform;
  rawDataId: number;
  read: boolean;
  recipientId: string;
  senderId: string;
  text: string;
  userId: string;
  urls?: MessageUrl[];
  type: MessageType;
  twitterTweetId?: string;
  mediaAttachment?: MessageMediaAttachment;
  instagramMessageReaction?: string;
  instagramMessageReactionEmoji: string;
  instagramStoryMentionUrl?: string;
  instagramMediaAttachmentUrl?: string;
  instagramMediaAttachmentType?: "video" | "audio" | "file" | "image";
  instagramStoryReplyUrl?: string;
}

export interface UnsentMessage {
  text: string;
  requestId: string;
  isShown: boolean;
}

export interface InflightMessagesByHashKey {
  [hashKey: string]: InflightMessage[];
}

export interface InflightMessage {
  hashKey: string;
  recipientId: string;
  id: string;
  status: InflightMessageStatus;
  text: string;
  createdAt: number;
  senderId: string;
  type: MessageType.TWITTER_DIRECT_MESSAGE;
}

export const isInflightMessage = (
  message: Message | InflightMessage,
): message is InflightMessage => {
  // WAS_eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof (message as any).rawDataId === "undefined";
};

export enum InflightMessageStatus {
  PENDING = "pending",
  SUCCESS = "success",
}

export type Listener = () => void;

// Using message.status to differentiate between Message and InflightMessage
export const instanceOfMessage = (
  message: Message | InflightMessage,
): message is Message => !("status" in message);
