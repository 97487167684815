import {useToast, usePrevious, ToastPosition} from "@chakra-ui/react";
import {FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef} from "react";

import {useAppDispatch, useAppSelector} from "../../../app-hooks";
import {setAutoresponder} from "../thunks";

import AutoresponderOnToast from "./autoresponder-on-toast";

const AutoresponderToastHoc: FC<PropsWithChildren> = ({children}) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const toastIdRef = useRef<string | number | undefined>();
  const {isUpdatingAutoresponder, isTurningOffAutoresponder} = useAppSelector(
    (state) => state.user,
  );
  const isChangingAutoresponderSetting =
    isUpdatingAutoresponder || isTurningOffAutoresponder;
  const prevIsChangingAutoresponderSetting = usePrevious(isChangingAutoresponderSetting);
  const integrations = useAppSelector((state) => state.user.user?.integrations);
  const autoresponderSetting = integrations?.[0]?.autoresponder;

  const turnAutoresponderOffHandler = useCallback(() => {
    const integrationId = integrations?.[0]?.id;

    if (!integrationId) return;

    const setting = {
      integrationId,
      enabled: false,
      message: autoresponderSetting?.message || "",
    };
    dispatch(setAutoresponder(setting, {isTurningOff: true}));
  }, [autoresponderSetting, dispatch, integrations]);

  const autoresponderToastOptions = useMemo(
    () => ({
      duration: null,
      position: "bottom-left" as ToastPosition,
      isClosable: false,
      render: () => (
        <AutoresponderOnToast
          disabled={isChangingAutoresponderSetting}
          turnAutoresponderOffHandler={turnAutoresponderOffHandler}
        />
      ),
    }),
    [isChangingAutoresponderSetting, turnAutoresponderOffHandler],
  );

  useEffect(() => {
    if (autoresponderSetting?.enabled && !toastIdRef.current) {
      toastIdRef.current = toast(autoresponderToastOptions);
    } else if (
      autoresponderSetting?.enabled &&
      toastIdRef.current &&
      typeof prevIsChangingAutoresponderSetting !== "undefined" &&
      prevIsChangingAutoresponderSetting !== isChangingAutoresponderSetting
    ) {
      // update toast after API calls are finished to make button enabled
      toast.update(toastIdRef.current, autoresponderToastOptions);
    } else if (!autoresponderSetting?.enabled && toastIdRef.current) {
      toast.close(toastIdRef.current);
      toastIdRef.current = undefined;
    }
  }, [
    autoresponderSetting?.enabled,
    autoresponderToastOptions,
    isChangingAutoresponderSetting,
    prevIsChangingAutoresponderSetting,
    toast,
    turnAutoresponderOffHandler,
  ]);
  return <>{children}</>;
};

export default AutoresponderToastHoc;
