"use strict";
exports.__esModule = true;
exports.twitterUserToSocialNetworkUser = exports.instagramUserToSocialNetworkUser = exports.isSocialNetworkUser = void 0;
var type_utils_1 = require("@workspace/type-utils");
var SocialNetwork_1 = require("./SocialNetwork");
exports.isSocialNetworkUser = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var avatar = _a.avatar, name = _a.name, handle = _a.handle, id = _a.id, bio = _a.bio;
    return (0, type_utils_1.isNonEmptyString)(avatar) &&
        typeof name === "string" &&
        (0, SocialNetwork_1.isSocialNetworkHandle)(handle) &&
        id === undefined
        ? true
        : (0, type_utils_1.isNonEmptyString)(id) &&
            (bio === undefined ? true : typeof bio === "string");
});
var instagramUserToSocialNetworkUser = function (_a) {
    var profile_pic_url = _a.profile_pic_url, full_name = _a.full_name, username = _a.username, pk = _a.pk;
    return ({
        avatar: profile_pic_url,
        name: full_name,
        handle: username,
        id: pk
    });
};
exports.instagramUserToSocialNetworkUser = instagramUserToSocialNetworkUser;
var twitterUserToSocialNetworkUser = function (_a) {
    var profile_image_url_https = _a.profile_image_url_https, name = _a.name, screen_name = _a.screen_name, verified = _a.verified;
    return ({
        avatar: profile_image_url_https,
        name: name,
        handle: screen_name,
        is_verified: verified
    });
};
exports.twitterUserToSocialNetworkUser = twitterUserToSocialNetworkUser;
