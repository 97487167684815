import {
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  chakra,
  Text,
} from "@chakra-ui/react";
import {useDisclosure} from "@workspace/ui";
import React, {useEffect, useState} from "react";

import {useAppDispatch} from "../../../../app-hooks";
import {ReactComponent as TwitterReAuthIcon} from "../../../../assets/icons/twitter-re-auth.svg";
import {DsmOutlinedButton} from "../../../../components/buttons";
import {DsmExternalSolidLink} from "../../../../components/links";
import useMixpanel from "../../../../helpers/hooks/use-mixpanel";
import {getIdToken, logout} from "../../../auth/auth-service";

const StyledTwitterReAuthIcon = chakra(TwitterReAuthIcon);

interface ShouldReAuthModalProps {
  isOpen: boolean;
}

const ShouldReAuthModal: React.FC<ShouldReAuthModalProps> = ({isOpen}) => {
  const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const [idToken, setIdToken] = useState<string>();
  const {onClose} = useDisclosure();

  const authTwitterHref = `${process.env.REACT_APP_FIREBASE_FUNC_DOMAIN}${encodeURI(
    "/twitterWebhooks/oauth/init",
  )}?userIdToken=${idToken}`;

  const logoutHandler = async () => {
    try {
      await logout(dispatch);
      mixpanel?.track("web.general.auth.logout");
      mixpanel?.reset();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getIdToken()
      .then((token) => {
        if (token) {
          setIdToken(token);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        py={6}
        mx={5}
        textAlign="center"
        minW={{base: "auto", md: "613px"}}
        mt="85px"
      >
        <Container maxW="450px">
          <StyledTwitterReAuthIcon mx="auto" />
          <ModalHeader
            fontWeight="bold"
            lineHeight={1.5}
            fontSize={{base: "md", md: "xl"}}
            color="gray.900"
          >
            We lost connection with your Twitter account
          </ModalHeader>

          <ModalBody
            lineHeight={1.5}
            color="gray.800"
            fontSize={{base: "baseMinor", md: "base"}}
          >
            <Text>
              Could you please re-authenticate your twitter account so you can continue
              using Howdy.
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center" px={{md: 6}}>
            <DsmExternalSolidLink
              py={2}
              mr={5}
              display="block"
              fontSize={{base: "baseMinor", md: "baseMajor"}}
              href={authTwitterHref}
            >
              Re-Authenticate Twitter
            </DsmExternalSolidLink>
            <DsmOutlinedButton onClick={logoutHandler}>Logout</DsmOutlinedButton>
          </ModalFooter>
        </Container>
      </ModalContent>
    </Modal>
  );
};

export default ShouldReAuthModal;
