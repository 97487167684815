import { firebaseAuth as auth } from "@workspace/firebase-app";
import { isFirebaseUserInfo } from "@workspace/firebase-datamodel";
import { useEffect, useMemo, useState } from "react";
export const useFirebaseUser = () => {
    const [userInfo, setUserInfo] = useState();
    const isAuthenticated = useMemo(() => {
        if (userInfo === undefined)
            return undefined;
        return userInfo !== null;
    }, [userInfo]);
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user === null) {
                setUserInfo(null);
                global?.window?.sessionStorage.clear();
            }
            else if (isFirebaseUserInfo(user)) {
                setUserInfo({ uid: user.uid, email: user.email });
            }
        });
        return () => unsubscribe();
    }, []);
    return { userInfo, isAuthenticated };
};
