import {chakra, Flex, Text, Link as ChakraLink} from "@chakra-ui/react";
import {firebaseAuth, firebaseFunctions} from "@workspace/firebase-app";
import {Button, colorName, Field, Input} from "@workspace/ui";
import React, {useContext, useEffect, useState} from "react";
import {useDsmChromeExtension} from "src/hooks/useDsmChromeExtension";
import {hasOwnProperty} from "../../../helpers/has-own-property";
import {clearLocalStorage} from "../../../helpers/local-storage-helpers";
import routes from "../../../router/routes";
import {register} from "../auth-service";
import {AuthWrapperWithBanner, NewAuthTitle} from "../components/auth-layout-components";
import PasswordInput from "../components/password-input";
import {EXTENSION_REDIRECT_PATH} from "../helpers/constants";
import {saveExtensionRedirectPath} from "../helpers/extension-redirect-helpers";
import {
  PASSWORD_REGEX,
  registrationErrorLabelsMap,
  USER_NAME_REGEX,
} from "../helpers/validation";
import Tap from "@tapfiliate/tapfiliate-js";
import * as yup from "yup";
import {isFiniteString, isMaybeObject, isNonEmptyString} from "@workspace/type-utils";
import {FirebaseErrorCode} from "../types";
import {Link, useHistory} from "react-router-dom";
import {UserAuthContext} from "src/contexts";

const StyledLink = chakra(Link);

const schema = yup.object({
  firstName: yup
    .string()
    .trim()
    .matches(USER_NAME_REGEX, "Only letters are supported")
    .required("First name is required"),
  lastName: yup
    .string()
    .trim()
    .matches(USER_NAME_REGEX, "Only letters are supported")
    .required("Last name is required"),
  email: yup.string().trim().required("Email is required").email("Must be a valid email"),
  password: yup
    .string()
    .trim()
    .test(
      "valid password",
      registrationErrorLabelsMap["auth/weak-password"],
      (value) => !!value && !PASSWORD_REGEX.test(value),
    )
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .test(
      "passwords match",
      "Passwords must match",
      (value, context) => value === context.parent.password,
    )
    .required("Confirm password is required"),
});

const isRegisterFieldName = (arg: unknown): arg is keyof yup.InferType<typeof schema> => {
  return isFiniteString(arg) && Object.keys(schema.fields).includes(arg);
};

const CreateAccount: React.FC = () => {
  const userAuth = useContext(UserAuthContext);
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<
    Partial<Record<keyof yup.InferType<typeof schema>, string>>
  >({});
  const [isCreating, setIsCreating] = useState(false);

  const {extensionSignIn} = useDsmChromeExtension();

  const submitHandler = async () => {
    try {
      const validData = await schema.validate(
        {firstName, lastName, email, password, confirmPassword},
        {abortEarly: false},
      );

      saveExtensionRedirectPath();
      setIsCreating(true);

      const response = await register({
        email: validData.email,
        password: validData.password,
      });

      // if the response doesn't contain user, it is an error
      if (response && !hasOwnProperty(response, "user")) {
        if (response.code === FirebaseErrorCode.AuthEmailAlreadyUsed) {
          setErrors({email: "This email is already in use"});
        } else if (response.code === FirebaseErrorCode.AuthInvalidEmail) {
          setErrors({
            email: registrationErrorLabelsMap[FirebaseErrorCode.AuthInvalidEmail],
          });
        } else if (response.code === FirebaseErrorCode.AuthWeakPassword) {
          setErrors({
            password: registrationErrorLabelsMap[FirebaseErrorCode.AuthWeakPassword],
          });
        }
        clearLocalStorage([EXTENSION_REDIRECT_PATH]);
      } else if (response.user) {
        const firebaseUser = firebaseAuth.currentUser;

        if (firebaseUser) {
          firebaseUser.updateProfile({
            displayName: `${validData.firstName} ${validData.lastName}`,
          });

          firebaseFunctions.httpsCallable("postRegisterActionsOnCall")({
            firstName: validData.firstName,
            lastName: validData.lastName,
          });

          Tap.trial(firebaseUser.uid);
        }

        // @ts-ignore
        const token = await response.user.getIdToken();

        extensionSignIn({
          token,
          // @ts-ignore
          uid: response.user.uid,
        });
      }
    } catch (err) {
      if (!isMaybeObject<yup.ValidationError>(err)) return;
      if (!Array.isArray(err.inner)) return;

      setErrors(
        err.inner.reduce<typeof errors>((acc, curr) => {
          if (
            isMaybeObject<yup.ValidationError["inner"][number]>(curr) &&
            isRegisterFieldName(curr.path) &&
            isNonEmptyString(curr.message)
          ) {
            acc[curr.path] = curr.message;
          }

          return acc;
        }, {}),
      );
    } finally {
      setIsCreating(false);
    }
  };

  useEffect(() => {
    Tap.init(process.env.REACT_APP_TAPFILIATE_ACCOUNT_ID!);
  }, []);

  useEffect(() => {
    if (!userAuth) return;

    history.push(routes.Integrations.route);
  }, [userAuth, history]);

  return (
    <AuthWrapperWithBanner>
      <NewAuthTitle title="Sign up" />
      <Text color={colorName.gray600} mb="10" fontSize="h6">
        Already have an account?{" "}
        <StyledLink to={routes.Login.route} color="primary.400" ml="2" fontWeight="600">
          Log in
        </StyledLink>
      </Text>
      <form onSubmit={(e) => e.preventDefault()}>
        <Flex gap="4" mb="4">
          <Field
            w="50%"
            label="First name"
            isError={!!errors.firstName}
            helpText={errors.firstName}
          >
            <Input
              id="name-input"
              value={firstName}
              fontSize="base"
              isInvalid={!!errors.firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Field>
          <Field
            w="50%"
            label="Last name"
            isError={!!errors.lastName}
            helpText={errors.lastName}
          >
            <Input
              id="name-input"
              value={lastName}
              isInvalid={!!errors.lastName}
              fontSize="base"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Field>
        </Flex>
        <Field label="Email" isError={!!errors.email} helpText={errors.email} mb="4">
          <Input
            id="email-input"
            value={email}
            isInvalid={!!errors.email}
            fontSize="base"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Field>
        <PasswordInput
          label="Password"
          value={password}
          saveValue={setPassword}
          fieldProps={{isError: !!errors.password, helpText: errors.password}}
        />
        <PasswordInput
          label="Confirm password"
          value={confirmPassword}
          fieldProps={{
            isError: !!errors.confirmPassword,
            helpText: errors.confirmPassword,
          }}
          saveValue={setConfirmPassword}
        />
        <Button
          type="submit"
          w="100%"
          isLoading={isCreating}
          loadingText="Creating"
          onClick={submitHandler}
          mb={6}
          size="lg"
        >
          Sign up
        </Button>
        <Text color={colorName.gray500} fontSize="xs" textAlign="center">
          By signing up, you accept our{" "}
          <ChakraLink
            textUnderlineOffset="4px"
            textDecoration="underline"
            target="_blank"
            href="https://www.notion.so/care-for-your-dms/Terms-of-Service-b275f4631c9e4c24a07023d3ef2b35ab?pvs=4"
          >
            Terms
          </ChakraLink>{" "}
          and{" "}
          <ChakraLink
            textUnderlineOffset="4px"
            textDecoration="underline"
            target="_blank"
            href="https://www.notion.so/care-for-your-dms/Privacy-Policy-3a021680e2184a43aab0d42bb11b1bc6?pvs=4"
          >
            Privacy Policy
          </ChakraLink>
        </Text>
      </form>
    </AuthWrapperWithBanner>
  );
};

export default CreateAccount;
