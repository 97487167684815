import * as Sentry from "@sentry/browser";
import {firebaseFirestore, firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import moment from "moment-timezone";
import {Dispatch} from "react";

import {HttpsCallableResult} from "../../services/firebase";
import {ReduxState} from "../../store/store";
import {FacebookPage} from "../integration/types";

import {setTimezone} from "./thunks";
import {User} from "./types";
import {
  setIsOptimisticallySavedAutoresponder,
  setIsOptimisticallySavedTimezone,
  setIsRemovingIntegration,
  setUser,
  setIsFetchingUser,
} from "./user-slice";

export const fetchUserData = (
  userId: string,
  // WAS_eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: () => ReduxState,
): void => {
  dispatch(setIsFetchingUser(true));

  firebaseFirestore
    .collection("users")
    .doc(userId)
    .onSnapshot(
      (snapshot) => {
        dispatch(setIsFetchingUser(false));

        // When user just registered,
        // it can happen that the corresponding document is not created yet.
        if (!snapshot.exists) return;

        const userDoc = snapshot.data();
        if (!userDoc) return;

        const state = getState();
        const isOptimisticallySavedAutoresponder =
          state.user.isOptimisticallySavedAutoresponder;

        const user = {
          ...userDoc,
          createdAt: userDoc?.createdAt?.toMillis(),
          // Explain: According to https://dsm-network.atlassian.net/browse/PE-862
          // 'isInboxBuilt' was toggled to always 'true' (to no waiting for inbox building
          // because we don't need this feature now).
          isInboxBuilt: true,
        } as User;

        if (userDoc.subscription) {
          user.subscription = {
            ...userDoc.subscription,
            // todo (artem): find out why do we need the createdAt field in the codebase at all
            createdAt: userDoc.subscription.createdAt?.toMillis(),
          };
        }

        // Because the autoresponder settings can be changed from more than one place in the app,
        // sometimes race conditions can happen. These checks are used to avoid an inconsistent
        // UI behavior.
        if (isOptimisticallySavedAutoresponder) {
          const currentAutoresponderSetting =
            state.user.user?.integrations[0].autoresponder;
          const newAutoresponderSetting = userDoc.integrations[0].autoresponder;

          const wasChangedAutoresponderSetting =
            currentAutoresponderSetting?.enabled !== newAutoresponderSetting.enabled ||
            currentAutoresponderSetting?.message !== newAutoresponderSetting.message;

          if (currentAutoresponderSetting && wasChangedAutoresponderSetting) {
            user.integrations[0].autoresponder = currentAutoresponderSetting;
          } else {
            dispatch(setIsOptimisticallySavedAutoresponder(false));
          }
        }

        const isOptimisticallySavedTimezone = state.user.isOptimisticallySavedTimezone;
        if (isOptimisticallySavedTimezone) {
          const currentTimezone = state.user.user?.timezone;
          const newTimezone = userDoc.timezone;

          const wasChangedTimezoneSettings = currentTimezone !== newTimezone;
          if (currentTimezone && wasChangedTimezoneSettings) {
            user.timezone = currentTimezone;
          } else {
            dispatch(setIsOptimisticallySavedTimezone(false));
          }
        }

        dispatch(setUser(user));

        if (!isOptimisticallySavedTimezone && !user.timezone) {
          const timezoneName = moment.tz.guess();
          dispatch(setTimezone(timezoneName, {showToast: false}));
        }
      },
      (err) => {
        console.error("user snap ", err);
        Sentry.captureException(err);
      },
    );
};

export const removeIntegrationAccount = async (
  // WAS_eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  getState: () => ReduxState,
  type: "instagram" | "twitter",
): Promise<HttpsCallableResult> => {
  const user = getState().user.user as User;
  const integration = user?.integrations.find((integration) => integration.type === type);
  const integrations = user?.integrations.filter(
    (integration) => integration.type !== type,
  );

  try {
    dispatch(setIsRemovingIntegration(true));
    const removeIntegrationResult = await firebaseFunctions.httpsCallable(
      "deleteIntegrationOnCall",
    )({
      integrationId: integration?.id,
    });
    dispatch(
      setUser({
        ...user,
        integrations,
      }),
    );
    return removeIntegrationResult;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  } finally {
    dispatch(setIsRemovingIntegration(false));
  }
};

export const setAutoresponderOnCall = async (data: {
  integrationId: string;
  enabled: boolean;
  message: string;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("setAutoresponderOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const setTimezoneOnCall = async (data: {
  timezone: string;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("setTimezoneOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const loadConnectedFacebookPagesOnCall = async (
  facebookIntegrationId: string,
): Promise<{accounts: FacebookPage[]}> => {
  try {
    const result = await firebaseFunctions.httpsCallable(
      "getConnectedFacebookPagesOnCall",
    )({integrationId: facebookIntegrationId});

    return result.data;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateIntegrationOnCall = async (data: {
  facebookIntegrationId: string;
  facebookPageId: string;
}): Promise<HttpsCallableResult> => {
  try {
    const result = await firebaseFunctions.httpsCallable("updateIntegrationOnCall")({
      id: data.facebookIntegrationId,
      facebookPageId: data.facebookPageId,
    });

    return result.data;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const subscribeToInstagramWebhooksOnCall = async (
  facebookIntegrationId: string,
): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("subscribeToInstagramWebhooksOnCall")({
      integrationId: facebookIntegrationId,
    });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const createBillingPortalOnCall = async (): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("createBillingPortalOnCall")();
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const sendPasswordChangedEmailOnCall = async (): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable(
      cloudFunctionName.sendPasswordChangedEmailOnCall,
    )();
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
