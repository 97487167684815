"use strict";
exports.__esModule = true;
exports.socialNetworkUserCollectionName = exports.socialNetworkCampaignsQueue = exports.conversationCollectionName = exports.collectionName = exports.collectionKeys = void 0;
exports.collectionKeys = [
    "instagram",
    "instagramCampaignsQueue",
    "instagramConversation",
    "instagramThreads",
    "twitter",
    "twitterCampaignsQueue",
    "twitterConversation",
    "messagesDailyCount",
    "pricingPlans",
    "subscriptions",
    "stripeCustomers",
    "stripeSubscriptions",
    "subscriptionsHistory",
    "users",
    "zapierWebhooks",
    "commissions",
    "scheduledMessagesDailyCount",
    "discoveriesQueue",
    "followUpMessagesQueue",
    "userLogs",
    "discoveries",
    "blockedTargets",
    "discoveryReports",
    "monitoring",
    "lists",
    "prospectiveListCampaigns",
];
exports.collectionName = {
    instagram: "instagram",
    instagramConversation: "instagramConversation",
    instagramThreads: "instagramThreads",
    twitter: "twitter",
    twitterConversation: "twitterConversation",
    messagesDailyCount: "messagesDailyCount",
    pricingPlans: "pricing_plans",
    subscriptions: "subscriptions",
    subscriptionsHistory: "subscriptionsHistory",
    stripeCustomers: "stripeCustomers",
    stripeSubscriptions: "stripeSubscriptions",
    users: "users",
    twitterCampaignsQueue: "twitterCampaignsQueue",
    instagramCampaignsQueue: "instagramCampaignsQueue",
    zapierWebhooks: "zapierWebhooks",
    commissions: "commissions",
    scheduledMessagesDailyCount: "scheduledMessagesDailyCount",
    userLogs: "userLogs",
    discoveriesQueue: "discoveriesQueue",
    followUpMessagesQueue: "followUpMessagesQueue",
    discoveries: "discoveries",
    blockedTargets: "blockedTargets",
    discoveryReports: "discoveryReports",
    monitoring: "monitoring",
    lists: "lists",
    prospectiveListCampaigns: "prospectiveListCampaigns"
};
var conversationCollectionName = function (socialNetwork) {
    var collectionRecord = {
        Instagram: exports.collectionName.instagramConversation,
        Twitter: exports.collectionName.twitterConversation
    };
    return collectionRecord[socialNetwork];
};
exports.conversationCollectionName = conversationCollectionName;
var socialNetworkCampaignsQueue = function (socialNetwork) {
    var collectionRecord = {
        Instagram: exports.collectionName.instagramCampaignsQueue,
        Twitter: exports.collectionName.twitterCampaignsQueue
    };
    return collectionRecord[socialNetwork];
};
exports.socialNetworkCampaignsQueue = socialNetworkCampaignsQueue;
var socialNetworkUserCollectionName = function (socialNetwork) {
    var collectionRecord = {
        Instagram: exports.collectionName.instagram,
        Twitter: exports.collectionName.twitter
    };
    return collectionRecord[socialNetwork];
};
exports.socialNetworkUserCollectionName = socialNetworkUserCollectionName;
