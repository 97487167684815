import {
  defineStyleConfig,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import {FC} from "react";

export const inputStyleConfig = defineStyleConfig({
  baseStyle: {
    field: {
      _disabled: {
        backgroundColor: "gray.50",
        border: "1px solid rgba(11, 13, 14, 0.1)",
      },
      _placeholder: {
        fontSize: "14px",
        color: "alpha.40",
      },
    },
  },
});

export type InputProps = ChakraInputProps;

export const Input: FC<InputProps> = (props) => {
  return (
    <ChakraInput
      // Explain: To prevent Platform-wide native keyboard shortcuts from triggering on typing.
      onKeyDown={(evt) => evt.stopPropagation()}
      onKeyUp={(evt) => evt.stopPropagation()}
      onKeyPress={(evt) => evt.stopPropagation()}
      focusBorderColor="primary.300"
      errorBorderColor="red.400"
      borderRadius="8px"
      fontSize="14px"
      borderColor="alpha.10"
      {...props}
    />
  );
};
