import {createAsyncThunk} from '@reduxjs/toolkit';

import {dsmToast} from '../../components/dsm-toast';
import {HttpsCallableResult} from '../../services/firebase';
import {ResponseErrorCode} from '../scheduled-messages/types';

import {addMembersToCampaign} from './bulk-send-service';
import {CampaignMemberData} from './types';

export const bulkSendMessage = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {template: string; members: CampaignMemberData[]}
>('bulk-send/bulk-send-message', async (data) => {
  try {
    return await addMembersToCampaign(data);
  } catch (e) {
    const {code, message} = e as {code: string; message: string};
    let failureMessage = 'Unable to send messages. Please try again.';

    if (code === ResponseErrorCode.RESOURCE_EXHAUSTED) {
      failureMessage = message;
    }

    dsmToast(failureMessage, 'error');
    throw e;
  }
});
