import {
  arrayTypeGuard,
  isLiteralObjectType,
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  NonNegativeInteger,
  objectTypeGuard,
} from "@workspace/type-utils";
import {createId, isItem, Item} from "./Item";
import {createdNow, CreationTime, isCreationTime, isUpdateTime, now, TIME, updatedNow, UpdateTime,} from "./time";
import {FollowUpIntervalOption} from "./FollowUpMessage";


export enum TargetCollectionOrigin {
  IG_FOLLOWERS = "IG_FOLLOWERS",
  IG_FOLLOWING = "IG_FOLLOWING",
  IG_HASHTAG = "IG_HASHTAG",
  IG_POST = "IG_POST",
  IG_LOCATION = "IG_LOCATION",
  LIST_UPLOAD = "LIST_UPLOAD",
  NO_ORIGIN = "NO_ORIGIN",
  TWEET = "TWEET",
  TW_FOLLOWERS = "TW_FOLLOWERS",
  TW_FOLLOWING = "TW_FOLLOWING",
  USER_LIST = "USER_LIST",
}

export const isTargetCollectionOrigin = objectTypeGuard<
  Exclude<Campaign["targetCollectionOrigin"], undefined>
>(({type, url}) => isNonEmptyString(type) && isNonEmptyString(url));

const FINISHED_CAMPAIGN_TIME_TO_LIVE = TIME.ONE_MINUTE * 30;
const CANCELLED_CAMPAIGN_TIME_TO_LIVE = TIME.ONE_MINUTE * 3;

export const getNumberOfPassedMessages = ({
  numSentMessages,
  failedMessageIndexes,
}: Pick<Campaign, "failedMessageIndexes" | "numSentMessages">) =>
  numSentMessages + (failedMessageIndexes?.length ?? 0);

export const CAMPAIGN_STATUS = {
  RUNNING: "RUNNING",
  PAUSED: "PAUSED",
  CANCELED: "CANCELED",
  FINISHED: "FINISHED",
  QUEUED: "QUEUED",
  ERROR: "ERROR",
  NOT_STARTED: "NOT_STARTED",
} as const satisfies Record<string, string>;

export type CampaignStatus = (typeof CAMPAIGN_STATUS)[keyof typeof CAMPAIGN_STATUS];

export const CAMPAIGN_TYPES = {
  FROM_DISCOVERY: "FROM_DISCOVERY",
  OTHER: "OTHER",
} as const

export type CampaignType = (typeof CAMPAIGN_TYPES)[keyof typeof CAMPAIGN_TYPES];

export const isCampaignStatus = isLiteralObjectType<CampaignStatus>(CAMPAIGN_STATUS);

export type CampaignMessage = {
  text: NonEmptyString;
  username: NonEmptyString;
};
export const isCampaignMessage = objectTypeGuard<CampaignMessage>(
  ({text, username}) => isNonEmptyString(text) && isNonEmptyString(username),
);
export const isCampaignMessages = arrayTypeGuard<CampaignMessage>(isCampaignMessage);

export type Campaign = Item &
  UpdateTime &
  CreationTime & {
    status: CampaignStatus;
    numSentMessages: NonNegativeInteger;
    failedMessageIndexes: number[];
    messages: CampaignMessage[];
    targetCollectionOrigin?: {handle: string; type: TargetCollectionOrigin; url: string};
    instagramId?: NonEmptyString;
    instagramHandle: NonEmptyString;
    instagramAvatar?: string;
    campaignType: CampaignType;
    followUpMessage?: string | null;
    followUpInterval?: FollowUpIntervalOption | null;
    sourceList?: NonEmptyString;
    isDeleted?: boolean;
    dataIsIncomplete?: boolean;
  };

export const isCampaign = objectTypeGuard<Campaign>(
  ({
    id,
    messages,
    numSentMessages,
    status,
    whenUpdated,
    whenCreated,
    targetCollectionOrigin,
    failedMessageIndexes,
     instagramHandle
  }) =>
    isItem({id}) &&
    isUpdateTime({whenUpdated}) &&
    isCreationTime({whenCreated}) &&
    isCampaignStatus(status) &&
    isNonNegativeInteger(numSentMessages) &&
    isCampaignMessages(messages) &&
    isNonEmptyString(instagramHandle) &&
    targetCollectionOrigin === undefined || isTargetCollectionOrigin(targetCollectionOrigin) &&
    Array.isArray(failedMessageIndexes),
);

export const isCampaigns = arrayTypeGuard<Campaign>(isCampaign);

export const newCampaign = ({
  messages,
  targetCollectionOrigin,
  instagramId,
  instagramHandle,
  instagramAvatar,
  campaignType,
  status,
  followUpMessage = null,
  followUpInterval = null,
  dataIsIncomplete = false,
  sourceList
}: Omit<Campaign, "numSentMessages" | "targetCollectionOrigin" | "failedMessageIndexes" | "isDeleted" | "whenCreated" | "whenUpdated" | "id">
  & { targetCollectionOrigin?: Campaign["targetCollectionOrigin"]
}) => ({
  id: createId(),
  numSentMessages: 0,
  failedMessageIndexes: [],
  status,
  messages,
  targetCollectionOrigin,
  instagramId,
  instagramHandle,
  instagramAvatar: instagramAvatar || "",
  isDeleted: false,
  campaignType,
  followUpMessage,
  followUpInterval,
  dataIsIncomplete,
  sourceList,
  ...updatedNow(),
  ...createdNow(),
});

export const nextCampaignMessage = ({
  status,
  numSentMessages,
  messages,
  failedMessageIndexes,
}: Pick<Campaign, "status" | "numSentMessages" | "messages" | "failedMessageIndexes">):
  | CampaignMessage
  | undefined => {
  if (status !== CAMPAIGN_STATUS.RUNNING) return undefined;

  return messages[getNumberOfPassedMessages({numSentMessages, failedMessageIndexes})];
};

export const campaignIsDone = ({
  status,
  numSentMessages,
  numMessages,
  failedMessageIndexes,
}: Pick<
  CampaignInfo,
  "status" | "numSentMessages" | "numMessages" | "failedMessageIndexes"
>): boolean => {
  if (status === CAMPAIGN_STATUS.FINISHED) return true;

  return (
    getNumberOfPassedMessages({numSentMessages, failedMessageIndexes}) >= numMessages
  );
};

export const canDeleteCampaign = ({
  whenUpdated,
  status,
  numSentMessages,
  numMessages,
  failedMessageIndexes,
  isDeleted
}: Pick<
  CampaignInfo,
  "status" | "numSentMessages" | "whenUpdated" | "numMessages" | "failedMessageIndexes" | "isDeleted"
>): boolean => {
  if(isDeleted) return false;

  if (status === CAMPAIGN_STATUS.CANCELED) {
    return CANCELLED_CAMPAIGN_TIME_TO_LIVE + whenUpdated < now();
  }

  if (campaignIsDone({status, numSentMessages, numMessages, failedMessageIndexes}))
    return FINISHED_CAMPAIGN_TIME_TO_LIVE + whenUpdated < now();

  return false;
};

export type CampaignInfo = Campaign & {
  numMessages: NonNegativeInteger;
};

export const isCampaignInfo = objectTypeGuard<CampaignInfo>(
  ({id, status, whenUpdated, numSentMessages, numMessages, failedMessageIndexes}) =>
    isItem({id}) &&
    isCampaignStatus(status) &&
    isNonNegativeInteger(numSentMessages) &&
    isNonNegativeInteger(numMessages) &&
    isUpdateTime({whenUpdated}) &&
    Array.isArray(failedMessageIndexes),
);

export const isCampaignsInfo = arrayTypeGuard<CampaignInfo>(isCampaignInfo);

export const campaignToCampaignInfo = ({
  ...campaign
}: Campaign): CampaignInfo => ({
  ...campaign,
  numMessages: campaign.messages.length,
});

export type CampaignMessageError = {
  message: NonEmptyString;
  timestamp: number;
  campaignId: NonEmptyString;
}
