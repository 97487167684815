import {SEPARATION_MARK} from '../../../helpers/constants';

/**
 * The text, which is received from the database, contains special separation marks,
 * so after it is broken into pieces by this mark, every second part has to be highlighted.
 */
const convertToHighlightedText = (text: string): string =>
  text
    .split(SEPARATION_MARK)
    .map((el, ind) =>
      ind % 2 !== 0 ? `<span class="highlighted">${el}</span>` : el
    )
    .join('');

export default convertToHighlightedText;
