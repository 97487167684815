import * as Sentry from "@sentry/browser";
import {firebaseFirestore, firebaseFunctions} from "@workspace/firebase-app";
import {HttpsCallableResult} from "../../services/firebase";
import {AppDispatch} from "../../store/store";
import {Listener} from "../conversation/types";

import {addScheduledMessages} from "./scheduled-messages-slice";
import {ScheduledMessage, ScheduledMessageStatus} from "./types";

const messagesStatusesToShow = [
  ScheduledMessageStatus.SCHEDULED,
  ScheduledMessageStatus.SENDING,
];

let listener: Listener | undefined = undefined;

export const releaseListener = (): void => {
  if (listener) {
    listener();
    listener = undefined;
  }
};

export const fetchScheduledMessages = async (
  conversationId: string,
  userId: string,
  dispatch: AppDispatch,
): Promise<ScheduledMessage[]> => {
  releaseListener();

  return new Promise(
    (resolve) =>
      (listener = firebaseFirestore
        .collection("users")
        .doc(userId)
        .collection("conversations")
        .doc(conversationId)
        .collection("scheduledMessages")
        .where("status", "in", messagesStatusesToShow)
        .orderBy("sendAt", "asc")
        .onSnapshot(
          (querySnapshot) => {
            const messagesData: ScheduledMessage[] = [];

            querySnapshot.forEach((doc) => {
              const data = doc.data();

              const message = {
                ...data,
                createdAt: data.createdAt?.toMillis(),
                sendAt: data.sendAt?.toMillis(),
              };

              messagesData.push(message as ScheduledMessage);
            });

            dispatch(addScheduledMessages({conversationId, messages: messagesData}));
            resolve(messagesData);
          },
          (e) => {
            console.error(e);
            Sentry.captureException(e);
          },
        )),
  );
};

export const setScheduledMessageOnCall = async (data: {
  conversationId: string;
  text: string;
  sendAt: number;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("scheduleMessageOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const deleteScheduledMessageOnCall = async (data: {
  conversationId: string;
  scheduledMessageId: string;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("deleteScheduledMessageOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const scheduleMessageToTwitterUserOnCall = async (data: {
  twitterUserId: string;
  text: string;
  sendAt: number;
}): Promise<{
  result: ScheduledMessage;
}> => {
  try {
    const response = await firebaseFunctions.httpsCallable(
      "scheduleMessageToTwitterUserOnCall",
    )(data);

    const {scheduledMessage} = response.data;

    return scheduledMessage;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
