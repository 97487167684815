"use strict";
exports.__esModule = true;
exports.isSendMessageResponsesToZapierInput = exports.isZapierMessageResponseRow = exports.isDeleteZapierWebhookInput = exports.isWriteZapierWebhookInput = exports.isReadZapierWebhookConfigOutput = exports.isZapierWebhookConfig = exports.isZapierWebhook = exports.isZapierWebhookType = exports.ZAPIER_WEBHOOK_TYPES = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.ZAPIER_WEBHOOK_TYPES = {
    MessageResponse: "MessageResponse"
};
exports.isZapierWebhookType = (0, type_utils_1.isLiteralObjectType)(exports.ZAPIER_WEBHOOK_TYPES);
exports.isZapierWebhook = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var url = _a.url, type = _a.type;
    return (0, type_utils_1.isNonEmptyString)(url) && (0, exports.isZapierWebhookType)(type);
});
exports.isZapierWebhookConfig = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var MessageResponse = _a.MessageResponse;
    return MessageResponse === undefined ? true : (0, exports.isZapierWebhook)(MessageResponse);
});
exports.isReadZapierWebhookConfigOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var MessageResponse = _a.MessageResponse;
    return MessageResponse === null ? true : (0, exports.isZapierWebhook)(MessageResponse);
});
exports.isWriteZapierWebhookInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var url = _a.url, type = _a.type;
    return (0, type_utils_1.isNonEmptyString)(url) && (0, exports.isZapierWebhookType)(type);
});
exports.isDeleteZapierWebhookInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var type = _a.type;
    return (0, exports.isZapierWebhookType)(type);
});
exports.isZapierMessageResponseRow = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var FROM = _a.FROM, TO = _a.TO, WHEN_SENT = _a.WHEN_SENT, WHEN_RECEIVED = _a.WHEN_RECEIVED, TEXT_SENT = _a.TEXT_SENT, TEXT_RECEIVED = _a.TEXT_RECEIVED, ELAPSED_DAYS = _a.ELAPSED_DAYS;
    return (0, models_1.isSocialNetworkHandle)(FROM) &&
        (0, models_1.isSocialNetworkHandle)(TO) &&
        (0, models_1.isDay)(WHEN_SENT) &&
        (0, models_1.isDay)(WHEN_RECEIVED) &&
        (0, type_utils_1.isNonEmptyString)(TEXT_SENT) &&
        (0, type_utils_1.isNonEmptyString)(TEXT_RECEIVED) &&
        (0, type_utils_1.isNonNegativeInteger)(ELAPSED_DAYS);
});
exports.isSendMessageResponsesToZapierInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var rows = _a.rows;
    return (0, type_utils_1.arrayTypeGuard)(exports.isZapierMessageResponseRow)(rows);
});
