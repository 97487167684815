"use strict";
exports.__esModule = true;
exports.MagicActionText = exports.isMagicDMAction = exports.MagicDMActions = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.MagicDMActions = {
    SOUND_LIKE_HUMAN: "SOUND_LIKE_HUMAN",
    POSITIVE_TONE: "POSITIVE_TONE",
    FIX_GRAMMAR: "FIX_GRAMMAR",
    SIMPLIFY_MESSAGE: "SIMPLIFY_MESSAGE",
    ASK_ANYTHING: "ASK_ANYTHING"
};
exports.isMagicDMAction = (0, type_utils_1.isLiteralObjectType)(exports.MagicDMActions);
exports.MagicActionText = {
    SOUND_LIKE_HUMAN: "Sound like a human",
    POSITIVE_TONE: "Rewrite in a positive tone",
    FIX_GRAMMAR: "Fix grammar",
    SIMPLIFY_MESSAGE: "Simplify message"
};
