"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.isUpdateCampaignDataInput = exports.isTriggerCampaignsCoolDownInput = exports.isCooldownType = exports.cooldownType = exports.isNotifySlackAboutCampaignErrorsInput = exports.isCampaignMessageError = exports.isSkipCampaignMessageInput = exports.isNextInstagramMessageOutput = exports.isIncreaseSentMessagesInput = exports.isNextInstagramMessageInput = exports.isUpdateInstagramCampaignsStatusesInput = exports.isReadInstagramCampaignsOutput = exports.isReadInstagramCampaignsInput = exports.isCreateInstagramCampaignOutput = exports.isCreateInstagramCampaignInput = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isCreateInstagramCampaignInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var instagramId = _a.instagramId, messages = _a.messages, targetCollectionOrigin = _a.targetCollectionOrigin, instagramHandle = _a.instagramHandle;
    return (0, models_1.isCampaignMessages)(messages) && (0, type_utils_1.isNonEmptyString)(instagramId) && (0, models_1.isTargetCollectionOrigin)(targetCollectionOrigin) && (0, type_utils_1.isNonEmptyString)(instagramHandle);
});
exports.isCreateInstagramCampaignOutput = (0, type_utils_1.objectTypeGuard)(models_1.isCampaignInfo);
exports.isReadInstagramCampaignsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var instagramId = _a.instagramId;
    return (0, type_utils_1.isNonEmptyString)(instagramId);
});
exports.isReadInstagramCampaignsOutput = models_1.isCampaignsInfo;
var isUpdateInstagramCampaignStatus = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var campaignId = _a.campaignId, status = _a.status;
    return (0, type_utils_1.isNonEmptyString)(campaignId) &&
        (0, models_1.isCampaignStatus)(status);
});
exports.isUpdateInstagramCampaignsStatusesInput = (0, type_utils_1.arrayTypeGuard)(isUpdateInstagramCampaignStatus);
exports.isNextInstagramMessageInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var instagramId = _a.instagramId, schedulerId = _a.schedulerId;
    return (0, type_utils_1.isNonEmptyString)(instagramId) && (0, type_utils_1.isNonEmptyString)(schedulerId);
});
exports.isIncreaseSentMessagesInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var campaignId = _a.campaignId, schedulerId = _a.schedulerId, messageId = _a.messageId, threadId = _a.threadId, instagramId = _a.instagramId, extensionVersion = _a.extensionVersion;
    return (0, type_utils_1.isNonEmptyString)(campaignId) && (0, type_utils_1.isNonEmptyString)(schedulerId) && (0, type_utils_1.isNonEmptyString)(messageId) && (0, type_utils_1.isNonEmptyString)(threadId) && (0, type_utils_1.isNonEmptyString)(instagramId) && (0, type_utils_1.isNonEmptyString)(extensionVersion);
});
exports.isNextInstagramMessageOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var campaignId = _a.campaignId, message = _a.message;
    return (0, models_1.isCampaignMessage)(message) && (0, models_1.isItem)({ id: campaignId });
});
exports.isSkipCampaignMessageInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var campaignId = _a.campaignId, schedulerId = _a.schedulerId;
    return (0, type_utils_1.isNonEmptyString)(campaignId) &&
        (0, type_utils_1.isNonEmptyString)(schedulerId);
});
exports.isCampaignMessageError = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var message = _a.message, timestamp = _a.timestamp, campaignId = _a.campaignId;
    return (0, type_utils_1.isNonEmptyString)(message) && (0, type_utils_1.isNonNegativeInteger)(timestamp) && (0, type_utils_1.isNonEmptyString)(campaignId);
});
exports.isNotifySlackAboutCampaignErrorsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var errors = _a.errors, extensionVersion = _a.extensionVersion;
    return (0, type_utils_1.arrayTypeGuard)(exports.isCampaignMessageError)(errors) && (0, type_utils_1.isNonEmptyString)(extensionVersion);
});
var cooldownTypes = [
    'frequentErrors',
    'instagramErrors',
    'genericCooldown',
];
exports.cooldownType = cooldownTypes.reduce(function (obj, key) {
    var _a;
    return (__assign(__assign({}, obj), (_a = {}, _a[key] = key, _a)));
}, {});
exports.isCooldownType = (0, type_utils_1.isLiteralType)(cooldownTypes);
exports.isTriggerCampaignsCoolDownInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var extensionVersion = _a.extensionVersion, cooldownType = _a.cooldownType;
    return (0, type_utils_1.isNonEmptyString)(extensionVersion) && (0, exports.isCooldownType)(cooldownType);
});
exports.isUpdateCampaignDataInput = (0, type_utils_1.objectTypeGuard)(models_1.isCampaignInfo);
