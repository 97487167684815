import {firebaseFunctions} from "@workspace/firebase-app";
import {Time, TIME} from "@workspace/models";
import {Box, Flex, Spinner, Text, VerifyEmailBlock} from "@workspace/ui";
import {FC, useCallback, useContext, useState} from "react";
import {dsmToast} from "src/components/dsm-toast";
import InnerAppLayout from "src/components/inner-app-layout";
import {UserAuthContext} from "src/contexts";
import {useAppSelector} from "src/hooks";

const RESEND_EMAIL_VERIFICATION_COOL_DOWN = TIME.ONE_MINUTE * 2;

const canResend = (timestamp?: Time) => {
  return !timestamp || timestamp + RESEND_EMAIL_VERIFICATION_COOL_DOWN <= Date.now();
};

export const VerifyEmail: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userAuth = useContext(UserAuthContext);
  const user = useAppSelector((state) => state.user.user);

  const handleResend = useCallback(async () => {
    if (isLoading) return;
    if (
      user?.lastResendEmailVerificationAt &&
      !canResend(user.lastResendEmailVerificationAt)
    ) {
      dsmToast(`You've sent the email less than two minutes ago`, "default");

      return;
    }
    try {
      setIsLoading(true);
      await firebaseFunctions.httpsCallable("resendVerificationEmailOnCall")();

      dsmToast("Email successfully resent", "success");
    } catch {
      dsmToast("Something went wrong", "error");
    } finally {
      setIsLoading(false);
    }
  }, [user, isLoading]);

  if (!userAuth?.email) return null;

  return (
    <InnerAppLayout hasBasicHeader showTrialInfo shouldRenderSwitchToDesktop={false}>
      <Flex
        pt="168px"
        justifyContent="center"
        alignItems="flex-start"
        bg="#F4F4F9"
        height="100%"
      >
        <Box bg="white" padding="64px" borderRadius="16px" mx="6">
          <VerifyEmailBlock email={userAuth.email}>
            <Flex
              mt="6"
              position="relative"
              alignItems="center"
              justifyContent="flex-end"
              gap="1"
              color="#2621E3"
              fontWeight="bold"
              userSelect="none"
              fontSize="h6"
              cursor={!isLoading ? "pointer" : "not-allowed"}
            >
              <Text onClick={handleResend}>Resend email</Text>
              {isLoading && <Spinner size="xs" />}
            </Flex>
          </VerifyEmailBlock>
        </Box>
      </Flex>
    </InnerAppLayout>
  );
};
