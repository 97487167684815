import { jsx as _jsx } from "react/jsx-runtime";
import { firebaseFunctions } from "@workspace/firebase-app";
import { isCloudFunctionInput } from "@workspace/firebase-datamodel";
import { cloudFunctionName } from "@workspace/firebase-definitions";
import { createContext, useCallback, useEffect, useMemo, useRef, useState, } from "react";
import { useFirebaseUser } from "../hooks";
export const BlockedTargetsContext = createContext({});
BlockedTargetsContext.displayName = "BlockedTargets";
export const BlockedTargetsProvider = ({ children }) => {
    const { isAuthenticated, userInfo } = useFirebaseUser();
    const didFetchRef = useRef(false);
    const [blockedTargets, setBlockedTargets] = useState([]);
    const blockedTargetIdsSet = useMemo(() => new Set(blockedTargets.map((target) => target.id)), [blockedTargets]);
    const handleBlockTarget = useCallback((target) => {
        try {
            if (!isCloudFunctionInput.blockTarget(target)) {
                throw new Error('Invalid BlockTargetOperation["in"]');
            }
            firebaseFunctions.httpsCallable(cloudFunctionName.blockTarget)(target);
        }
        catch (err) {
            console.error(err);
        }
    }, []);
    const isTargetBlocked = useCallback((targetId) => {
        return blockedTargetIdsSet.has(targetId);
    }, [blockedTargetIdsSet]);
    useEffect(() => {
        if (isAuthenticated && userInfo && !didFetchRef.current) {
            const fetchBlockedTargets = async () => {
                const response = await firebaseFunctions.httpsCallable(cloudFunctionName.getBlockedTargets)();
                const fetchedBlockedTargets = (response.data ?? []);
                setBlockedTargets(fetchedBlockedTargets);
            };
            fetchBlockedTargets();
            didFetchRef.current = true;
        }
    }, [isAuthenticated, userInfo]);
    const value = useMemo(() => ({
        blockTarget: handleBlockTarget,
        isTargetBlocked,
        blockedTargets,
    }), [blockedTargets, isTargetBlocked, handleBlockTarget]);
    return (_jsx(BlockedTargetsContext.Provider, { value: value, children: children }));
};
