import discoveryWelcomeStepImage from "../../assets/images/discovery-welcome-step.svg";
import discoveryAudienceStepImage from "../../assets/images/discovery-audience-step.svg";
import discoveryLocationStepImage from "../../assets/images/discovery-location-step.svg";
import discoveryInterestsStepImage from "../../assets/images/discovery-interests-step.svg";
import discoveryProspectsStepImage from "../../assets/images/discovery-prospects-step.svg";
import discoveryHashtagsStepImage from "../../assets/images/discovery-hashtags-step.svg";
import discoveryICPStepImage from "../../assets/images/discovery-icp-step.svg";
import discoveryPricingStepImage from "../../assets/images/discovery-pricing-step.svg";

export const DISCOVERY_LOCAL_STORAGE_KEY = "discovery_eight_steps";

export const setWelcomeEightToLocalStorage = (item: Record<string, unknown>) => {
  try {
    localStorage.setItem(DISCOVERY_LOCAL_STORAGE_KEY, JSON.stringify(item));
  } catch (err) {
    console.error(err);
  }
};

export const getWelcomeEightFromLocalStorage = () => {
  try {
    const item = localStorage.getItem(DISCOVERY_LOCAL_STORAGE_KEY);

    if (!item) return null;

    const parsedItem = JSON.parse(item);

    if (!parsedItem || typeof parsedItem !== "object" || Array.isArray(parsedItem))
      return null;

    return parsedItem as Record<string, unknown>;
  } catch {
    return null;
  }
};

export const IMAGES_CONFIG = [
  {
    src: discoveryWelcomeStepImage,
    parentProps: {
      pl: {
        base: "6",
        xl: "12",
      },
    },
  },
  {
    src: discoveryAudienceStepImage,
    parentProps: {
      pl: {
        base: "12",
        xl: "104px",
      },
      pr: {
        base: "12",
        xl: "104px",
      },
    },
  },
  {
    src: discoveryLocationStepImage,
    parentProps: {
      pl: {
        base: "8",
        xl: "80px",
      },
      pr: {
        base: "8",
        xl: "80px",
      },
    },
  },
  {
    src: discoveryInterestsStepImage,
    parentProps: {
      pl: {
        base: "12",
        xl: "128px",
      },
      pr: {
        base: "12",
        xl: "128px",
      },
    },
  },
  {
    src: discoveryProspectsStepImage,
    parentProps: {
      pl: {
        base: "12",
        xl: "128px",
      },
      pr: {
        base: "12",
        xl: "128px",
      },
    },
  },
  {
    src: discoveryHashtagsStepImage,
    parentProps: {
      pl: {
        base: "8",
        xl: "80px",
      },
      pr: {
        base: "8",
        xl: "80px",
      },
    },
  },
  {
    src: discoveryICPStepImage,
    parentProps: {
      pl: {
        base: "13",
        xl: "136px",
      },
      pr: {
        base: "13",
        xl: "136px",
      },
    },
  },
  {
    src: discoveryPricingStepImage,
    parentProps: {
      pl: {
        base: "12",
        xl: "121px",
      },
      pr: {
        base: "12",
        xl: "121px",
      },
    },
  },
  {
    src: discoveryWelcomeStepImage,
    parentProps: {
      pl: {
        base: "6",
        xl: "12",
      },
    },
  },
];
