import * as Sentry from "@sentry/browser";
import {firebaseAuth, firebaseFunctions} from "@workspace/firebase-app";
import type firebase from "firebase/compat/app";
import { DISCOVERY_LOCAL_STORAGE_KEY } from "src/pages/Discovery/consts";

import {clearLocalStorage} from "../../helpers/local-storage-helpers";
import routes from "../../router/routes";
import {sendNotification} from "../../services/notifications-service";
import {AppDispatch} from "../../store/store";
import {releaseListeners as releaseConversationsListeners} from "../conversation/conversations-service";
import {releaseListener as releaseMessagesListener} from "../message/message-service";
import {resetApp} from "../user/user-slice";

import {EXTENSION_REDIRECT_PATH} from "./helpers/constants";

export type FirebaseAuthResponse = firebase.auth.Error | firebase.auth.UserCredential;

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<FirebaseAuthResponse> => {
  try {
    return firebaseAuth
      .setPersistence("local")
      .then(() => firebaseAuth.signInWithEmailAndPassword(email, password))
      .then((user: firebase.auth.UserCredential) => {
        // Signed in
        return user;
      })
      .catch((error: firebase.auth.Error) => {
        // For error codes and more info -
        // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
        return error;
      });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw e;
  }
};

export const logout = async (dispatch: AppDispatch): Promise<void> => {
  releaseMessagesListener();
  releaseConversationsListeners();
  await firebaseAuth.signOut();
  clearLocalStorage(["inboxOrder", EXTENSION_REDIRECT_PATH, DISCOVERY_LOCAL_STORAGE_KEY]);
  dispatch(resetApp());
};

export const register = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<FirebaseAuthResponse> => {
  try {
    const response = await firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(async (user: firebase.auth.UserCredential) => {
        // Signed in
        await sendNotification({text: `New user registered: ${email}`});
        return user;
      })
      .catch((error: firebase.auth.Error) => {
        // For error codes and more info -
        // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#createuserwithemailandpassword
        return error;
      });
    return response;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    throw e;
  }
};

export const getIdToken = async ({
  forceRefresh = true,
}: {
  forceRefresh?: boolean;
} = {}): Promise<string | undefined> => {
  try {
    return await firebaseAuth.currentUser?.getIdToken(forceRefresh);
  } catch (e) {
    throw e;
  }
};

// https://firebase.google.com/docs/auth/web/manage-users#send_a_password_reset_email
export const sendResetPasswordLink = (email: string): Promise<void> => {
  const {protocol, host} = window.location;
  const actionSettings = {
    url: `${protocol}//${host}${routes.Action.route}`,
  };

  return new Promise((resolve, reject) => {
    firebaseAuth
      .sendPasswordResetEmail(email, actionSettings)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export const changePassword = async (
  code: string,
  password: string,
): Promise<firebase.auth.Error | void> => {
  try {
    return firebaseAuth
      .confirmPasswordReset(code, password)
      .catch((error: firebase.auth.Error) => error);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const signInWithIdToken = async (
  idToken: string,
): Promise<FirebaseAuthResponse | undefined> => {
  try {
    const response = await firebaseFunctions.httpsCallable("createCustomTokenOnCall")({
      idToken,
    });

    if (
      !response.data ||
      typeof response.data !== "object" ||
      typeof response.data.token !== "string"
    )
      return;

    const customToken: string = response.data.token;

    if (!customToken) return;

    if (customToken) {
      await firebaseAuth.signInWithCustomToken(customToken);
      return firebaseAuth.signInWithCustomToken(customToken);
    }
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);

    return;
  }
};
