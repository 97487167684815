import * as yup from "yup";
import {Box, chakra, Flex, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import {hasOwnProperty} from "../../../helpers/has-own-property";
import useMixpanel from "../../../helpers/hooks/use-mixpanel";
import routes from "../../../router/routes";
import {signIn} from "../auth-service";
import {AuthWrapperWithBanner, NewAuthTitle} from "../components/auth-layout-components";
import PasswordInput from "../components/password-input";

import {useDsmChromeExtension} from "src/hooks";
import {Button, colorName, Field, Input} from "@workspace/ui";
import {isFiniteString, isMaybeObject, isNonEmptyString} from "@workspace/type-utils";

const schema = yup.object({
  email: yup.string().trim().required("Email is required").email("Must be a valid email"),
  password: yup.string().trim().required("Password is required"),
});

const isLoginFieldName = (arg: unknown): arg is keyof yup.InferType<typeof schema> => {
  return isFiniteString(arg) && Object.keys(schema.fields).includes(arg);
};

const StyledLink = chakra(Link);

const Login: React.FC = () => {
  const {extensionSignIn} = useDsmChromeExtension();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<
    Partial<Record<keyof yup.InferType<typeof schema>, string>>
  >({});
  const [isSigningIn, setIsSigningIn] = useState(false);

  const mixpanel = useMixpanel();
  const submitHandler = async () => {
    try {
      const validData = await schema.validate({email, password}, {abortEarly: false});

      setIsSigningIn(true);

      const response = await signIn({
        email: validData.email,
        password: validData.password,
      });

      if (response && hasOwnProperty(response, "user") && response.user) {
        // @ts-ignore
        const token = await response.user.getIdToken();
        extensionSignIn({
          token,
          // @ts-ignore
          uid: response.user.uid,
        });
        mixpanel?.track("web.general.auth.login", {success: true});
      } else {
        setErrors({email: "Incorrect email", password: "Incorrect password"});
      }

      setIsSigningIn(false);
    } catch (err) {
      if (!isMaybeObject<yup.ValidationError>(err)) return;
      if (!Array.isArray(err.inner)) return;

      setErrors(
        err.inner.reduce<typeof errors>((acc, curr) => {
          if (
            isMaybeObject<yup.ValidationError["inner"][number]>(curr) &&
            isLoginFieldName(curr.path) &&
            isNonEmptyString(curr.message)
          ) {
            acc[curr.path] = curr.message;
          }

          return acc;
        }, {}),
      );
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <AuthWrapperWithBanner>
      <NewAuthTitle title="Log in" />
      <Text color={colorName.gray600} mb="10" fontSize="h6">
        Don’t have an account?{" "}
        <StyledLink
          to={routes.CreateAccount.route}
          color="primary.400"
          ml="2"
          fontWeight="600"
        >
          Sign up
        </StyledLink>
      </Text>
      <form onSubmit={(e) => e.preventDefault()}>
        <Field label="Email" isError={!!errors.email} helpText={errors.email} mb={4}>
          <Input
            id="email-input"
            value={email}
            isInvalid={!!errors.email}
            fontSize="base"
            onChange={(e) => setEmail(e.target.value)}
            data-cy="sign-in-email"
          />
        </Field>
        <Box mb="6">
          <PasswordInput
            label="Password"
            value={password}
            saveValue={setPassword}
            fieldProps={{
              isError: !!errors.password,
              helpText: errors.password,
            }}
          />
        </Box>
        <Button
          type="submit"
          w="100%"
          isLoading={isSigningIn}
          loadingText="Log in"
          onClick={submitHandler}
          data-cy="sign-in-submit"
          size="lg"
          mb="28px"
        >
          Log in
        </Button>

        <Flex justifyContent="center" fontSize="h6">
          <StyledLink to={routes.ForgotPassword.route} color="#2621E3" fontWeight="600">
            Forgot password
          </StyledLink>
        </Flex>
      </form>
    </AuthWrapperWithBanner>
  );
};

export default Login;
