import {SocialNetworkUser} from "./SocialNetworkUser";
import {FirebaseUserInfo} from "@workspace/firebase-datamodel";
import {now, TIME, Time, UpdateTime} from "./time";
import {NonEmptyString} from "@workspace/type-utils";

export type DiscoveryQueue = UpdateTime & {
  activeSchedulerId?: NonEmptyString;
};

export const DiscoveryStages = {
  GENERATE_POSTS: "GENERATE_POSTS",
  GENERATE_TARGETS: "GENERATE_TARGETS",
  IDLE: "IDLE",
  COMPLETED: "COMPLETED",
} as const satisfies Record<string, string>;

export type DiscoveryStage = (typeof DiscoveryStages)[keyof typeof DiscoveryStages];

export type DiscoveryTarget = SocialNetworkUser & {
  followers: string;
  following: string;
  posts: string;
  isVerified: boolean;
  comment: string;
  isUsed: boolean;
  hashtag?: string;
  postCaption?: string;
};

export type DiscoveryPost = {
  link: string;
  nextCursor?: string;
  caption?: string;
  type: "post" | "hashtag" | "location";
};

export const SELLINGS = [
  "Coaching",
  "Real estate",
  "Financial services",
  "Consulting",
  "Personal training",
  "Legal",
  "Other",
] as const;

export type Selling = (typeof SELLINGS)[number];

export const BUSINESS_TYPE = [
  "Individual",
  "Company",
] as const;

export type BusinessType = (typeof BUSINESS_TYPE)[number];

export const AUDIENCES = [
  "Professionals",
  "Businesses",
  "General consumers",
  "Content creators",
] as const;

export type Audience = (typeof AUDIENCES)[number];

export const PRICINGS = [
  "under500",
  "between500and1000",
  "between1000and2500",
  "between2500and10000",
  "over10000",
] as const satisfies readonly string[];

export type Pricing = (typeof PRICINGS)[number];

export type Discovery = {
  userId: FirebaseUserInfo["uid"];
  selling: Selling;
  otherService?: string;
  audience: Audience;
  lastSentCanCreateCampaignEmail?: Time;
  lastPing?: Time;
  descriptionOfMainClients: string;
  clientLocation: string[];
  clientInterests: string;
  ICPExamples: string[];
  topInstagramPages: string[];
  pricing: Pricing;
  extraPreferences: string;
  currentStage: DiscoveryStage;
  activePage: number;
  generatedPosts: Array<DiscoveryPost>;
  activePost: number;
  collectedTargetsCount: number;
  usedTargetsCount: number;
  lastCreatedCampaign: number | null;
  instagramSession: string;
  hashtags: string[];
  isLoggedOut?: boolean;
  businessType: BusinessType;
  linkToOffer?: string;
  companyName?: string;
  website?: string;
};

export type NewDiscovery = Omit<
  Discovery,
  "activePost" | "activePage" | "currentStage" | "generatedTargets"
>;

export const newDiscovery = (
  discovery: Omit<
    Discovery,
    | "activePost"
    | "activePage"
    | "currentStage"
    | "generatedTargets"
    | "generatedPosts"
    | "collectedTargetsCount"
    | "usedTargetsCount"
  >,
): Discovery => ({
  ...discovery,
  currentStage: DiscoveryStages.GENERATE_POSTS,
  activePage: 0,
  activePost: 0,
  generatedPosts: [] as Array<DiscoveryPost>,
  collectedTargetsCount: 0,
  usedTargetsCount: 0,
  hashtags: []
});

export const getNumberOfRemainingTargets = (discovery: Discovery) => {
  return (discovery.collectedTargetsCount || 0) - (discovery.usedTargetsCount || 0);
};

export const DISCOVERY_IDLE_TIME = TIME.ONE_MINUTE * 10;
export const discoveryIsIdle = (discovery: Discovery) => {
  if (discovery.lastPing === undefined) return true;

  const lastUpdatedInterval = now() - discovery.lastPing;

  return lastUpdatedInterval > DISCOVERY_IDLE_TIME;
};

export const DiscoveryUIStages = {
  NOT_CREATED: "NOT_CREATED",
  COLLECTING: "COLLECTING",
  IDLE: "IDLE",
  CAN_CREATE_CAMPAIGN: "CAN_CREATE_CAMPAIGN",
  CREATED_FOR_TODAY: "CREATED_FOR_TODAY",
  IS_LOGGED_OUT: "IS_LOGGED_OUT",
} as const satisfies Record<string, string>;

export type DiscoveryUIStage = (typeof DiscoveryUIStages)[keyof typeof DiscoveryUIStages];

export type DiscoveryData = {
  status: DiscoveryUIStage;
  targets?: DiscoveryTarget[];
  discoveryId?: string;
  isCampaignCreatedAndNotStarted?: boolean;
  campaignInstagramHandle?: string;
  campaignId?: string;
  whenUpdated?: Time;
  lastSentCanCreateCampaignEmail?: Time;
};

export type DiscoveryReport = {
  currentCollectedCount: number;
  timestamp: number;
  errors: string[];
  targetsCollected: number;
  action: DiscoveryStage;
  validatedTargets: number;
}

export const AUTH_ERROR_KEY = "auth-error";
