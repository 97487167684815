import {isLiteralObjectType, isNonNegativeInteger, objectTypeGuard,} from "@workspace/type-utils";

export const COMMISSION_TYPE = {
  NEW_PLAN: "new-plan",
  ACTIVE_PLAN: "active-plan",
  PENDING: "pending",
} as const satisfies Record<string, string>

export type CommissionType = typeof COMMISSION_TYPE[keyof typeof COMMISSION_TYPE];

export type Commission = {
  commissionType: CommissionType
  commissionId?: string
}

export const isCommission = objectTypeGuard<Commission>(({ commissionType, commissionId }) =>
  isLiteralObjectType<CommissionType>(COMMISSION_TYPE)(commissionType) &&
  (commissionId === undefined || isNonNegativeInteger(commissionId))
)
