import * as Sentry from "@sentry/browser";
import {firebaseAuth} from "@workspace/firebase-app";
import {FirebaseUserInfo} from "@workspace/firebase-datamodel";
import {FC, PropsWithChildren, createContext, useEffect, useMemo, useState} from "react";
import {hasOwnProperty} from "src/helpers/has-own-property";
import {useWatchAuthCookieChange} from "src/hooks";

import {useAppDispatch} from "../app-hooks";
import clearUserInSentry from "../helpers/clear-user-in-sentry";
import useMixpanel from "../helpers/hooks/use-mixpanel";
import {setUserListener} from "../modules/user/thunks";

/**
The `User` type at first it is `undefined`, until it is observed by `onAuthStateChanged`;
then it is `firebase.User` or `null` if user is authenticated or not.
*/
type User = FirebaseUserInfo | null | undefined;

export const UserAuthContext = createContext<User>(undefined);

export const UserAuthProvider: FC<PropsWithChildren> = ({children}) => {
  const dispatch = useAppDispatch();
  const mixpanel = useMixpanel();

  const {checkIsSynced} = useWatchAuthCookieChange();

  const [auth, setAuth] = useState<{
    user: User;
    previousState?: undefined | "signIn" | "signOut";
  }>({user: undefined});

  const uid = useMemo(() => auth?.user?.uid, [auth]);
  const email = useMemo(() => auth?.user?.email, [auth]);

  const [isSyncChecked, setIsSyncChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      try {
        if (!user && !isSyncChecked) return;
        setAuth((state) => {
          if (state.user === undefined) return {user};
          return {
            previousState: user ? "signIn" : "signOut",
            user,
          };
        });
      } catch (error) {
        console.error(error);
      }
    });

    return () => unsubscribe();
  }, [isSyncChecked]);

  useEffect(() => {
    if (auth.previousState === "signOut") {
      clearUserInSentry();
    }
  }, [auth]);

  useEffect(() => {
    if (isSyncChecked) return;
    const handleCheck = async () => {
      const response = await checkIsSynced();

      if (response && hasOwnProperty(response, "user")) {
        setAuth({user: response.user as User});
      }

      setIsSyncChecked(true);
    };

    handleCheck();
  }, [isSyncChecked, checkIsSynced]);

  useEffect(() => {
    try {
      if (!uid) return;
      Sentry.setUser({id: uid, email: email ?? undefined});
      dispatch(setUserListener(uid));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, uid, email]);

  useEffect(() => {
    if (!uid) return;
    if (!mixpanel) return;
    mixpanel.identify(uid);
  }, [mixpanel, uid]);

  if (auth.user === undefined) return null;

  return (
    <UserAuthContext.Provider value={auth.user}>{children}</UserAuthContext.Provider>
  );
};
