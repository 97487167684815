import {IsNever} from "type-samurai";
import {FeatureName} from "@workspace/firebase-datamodel";

export const FEATURE_NAME_LABEL: Record<FeatureName, string> = {
  collect_leads_from_engagement: "Collect leads from engagement",
  custom_message_templates: "Custom templates",
  manage_campaigns: "Manage campaigns",
  list_upload: "List upload",
  magic_dm: "Magic DMs ✨",
  follow_up_messages: "Follow-up messages",
  scheduling_for_the_next_day: "Scheduling for the next day",
};

// TODO Kamil replace
export type Permutation<T, U = T> = IsNever<T> extends true
  ? []
  : T extends T
  ? [T, ...Permutation<Exclude<U, T>>]
  : [];

export const FEATURES_RENDER_ORDER: Permutation<FeatureName> = [
  "collect_leads_from_engagement",
  "custom_message_templates",
  "manage_campaigns",
  "list_upload",
  "magic_dm",
  "follow_up_messages",
  "scheduling_for_the_next_day",
];
