"use strict";
exports.__esModule = true;
exports.isNonEmptyString = exports.isFiniteString = void 0;
var stringMaxLength = 2048;
var isFiniteString = function (arg) {
    return typeof arg === "string" && arg.length <= stringMaxLength;
};
exports.isFiniteString = isFiniteString;
var isNonEmptyString = function (arg) {
    return (0, exports.isFiniteString)(arg) && arg !== "";
};
exports.isNonEmptyString = isNonEmptyString;
