import {isSocialNetworkHandle, SocialNetworkHandle, socialNetworkHostname,} from "@workspace/models";
import {isFiniteString, isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";

/**

@example
```json
{
    "pk": "1433901581",
    "username": "sandra.cvjet",
    "full_name": "𝕾𝖆𝖓𝖉𝖗𝖆 𝕮𝖛𝖏𝖊𝖙𝖎𝖈𝖆𝖓𝖎𝖓",
    "is_private": true,
    "pk_id": "1433901581",
    "profile_pic_url": "https://instagram.fmxp5-1.fna.fbcdn.net/v/t51.2885-19/219781122_947092819189609_7565128596778848674_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fmxp5-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=0gOrt9ryu1EAX87iU0U&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfAp-PhBKoQSysqdEFxBniYOjk6DXuBTBzKngpxl649Tzw&oe=636EBE35&_nc_sid=a9513d",
    "profile_pic_id": "2622107354309105557_1433901581",
    "is_verified": false
}
```
*/
export type InstagramUser = {
  /** User id, e.g. `"1962597440"`. */
  pk: NonEmptyString;
  is_private: boolean;
  is_verified: boolean;
  full_name: NonEmptyString;
  username: SocialNetworkHandle;
  /** Social Network avatar URL. */
  profile_pic_url: NonEmptyString;
  profile_pic_id: NonEmptyString;
};

export const isInstagramUser = objectTypeGuard<InstagramUser>(
  ({pk, full_name, is_private, is_verified, username, profile_pic_url}) =>
    isNonEmptyString(pk) &&
    isFiniteString(full_name) &&
    typeof is_private === "boolean" &&
    typeof is_verified === "boolean" &&
    typeof profile_pic_url === "string" &&
    isSocialNetworkHandle(username),
);

export const instagramUserProfileUrl = (arg: InstagramUser["username"]) =>
  `https://${socialNetworkHostname["Instagram"]}/${arg}/`;
