"use strict";
exports.__esModule = true;
exports.isCommission = exports.COMMISSION_TYPE = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.COMMISSION_TYPE = {
    NEW_PLAN: "new-plan",
    ACTIVE_PLAN: "active-plan",
    PENDING: "pending"
};
exports.isCommission = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var commissionType = _a.commissionType, commissionId = _a.commissionId;
    return (0, type_utils_1.isLiteralObjectType)(exports.COMMISSION_TYPE)(commissionType) &&
        (commissionId === undefined || (0, type_utils_1.isNonNegativeInteger)(commissionId));
});
