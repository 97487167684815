"use strict";
exports.__esModule = true;
exports.isCreateCheckoutSessionOutput = exports.isCreateCheckoutSessionInput = exports.isCheckoutSessionStripeMetadata = void 0;
var type_utils_1 = require("@workspace/type-utils");
var PricingPlan_1 = require("./PricingPlan");
exports.isCheckoutSessionStripeMetadata = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var uid = _a.uid, pricingPlan = _a.pricingPlan;
    return (0, type_utils_1.isNonEmptyString)(uid) && (0, PricingPlan_1.isPricingPlanKey)({ pricingPlan: pricingPlan });
});
exports.isCreateCheckoutSessionInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var pricingPlan = _a.pricingPlan;
    return (0, PricingPlan_1.isPricingPlanKey)({ pricingPlan: pricingPlan });
});
exports.isCreateCheckoutSessionOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var url = _a.url;
    return (0, type_utils_1.isNonEmptyString)(url);
});
