"use strict";
exports.__esModule = true;
exports.isGenerateTestingMessagesInput = exports.isGenerateTestingTargetsInput = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.isGenerateTestingTargetsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var count = _a.count;
    return (0, type_utils_1.isNonNegativeInteger)(count);
});
exports.isGenerateTestingMessagesInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var count = _a.count;
    return (0, type_utils_1.isNonNegativeInteger)(count);
});
