import {helpEmail} from "@workspace/locators";
import {FC, useCallback} from "react";
import {Flex, Link, Text} from "@chakra-ui/react";
import {Heading} from "@workspace/ui";

import {Layout} from "../components/layout";
import InnerAppLayout from "../../../components/inner-app-layout";

import {OutlinedBox} from "src/components";
import {DsmSolidButton} from "src/components/buttons";
import {useHistory} from "react-router";
import {routes} from "src/router";

export const TrialExpired: FC = () => {
  const history = useHistory();

  const handleUpgradePlan = useCallback(() => {
    history.push(routes.PaymentPlans.route);
  }, [history]);

  return (
    <InnerAppLayout hasBasicHeader showTrialInfo>
      <Layout>
        <OutlinedBox>
          <Heading variant="h3" color="gray.900" mb="4">
            Your trial has expired
          </Heading>
          <Text fontSize="baseMajor" fontWeight="450" color="gray.900" mb="8">
            Please upgrade now to continue using Howdy
          </Text>
          <DsmSolidButton onClick={handleUpgradePlan} mb="8">
            Upgrade plan
          </DsmSolidButton>
          <Flex
            alignItems="center"
            justifyContent="center"
            fontWeight="450"
            fontSize="baseMinor"
            gap="1"
          >
            <Text color="gray.500">or</Text>
            <Link textDecoration="underline" href={`mailto:${helpEmail}`}>
              Contact us
            </Link>
          </Flex>
        </OutlinedBox>
      </Layout>
    </InnerAppLayout>
  );
};
