import {keyframes} from "@chakra-ui/react";

/**
 * @example
 * ```tsx
 * <Flex animation={`${spinKeyframes 5s infinite linear}`}">
 *   {icon}
 * </Flex>
 * ```
 */
export const spinKeyframes = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
