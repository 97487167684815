"use strict";
exports.__esModule = true;
exports.isGenerateMagicDMOutput = exports.isGenerateMagicDMInput = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isGenerateMagicDMInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var action = _a.action, text = _a.text;
    return (0, type_utils_1.isNonEmptyString)(text) && (0, models_1.isMagicDMAction)(action);
});
exports.isGenerateMagicDMOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var message = _a.message;
    return (0, type_utils_1.isNonEmptyString)(message);
});
