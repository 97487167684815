"use strict";
exports.__esModule = true;
exports.isReadMessagesDailyCountOutput = exports.isScheduledMessagesDailyCount = exports.isMessagesDailyCount = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isMessagesDailyCount = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var day = _a.day, twitter = _a.twitter, instagram = _a.instagram, instagramSkipped = _a.instagramSkipped;
    return (0, models_1.isDay)(day) &&
        (0, type_utils_1.isNonNegativeInteger)(twitter) &&
        (0, type_utils_1.isNonNegativeInteger)(instagram) &&
        (0, type_utils_1.isNonNegativeInteger)(instagramSkipped);
});
exports.isScheduledMessagesDailyCount = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var day = _a.day, twitter = _a.twitter, instagram = _a.instagram;
    return (0, models_1.isDay)(day) &&
        (0, type_utils_1.isNonNegativeInteger)(twitter) &&
        (0, type_utils_1.isNonNegativeInteger)(instagram);
});
exports.isReadMessagesDailyCountOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var twitter = _a.twitter, instagram = _a.instagram, day = _a.day, instagramSkipped = _a.instagramSkipped;
    return (0, models_1.isDay)(day) && (0, type_utils_1.isNonNegativeInteger)(twitter) && (0, type_utils_1.isNonNegativeInteger)(instagram) && (instagramSkipped === undefined || (0, type_utils_1.isNonNegativeInteger)(instagramSkipped));
});
