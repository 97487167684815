import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from "react";
import { useFirebaseUser } from "../hooks/useFirebaseUser";
export const FirebaseUserContext = createContext({
    isAuthenticated: undefined,
    userInfo: undefined,
});
FirebaseUserContext.displayName = "FirebaseUserContext";
export const FirebaseUserContextProvider = ({ children }) => {
    const context = useFirebaseUser();
    return (_jsx(FirebaseUserContext.Provider, { value: context, children: children }));
};
