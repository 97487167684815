import {
  Avatar as ChakraAvatar,
  AvatarBadge,
  AvatarProps as ChakraAvatarProps,
  defineStyleConfig,
} from "@chakra-ui/react";
import {FC} from "react";
import {Icon} from "./icons";
import {brandColors, colorHex} from "../colors";

export const avatarStyleConfig = defineStyleConfig({
  baseStyle: {
    container: {
      position: "relative",
    },
    badge: {
      border: "none",
      transform: "none",
    },
  },
  sizes: {
    xs: (props) => {
      const isInstagram = props["data-variant"] === "instagram";

      return {
        container: {
          width: 8,
          height: 8,
        },
        badge: {
          boxSize: 4,
          borderRadius: isInstagram ? "5px" : "50%",
        },
      };
    },
    sm: (props) => {
      const isInstagram = props["data-variant"] === "instagram";

      return {
        container: {
          width: 10,
          height: 10,
        },
        badge: {
          boxSize: 5,
          borderRadius: isInstagram ? "6px" : "50%",
        },
      };
    },
    md: (props) => {
      const isInstagram = props["data-variant"] === "instagram";

      return {
        container: {
          width: 14,
          height: 14,
        },
        badge: {
          boxSize: 6,
          borderRadius: isInstagram ? "8px" : "50%",
        },
      };
    },
    lg: (props) => {
      const isInstagram = props["data-variant"] === "instagram";

      return {
        container: {
          width: 16,
          height: 16,
        },
        badge: {
          boxSize: "30px",
          borderRadius: isInstagram ? "10px" : "50%",
        },
      };
    },
    xl: (props) => {
      const isInstagram = props["data-variant"] === "instagram";

      return {
        container: {
          width: 20,
          height: 20,
        },
        badge: {
          boxSize: 9,
          borderRadius: isInstagram ? "12px" : "50%",
        },
      };
    },
  },
});

export type AvatarProps = Omit<ChakraAvatarProps, "size" | "icon" | "bg" | "variant"> & {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?: "default" | "previousConversation" | "instagram" | "twitter";
  src?: string;
};

export const Avatar: FC<AvatarProps> = ({
  size = "md",
  src = "",
  variant = "default",
  ...props
}) => {
  const emptyIconProps: Pick<ChakraAvatarProps, "icon" | "bg"> = {};

  if (!src) {
    const emptyIconSizeMap = {
      xs: 20,
      sm: 25,
      md: 35,
      lg: 40,
      xl: 50,
    };

    emptyIconProps.icon = (
      <Icon
        size={emptyIconSizeMap[size]}
        color={colorHex.primary500}
        name="user"
        aria-label="User Icon"
      />
    );
    emptyIconProps.bg = "primary.50";
  }

  const renderBadge = () => {
    if (variant === "default") return null;

    const iconSizeMap = {
      xs: 14,
      sm: 17.5,
      md: 21,
      lg: 26.25,
      xl: 31.5,
    };

    const colorMap = {
      instagram: brandColors.instagram,
      twitter: brandColors.twitter,
      previousConversation: colorHex.primary400,
    };

    return (
      <AvatarBadge bg="white" color={colorMap[variant]}>
        <Icon size={iconSizeMap[size]} name={variant} aria-label={variant} />
      </AvatarBadge>
    );
  };

  return (
    <ChakraAvatar
      size={size}
      src={src}
      data-variant={variant}
      {...emptyIconProps}
      {...props}
    >
      {renderBadge()}
    </ChakraAvatar>
  );
};
