import {
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  chakra,
  Text,
} from "@chakra-ui/react";
import {useDisclosure} from "@workspace/ui";
import {FC, useCallback, useMemo, useState, useEffect} from "react";
import {Platform} from "src/modules/integration/types";
import {useAppDispatch, useAppSelector} from "src/hooks";
import {ReactComponent as TwitterReAuthSuccessIcon} from "../../../../assets/icons/twitter-re-auth-success.svg";
import {DsmSolidButton} from "../../../../components/buttons";
import {setShowModalAuthSuccess} from "../../../user/user-slice";
import {IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY} from "src/modules/integration/pages/integrations";

const StyledTwitterReAuthSuccessIcon = chakra(TwitterReAuthSuccessIcon);

const MS_TO_WAIT = 30000;

export const ReAuthSuccessModal: FC = () => {
  const dispatch = useAppDispatch();
  const {onClose} = useDisclosure();

  const {showModalAuthSuccess: showModal, user} = useAppSelector((state) => state.user);

  const [shouldStopWaiting, setShouldStopWaiting] = useState(false);

  const isConnecting = useMemo(() => {
    try {
      return !!localStorage.getItem(IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY);
    } catch {
      return false;
    }
  }, []);

  const hasTwitterIntegration = useMemo(
    () =>
      !!user?.integrations.find((integration) => integration.type === Platform.Twitter),
    [user],
  );

  const isLoading = useMemo(
    () => isConnecting && !shouldStopWaiting && !hasTwitterIntegration,
    [shouldStopWaiting, hasTwitterIntegration, isConnecting],
  );

  const isOpen = useMemo(() => !!showModal, [showModal]);

  const onModalClose = useCallback(() => {
    try {
      localStorage.removeItem(IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY);
    } catch {}
    dispatch(setShowModalAuthSuccess(false));
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldStopWaiting(true);
    }, MS_TO_WAIT);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        py={6}
        mx={5}
        textAlign="center"
        minW={{base: "auto", md: "613px"}}
        mt="85px"
      >
        <Container maxW="455px">
          <StyledTwitterReAuthSuccessIcon mx="auto" />
          <ModalHeader
            fontWeight="bold"
            lineHeight={1.5}
            fontSize={{base: "md", md: "xl"}}
            color="gray.900"
          >
            Successfully connect your account to twitter
          </ModalHeader>

          <ModalBody lineHeight={1.5} color="gray.800" fontSize="base">
            <Text>
              Thank you for re-authenticating your twitter account so you can continue
              using Howdy.
            </Text>
          </ModalBody>

          <ModalFooter justifyContent="center">
            <DsmSolidButton
              onClick={onModalClose}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Close
            </DsmSolidButton>
          </ModalFooter>
        </Container>
      </ModalContent>
    </Modal>
  );
};
