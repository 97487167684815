import {Platform} from '../integration/types';

export enum ScheduledMessageStatus {
  SCHEDULED = 'scheduled',
  SENDING = 'sending',
  SENT = 'sent',
  PREVENTED = 'prevented',
  BLOCKED = 'blocked',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export interface ScheduledMessage {
  id: string;
  userId: string;
  conversationId: string;
  integrationId: string;
  platform: Platform;
  text: string;
  createdAt: Date;
  sendAt: number;
  status: ScheduledMessageStatus;
  errorReason: string;
}

export enum ResponseErrorCode {
  RESOURCE_EXHAUSTED = 'resource-exhausted',
}
