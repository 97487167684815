import {menuAnatomy} from "@chakra-ui/anatomy";
import {FC} from "react";
import {
  createMultiStyleConfigHelpers,
  useMenuItem,
  MenuItemProps as ChakraMenuItemProps,
  Button,
  useMultiStyleConfig,
  Flex,
  Box,
  BoxProps,
  ButtonProps,
} from "@chakra-ui/react";
import {Icon, IconKey} from "./icons";
import {colorName} from "../colors";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(
  menuAnatomy.keys,
);

const baseStyle = definePartsStyle(({selected}) => ({
  item: {
    outline: "none",
    bg: selected ? colorName.gray800 : colorName.white,
    color: selected ? colorName.white : colorName.gray900,
    borderRadius: "8px",
    cursor: selected ? "default" : "pointer",
    px: "3",
    py: "2",
    fontWeight: 500,
    fontSize: "14px",
    _hover: {
      bg: selected ? colorName.gray800 : colorName.alpha05,
    },
  },
}));

export const menuStyleConfig = defineMultiStyleConfig({baseStyle});

export type MenuItemProps = Omit<ChakraMenuItemProps, "icon"> & {
  leftIcon?: IconKey;
  rightIcon?: IconKey;
  amount?: number | string;
  selected?: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  leftIcon,
  rightIcon,
  amount,
  selected,
  ...props
}) => {
  const menuItemProps = useMenuItem({
    ...props,
    selected,
  } as ChakraMenuItemProps);

  const styles = useMultiStyleConfig("Menu", menuItemProps);

  const iconWrapperProps: BoxProps = {
    color: selected ? colorName.white : colorName.gray500,
    _groupHover: {
      color: selected ? colorName.white : colorName.gray900,
    },
  };

  return (
    <Button
      __css={styles["item"] ?? {}}
      {...(menuItemProps as ButtonProps)}
      data-group="group"
    >
      <Flex alignItems="center" justifyContent="space-between" gap="3" w="100%">
        <Flex alignItems="center" gap="2" w="100%">
          {leftIcon && (
            <Box {...iconWrapperProps}>
              <Icon name={leftIcon} size="20px" />
            </Box>
          )}
          {menuItemProps.children}

          {amount !== undefined && (
            <Box
              minW="5"
              h="5"
              p="1"
              as="span"
              fontWeight="700"
              lineHeight="12px"
              fontSize="11px"
              borderRadius="4px"
              color={selected ? colorName.white : colorName.black}
              bg={selected ? "#ffffff33" : colorName.alpha10}
            >
              {amount}
            </Box>
          )}
        </Flex>
        {rightIcon && (
          <Box {...iconWrapperProps}>
            <Icon name={rightIcon} size="20px" />
          </Box>
        )}
      </Flex>
    </Button>
  );
};

export {Menu, MenuList} from "@chakra-ui/react";
