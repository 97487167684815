import {
  isSocialNetworkHandle,
  isSocialNetwork,
  BlockTargetOperation,
} from "@workspace/models";
import {isFiniteString, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";

export const isBlockTargetInput = objectTypeGuard<BlockTargetOperation["in"]>(
  ({id, socialNetwork, username, handle}) => {
    return (
      isNonEmptyString(id) &&
      isFiniteString(username) &&
      isSocialNetworkHandle(handle) &&
      isSocialNetwork(socialNetwork)
    );
  },
);
