import {checkboxAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import {colorName} from "../colors";

export {Checkbox} from "@chakra-ui/react";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys,
);

const baseStyle = definePartsStyle({
  label: {
    color: colorName.gray900,
    'font-size': "14px !important",
    fontWeight: "normal",
    _disabled: {
      opacity: 0.4
    }
  },
  control: {
    borderRadius: '6px',
    width: '20px',
    height: '20px',
    borderColor: colorName.gray200,
    _checked: {
      bg: colorName.primary400,
      borderColor: colorName.primary400,
      _hover: {
        bg: colorName.primary500,
        borderColor: colorName.primary500,
      }
    },
    _indeterminate: {
      bg: colorName.primary400,
      borderColor: colorName.primary400,
      _hover: {
        bg: colorName.primary500,
        borderColor: colorName.primary500,
      }
    },
    _disabled: {
      bg: colorName.white,
      borderColor: colorName.gray200,
      opacity: 0.4,
      _checked: {
        bg: colorName.primary200,
        borderColor: colorName.primary200,
        color: colorName.white
      }
    }
  },
});

export const checkboxStyleConfig = defineMultiStyleConfig({baseStyle});
