import * as Sentry from "@sentry/browser";
import {firebaseFunctions} from "@workspace/firebase-app";
import {HttpsCallableResult} from "./firebase";

const sendNotificationOnCall = firebaseFunctions.httpsCallable("sendNotificationOnCall");

export const sendNotification = async (data: {
  text: string;
}): Promise<HttpsCallableResult> => {
  try {
    return await sendNotificationOnCall(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
