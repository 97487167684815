"use strict";
exports.__esModule = true;
exports.isSomeEnum = void 0;
/**
 * Use `isSomeEnum` as a *type guard* helper to reduce boilerplate.
 *
 * @example
 * ```ts
 * export enum MyEnum = { A, B, C };
 *
 * const isMyEnum = isSomeEnum(MyEnum);
 * ```
 */
var isSomeEnum = function (e) { return function (token) { return !!e && typeof e === "object" && Object.values(e).includes(token); }; };
exports.isSomeEnum = isSomeEnum;
