import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from "react";
import { useSubscription } from "../hooks";
export const SubscriptionContext = createContext({
    hasSubscription: undefined,
    pricingPlanName: undefined,
    readSubscriptionIsPending: undefined,
    remainingTrialDays: undefined,
    subscriptionIsCanceled: undefined,
    subscription: undefined,
    trialState: undefined,
});
SubscriptionContext.displayName = "SubscriptionContext";
export const SubscriptionContextProvider = ({ children }) => {
    const subscriptionContext = useSubscription();
    return (_jsx(SubscriptionContext.Provider, { value: subscriptionContext, children: children }));
};
