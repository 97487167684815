import {Box, chakra} from "@chakra-ui/react";
import Tagify, {BaseTagData} from "@yaireo/tagify";
import Tags from "@yaireo/tagify/dist/react.tagify";
import React, {useCallback, useRef, useMemo, useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";

import {useAppDispatch, useAppSelector} from "../../app-hooks";
import useMixpanel from "../../helpers/hooks/use-mixpanel";
import {addTagsToFriend, removeTagsFromFriend} from "../../modules/friend/thunks";
import {Friend} from "../../modules/friend/types";
import {ShortcutKeyKeyboardKey} from "../../modules/shortcut-keys/types";
import {dsmToast} from "../dsm-toast";

import AddTagButton from "./add-tag-button";

const StyledBox = chakra(Box, {
  baseStyle: {
    ml: "-5px",
    ".tagify": {
      "--tag-remove-btn-bg--hover": "transparent",
      "--tag-bg": "#F7F8FA",
      "--tag-hover": "#EEEEF0",
      border: "none",
    },
    ".tagify__input": {
      display: "none",
    },
    ".tagify__tag": {
      my: 1,
    },
    ".tagify__tag > div": {
      borderRadius: "25px",
      fontSize: "xs",
      color: "gray.600",
      minW: 0,
      padding: "3px 12px",
      pr: "2px",
    },
    ".tagify__tag > div::before": {
      borderWidth: "1px",
      borderColor: "gray.300",
    },
    ".tagify__tag:hover > div::before": {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    ".tagify__tag__removeBtn": {
      color: "gray.600",
      "&:hover": {
        color: "secondary.500",
      },
    },
    ".tagify__tag__removeBtn:hover+div": {
      color: "secondary.500",
      "& > span": {
        opacity: 1,
      },
      "&::before": {
        backgroundColor: "rgba(255, 80, 80, 0.15)",
        borderColor: "secondary.500",
      },
    },
    ".tagify__tag--editable": {
      mr: 0.5,
    },
    ".tagify__tag--editable > div": {
      px: 1,
      minW: 0,
      minHeight: "21px",
      verticalAlign: "middle",
    },
    ".tagify__tag--editable .tagify__tag-text": {
      minWidth: "24px",
    },
    ".tagify__tag--editable > div::before": {
      boxShadow: "0 0 0 1px #0021D1 inset !important",
      border: "none",
    },
    ".tagify__tag--editable > .tagify__tag__removeBtn": {
      display: "none",
    },
    ".tags-input": {
      display: "inline-block",
      border: "none",
    },
  },
});

interface TagSectionProps {
  friend: Friend;
  allowHotKey?: boolean;
  onTagClick?: (tag: string) => void;
}

const TagSection: React.FC<TagSectionProps> = ({friend, allowHotKey, onTagClick}) => {
  const tagifyRef = useRef<Tagify | undefined>();
  const dispatch = useAppDispatch();
  const pattern = useMemo(() => new RegExp("^[A-Za-z0-9 \\-_]+$"), []);
  const id = friend.id;

  const initialFriendTags = useMemo(
    () =>
      Object.keys(friend.tags)
        .filter((tag) => friend.tags[tag])
        .sort(),
    [friend.tags],
  );
  const [friendTags, setFriendTags] = useState(initialFriendTags);

  const userTags = useAppSelector((state) => state.user.user?.tags || {});
  const tagSuggestions = useMemo(() => {
    const tags = Object.keys(userTags).filter(
      (tag) => typeof friend.tags[tag] === "undefined",
    );
    tags.sort((tag1, tag2) => userTags[tag2] - userTags[tag1]);
    return tags;
  }, [friend.tags, userTags]);

  const addTagToFriendTags = (tagToAdd: string) => {
    setFriendTags((prevTags) =>
      prevTags.includes(tagToAdd) ? [...prevTags] : [...prevTags, tagToAdd].sort(),
    );
  };

  const removeTagFromFriendTags = (tagToRemove: string) => {
    setFriendTags((prevTags) =>
      prevTags.includes(tagToRemove)
        ? prevTags.filter((tag) => tag !== tagToRemove)
        : prevTags,
    );
  };

  const addTagHandler = useCallback(() => {
    tagifyRef.current?.addEmptyTag();
  }, []);

  const mixpanel = useMixpanel();
  const onEditBeforeUpdate = useCallback(
    // The reason for using 'any' here is an inaccuracy in 'Tagify' typings
    // Actually, 'event.detail' contains field 'previousData', but it is not added to the event type
    // WAS_eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (e: any) => {
      tagifyRef.current?.dropdown.hide.apply(e.detail.data);

      const prevValue = e.detail.previousData.value;
      const tagToAdd = e.detail.data?.value;

      // If there is some previous value it means that actually not new tag was added,
      // but one of the existing tags was edited
      if (prevValue !== "" || !tagToAdd) return;

      if (!pattern.test(tagToAdd)) {
        dsmToast(
          "A tag can only contain letters, numbers, underscores, and dashes.",
          "error",
        );
        // this will update tag list and remove the invalid tag
        setTimeout(() => setFriendTags([...friendTags]), 4000);

        return;
      }

      const currentValues = e.detail.tagify.value.map(
        (tagData: BaseTagData) => tagData.value,
      );

      // This handles the case when there already exists exactly matching tag.
      // It still will be added to the Tagify's internal state and immediately removed after that.
      if (!currentValues.includes(tagToAdd)) {
        addTagToFriendTags(tagToAdd);
      } else {
        dsmToast(`${tagToAdd} was already added.`, "error");
        addTagToFriendTags(tagToAdd);
        return;
      }

      try {
        await dispatch(addTagsToFriend({friendId: id, tags: [tagToAdd]}));
        mixpanel?.track("web.friends.tags.add", {tag: tagToAdd});
      } catch (e) {
        removeTagFromFriendTags(tagToAdd);
      }
    },
    [dispatch, friendTags, id, pattern, mixpanel],
  );

  const onRemove = useCallback(
    async (e: CustomEvent<Tagify.RemoveEventData<Tagify.BaseTagData>>) => {
      const tagToRemove = e.detail.data?.value;

      if (!tagToRemove) return;

      removeTagFromFriendTags(tagToRemove);

      try {
        await dispatch(removeTagsFromFriend({friendId: id, tags: [tagToRemove]}));
        mixpanel?.track("web.friends.tags.remove", {tag: tagToRemove});
      } catch (e) {
        addTagToFriendTags(tagToRemove);
      }
    },
    [dispatch, id, mixpanel],
  );

  const onClick = (e: CustomEvent<Tagify.ClickEventData<Tagify.BaseTagData>>) => {
    const tag = e.detail.data.value;

    if (onTagClick && pattern.test(tag)) {
      onTagClick(tag);
    }
  };

  useHotkeys(
    ShortcutKeyKeyboardKey.AddTag,
    (e: KeyboardEvent) => {
      if (allowHotKey) {
        e.preventDefault();
        addTagHandler();
      }
    },
    [allowHotKey],
  );

  return (
    <StyledBox id={id}>
      <Tags
        settings={{
          maxTags: 20,
          backspace: false,
          duplicates: true,
          editTags: false,
          keepInvalidTags: true,
          dropdown: {
            maxItems: 5,
            enabled: 0,
            position: "text",
          },
          pattern,
        }}
        whitelist={tagSuggestions}
        tagifyRef={tagifyRef}
        value={friendTags}
        onClick={onClick}
        onRemove={onRemove}
        onEditBeforeUpdate={onEditBeforeUpdate}
      />
      <AddTagButton
        containerId={id}
        isLabelShown={friendTags.length === 0}
        addTagHandler={addTagHandler}
      />
    </StyledBox>
  );
};

export default TagSection;
