import {ColorModeScript} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";

import {App} from "./app";
import {sentryConfig} from "./config/sentryConfig";
import {redirect} from "./redirect";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

if (process.env.REACT_APP_ENVIRONMENT !== "local") {
  Sentry.init(sentryConfig);
}

redirect();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ColorModeScript />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
