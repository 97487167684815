import {isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import type firebase from "firebase/compat/app";

export type FirebaseUserInfo = Pick<firebase.UserInfo, "uid"> & {
  email: string | null | undefined;
};

export const isFirebaseUserInfo = objectTypeGuard<FirebaseUserInfo>(({uid, email}) =>
  isNonEmptyString(uid) && typeof email === "string" ? isNonEmptyString(email) : true,
);
