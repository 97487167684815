import React from 'react';
import InnerAppLayout from "../../components/inner-app-layout";
import {Flex, Text, Heading, Box} from "@workspace/ui";

export const CampaignStarted = () => {
  return (
    <InnerAppLayout hasBasicHeader>
      <Flex
        mt="72px"
        color="gray.900"
        position="relative"
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          opacity="0.2"
          background="#6461EB"
          filter="blur(116px)"
          width="592px"
          height="388px"
        />
        <Flex
          bg="white"
          boxShadow="0px 30px 84px rgba(10, 9, 68, 0.08), 0px 8px 32px rgba(10, 9, 68, 0.07), 0px 3px 14px rgba(10, 9, 68, 0.03), 0px 1px 3px rgba(10, 9, 68, 0.13)"
          borderRadius="16px"
          flexDirection="column"
          alignItems="center"
          py={16}
          width="528px"
          zIndex="2"
          mx="auto"
        >
          <Text mb="8" fontSize="40px">🚀</Text>
          <Heading fontSize="h2" lineHeight="h2" mb="4">
            Your campaign has launched
          </Heading>
          <Text width="400px" textAlign="center" fontSize="h6" lineHeight="h6">
            All the prospects from your list will receive DMs in the next 24 hours. We will notify you when the new list of prospects is ready for you to review.
          </Text>
        </Flex>
      </Flex>
    </InnerAppLayout>
  );
};
