import React, {FC} from 'react';
import {StepSectionSubtext, StepTitle} from "../styled";
import {Box, Grid, GridItem} from "@chakra-ui/react";
import {Chip} from "../Chip";
import {Field, IconKey, Input} from "@workspace/ui";
import {BUSINESS_TYPE} from "@workspace/models";
import {BusinessTypeProps} from "./types";

export const BusinessTypeStep: FC<BusinessTypeProps> = ({
  onChange,
  businessType,
  companyName,
  website,
  linkToOffer,
}
) => {
  return (
    <Box>
      <StepTitle mb="10">Are you an individual or a company?</StepTitle>
      <StepSectionSubtext mb={4}>Select the answer</StepSectionSubtext>
      <Grid mb="10" gap="3" templateColumns="repeat(2,1fr)">
        {
          BUSINESS_TYPE.map(type => (
            <GridItem key={type}>
              <Chip onClick={() => onChange("businessType", type)} isSelected={businessType === type} icon={type.toLowerCase() as IconKey}>
                {type}
              </Chip>
            </GridItem>
          ))
        }
      </Grid>
      {
        businessType === "Company" && (
          <>
            <Field mb="6" label="Company name" w="full">
              <Input value={companyName} onChange={({target}) => onChange("companyName", target.value)}/>
            </Field>
            <Field mb="6" label="Website" w="full">
              <Input value={website} onChange={({target}) => onChange("website", target.value)}/>
            </Field>
          </>
        )
      }
      <Field label="Link to offer (optional)" w="full">
        <Input value={linkToOffer} onChange={({target}) => onChange("linkToOffer", target.value)}/>
      </Field>
    </Box>
  );
};
