import {ButtonProps} from "@chakra-ui/react";
import React from "react";
import {useDsmChromeExtension} from "src/hooks";

import {useAppDispatch} from "../../../app-hooks";
import {DsmLightButton} from "../../../components/buttons";
import {logout} from "../../auth/auth-service";

const LogoutButton: React.FC<ButtonProps> = ({...props}) => {
  const dispatch = useAppDispatch();
  const {extensionSignOut} = useDsmChromeExtension();

  const logoutHandler = async () => {
    try {
      await logout(dispatch);
      extensionSignOut();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DsmLightButton onClick={logoutHandler} {...props}>
      Log out
    </DsmLightButton>
  );
};

export default LogoutButton;
