import { devprint } from "./devprint";
/**
 *
 * @param valueBeingPiped
 * @param devprint
 * @param printInterimResults
 */
export function pipe(valueBeingPiped, printInterimResults) {
    return {
        thru(first, ...functionsToApplyOnValue) {
            if (!first)
                return undefined;
            let interimResult = first(valueBeingPiped);
            functionsToApplyOnValue.forEach((fn) => {
                interimResult = fn(interimResult);
                if (printInterimResults)
                    devprint(`Piping...`, interimResult);
            });
            return interimResult;
        },
    };
}
