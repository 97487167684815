import {
  TabList,
  TabPanels,
  TabPanel,
  Alert,
  AlertDescription,
  Box,
  chakra,
  Tab,
  Tabs,
  Textarea,
  Tag,
} from "@chakra-ui/react";
import debounce from "lodash/debounce";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {IoWarning} from "react-icons/io5";

import {useAppDispatch, useAppSelector} from "../../../app-hooks";
import {MAX_MOBILE_RESOLUTION} from "../../../helpers/constants";
import useMixpanel from "../../../helpers/hooks/use-mixpanel";
import useWindowWidth from "../../../helpers/hooks/use-window-width";
import FollowerRow from "../../friend/components/follower-row";
import {
  removeTemplateById,
  resetTemplatesById,
  setCurrentRecipient,
  updateTemplate,
  updateTemplateById,
} from "../bulk-send-slice";
import convertTemplateToPreview from "../helpers/convert-template-to-preview";
import {TemplateVariable} from "../helpers/helpers-variables";

import EmptyState from "./empty-state";
import SuccessState from "./success-state";
import TemplatePreview from "./template-preview";

export enum TabIndex {
  MESSAGE_TEMPLATE,
  PERSONALIZATIONS,
}

const StyledIoWarning = chakra(IoWarning);
const StyledTab = chakra(Tab, {
  baseStyle: {
    py: 2.5,
    px: 5,
    color: "black",
    fontSize: {base: "baseMinor", md: "base"},
    "&[aria-selected=true].chakra-tabs__tab": {
      color: "primary.500",
    },
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
  },
});
const StyledTag = chakra(Tag, {
  baseStyle: {
    mr: 3,
    px: 2,
    py: 0.5,
    cursor: "pointer",
    fontSize: "xs",
    borderRadius: "6px",
    color: "gray.600",
    bgColor: "primary.100",
    borderColor: "gray.100",
    borderWidth: "1px",
  },
});
const StyledTextarea = chakra(Textarea, {
  baseStyle: {
    mb: 2,
    wordBreak: "break-all",
    fontSize: "baseMinor",
    color: "gray.700",
    _focus: {outline: "none", borderColor: "primary.500"},
  },
});

interface TemplateTabsProps {
  activeTabIndex: number;
  updateActiveTabIndex: (index?: TabIndex) => void;
}

const TemplateTabs: React.FC<TemplateTabsProps> = ({
  activeTabIndex,
  updateActiveTabIndex,
}) => {
  const dispatch = useAppDispatch();
  const [newCursorPos, setNewCursorPos] = useState<number>();
  const variables = Object.values(TemplateVariable);
  const {
    templatesById,
    currentRecipient,
    selectedRecipients,
    template,
    isSuccessfullySent,
  } = useAppSelector((state) => state.bulkSend);
  const templateTextareaRef = useRef<HTMLTextAreaElement | undefined | null>(
    undefined,
  ) as React.MutableRefObject<HTMLTextAreaElement | null>;
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MAX_MOBILE_RESOLUTION;
  const mixpanel = useMixpanel();

  const noRecipientsSelected = useMemo(
    () => !selectedRecipients.length,
    [selectedRecipients],
  );

  const personalizedTemplatesCount = useMemo(
    () => Object.keys(templatesById).length,
    [templatesById],
  );

  const currentRecipientTemplate = useMemo(() => {
    if (!currentRecipient?.twitterId) return;

    return currentRecipient.twitterId in templatesById
      ? templatesById[currentRecipient.twitterId]
      : convertTemplateToPreview(template, currentRecipient);
  }, [currentRecipient, template, templatesById]);

  const insertVariableHandler = (variable: string) => {
    if (templateTextareaRef.current) {
      const cursorPos = templateTextareaRef.current.selectionStart;
      const updatedTemplate =
        template.slice(0, cursorPos) + variable + template.slice(cursorPos);

      dispatch(updateTemplate(updatedTemplate));
      setNewCursorPos(cursorPos + variable.length);
      mixpanel?.track("web.community.bulk-send.use-template-variable", {
        variable,
      });
    }
  };

  const trackTemplateChange = debounce(
    () => mixpanel?.track("web.community.bulk-send.change-template"),
    1000,
  );

  const changeTemplateHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(updateTemplate(e.target.value));
    trackTemplateChange();

    if (personalizedTemplatesCount) {
      dispatch(resetTemplatesById());
    }
  };

  const trackPersonalizedTemplateChange = debounce(
    () => mixpanel?.track("web.community.bulk-send.change-personalized-template"),
    1000,
  );

  const changePersonalizedTemplateHandler = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const personalizedTemplate = e.target.value;

      if (currentRecipient) {
        dispatch(
          updateTemplateById({
            template: personalizedTemplate,
            twitterUserId: currentRecipient.twitterId,
          }),
        );
        trackPersonalizedTemplateChange();
      }
    },
    [currentRecipient, dispatch, trackPersonalizedTemplateChange],
  );

  const changeTabHandler = useCallback(
    (index: number) => {
      const currentRecipientId =
        currentRecipient?.twitterId || currentRecipient?.instagramId;

      if (index !== activeTabIndex) {
        updateActiveTabIndex(index);
      }
      // prevent opening empty Personalizations tab
      if (
        index === TabIndex.PERSONALIZATIONS &&
        !currentRecipient &&
        !isSuccessfullySent
      ) {
        dispatch(setCurrentRecipient(selectedRecipients[0]));
      }

      /**
       * If the user completely deletes the personalized message, the recipient will receive
       * a common template. The personalized label will not be displayed in the recipient's row,
       * but the entry remains in the 'templatesById' to allow the user to completely clear
       * the textarea and avoid blinking. Only after the user selects another recipient or toggles
       * the tab the recipient's record will be removed from the 'templatesById'.
       */
      if (
        index === TabIndex.MESSAGE_TEMPLATE &&
        currentRecipient &&
        templatesById[currentRecipientId as string] === ""
      ) {
        dispatch(removeTemplateById(currentRecipient.twitterId));
      }
    },
    [
      activeTabIndex,
      currentRecipient,
      dispatch,
      isSuccessfullySent,
      selectedRecipients,
      templatesById,
      updateActiveTabIndex,
    ],
  );

  useEffect(() => {
    if (typeof newCursorPos !== "undefined" && templateTextareaRef.current) {
      templateTextareaRef.current.focus();
      templateTextareaRef.current.setSelectionRange(newCursorPos, newCursorPos);
    }
  }, [newCursorPos]);

  return (
    <Tabs onChange={changeTabHandler} index={activeTabIndex}>
      <TabList borderBottomWidth="1px" borderBottomColor="gray.200">
        <StyledTab>Message template</StyledTab>
        <StyledTab>Personalizations</StyledTab>
      </TabList>

      <TabPanels>
        <TabPanel px={{base: 0, md: 4}} pl={{md: 0}} py={2}>
          {noRecipientsSelected ? (
            <EmptyState />
          ) : isSuccessfullySent ? (
            <SuccessState />
          ) : (
            <>
              <Alert p={2} status="warning" bgColor="orange.50" mb={2}>
                <StyledIoWarning mr={{base: 1, sm: 3}} color="orange.600" size="24px" />
                <Box flex="1" fontSize={{base: "baseMinor", md: "base"}}>
                  <AlertDescription display="flex" alignItems="center">
                    <chakra.span
                      mr={{base: 2, sm: 3}}
                      fontWeight="bold"
                      fontSize="baseMajor"
                    >
                      Note:
                    </chakra.span>
                    Editing this message template will override all personalizations
                  </AlertDescription>
                </Box>
              </Alert>

              <StyledTextarea
                rows={12}
                ref={templateTextareaRef}
                value={template}
                onChange={changeTemplateHandler}
              />
              <Box mb={6}>
                {variables.map((variable) => (
                  <StyledTag
                    key={variable}
                    onClick={() => insertVariableHandler(variable)}
                  >
                    {variable}
                  </StyledTag>
                ))}
              </Box>

              {!isMobile && <TemplatePreview template={template} />}
            </>
          )}
        </TabPanel>

        <TabPanel px={{base: 0, md: 4}} pl={{md: 0}} py={2}>
          {noRecipientsSelected ? (
            <EmptyState />
          ) : isSuccessfullySent ? (
            <SuccessState />
          ) : (
            currentRecipient && (
              <>
                <FollowerRow follower={currentRecipient} />
                <StyledTextarea
                  rows={12}
                  value={currentRecipientTemplate}
                  onChange={changePersonalizedTemplateHandler}
                />
              </>
            )
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TemplateTabs;
