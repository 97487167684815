import {Flex, Text} from '@chakra-ui/react';
import React from 'react';

import {DsmSolidButton} from '../../../components/buttons';

interface BulkSendButtonProps {
  label: string;
  disabled: boolean;
  isLoading: boolean;
  alignment?: string;
  onClick: () => void;
}

const BulkSendButton: React.FC<BulkSendButtonProps> = ({
  label,
  disabled,
  isLoading,
  alignment = 'left',
  onClick,
}) => (
  <Flex
    direction="column"
    alignItems={alignment === 'right' ? 'flex-end' : 'flex-start'}
  >
    <DsmSolidButton
      minW="190px"
      disabled={disabled}
      isLoading={isLoading}
      loadingText="Bulk send"
      onClick={onClick}
    >
      Bulk send
    </DsmSolidButton>
    <Text color="gray.700" fontSize="xs">
      {label}
    </Text>
  </Flex>
);

export default BulkSendButton;
