import React, {FC, useCallback, useRef} from 'react';
import AsyncSelect from "react-select/async";
import {colorHex, Icon} from "@workspace/ui";
import {components, SelectInstance} from "react-select";
import {Box, Flex, Image, Spinner, Text} from "@chakra-ui/react";
import {useDebouncedCallback} from "@workspace/react";
import {searchInstagramByUsername} from "../../../../utils/searchInstagramByUsername";
import {ProfileOption, ProfileSearchDropdownProps} from "./types";

const ProfileSearchDropdown: FC<ProfileSearchDropdownProps> = ({ onChange, profiles, maxProfiles }) => {
  const selectRef = useRef<SelectInstance<ProfileOption, true>>(null);

  const handleLoadOptions = useDebouncedCallback(
    async (inputValue: string, callback: (newOptions: Array<ProfileOption>) => void) => {
      const searchResult = await searchInstagramByUsername(inputValue);

      if(searchResult){
        callback([
          {
            value: searchResult.handle,
            username: searchResult.handle,
            full_name: searchResult.name,
            avatar: searchResult.avatar,
          }
        ])
      }

      callback([])
    },
    500,
  );

  const handleChange = useCallback(
    (newProfiles: readonly ProfileOption[]) => {
      onChange(newProfiles);
      selectRef.current?.focus();
    },
    [onChange],
  );

  const deleteProfile = (username: string) => {
    onChange(profiles.filter(profile => profile.username !== username))
  }

  return (
    <>
      <AsyncSelect
        autoFocus
        ref={selectRef}
        hideSelectedOptions
        styles={{
          option: (styles, {isSelected}) => ({
            ...styles,
            px: 12,
            py: 8,
            borderRadius: "12px",
            cursor: isSelected ? "default" : "pointer",
            color: "gray.900",
            background: isSelected ? colorHex.primary50 : colorHex.white,
            ":hover": {
              background: isSelected ? colorHex.primary50 : colorHex.alpha05,
            },
          }),
          control: (styles, props) => ({
            ...styles,
            borderColor: colorHex.alpha10,
            boxShadow: "none",
            borderRadius: "8px",
            minHeight: "44px",
            paddingLeft: "12px",
          }),
          menuList: (styles) => ({
            ...styles,
            padding: "4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "8px",
          }),
        }}
        value={profiles}
        loadOptions={handleLoadOptions}
        placeholder=""
        onChange={handleChange}
        components={{
          Control: ({children, ...props}) => (
            <components.Control {...props}>
              <Icon name="search" size={20} color="#B8BFC7"/>
              {children}
            </components.Control>
          ),
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          LoadingIndicator: () => <Spinner size="sm" mr="4"/>,
          MultiValue: () => null,
          MultiValueRemove: () => null,
          ValueContainer: props => (
            <components.ValueContainer {...props}>
              {props.children}
              {(!props.selectProps.menuIsOpen || !props.selectProps.inputValue) && (
                <Text
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  pl="2.5"
                  left="0"
                  pointerEvents="none"
                  color="alpha.40"
                >
                  Instagram username...
                </Text>
              )}
            </components.ValueContainer>
          ),
          NoOptionsMessage: (props) => {
            if (!props.selectProps.inputValue) return (
              <Box px="3" py="2">
                <Text color="alpha.50" fontSize="h6" lineHeight="20px" textAlign="center">
                  Start typing...
                </Text>
              </Box>
            )

            return (
              <Box px="3" py="2">
                <Text color="alpha.50" fontSize="h6" lineHeight="20px">
                  No user found
                </Text>
              </Box>
            )
          },
          Option: ({data, ...props}) => {
            return (
              <components.Option
                data={data}
                {...(props as any)}
                _hover={{
                  bg: "alpha.05",
                  cursor: "pointer",
                }}
              >
                <Flex px="4" py="2.5">
                  <Image src={data.avatar} alt={data.full_name} width="20px" height="20px" borderRadius="50%" mr={3}/>
                  <Text mr={2} color="gray.900" fontWeight={500} fontSize="14px">{data.full_name}</Text>
                  <Text color="alpha.60" fontWeight={500} fontSize="14px">@{data.username}</Text>
                </Flex>
              </components.Option>
            );
          },
        }}
        isMulti
        isDisabled={maxProfiles ? profiles.length >= maxProfiles : false}
      />
      <Flex mt="4" direction="column" rowGap="2">
        {
          profiles.map(profile => (
            <Flex px="4" py="2.5" key={profile.username} align="center" bg="alpha.05" borderRadius="8px">
              <Image src={profile.avatar} alt={profile.full_name} width="20px" height="20px" borderRadius="50%" mr={3}/>
              <Text mr={2} color="gray.900" fontWeight={500} fontSize="14px">{profile.full_name}</Text>
              <Text color="alpha.60" mr="auto" fontWeight={500} fontSize="14px">@{profile.username}</Text>
              <button onClick={() => deleteProfile(profile.username)}>
                <Icon name="x" color="#0B0D0E80" size={16}/>
              </button>
            </Flex>
          ))
        }
      </Flex>
    </>
  );
};

export default ProfileSearchDropdown;
