"use strict";
exports.__esModule = true;
exports.followUpIntervalToTime = exports.FOLLOW_UP_INTERVAL_OPTION = exports.isFollowUpMessageStatus = exports.FOLLOW_UP_MESSAGE_STATUS = void 0;
var type_utils_1 = require("@workspace/type-utils");
var time_1 = require("./time");
exports.FOLLOW_UP_MESSAGE_STATUS = {
    AWAITING_RESPONSE: 'AWAITING_RESPONSE',
    HAS_RESPONDED: 'HAS_RESPONDED',
    HAS_SENT_FOLLOW_UP: 'HAS_SENT_FOLLOW_UP',
    FOLLOW_UP_FAILED: 'FOLLOW_UP_FAILED'
};
exports.isFollowUpMessageStatus = (0, type_utils_1.isLiteralObjectType)(exports.FOLLOW_UP_MESSAGE_STATUS);
exports.FOLLOW_UP_INTERVAL_OPTION = {
    ONE_DAY: 'ONE_DAY',
    THREE_DAYS: 'THREE_DAYS',
    SEVEN_DAYS: 'SEVEN_DAYS'
};
var followUpIntervalToTime = function (interval) {
    switch (interval) {
        case exports.FOLLOW_UP_INTERVAL_OPTION.ONE_DAY:
            return time_1.TIME.ONE_DAY;
        case exports.FOLLOW_UP_INTERVAL_OPTION.THREE_DAYS:
            return time_1.TIME.ONE_DAY * 3;
        case exports.FOLLOW_UP_INTERVAL_OPTION.SEVEN_DAYS:
            return time_1.TIME.ONE_DAY * 7;
    }
};
exports.followUpIntervalToTime = followUpIntervalToTime;
