import {Box, chakra, Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Stack, Text,} from "@chakra-ui/react";
import {FC, PropsWithChildren} from "react";
import {Icon} from "./icons";
import {IconKey} from "./index";

const StyledBox = chakra(Box, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "base",
    width: "100%",
    color: "gray.900",
    svg: {
      color: "gray.900",
    },
  },
});

export type NavBarMenuOption = {
  icon: IconKey;
  text: string;
  to?: string;
  onClick?: () => void;
}

type Props = PropsWithChildren<{
  menuText: string;
  showMenu?: boolean;
  Link: FC<PropsWithChildren<{ to: string }>>;
  menuOptions: Array<NavBarMenuOption>;
  logoLink: string;
}>

export const Navbar = ({ menuText, showMenu, children, Link, menuOptions, logoLink }: Props) => {
  return (
    <Box
      position="relative"
      zIndex="10"
      as="nav"
      px="6"
      py="3.5"
      bg="white"
      borderColor="alpha.05"
      borderBottomWidth="1px"
      height="56px"
    >
      <Flex
        align="center"
        wrap="wrap"
        maxWidth="1440px"
        mx="auto"
      >
        <Box flex={1}>
          <Link to={logoLink}>
            <Icon name="logo" width="119px" height="auto" />
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" flex={1}>
          {children}
        </Box>
        <Box
          w="auto"
          display="flex"
          _hover={{cursor: "pointer"}}
          data-cy="account-info"
          justifyContent="flex-end"
          flex={1}
        >
          {showMenu && (
            <Menu placement="bottom-end">
              <MenuButton w="auto" display="block" textAlign="left">
                <Stack isInline>
                  <Text fontWeight={500} fontSize="sm" lineHeight="1.1">
                    {menuText ?? ""}
                  </Text>
                  <Icon name="down" />
                </Stack>
              </MenuButton>

              <MenuList fontSize="sm" minWidth="190px" py={0} zIndex={10} overflow="hidden">
                <MenuGroup>
                  {
                    menuOptions.map(option => (
                      <MenuItem as={Box} fontSize="base" px={3} py={2} key={option.text}>
                        {
                          option.to ? (
                            <Link to={option.to}>
                              <StyledBox>
                                <Icon name={option.icon} size="24px" color="gray.500" />
                                <Text ml={2.5}>
                                  {option.text}
                                </Text>
                              </StyledBox>
                            </Link>
                          ) : (
                            <StyledBox onClick={option.onClick}>
                              <Icon name={option.icon} size="24px" color="gray.500" />
                              <Text ml={2.5}>
                                {option.text}
                              </Text>
                            </StyledBox>
                          )
                        }
                      </MenuItem>
                    ))
                  }
                </MenuGroup>
              </MenuList>
            </Menu>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
