import {createSlice} from '@reduxjs/toolkit';

import {onConversationOpen} from '../conversation/conversations-slice';
import {
  InflightMessage,
  InflightMessageStatus,
  Message,
  UnsentMessage,
} from '../message/types';
import {resetApp} from '../user/user-slice';

import parseHashKeyForRecipientId from './helpers/parse-hash-key-for-recipient-id';
import {
  markMessageRead,
  sendDirectMessage,
  sendDirectMessageToTwitterUser,
} from './thunks';

const removeMessageFromInflightMessages = (
  inflightMessages: InflightMessage[] = [],
  hashKey: string,
  status?: InflightMessageStatus
) => {
  const index = inflightMessages.findIndex((message) => {
    if (typeof status !== 'undefined') {
      return message.status === status && message.hashKey === hashKey;
    }
    return message.hashKey === hashKey;
  });

  if (index !== -1) {
    inflightMessages.splice(index, 1);
  }
};

const markInflightMessageStatusSuccess = (
  inflightMessages: InflightMessage[] = [],
  hashKey: string
) => {
  // If we have few messages with the same text we take the first one, but not
  // the one which status was already changed to SUCCESS. Same in rejected action.
  const index = inflightMessages.findIndex(
    (message) =>
      message.status === InflightMessageStatus.PENDING &&
      message.hashKey === hashKey
  );

  // check if the pending message exists
  if (index !== -1) {
    inflightMessages[index].status = InflightMessageStatus.SUCCESS;
  }
};

interface InitialState {
  messagesByConversationId: {[conversationId: string]: Message[]};
  unsentMessages: UnsentMessage[];
  inflightMessagesByRecipientId: Record<string, InflightMessage[]>;
  isFetchingMessages: boolean;
  isMarkingMessageAsRead: boolean;
}

const initialState: InitialState = {
  // TODO evict for memory
  messagesByConversationId: {},
  unsentMessages: [],
  inflightMessagesByRecipientId: {},
  isFetchingMessages: false,
  isMarkingMessageAsRead: false,
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessages: (state, action) => {
      state.isFetchingMessages = false;
      state.messagesByConversationId = {
        ...state.messagesByConversationId,
        [action.payload.conversationId]: action.payload.messages,
      };
    },
    markUnsentMessageAsShown: (state, action) => {
      const index = state.unsentMessages.findIndex(
        (message) => message.requestId === action.payload
      );
      state.unsentMessages[index].isShown = true;
    },
    addInflightMessage: (state, action) => {
      const {recipientId} = action.payload;
      if (!state.inflightMessagesByRecipientId[recipientId]) {
        state.inflightMessagesByRecipientId[recipientId] = [action.payload];
      } else {
        state.inflightMessagesByRecipientId[recipientId].push(action.payload);
      }
    },
    dedupeInflightMessage: (state, action) => {
      const {recipientId, hashKey} = action.payload;

      // The most first message that matches the hashKey should be the earliest
      // one. In case it's not SUCCESS (for whatever reason), still remove it.
      // The only case where this wouldn't be right for the UX is if the user
      // sends the same message from 2 separate places, but that's a super
      // rare edge case.
      removeMessageFromInflightMessages(
        state.inflightMessagesByRecipientId[recipientId],
        hashKey
      );
    },
    clearInflightMessages: (state) => {
      state.inflightMessagesByRecipientId = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendDirectMessage.fulfilled, (state, action) => {
        const {hashKey} = action.meta.arg;
        const recipientId = parseHashKeyForRecipientId(hashKey);

        markInflightMessageStatusSuccess(
          state.inflightMessagesByRecipientId[recipientId],
          hashKey
        );
      })
      .addCase(sendDirectMessageToTwitterUser.fulfilled, (state, action) => {
        const {hashKey} = action.meta.arg;
        const recipientId = action.meta.arg.twitterUserId;

        markInflightMessageStatusSuccess(
          state.inflightMessagesByRecipientId[recipientId],
          hashKey
        );
      })
      .addCase(sendDirectMessage.rejected, (state, action) => {
        const {text, hashKey} = action.meta.arg;
        const {requestId} = action.meta;
        const recipientId = parseHashKeyForRecipientId(hashKey);

        removeMessageFromInflightMessages(
          state.inflightMessagesByRecipientId[recipientId],
          hashKey,
          InflightMessageStatus.PENDING
        );

        state.unsentMessages.push({
          text,
          requestId,
          isShown: false,
        });
      })
      .addCase(sendDirectMessageToTwitterUser.rejected, (state, action) => {
        const {text, twitterUserId: recipientId, hashKey} = action.meta.arg;
        const {requestId} = action.meta;

        removeMessageFromInflightMessages(
          state.inflightMessagesByRecipientId[recipientId],
          hashKey,
          InflightMessageStatus.PENDING
        );

        state.unsentMessages.push({
          text,
          requestId,
          isShown: false,
        });
      })
      .addCase(markMessageRead.pending, (state) => {
        state.isMarkingMessageAsRead = true;
      })
      .addCase(markMessageRead.fulfilled, (state) => {
        state.isMarkingMessageAsRead = false;
      })
      .addCase(markMessageRead.rejected, (state) => {
        state.isMarkingMessageAsRead = false;
      })
      .addCase(onConversationOpen, (state, action) => {
        const conversationId = action.payload;
        if (
          conversationId &&
          typeof state.messagesByConversationId[conversationId] === 'undefined'
        ) {
          state.isFetchingMessages = true;
        }
      })
      .addCase(resetApp, () => initialState);
  },
});

export const {
  addMessages,
  markUnsentMessageAsShown,
  addInflightMessage,
  dedupeInflightMessage,
  clearInflightMessages,
} = messagesSlice.actions;

export const reducer = messagesSlice.reducer;
