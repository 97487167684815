import {createAsyncThunk} from "@reduxjs/toolkit";
import {firebaseStorage} from "@workspace/firebase-app";

import {dsmToast} from "../../components/dsm-toast";
import {HttpsCallableResult} from "../../services/firebase";
import {AppThunk} from "../../store/store";
import {FacebookPage} from "../integration/types";

import {User} from "./types";
import {
  fetchUserData,
  loadConnectedFacebookPagesOnCall,
  removeIntegrationAccount,
  setAutoresponderOnCall,
  setTimezoneOnCall,
  subscribeToInstagramWebhooksOnCall,
  updateIntegrationOnCall,
} from "./user-service";
import {
  setIsTurningOffAutoresponder,
  setIsUpdatingAutoresponder,
  setIsUpdatingTimezone,
  setTwitterHistoryUploadStatus,
  startTwitterHistoryUpload,
  updateAutoresponder,
  updateTimezone,
} from "./user-slice";

export const setUserListener =
  (userId: string): AppThunk<void> =>
  (dispatch, getState) => {
    fetchUserData(userId, dispatch, getState);
  };

export const setAutoresponder =
  (
    data: {
      integrationId: string;
      enabled: boolean;
      message: string;
    },
    {
      isUpdating = false,
      isTurningOff = false,
    }: {
      isUpdating?: boolean;
      isTurningOff?: boolean;
    },
  ): AppThunk =>
  async (dispatch, getState) => {
    const previousAutoresponderSetting =
      getState().user.user?.integrations[0]?.autoresponder;

    const closeSuccessToast = dsmToast("Autoresponder settings saved.", "success");

    if (isTurningOff) dispatch(setIsTurningOffAutoresponder(true));
    if (isUpdating) dispatch(setIsUpdatingAutoresponder(true));
    dispatch(updateAutoresponder(data));

    try {
      await setAutoresponderOnCall(data);
    } catch (e) {
      dispatch(
        updateAutoresponder({
          integrationId: data.integrationId,
          ...previousAutoresponderSetting,
        }),
      );

      closeSuccessToast();
      dsmToast("Autoresponder settings could not be saved.", "error");

      console.error(e);
    } finally {
      if (isTurningOff) dispatch(setIsTurningOffAutoresponder(false));
      if (isUpdating) dispatch(setIsUpdatingAutoresponder(false));
    }
  };

export const setTimezone =
  (timezone: string, {showToast = true}: {showToast?: boolean} = {}): AppThunk =>
  async (dispatch, getState) => {
    const previousTimezone = getState().user.user?.timezone;

    let timezoneDisplayName: string | undefined;
    let closeSuccessToast: (() => void) | undefined;
    if (showToast) {
      timezoneDisplayName = timezone.replace(/_/g, " ");
      closeSuccessToast = dsmToast(`${timezoneDisplayName} saved.`, "success");
    }

    dispatch(setIsUpdatingTimezone(true));
    dispatch(updateTimezone(timezone));

    try {
      await setTimezoneOnCall({timezone});
    } catch (e) {
      dispatch(updateTimezone(previousTimezone));

      if (closeSuccessToast) {
        closeSuccessToast();
        dsmToast(`${timezoneDisplayName} could not be saved. Please try again`, "error");
      }

      console.error(e);
    } finally {
      dispatch(setIsUpdatingTimezone(false));
    }
  };

export const getFacebookPages = createAsyncThunk<{accounts: FacebookPage[]}, string>(
  "user/getFacebookPages",
  async (facebookIntegrationId) =>
    await loadConnectedFacebookPagesOnCall(facebookIntegrationId),
);

export const integrateInstagramAccount = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {
    facebookIntegrationId: string;
    facebookPageId: string;
  }
>("user/integrateInstagramAccount", async (data) => {
  try {
    await updateIntegrationOnCall(data);
    return await subscribeToInstagramWebhooksOnCall(data.facebookIntegrationId);
  } catch (e) {
    throw e;
  }
});
export const uploadTwitterMessagesHistory =
  ({file, user}: {file: File; user: User}): AppThunk =>
  async (dispatch) => {
    const storageRef = firebaseStorage.ref();
    const timestamp = new Date().getTime();
    const filename = `messages-history-js-${user.id}-${timestamp}.js`;
    const fileRef = storageRef.child(filename);

    dispatch(startTwitterHistoryUpload({filename}));
    try {
      await fileRef.put(file);
      dispatch(setTwitterHistoryUploadStatus({code: "completed"}));
    } catch (e) {
      console.error(e);
      dispatch(
        setTwitterHistoryUploadStatus({
          code: "error",
          message: e instanceof Error ? e.message : "Unknown error",
        }),
      );
    }
  };

export const removeIntegration =
  ({type}: {type: "instagram" | "twitter"}): AppThunk =>
  async (dispatch, getState) => {
    try {
      await removeIntegrationAccount(dispatch, getState, type);
    } catch {}
  };
