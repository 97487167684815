import React, {useState} from 'react';
import {ViewLeadsTable} from "./ViewLeadsTable";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router";
import {firebaseFunctions as functions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {isValidList} from "@workspace/firebase-datamodel";
import {useHistory} from "react-router-dom";
import InnerAppLayout from "../../components/inner-app-layout";
import {Flex, Link, Text} from "@chakra-ui/react";
import {Button, Icon} from "@workspace/ui";
import {ListCampaign} from "@workspace/models";
import {helpEmail, userListCampaignQueryKey} from "@workspace/locators";

const ViewLeads = () => {
  const [createdCampaign, setCreatedCampaign] = useState<ListCampaign>()

  const params = useParams<{ slug: string }>();
  const history = useHistory()

  const listQuery = useQuery({
    queryKey: ["list", params.slug],
    queryFn: async () => {
      const response = await functions.httpsCallable(cloudFunctionName.fetchUserListBySlug)({
        slug: params.slug
      })

      const list = response.data;

      if(isValidList(list)){
        return list
      }

      history.push("/lists")

      return null
    },
  });


  const renderBreadcrumbs = () => {
    if(listQuery.data && !listQuery.isPlaceholderData){
      return (
        <Flex gap={3} margin="0 auto" align="center">
          <Flex gap={2} fontSize="14px" fontWeight="500" color="gray.500" align="center" as={Link} href="/lists">
            <Icon name='lists' size={20}/>
            Lists
          </Flex>
          <Text fontSize="14px" color="#0B0D0E66">
            /
          </Text>
          <Text fontSize="14px" fontWeight="500" color="gray.900">
            {listQuery.data.name}
          </Text>
        </Flex>
      )
    }
  }

  const renderContent = () => {
    if(createdCampaign){
      return (
        <Flex pt={20} mx='auto' w={420} h='100%' direction='column' pb={8}>
          <Text fontFamily="Cabinet Grotesk" fontSize={48} lineHeight="52px" fontWeight="800" color="gray.900" mb={6}>
            Say howdy. <br/> Let your words fly.
          </Text>
          <Text mb={10}>
            You’ve selected {createdCampaign.targets.length} leads, now it’s time to make them prospects. Let’s head over to Howdy Chrome Extension to start writing messages.
          </Text>
          <a href={`https://www.instagram.com/?${userListCampaignQueryKey}=${createdCampaign.id}`} target="_blank" rel="noreferrer">
            <Button gap={2} w="100%">
              Open Howdy Chrome Extension
              <Icon name="arrowUpRight" color="white" size={12} />
            </Button>
          </a>
          <Text mx="auto" fontFamily="Inter" whiteSpace="pre" mt="auto" fontSize="12px" fontWeight={500} color="#0B0D0E99" display="flex">
            Need more help?
            {" "}
            <Link textDecoration="underline" href={`mailto:${helpEmail}`}>
              Contact us
            </Link>
          </Text>
        </Flex>
      )
    }else{
      return <ViewLeadsTable setCreatedCampaign={setCreatedCampaign}/>
    }
  }



  return (
    <InnerAppLayout hasBasicHeader navbarMiddleContent={renderBreadcrumbs()}>
      {renderContent()}
    </InnerAppLayout>
  )
};

export default ViewLeads;
