import {FC} from "react";
import {Box} from "@chakra-ui/react";
import {StepSectionSubtext, StepSectionTitle, StepTitle} from "../styled";
import {ICPStepProps} from "./types";
import ProfileSearchDropdown from "../ProfileSearchDropdown/ProfileSearchDropdown";

export const MINIMUM_ICP_PROFILE_URLS = 0;
export const MAXIMUM_ICP_PROFILE_URLS = 5;

export const ICPStep: FC<ICPStepProps> = ({profiles, onChange}) => {
  return (
    <Box>
      <StepTitle mb="1">What is your ideal customer profile?</StepTitle>
      <StepSectionTitle mb="10">
        Please add profiles that are publicly accessible.
      </StepSectionTitle>
      <StepSectionSubtext>
        Select people
      </StepSectionSubtext>
      <ProfileSearchDropdown
        profiles={profiles}
        onChange={onChange}
        maxProfiles={MAXIMUM_ICP_PROFILE_URLS}
      />
    </Box>
  );
};
