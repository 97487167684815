import {components as reactSelectComponents, default as ReactSelect, Props as ReactSelectProps,} from "react-select";
import {colorHex, colorName} from "../colors";
import {Icon, IconKey} from "./icons";
import {Box, Flex} from "@chakra-ui/react";
import {IconBaseProps} from "react-icons";
import {Checkbox} from "./Checkbox";

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = ReactSelectProps & {
  value: string;
  options: Array<SelectOption>;
  onChange: (newValue: unknown) => void;
  dropdownIcon?: IconKey;
  leftIcon?: IconKey;
  leftIconProps?: IconBaseProps;
  size?: "md" | "lg";
};

export const Select = ({
  options,
  value,
  onChange,
  components = {},
  dropdownIcon,
  leftIcon,
  leftIconProps,
  size = "lg",
  ...props
}: SelectProps) => {
  const selectComponents: ReactSelectProps["components"] = {
    DropdownIndicator: (props) => (
      <reactSelectComponents.DropdownIndicator {...props}>
        <Box color={props.isDisabled ? colorName.gray400 : colorName.gray900}>
          <Icon name={dropdownIcon || "down"} />
        </Box>
      </reactSelectComponents.DropdownIndicator>
    ),
    IndicatorSeparator: () => null,
    Placeholder: (props) => (
      <reactSelectComponents.Placeholder {...props}>
        <Flex alignItems="center">
          {leftIcon && (
            <Box color={colorHex.gray500} mr={2}>
              <Icon {...leftIconProps} name={leftIcon} />
            </Box>
          )}
          <Box color={colorName.gray500}>{props.children}</Box>
        </Flex>
      </reactSelectComponents.Placeholder>
    ),
    Option: (props) => (
      <reactSelectComponents.Option {...props}>
        <Flex alignItems="center">
          {props.isMulti && (
            <Box mr={2}>
              <Checkbox isChecked={props.isSelected} />
            </Box>
          )}
          {props.children}
        </Flex>
      </reactSelectComponents.Option>
    ),
    ...components,
  };

  return (
    <ReactSelect
      theme={(selectTheme) => ({
        ...selectTheme,
        colors: {
          ...selectTheme["colors"],
          primary: colorHex.primary500,
          primary25: colorHex.primary100,
        },
      })}
      hideSelectedOptions={false}
      closeMenuOnSelect={!props.isMulti}
      placeholder="Select"
      autoFocus
      styles={{
        option: (styles, {isSelected}) => {
          let newStyles = {
            ...styles,
            cursor: "pointer",
            fontSize: "14px",
            color: colorHex.gray900,
            fontWeight: "400",
            "&:hover": {
              backgroundColor: colorHex.gray50,
            },
          };

          if (isSelected) {
            newStyles = {
              ...newStyles,
              background: colorHex.primary50,
              color: colorHex.gray900,
            };
          }

          return newStyles;
        },
        valueContainer: (styles) => ({
          ...styles,
          fontSize: "14px",
          lineHeight: "14px",
          color: colorHex.gray900,
          fontWeight: "500",
        }),
        singleValue: (styles) => ({
          ...styles,
          height: "100%",
          display: "flex",
          alignItems: "center",
        }),
        placeholder: (styles) => ({
          ...styles,
          fontWeight: "400",
          color: colorHex.gray900,
        }),
        control: (styles, {isFocused}) => {
          const height = size === "md" ? "40px" : "48px";

          return {
            ...styles,
            borderRadius: "8px",
            border: "1px solid rgba(11, 13, 14, 0.15)",
            boxShadow: isFocused ? `0 0 0 1px ${colorHex.primary300}` : "",
            height,
          }
        },
        menu: (styles) => ({
          ...styles,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
          borderRadius: "12px",
          overflow: "hidden",
          border: "1px solid rgba(11, 13, 14, 0.1)",
        }),
        menuList: (styles) => ({
          ...styles,
          padding: "0",
        }),
      }}
      options={options}
      value={options?.find((option: {value: string}) => option.value === value)}
      onChange={onChange}
      components={selectComponents}
      isSearchable={props.isSearchable || false}
      {...props}
    />
  );
};
