import {
  MAX_MESSAGE_TEMPLATE_TITLE_LENGTH,
  MESSAGE_TEMPLATE_NAME_REGEX,
} from "@workspace/firebase-datamodel";
import {Field, Flex, Input, Modal, ModalProps, Spinner} from "@workspace/ui";
import {FC, useCallback, useEffect, useState} from "react";

export const CreateMessageTemplateModal: FC<
  Omit<ModalProps, "onConfirm" | "children"> & {
    onConfirm: (name: string) => void;
    isLoading: boolean;
  }
> = ({onConfirm, confirmButtonProps = {}, isLoading, ...props}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState<string>("");

  const handleConfirm = useCallback(() => {
    setError("");

    const trimmedName = name.trim();

    if (trimmedName.length > MAX_MESSAGE_TEMPLATE_TITLE_LENGTH) {
      setError("Maximum characters allowed: 30");
      return;
    }
    if (!MESSAGE_TEMPLATE_NAME_REGEX.test(trimmedName)) {
      setError("Template name must consist of english letters");
      return;
    }

    onConfirm(name);
  }, [name, onConfirm]);

  useEffect(() => {
    setName("");
  }, [props.isOpen]);

  return (
    <Modal
      title="Create new template"
      confirmButtonProps={{
        children: isLoading ? (
          <Flex alignItems="center" gap="1">
            Creating <Spinner size="xs" />
          </Flex>
        ) : (
          "Create"
        ),
        disabled: !name.trim() || isLoading,
        ...confirmButtonProps,
      }}
      onConfirm={handleConfirm}
      overlayProps={{
        w: "100%",
      }}
      contentProps={{
        containerProps: {
          left: "50%",
          transform: "translateX(-50%)",
          w: "380px",
          top: "150px",
        },
      }}
      {...props}
    >
      <Field label="Template name" helpText={error} isError={!!error}>
        <Input
          h="40px"
          value={name}
          onChange={(e) =>
            e.target.value.length <= MAX_MESSAGE_TEMPLATE_TITLE_LENGTH
              ? setName(e.target.value)
              : undefined
          }
          isInvalid={!!error}
        />
      </Field>
    </Modal>
  );
};
