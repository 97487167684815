"use strict";
exports.__esModule = true;
exports.arrayTypeGuard = void 0;
/**
 * Use `arrayTypeGuard` as a *type guard* helper to reduce boilerplate.
 *
 * @example
 * ```ts
 * export type MyItems = MyItem[];
 *
 * const isMyItems = arrayTypeGuard<MyItem>(isMyItem);
 * ```
 */
var arrayTypeGuard = function (check) {
    return function (arg) {
        return Array.isArray(arg) && arg.every(function (item) { return check(item); });
    };
};
exports.arrayTypeGuard = arrayTypeGuard;
