"use strict";
exports.__esModule = true;
exports.isParsableToInteger = exports.isNonNegativeInteger = void 0;
var isNonNegativeInteger = function (arg) {
    return typeof arg === "number" && arg >= 0 && Number.isInteger(arg);
};
exports.isNonNegativeInteger = isNonNegativeInteger;
var isParsableToInteger = function (arg) {
    return !!arg && (typeof arg === "string" || typeof arg === "number") && Number.isInteger(+arg);
};
exports.isParsableToInteger = isParsableToInteger;
