export class LRUCache {
    get storage() {
        return this.storageType === 'local' ? localStorage : sessionStorage;
    }
    constructor(prefix, ttl, storageType = 'session') {
        var _a;
        this.storageType = storageType;
        this.prefix = prefix !== null && prefix !== void 0 ? prefix : "";
        this.ttl = ttl !== null && ttl !== void 0 ? ttl : null;
        this.keysStoreKey = `${this.prefix}:keys`;
        try {
            const keys = JSON.parse((_a = this.storage.getItem(this.keysStoreKey)) !== null && _a !== void 0 ? _a : "[]");
            this.storedKeys = new Set(Array.isArray(keys) ? keys : []);
        }
        catch (_b) {
            this.storedKeys = new Set();
        }
    }
    clear() {
        this.storedKeys.forEach((name) => {
            this._removeStorageValueItem(name);
        });
        this.storedKeys = new Set();
    }
    get(name) {
        if (!this.storedKeys.has(name))
            return null;
        const data = this._getStorageValueItem(name);
        this.setMostRecentKey(name);
        if (data === null)
            return null;
        const parsedData = JSON.parse(data);
        if (!parsedData)
            return null;
        if (this.ttl === null)
            return parsedData.value;
        const { updatedAt } = parsedData;
        const now = Date.now();
        if (this.ttl < now - updatedAt) {
            this._removeStorageValueItem(name);
            return null;
        }
        return parsedData.value;
    }
    set(name, data) {
        const dataWithUpdatedAt = {
            value: data,
            updatedAt: Date.now(),
        };
        const dataStr = JSON.stringify(dataWithUpdatedAt);
        if (!this.storedKeys.has(name)) {
            this.storedKeys.add(name);
        }
        else {
            this.setMostRecentKey(name);
        }
        do {
            try {
                this.storage.setItem(this.keysStoreKey, JSON.stringify([...this.storedKeys]));
                this._setStorageValueItem(name, dataStr);
                break;
            }
            catch (err) {
                this.removeLeastRecentItem();
            }
        } while (this.storedKeys.size > 0);
    }
    /**
     * The oldest item is the first in the stored keys array
     */
    removeLeastRecentItem() {
        const [leastRecentKeyName] = this.storedKeys;
        if (leastRecentKeyName === undefined)
            return;
        this._removeStorageValueItem(leastRecentKeyName);
    }
    setMostRecentKey(name) {
        this.storedKeys = new Set([...this.storedKeys].filter((storedName) => storedName !== name));
        this.storedKeys.add(name);
    }
    _getStorageValueItem(name) {
        return this.storage.getItem(`${this.prefix}:value:${name}`);
    }
    _setStorageValueItem(name, value) {
        this.storage.setItem(`${this.prefix}:value:${name}`, value);
    }
    _removeStorageValueItem(name) {
        this.storedKeys = new Set([...this.storedKeys].filter((storedName) => storedName !== name));
        this.storage.removeItem(`${this.prefix}:value:${name}`);
    }
}
export class LRUCacheLocalStorage extends LRUCache {
    constructor(prefix, ttl) {
        super(prefix, ttl, 'local');
    }
}
