"use strict";
exports.__esModule = true;
exports.yesterday = exports.today = exports.timeToDay = exports.dateToDay = exports.isDay = void 0;
var date_1 = require("./date");
var time_1 = require("./time");
var isDay = function (arg) {
    if (typeof arg !== "string")
        return false;
    var date = new Date(arg);
    if ((0, date_1.isInvalidDate)(date))
        return false;
    var day = date.toJSON().substring(0, 10);
    return day === arg;
};
exports.isDay = isDay;
/** Convert `Date` to `Day`. */
var dateToDay = function (date) {
    if ((0, date_1.isInvalidDate)(date))
        throw new date_1.ErrorInvalidDate();
    return date.toJSON().substring(0, 10);
};
exports.dateToDay = dateToDay;
/** Convert `Time` to `Day`. */
var timeToDay = function (time) {
    var date = new Date(time);
    return (0, exports.dateToDay)(date);
};
exports.timeToDay = timeToDay;
var today = function () { return (0, exports.dateToDay)(new Date()); };
exports.today = today;
var yesterday = function () {
    var today = new Date();
    var yesterday = new Date(today.getTime() - time_1.TIME.ONE_DAY);
    return (0, exports.dateToDay)(yesterday);
};
exports.yesterday = yesterday;
