import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {resetApp} from '../user/user-slice';

export type DraftsByConversationId = {
  draftsByConversationId: Record<string, string>;
};

export const initialState: DraftsByConversationId = {
  draftsByConversationId: {},
};

const draftsSlice = createSlice({
  name: 'drafts',
  initialState,
  reducers: {
    setDraftsByConversationId: (
      _state,
      action: PayloadAction<Record<string, string>>
    ) => ({
      draftsByConversationId: action.payload,
    }),
    setDraft: (
      state,
      action: PayloadAction<{conversationId: string; draft: string}>
    ) => ({
      draftsByConversationId: {
        ...state.draftsByConversationId,
        [action.payload.conversationId]: action.payload.draft,
      },
    }),
    clearDraft: (state, action: PayloadAction<string>) => {
      const newDrafts = {...state.draftsByConversationId};
      delete newDrafts[action.payload];
      return {draftsByConversationId: newDrafts};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetApp, () => initialState);
  },
});

export const {setDraft, setDraftsByConversationId, clearDraft} =
  draftsSlice.actions;

export const reducer = draftsSlice.reducer;
