import {DocumentData} from '../../../services/firebase';
import {Conversation} from '../types';

import {convertToTimestamp} from './firestore-helpers';

/**
 * We can receive conversation data from a database with dates of three types: strings,
 * numbers and firebase.firestore.Timestamp. The last one is non-serializable and can not be saved
 * to the store. This function handles converting dates into appropriate formats that can be
 * safely used in the application.
 */
export const transformConversation = (
  conversationData: DocumentData
): Conversation => {
  const data = {...conversationData};

  for (const key in data.masterParticipantsById) {
    data.masterParticipantsById[key] = {
      ...data.masterParticipantsById[key],
      createdAt: Date.parse(data.masterParticipantsById[key]?.createdAt),
      updatedAt:
        data.masterParticipantsById[key].updatedAt &&
        convertToTimestamp(data.masterParticipantsById[key]?.updatedAt),
    };
  }

  for (const key in data.friendshipsById) {
    data.friendshipsById[key] = {
      ...data.friendshipsById[key],
      updatedAt:
        data.friendshipsById[key]?.updatedAt &&
        convertToTimestamp(data.friendshipsById[key]?.updatedAt),
    };
  }

  const conversation: Partial<Conversation> = {
    ...data,
    markedAt: data.markedAt && convertToTimestamp(data.markedAt),
    createdAt: data.createdAt && convertToTimestamp(data.createdAt),
    lastActivityAt:
      data.lastActivityAt && convertToTimestamp(data.lastActivityAt),
  };

  if (data.friend && data.friend.createdAt) {
    conversation.friend = {
      ...data.friend,
      createdAt: Date.parse(data.friend.createdAt),
    };
  }

  return conversation as Conversation;
};
