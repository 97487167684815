"use strict";
exports.__esModule = true;
exports.objectTypeGuard = exports.isMaybeObject = void 0;
/**
 * Use `isMaybeObject` as a *type guard* helper.
 *
 * @example
 *```ts
 *type Foo = { bar: boolean };

 *const isFoo = (arg: unknown): arg is Foo => {
 *  if (isMaybeObject<Foo>(arg)) return false;
 *  const { bar } = arg;
 *  return typeof bar === "boolean";
 *}
 *```
 */
var isMaybeObject = function (arg) { return typeof arg === "object" && arg !== null && !Array.isArray(arg); };
exports.isMaybeObject = isMaybeObject;
/**
 * Use `objectTypeGuard` as a *type guard* helper to reduce boilerplate.
 *
 * @example
 * ```ts
 * type Foo = { bar: boolean };
 *
 * const isFoo = objectTypeGuard<Foo>(({ bar }) => typeof bar === "boolean");
 * ```
 */
var objectTypeGuard = function (check) {
    return function (arg) {
        return (0, exports.isMaybeObject)(arg) && check(arg);
    };
};
exports.objectTypeGuard = objectTypeGuard;
