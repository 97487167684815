import {Container, Text, chakra} from '@chakra-ui/react';
import React from 'react';

import {ReactComponent as SuccessImage} from '../../../assets/images/bulk-send-success.svg';

const StyledSuccessImage = chakra(SuccessImage);

const SuccessState: React.FC = () => (
  <Container maxW="500px" display="flex" flexDir="column" alignItems="center">
    <StyledSuccessImage my={24} maxW="465px" />
    <Text maxW="355px" fontSize="xl" textAlign="center" color="black">
      Your message has been sent.
    </Text>
  </Container>
);

export default SuccessState;
