import {Box, Flex, chakra, Text, Link} from "@chakra-ui/react";
import {format} from "date-fns";
import React from "react";
import {
  IoLinkOutline,
  IoLocationOutline,
  IoTimeOutline,
  IoPeopleOutline,
} from "react-icons/io5";

import {ReactComponent as VerifiedBadgeIcon} from "../../../assets/icons/verified-badge.svg";
import HighlightingWrapper from "../../../components/highlighting-wrapper";
import TagSection from "../../../components/tag-section/tag-section";
import clearTextHighlighting from "../../../helpers/clear-text-highlighting";
import {formatNumber} from "../../conversation/helpers/numbro-helpers";
import {buildTwitterProfileLink} from "../../conversation/helpers/profile-link-helpers";
import {buildConnectionsLabel} from "../helpers/build-connections-label";
import {Friend} from "../types";

import ConnectionsLabel from "./connections-label";
import FollowerToolbar from "./toolbar/follower-toolbar";

const StyledVerifiedBadgeIcon = chakra(VerifiedBadgeIcon);
const StyledBox = chakra(Box, {
  baseStyle: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "baseMinor",
    mb: 1,
  },
});
const Count = chakra("span", {
  baseStyle: {
    fontWeight: 500,
    textTransform: "uppercase",
  },
});

interface FollowerInfoProps {
  follower: Friend;
  showMessageButton?: boolean;
}

const FollowerInfo: React.FC<FollowerInfoProps> = ({follower, showMessageButton}) => {
  const {username} = follower;
  const {followersCount, followingCount} = follower.publicMetrics || {};
  const connectionsLabel = buildConnectionsLabel(follower);
  const twitterProfileLink = follower.twitterId
    ? buildTwitterProfileLink(follower.twitterId)
    : "";
  const plainTextUsername = username && clearTextHighlighting(username);

  return (
    <Box
      mb={{base: 4, sm: 0}}
      flex="1"
      fontSize="baseMinor"
      maxW={{base: "255px", sm: "580px"}}
    >
      <Flex mb={{base: 3, md: 1}} alignItems="center">
        <Link
          lineHeight="1.2"
          href={twitterProfileLink}
          title="Go to Twitter profile" // can add username later
          target="_blank"
          display="inline-flex"
          textDecor="none"
          alignItems={{md: "center"}}
          flexDir={{base: "column", md: "row"}}
          _hover={{
            textDecor: "none",
          }}
          _focus={{
            outline: "none",
          }}
        >
          <Flex alignItems="center">
            <chakra.span
              mr={1}
              fontWeight="medium"
              fontSize={{base: "base", md: "baseMajor"}}
            >
              {follower.name && <HighlightingWrapper text={follower.name} />}
            </chakra.span>
            {follower.verified && <StyledVerifiedBadgeIcon mr={1} />}
          </Flex>
          {username && (
            <chakra.span color="gray.500" fontSize={{base: "xs", md: "baseMinor"}}>
              @<HighlightingWrapper text={username} />
            </chakra.span>
          )}
        </Link>
        {connectionsLabel && (
          <Box display={{base: "none", md: "block"}}>
            <ConnectionsLabel label={connectionsLabel} />
          </Box>
        )}
      </Flex>
      <Flex display={{base: "flex", md: "none"}}>
        <FollowerToolbar follower={follower} showMessageButton={showMessageButton} />
      </Flex>
      {follower.description && (
        <Text whiteSpace="break-spaces" mb={2}>
          <HighlightingWrapper text={follower.description || " "} withLinks />
        </Text>
      )}

      <Flex flexWrap="wrap">
        {follower.url && (
          <Flex w="100%" fontSize="14px">
            <StyledBox>
              <IoLinkOutline size="16px" />
              <Link href={clearTextHighlighting(follower.url)} target="_blank">
                <HighlightingWrapper text={follower.url} />
              </Link>
            </StyledBox>
          </Flex>
        )}

        {follower.location && (
          <StyledBox mr={4}>
            <IoLocationOutline size="16px" />
            {<HighlightingWrapper text={follower.location} />}
          </StyledBox>
        )}
        {!isNaN(follower.createdAt) && (
          <StyledBox>
            <IoTimeOutline size="16px" />
            Joined in {format(follower.createdAt, "MMMM y")}
          </StyledBox>
        )}

        <Flex w="100%" fontSize="14px">
          {typeof followersCount !== "undefined" && (
            <StyledBox whiteSpace="nowrap">
              <IoPeopleOutline size="16px" />
              <chakra.span mr={2}>
                <Count>{formatNumber(followersCount)}</Count> Followers
              </chakra.span>
            </StyledBox>
          )}
          {typeof followingCount !== "undefined" && (
            <chakra.span whiteSpace="nowrap">
              {"•"}
              <Count ml={2}>{formatNumber(followingCount)}</Count> Following
            </chakra.span>
          )}
        </Flex>
      </Flex>
      {plainTextUsername && (
        <Link
          href={`https://twitter.com/${plainTextUsername}/followers_you_follow`}
          title={`Go to ${plainTextUsername}'s followers you follow`}
          target="_blank"
          display="inline-flex"
          textDecor="none"
          alignItems={{md: "center"}}
          flexDir={{base: "column", md: "row"}}
          _hover={{
            textDecor: "underline",
          }}
          _focus={{
            outline: "none",
          }}
        >
          <chakra.span color="gray.500" fontSize="xs">
            See followers you follow
          </chakra.span>
        </Link>
      )}
      <TagSection friend={follower} />
    </Box>
  );
};

export default FollowerInfo;
