"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.AUTH_ERROR_KEY = exports.DiscoveryUIStages = exports.discoveryIsIdle = exports.DISCOVERY_IDLE_TIME = exports.getNumberOfRemainingTargets = exports.newDiscovery = exports.PRICINGS = exports.AUDIENCES = exports.BUSINESS_TYPE = exports.SELLINGS = exports.DiscoveryStages = void 0;
var time_1 = require("./time");
exports.DiscoveryStages = {
    GENERATE_POSTS: "GENERATE_POSTS",
    GENERATE_TARGETS: "GENERATE_TARGETS",
    IDLE: "IDLE",
    COMPLETED: "COMPLETED"
};
exports.SELLINGS = [
    "Coaching",
    "Real estate",
    "Financial services",
    "Consulting",
    "Personal training",
    "Legal",
    "Other",
];
exports.BUSINESS_TYPE = [
    "Individual",
    "Company",
];
exports.AUDIENCES = [
    "Professionals",
    "Businesses",
    "General consumers",
    "Content creators",
];
exports.PRICINGS = [
    "under500",
    "between500and1000",
    "between1000and2500",
    "between2500and10000",
    "over10000",
];
var newDiscovery = function (discovery) { return (__assign(__assign({}, discovery), { currentStage: exports.DiscoveryStages.GENERATE_POSTS, activePage: 0, activePost: 0, generatedPosts: [], collectedTargetsCount: 0, usedTargetsCount: 0, hashtags: [] })); };
exports.newDiscovery = newDiscovery;
var getNumberOfRemainingTargets = function (discovery) {
    return (discovery.collectedTargetsCount || 0) - (discovery.usedTargetsCount || 0);
};
exports.getNumberOfRemainingTargets = getNumberOfRemainingTargets;
exports.DISCOVERY_IDLE_TIME = time_1.TIME.ONE_MINUTE * 10;
var discoveryIsIdle = function (discovery) {
    if (discovery.lastPing === undefined)
        return true;
    var lastUpdatedInterval = (0, time_1.now)() - discovery.lastPing;
    return lastUpdatedInterval > exports.DISCOVERY_IDLE_TIME;
};
exports.discoveryIsIdle = discoveryIsIdle;
exports.DiscoveryUIStages = {
    NOT_CREATED: "NOT_CREATED",
    COLLECTING: "COLLECTING",
    IDLE: "IDLE",
    CAN_CREATE_CAMPAIGN: "CAN_CREATE_CAMPAIGN",
    CREATED_FOR_TODAY: "CREATED_FOR_TODAY",
    IS_LOGGED_OUT: "IS_LOGGED_OUT"
};
exports.AUTH_ERROR_KEY = "auth-error";
