import {CHROME_EXTENSION_ID} from "../modules/integration/helpers/constants";

type Cookie = {
  name: string;
  value: string;
}

export const getInstagramCookies = (): Promise<Cookie[]> => {
  return new Promise((resolve, reject) => {
    try{
      let port = chrome.runtime.connect(CHROME_EXTENSION_ID, {name: "GET_INSTAGRAM_SESSION_ID"});

      port.onMessage.addListener((msg) => {
        if (msg.command === "RETURN_INSTAGRAM_SESSION_ID") {
          port.disconnect()

          resolve(msg.payload)
        }
      })
    }catch(e){
      reject("Something went wrong while connecting to the extension")
    }
  })
}

export const getInstagramUserDetails = (): Promise<Cookie[]> => {
  return new Promise((resolve, reject) => {
    try{
      let port = chrome.runtime.connect(CHROME_EXTENSION_ID, {name: "GET_INSTAGRAM_USER_DETAILS"});

      port.onMessage.addListener((msg) => {
        if (msg.command === "RETURN_INSTAGRAM_USER_DETAILS") {
          port.disconnect()

          resolve(msg.payload)
        }
      })
    }catch(e){
      reject("Something went wrong while connecting to the extension")
    }
  })
}
