import {FC, ReactNode} from "react";
import {
  Box,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps as ChakraModalProps,
} from "@chakra-ui/react";
import {ColorName} from "../colors";
import {Button, ButtonProps} from "./Button";
import {Icon, IconKey} from "./icons";

export type ModalVariant = "info" | "error" | "success" | "warning" | "delete";

export type ModalProps = Omit<ChakraModalProps, "variant"> & {
  isOpen: boolean;
  title?: ReactNode | string;
  onClose: () => void;
  variant?: ModalVariant;
  children?: ReactNode;
  closeButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  onConfirm?: () => void;
  extraActionText?: string;
  noFooter?: boolean;
  noHeader?: boolean;
  onExtraAction?: () => void;
  overlayProps?: ModalOverlayProps;
  contentProps?: ModalContentProps;
  bodyProps?: ModalBodyProps;
  headerProps?: ModalHeaderProps;
};

const variantIconMap = {
  info: {
    name: "infoFilled",
    color: "gray.400",
  },
  error: {
    name: "errorFilled",
    color: "red.500",
  },
  success: {
    name: "checkFilled",
    color: "green.500",
  },
  warning: {
    name: "warningFilled",
    color: "orange.400",
  },
  delete: {
    name: "trash",
    color: "red.500",
  },
} as const satisfies Record<ModalVariant, {name: IconKey; color: ColorName}>;

export const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  variant,
  children,
  title,
  onConfirm,
  extraActionText,
  onExtraAction,
  closeButtonProps = {},
  confirmButtonProps = {},
  contentProps = {},
  overlayProps = {},
  bodyProps = {},
  headerProps = {},
  noFooter,
  noHeader,
  ...props
}) => {
  const renderIcon = () => {
    if (!variant) return null;

    const {name, color} = variantIconMap[variant];
    return (
      <Box color={color}>
        <Icon name={name} size={24} />
      </Box>
    );
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...props}>
      <ModalOverlay {...overlayProps} />
      <ModalContent {...contentProps}>
        {!noHeader && (
          <ModalHeader display="flex" alignItems="center" gap="2.5">
            {renderIcon()}
            <Box fontWeight={700} fontSize="16px" color="black" flexGrow={1}>
              {title ?? "Title"}
            </Box>
          </ModalHeader>
        )}
        <ModalBody fontSize="sm" color="gray.900" {...bodyProps}>
          {children}
        </ModalBody>
        {!noFooter && (
          <ModalFooter>
            {extraActionText && (
              <Button p={0} variant="ghost" onClick={onExtraAction}>
                {extraActionText}
              </Button>
            )}
            <Button
              variant="ghost"
              onClick={onClose}
              mr={2}
              ml="auto"
              {...closeButtonProps}
            >
              {closeButtonProps.children ?? "Dismiss"}
            </Button>
            <Button variant="primary" onClick={onConfirm} {...confirmButtonProps}>
              {confirmButtonProps.children ?? "Submit"}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};
