import {Alert, AlertIcon, AlertProps, Box, Text} from '@chakra-ui/react';
import React from 'react';

interface AuthAlertProps {
  header: string;
}
const AuthAlert: React.FC<AuthAlertProps & AlertProps> = ({
  header,
  children,
  ...props
}) => (
  <Alert variant="left-accent" alignItems="flex-start" {...props}>
    <AlertIcon />
    <Box>
      <Text fontWeight="bold" fontSize="baseMajor">
        {header}
      </Text>
      {children}
    </Box>
  </Alert>
);

export default AuthAlert;
