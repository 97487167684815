import {
  isNonNegativeInteger,
  isNonEmptyString,
  isLiteralType,
  objectTypeGuard, NonNegativeInteger,
} from "@workspace/type-utils";
import {Item} from "./Item";
import {CreationTime, isTime, Time} from "./time";

export const MESSAGE_CONTENT = ["SAME", "RANDOM"] as const;
export type MessageContent = (typeof MESSAGE_CONTENT)[number];

export const TARGET_TYPE = ["NEW", "EXISTING", "MIX"] as const;
export type TargetType = (typeof TARGET_TYPE)[number];

const CAMPAIGN_STATUS = ["RUNNING", "PAUSED", "FINISHED", "CANCELLED"] as const;
type CampaignStatus = (typeof CAMPAIGN_STATUS)[number];

export type NewTestingCampaign = {
  totalDms: number;
  groupSize: number;
  individualInterval: {
    min: number;
    max: number;
  };
  groupInterval: {
    min: number;
    max: number;
  };
  messageContent: MessageContent;
  targetType: TargetType;
  targetTypeExistingCount: number;
};

export type TestingCampaign = Item & CreationTime & NewTestingCampaign & {
  instagramHandle: string;
  instagramAvatarURL: string;
  targets: Array<{
    handle: string;
    message: string;
    sentAt?: Time,
    failed?: boolean;
  }>,
  failedMessages: Array<{
    index: number;
    error: string;
  }>
  numSentMessages: NonNegativeInteger;
  status: CampaignStatus;
  completedAt?: Time | null;
};

export const isMessageContent = isLiteralType<MessageContent>(MESSAGE_CONTENT);

export const isTargetType = isLiteralType<TargetType>(TARGET_TYPE);

export const isMinMaxObject = objectTypeGuard<{
  min: number;
  max: number;
}>(({min, max}) => {
  return isNonNegativeInteger(min) && isNonNegativeInteger(max) && min < max;
});

export const isNewTestingCampaign = objectTypeGuard<NewTestingCampaign>(
  ({
    totalDms,
    groupSize,
    messageContent,
    targetType,
    individualInterval,
    groupInterval,
  }) => {
    return (
      isNonNegativeInteger(totalDms) &&
      isNonNegativeInteger(groupSize) &&
      isMessageContent(messageContent) &&
      isTargetType(targetType) &&
      isMinMaxObject(individualInterval) &&
      isMinMaxObject(groupInterval)
    );
  },
);

export const isTestingCampaign = objectTypeGuard<TestingCampaign>(
  ({id, whenCreated, ...newCampaignFields}) => {
    return (
      isNewTestingCampaign(newCampaignFields) &&
      isNonEmptyString(id) &&
      isTime(whenCreated)
    );
  },
);

export type TestingChromeStorage = {
  activeCampaign?: TestingCampaign["id"] | null;
  campaigns: Record<TestingCampaign["id"], TestingCampaign>;
};

export const isTestingCampaignMap = objectTypeGuard<
  Record<TestingCampaign["id"], TestingCampaign>
>((obj) => {
  return Object.keys(obj).every((testingCampaignId) =>
    isTestingCampaign(obj[testingCampaignId]),
  );
});

export const isTestingChromeStorage = objectTypeGuard<TestingChromeStorage>(
  ({activeCampaign, campaigns}) => {
    return (
      isTestingCampaignMap(campaigns) &&
      (activeCampaign === null ||
        activeCampaign === undefined ||
        isNonEmptyString(activeCampaign))
    );
  },
);
