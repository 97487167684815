import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import {FC, MouseEvent, useCallback} from "react";

import {useAppSelector} from "../../../app-hooks";
import {Friend} from "../../friend/types";

import RecipientRow from "./recipient-row";

interface MobileRecipientsListProps {
  recipients: Friend[];
  isOpen: boolean;
  onClose: () => void;
  removeRecipient: (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
    recipient: Friend,
  ) => void;
  onClick: (recipient: Friend) => void;
}

const MobileRecipientsList: FC<MobileRecipientsListProps> = ({
  recipients,
  isOpen,
  onClose,
  removeRecipient,
  onClick,
}) => {
  const {templatesById, currentRecipient} = useAppSelector((state) => state.bulkSend);

  const clickHandler = useCallback(
    (recipient: Friend) => {
      onClose();
      onClick(recipient);
    },
    [onClick, onClose],
  );

  return (
    <Drawer size="lg" placement="bottom" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent borderRadius={14}>
        <DrawerCloseButton
          _focus={{outline: "none"}}
          _active={{bgColor: "transparent"}}
          _hover={{bgColor: "transparent"}}
        />
        <DrawerHeader
          color="gray.800"
          fontSize="md"
          borderBottomWidth="1px"
          fontWeight="regular"
        >
          Recipients list
        </DrawerHeader>
        <DrawerBody>
          {recipients.map((recipient) => {
            const recipientId = recipient.twitterId || recipient.instagramId;

            return (
              <RecipientRow
                key={recipient.id}
                recipient={recipient}
                isPersonalized={!!templatesById[recipientId as string]}
                isCurrent={recipient.id === currentRecipient?.id}
                // @ts-ignore
                deleteHandler={(e) => removeRecipient(e, recipient)}
                onClick={() => clickHandler(recipient)}
              />
            );
          })}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileRecipientsList;
