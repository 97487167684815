import {extendTheme} from "@chakra-ui/react";
import {
  avatarStyleConfig,
  badgeStyleConfig,
  breadcrumbStyleConfig,
  buttonStyleConfig,
  checkboxStyleConfig,
  inputStyleConfig,
  menuStyleConfig,
  progressStyleConfig,
  radioStyleConfig,
  switchStyleConfig,
  tabsStyleConfig,
  textareaStyleConfig
} from "./components";
import {colorHex} from "./colors";

export const theme = extendTheme({
  colors: {
    transparent: "transparent",
    black: colorHex.black,
    white: colorHex.white,
    alpha: {
      "05": colorHex.alpha05,
      10: colorHex.alpha10,
      20: colorHex.alpha20,
      30: colorHex.alpha30,
      40: colorHex.alpha40,
      50: colorHex.alpha50,
      60: colorHex.alpha60,
    },
    red: {
      50: colorHex.red50,
      100: colorHex.red100,
      200: colorHex.red200,
      300: colorHex.red300,
      400: colorHex.red400,
      500: colorHex.red500,
      600: colorHex.red600,
      700: colorHex.red700,
      800: colorHex.red800,
      900: colorHex.red900,
    },
    orange: {
      50: colorHex.orange50,
      100: colorHex.orange100,
      200: colorHex.orange200,
      300: colorHex.orange300,
      400: colorHex.orange400,
      500: colorHex.orange500,
      600: colorHex.orange600,
      700: colorHex.orange700,
      800: colorHex.orange800,
      900: colorHex.orange900,
    },
    gray: {
      50: colorHex.gray50,
      100: colorHex.gray100,
      200: colorHex.gray200,
      300: colorHex.gray300,
      400: colorHex.gray400,
      500: colorHex.gray500,
      600: colorHex.gray600,
      700: colorHex.gray700,
      800: colorHex.gray800,
      900: colorHex.gray900,
    },
    green: {
      50: colorHex.green50,
      100: colorHex.green100,
      200: colorHex.green200,
      300: colorHex.green300,
      400: colorHex.green400,
      500: colorHex.green500,
      600: colorHex.green600,
      700: colorHex.green700,
      800: colorHex.green800,
      900: colorHex.green900,
    },
    primary: {
      50: colorHex.primary50,
      100: colorHex.primary100,
      200: colorHex.primary200,
      300: colorHex.primary300,
      400: colorHex.primary400,
      500: colorHex.primary500,
      600: colorHex.primary600,
      700: colorHex.primary700,
      800: colorHex.primary800,
      900: colorHex.primary900,
    },
  },
  fontSizes: {
    h1: "32px",
    h2: "24px",
    h3: "20px",
    h4: "18px",
    h5: "16px",
    h6: "14px",
  },
  lineHeights: {
    h1: "40px",
    h2: "32px",
    h3: "28px",
    h4: "24px",
    h5: "24px",
    h6: "20px",
  },
  letterSpacings: {
    h1: "-0.02em",
    h2: "-0.02em",
    h3: "-0.01em",
    h4: "-0.01em",
    h5: "0",
    h6: "0",
  },
  components: {
    Breadcrumb: breadcrumbStyleConfig,
    Button: buttonStyleConfig,
    Switch: switchStyleConfig,
    Avatar: avatarStyleConfig,
    Tabs: tabsStyleConfig,
    Menu: menuStyleConfig,
    Progress: progressStyleConfig,
    Badge: badgeStyleConfig,
    Input: inputStyleConfig,
    Textarea: textareaStyleConfig,
    Checkbox: checkboxStyleConfig,
    Radio: radioStyleConfig,
  },
});
