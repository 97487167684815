"use strict";
// To get configurations run
//
//     firebase use dev
//
// or
//
//     firebase use prod
//
// and then
//
//     firebase apps:sdkconfig
exports.__esModule = true;
exports.firebaseAppConfigProd = exports.firebaseAppConfigDev = void 0;
exports.firebaseAppConfigDev = {
    projectId: "dsm-dev-50f47",
    appId: "1:1056369461849:web:d4c6a7d71a92fa5856bf48",
    databaseURL: "https://dsm-dev-50f47.firebaseio.com",
    storageBucket: "dsm-dev-50f47.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyDe3pNF3q3LSp9bVZ6k1O3D9v9nn7Zm6fo",
    authDomain: "dsm-dev-50f47.firebaseapp.com",
    messagingSenderId: "1056369461849",
    measurementId: "G-X7SNJ1P1LK"
};
exports.firebaseAppConfigProd = {
    projectId: "dsm-network-prod",
    appId: "1:867475646543:web:720486748d684de6ac623a",
    databaseURL: "https://dsm-network-prod.firebaseio.com",
    storageBucket: "dsm-network-prod.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyCTEQJHYPMn0jhxhc1CFc1jgawyEseQjwM",
    authDomain: "dsm-network-prod.firebaseapp.com",
    messagingSenderId: "867475646543",
    measurementId: "G-VDCB0M2FFD"
};
