import {Box, Heading} from '@chakra-ui/layout';
import React from 'react';

import {useAppSelector} from '../../../app-hooks';
import {Friend} from '../../friend/types';

import RecipientRow from './recipient-row';

interface RecipientsListProps {
  recipients: Friend[];
  removeRecipient: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    recipient: Friend
  ) => void;
  onClick: (recipient: Friend) => void;
}

const RecipientsList: React.FC<RecipientsListProps> = ({
  recipients,
  removeRecipient,
  onClick,
}) => {
  const {templatesById, currentRecipient} = useAppSelector(
    (state) => state.bulkSend
  );

  return (
    <Box
      borderColor="gray.300"
      borderWidth="1px"
      borderRadius="16px 16px 0 0"
      borderBottom="none"
    >
      <Heading
        as="h2"
        px={4}
        py={6}
        color="gray.800"
        fontSize="md"
        fontWeight="normal"
        borderBottomColor="gray.150"
        borderBottomWidth="1px"
      >
        Recipients ({recipients.length})
      </Heading>
      {/* 229px = list heading + page heading with margin + TopNavbar */}
      <Box h="calc(100vh - 229px)" overflowY="auto" pb={3}>
        {recipients.map((recipient) => {
          const recipientId = recipient.twitterId || recipient.instagramId;

          return (
            <RecipientRow
              key={recipient.id}
              recipient={recipient}
              isPersonalized={!!templatesById[recipientId as string]}
              isCurrent={recipient.id === currentRecipient?.id}
              deleteHandler={(e) => removeRecipient(e, recipient)}
              onClick={() => onClick(recipient)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default RecipientsList;
