"use strict";
exports.__esModule = true;
exports.instagramUserProfileUrl = exports.isInstagramUser = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isInstagramUser = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var pk = _a.pk, full_name = _a.full_name, is_private = _a.is_private, is_verified = _a.is_verified, username = _a.username, profile_pic_url = _a.profile_pic_url;
    return (0, type_utils_1.isNonEmptyString)(pk) &&
        (0, type_utils_1.isFiniteString)(full_name) &&
        typeof is_private === "boolean" &&
        typeof is_verified === "boolean" &&
        typeof profile_pic_url === "string" &&
        (0, models_1.isSocialNetworkHandle)(username);
});
var instagramUserProfileUrl = function (arg) {
    return "https://".concat(models_1.socialNetworkHostname["Instagram"], "/").concat(arg, "/");
};
exports.instagramUserProfileUrl = instagramUserProfileUrl;
