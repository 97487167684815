import {UseToastOptions, createStandaloneToast} from "@chakra-ui/toast";
import {Toast, ToastVariant} from "@workspace/ui";

import theme from "../theme/theme";

export const dsmToast = (
  label: string,
  variant: ToastVariant,
  options: Partial<UseToastOptions> = {},
): (() => void) => {
  const {toast} = createStandaloneToast({theme: theme});

  const defaultToastOptions: Partial<UseToastOptions> = {
    position: "bottom-right",
    duration: 5000,
    render: () => <Toast label={label} variant={variant} lifetime={false} />,
  };

  const toastId = toast({
    ...defaultToastOptions,
    ...options,
  });

  return () => {
    if (toastId) toast.close(toastId);
  };
};
