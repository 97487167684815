"use strict";
exports.__esModule = true;
exports.isFollowUpMessagesQueue = exports.isUpdateFollowUpMessageStatusInput = exports.isNextFollowUpMessageOutput = exports.isNextFollowUpMessageInput = exports.isFollowUpMessage = void 0;
var type_utils_1 = require("@workspace/type-utils");
var models_1 = require("@workspace/models");
exports.isFollowUpMessage = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, initialMessageId = _a.initialMessageId, status = _a.status, followUpTime = _a.followUpTime, message = _a.message, userId = _a.userId, userInstagramId = _a.userInstagramId, username = _a.username, campaignId = _a.campaignId;
    return (0, type_utils_1.isNonEmptyString)(id) &&
        (0, type_utils_1.isNonEmptyString)(initialMessageId) &&
        (0, models_1.isFollowUpMessageStatus)(status) &&
        (0, models_1.isTime)(followUpTime) &&
        (0, type_utils_1.isNonEmptyString)(message) &&
        (0, type_utils_1.isNonEmptyString)(userId) &&
        (0, type_utils_1.isNonEmptyString)(userInstagramId) &&
        (0, type_utils_1.isNonEmptyString)(username) &&
        (0, type_utils_1.isNonEmptyString)(campaignId);
});
exports.isNextFollowUpMessageInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var instagramId = _a.instagramId, schedulerId = _a.schedulerId;
    return (0, type_utils_1.isNonEmptyString)(instagramId) && (0, type_utils_1.isNonEmptyString)(schedulerId);
});
exports.isNextFollowUpMessageOutput = (0, type_utils_1.objectTypeGuard)(exports.isFollowUpMessage);
exports.isUpdateFollowUpMessageStatusInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, status = _a.status;
    return (0, type_utils_1.isNonEmptyString)(id) && (0, models_1.isFollowUpMessageStatus)(status);
});
exports.isFollowUpMessagesQueue = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var activeSchedulerId = _a.activeSchedulerId, whenUpdated = _a.whenUpdated;
    return (0, models_1.isUpdateTime)({ whenUpdated: whenUpdated }) && activeSchedulerId === undefined
        ? true
        : (0, type_utils_1.isNonEmptyString)(activeSchedulerId);
});
