import {createAsyncThunk} from '@reduxjs/toolkit';

import {fetchScheduledMessages} from '../../modules/scheduled-messages/scheduled-messages-service';
import {HttpsCallableResult} from '../../services/firebase';
import {AppThunk} from '../../store/store';
import {
  markAsRead,
  markAsReadRejected,
  onConversationOpen,
} from '../conversation/conversations-slice';
import {Conversation} from '../conversation/types';

import {
  fetchMessages,
  markMessageReadOnCall,
  sendDirectMessageOnCall,
  sendDirectMessageToTwitterUserOnCall,
} from './message-service';

export const setMessagesListener =
  (conversationId: string, userId: string): AppThunk =>
  async (dispatch, getState) => {
    return Promise.all([
      fetchMessages(conversationId, userId, dispatch, getState),
      fetchScheduledMessages(conversationId, userId, dispatch),
    ]);
  };

export const sendDirectMessage = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {text: string; conversationId: string; hashKey: string}
>(
  'messages/sendDirectMessage',
  async ({text, conversationId}) =>
    await sendDirectMessageOnCall({text, conversationId})
);

export const sendDirectMessageToTwitterUser = createAsyncThunk<
  Conversation,
  {text: string; twitterUserId: string; hashKey: string}
>(
  'messages/sendDirectMessageToTwitterUser',
  async ({text, twitterUserId}, {dispatch}) => {
    const response = await sendDirectMessageToTwitterUserOnCall({
      messageText: text,
      twitterUserId,
    });

    // *hack* ideally, this would be handled in conversations-slice on
    // sendDirectMessageToTwitterUser.fulfilled, but there is a circular dep
    dispatch(onConversationOpen(response.data.conversation.id));

    return response.data.conversation;
  }
);

export const markMessageRead = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {conversationId: string; messageId: string}
>(
  'messages/markMessageRead',
  async ({conversationId, messageId}, {dispatch}) => {
    // *hack* ideally, markAsRead and markAsReadRejected would be handled in
    // conversations-slice on markMessageRead.pending, but there is a circular dep
    try {
      dispatch(markAsRead({conversationId}));
      return await markMessageReadOnCall(messageId);
    } catch (e) {
      dispatch(markAsReadRejected({conversationId}));
      throw e;
    }
  }
);
