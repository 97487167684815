import {ErrorLabelsMap, FirebaseErrorCode} from "../types";

export const DEFAULT_ERROR_LABEL = "An error occurred. Please try again.";

export const USER_NAME_REGEX = /^[a-z ,.'-]+$/i;

// Regex matches words with less than 8 characters or the words that contain only numbers, only letters in lower case, upper case, or words with without special character.
// If password matches the regex then it is invalid
export const PASSWORD_REGEX = /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/;

export const registrationErrorLabelsMap = {
  [FirebaseErrorCode.AuthEmailAlreadyUsed]: "This email is already in use",
  [FirebaseErrorCode.AuthInvalidEmail]: "Please check that the email address is valid.",
  [FirebaseErrorCode.AuthNotAllowed]: "Account not found.",
  [FirebaseErrorCode.AuthWeakPassword]:
    "Password must contain at least 8 characters both upper and lower case, numbers and special characters",
} as const satisfies ErrorLabelsMap;

export const loginErrorLabelsMap: ErrorLabelsMap = {
  [FirebaseErrorCode.AuthInvalidEmail]: "Please check that the email address is valid.",
  [FirebaseErrorCode.AuthUserDisabled]: "This account has been disabled.",
  [FirebaseErrorCode.AuthUserNotFound]: "There is no account for this email.",
  [FirebaseErrorCode.AuthWrongPassword]: "The password is incorrect.",
  [FirebaseErrorCode.AuthTooManyRequests]:
    "Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.",
};

export const changePasswordErrorLabelsMap: Record<string, string> = {
  [FirebaseErrorCode.AuthExpiredActionCode]: "The password reset link has expired.",
  [FirebaseErrorCode.AuthInvalidActionCode]: "The password reset link is invalid.",
  [FirebaseErrorCode.AuthUserNotFound]: "There is no account for this email.",
  [FirebaseErrorCode.AuthUserDisabled]: "This account has been disabled.",
  [FirebaseErrorCode.AuthWeakPassword]:
    "Password must contain at least 8 characters both upper and lower case, numbers and special characters",
};
