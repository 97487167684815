import {
  Day,
  isDay,
  isSocialNetworkHandle,
  Operation,
  SocialNetworkHandle
} from "@workspace/models";
import {
  arrayTypeGuard, isLiteralObjectType,
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  objectTypeGuard,
} from "@workspace/type-utils";

export const ZAPIER_WEBHOOK_TYPES = {
  MessageResponse: "MessageResponse",
} as const satisfies Record<string, string>

export type ZapierWebhookType = (typeof ZAPIER_WEBHOOK_TYPES)[keyof typeof ZAPIER_WEBHOOK_TYPES];
export const isZapierWebhookType = isLiteralObjectType<ZapierWebhookType>(ZAPIER_WEBHOOK_TYPES);

export type ZapierWebhook = {
  url: NonEmptyString;
  type: ZapierWebhookType;
};

export const isZapierWebhook = objectTypeGuard<ZapierWebhook>(
  ({url, type}) => isNonEmptyString(url) && isZapierWebhookType(type),
);

export type ZapierWebhookConfig = {
  [key in ZapierWebhookType]: ZapierWebhook;
};

export const isZapierWebhookConfig = objectTypeGuard<ZapierWebhookConfig>(
  ({MessageResponse}) =>
    MessageResponse === undefined ? true : isZapierWebhook(MessageResponse),
);

export type ReadZapierWebhookConfig = Operation<void, ZapierWebhookConfig | null>;

export const isReadZapierWebhookConfigOutput = objectTypeGuard<
  Exclude<ReadZapierWebhookConfig["out"], null>
>(({MessageResponse}) =>
  MessageResponse === null ? true : isZapierWebhook(MessageResponse),
);

export type WriteZapierWebhook = Operation<ZapierWebhook, void>;

export const isWriteZapierWebhookInput = objectTypeGuard<WriteZapierWebhook["in"]>(
  ({url, type}) => isNonEmptyString(url) && isZapierWebhookType(type),
);

export const isDeleteZapierWebhookInput = objectTypeGuard<WriteZapierWebhook["in"]>(
  ({type}) => isZapierWebhookType(type),
);

export type ZapierMessageResponseRow = {
  FROM: SocialNetworkHandle;
  TO: SocialNetworkHandle;
  TEXT_SENT: NonEmptyString;
  TEXT_RECEIVED: NonEmptyString;
  WHEN_SENT: Day;
  WHEN_RECEIVED: Day;
  ELAPSED_DAYS: number;
};

export const isZapierMessageResponseRow = objectTypeGuard<ZapierMessageResponseRow>(
  ({FROM, TO, WHEN_SENT, WHEN_RECEIVED, TEXT_SENT, TEXT_RECEIVED, ELAPSED_DAYS}) =>
    isSocialNetworkHandle(FROM) &&
    isSocialNetworkHandle(TO) &&
    isDay(WHEN_SENT) &&
    isDay(WHEN_RECEIVED) &&
    isNonEmptyString(TEXT_SENT) &&
    isNonEmptyString(TEXT_RECEIVED) &&
    isNonNegativeInteger(ELAPSED_DAYS),
);

export type SendMessageResponsesToZapier = Operation<
  {rows: ZapierMessageResponseRow[]},
  void
>;

export const isSendMessageResponsesToZapierInput = objectTypeGuard<
  SendMessageResponsesToZapier["in"]
>(({rows}) => arrayTypeGuard<ZapierMessageResponseRow>(isZapierMessageResponseRow)(rows));
