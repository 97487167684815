import {Box, Divider, Flex, Progress, Text} from "@chakra-ui/react";
import {captureException} from "@sentry/browser";
import {firebaseFunctions as functions} from "@workspace/firebase-app";
import {
  isCloudFunctionOutput,
  MonthlyPricingPlan,
  monthlyPricingPlans,
} from "@workspace/firebase-datamodel";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {
  MessagesCountContext,
  SubscriptionContext,
  useFirebaseUser,
} from "@workspace/react";
import {Button, Heading} from "@workspace/ui";
import {FC, useCallback, useContext, useMemo, useState} from "react";
import {dayToText} from "src/helpers/date";
// import {createBillingPortalOnCall} from "../user-service";
import {useHistory} from "react-router";
import {routes} from "src/router";
import format from "date-fns/format";

// To match the design when the 0 progress is still visible
const MINIMUM_PROGRESS_PERCENT = 1.4;

export const Subscription: FC = () => {
  const history = useHistory();

  const {remainingTrialDays, hasSubscription, subscriptionIsCanceled, subscription} =
    useContext(SubscriptionContext);

  const {dailyQuota, dailyCountPerPlatform} = useContext(MessagesCountContext);
  const {userInfo} = useFirebaseUser();

  const [createBillingPortalSessionIsPending, setCreateBillingPortalSessionIsPending] =
    useState(false);

  const {managePlanIsDisabled, managePlanIsLoading} = useMemo(() => {
    if (hasSubscription) {
      return {
        managePlanIsDisabled: false,
        managePlanIsLoading: createBillingPortalSessionIsPending,
      };
    }
    return {
      managePlanIsDisabled: true,
      managePlanIsLoading: false,
    };
  }, [hasSubscription, createBillingPortalSessionIsPending]);

  const handleSelectPlan = useCallback(() => {
    history.push(routes.PaymentPlans.route);
  }, [history]);

  const handleManagePlan = useCallback(async () => {
    try {
      if (managePlanIsDisabled) return;
      if (managePlanIsLoading) return;
      setCreateBillingPortalSessionIsPending(true);
      const {data: billingPortalSession} = await functions.httpsCallable(
        cloudFunctionName.createBillingPortalSession,
      )();
      if (isCloudFunctionOutput.createBillingPortalSession(billingPortalSession))
        window.open(billingPortalSession.url, "_self");
    } catch (error) {
      captureException(error);
    } finally {
      setCreateBillingPortalSessionIsPending(false);
    }
  }, [managePlanIsDisabled, managePlanIsLoading]);

  const getProgressPerPlatform = useCallback(
    (platform: keyof typeof dailyCountPerPlatform) => {
      const dailyCount = dailyCountPerPlatform[platform];

      if (dailyQuota === undefined || dailyCount === undefined)
        return MINIMUM_PROGRESS_PERCENT;

      return Math.max((100 * dailyCount) / dailyQuota, MINIMUM_PROGRESS_PERCENT);
    },
    [dailyCountPerPlatform, dailyQuota],
  );

  const renderActiveSubscriptionText = () => {
    if (typeof remainingTrialDays === "number") {
      let heading;

      if (remainingTrialDays === 0) {
        heading = "Trial Expired";
      } else {
        heading = `${dayToText(remainingTrialDays)} left in trial`;
      }

      return (
        <Box>
          <Text color="gray.900" fontWeight="bold" fontSize="md">
            {heading}
          </Text>
          {!!dailyQuota && (
            <Text color="gray.600" fontSize="base">
              {dailyQuota} messages per day
            </Text>
          )}
        </Box>
      );
    }

    if (subscriptionIsCanceled) {
      return (
        <Box>
          <Text color="gray.900" fontWeight="bold" fontSize="md">
            Plan canceled
          </Text>
          <Text color="gray.600" fontSize="14px">
            You can keep using Howdy until{" "}
            {subscription?.cancelAt ? format(subscription?.cancelAt!, "d MMM yyyy") : ""}.
          </Text>
        </Box>
      );
    }

    const isMonthly =
      monthlyPricingPlans[subscription?.pricingPlan as MonthlyPricingPlan] !== undefined;

    return (
      <Box>
        {!!dailyQuota && (
          <Text color="gray.900" fontWeight="bold" fontSize="md">
            {dailyQuota} messages/day
          </Text>
        )}
        <Text color="gray.600" fontSize="14px">
          {isMonthly ? "Monthly" : "Yearly"} plan
        </Text>
      </Box>
    );
  };

  const renderActionButton = () => {
    let buttonText;

    if (typeof remainingTrialDays === "number") {
      if (remainingTrialDays === 0) {
        buttonText = "Upgrade Plan";
      } else {
        buttonText = "Manage Plan";
      }
    } else {
      if (subscriptionIsCanceled) {
        buttonText = "Renew Plan";
      } else {
        buttonText = "Manage Plan";
      }
    }

    return (
      <Button
        variant="primary"
        px="4"
        py="3"
        isLoading={managePlanIsLoading}
        onClick={handleManagePlan}
        disabled={managePlanIsDisabled}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Flex gap="4" fontFamily="Inter">
      <Box width="40%">
        <Heading as="h2" color="gray.900" variant="h3" mb="2">
          Your Howdy Plan
        </Heading>
        <Text color="gray.600" fontSize="14px" lineHeight="20px">
          View and manage all your subscription details
        </Text>
      </Box>
      <Box
        p="8"
        width="60%"
        borderRadius="md"
        border="1px solid rgba(0, 0, 0, 0.08)"
        boxShadow="0px 5px 12px rgba(0, 0, 0, 0.05)"
      >
        {hasSubscription === true && (
          <Flex
            justifyContent="space-between"
            alignItems={typeof remainingTrialDays === "number" ? "start" : "center"}
          >
            {renderActiveSubscriptionText()}
            {renderActionButton()}
          </Flex>
        )}
        {hasSubscription === false && (
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="gray.900" fontSize="md">
              No active subscription
            </Text>
            <Button onClick={handleSelectPlan}>Select plan</Button>
          </Flex>
        )}
        {typeof dailyCountPerPlatform.instagram === "number" &&
          typeof dailyCountPerPlatform.twitter === "number" &&
          typeof dailyQuota === "number" &&
          dailyQuota !== 0 && (
            <>
              <Divider my="6" />
              <Box>
                <Text color="gray.700" fontWeight="bold" fontSize="md" mb="6">
                  Remaining daily limit
                </Text>
                <Flex gap="3" fontFamily="Inter">
                  <Box flexGrow="1">
                    <Flex mb="3" fontSize="14px">
                      <Text fontWeight="bold" color="gray.900" mr="2">
                        Instagram
                      </Text>
                      <Text color="gray.600">
                        {dailyCountPerPlatform.instagram}/{dailyQuota}
                      </Text>
                    </Flex>
                    <Progress
                      borderRadius="full"
                      size="sm"
                      colorScheme="primary"
                      value={getProgressPerPlatform("instagram")}
                    />
                  </Box>
                  <Box flexGrow="1">
                    <Flex mb="3" fontSize="14px">
                      <Text fontWeight="bold" color="gray.900" mr="2">
                        Twitter
                      </Text>
                      <Text color="gray.600">
                        {dailyCountPerPlatform.twitter}/{dailyQuota}
                      </Text>
                    </Flex>
                    <Progress
                      borderRadius="full"
                      size="sm"
                      colorScheme="primary"
                      value={getProgressPerPlatform("twitter")}
                    />
                  </Box>
                </Flex>
              </Box>
              <Divider my="6" />
              <Box>
                <Text color="gray.600" fontSize="base">
                  Email:
                </Text>
                <Text color="gray.900" fontSize="base">
                  {userInfo?.email ?? ""}
                </Text>
              </Box>
            </>
          )}
      </Box>
    </Flex>
  );
};
