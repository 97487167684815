export const cloudFunctionNames = [
    "createBillingPortalSession",
    "createCheckoutSession",
    "createConversation",
    "incrementMessagesDailyCount",
    "readConversation",
    "readMessagesDailyCount",
    "readMessagesDailyQuota",
    "readSubscription",
    "selectConversations",
    "readInstagramUser",
    "writeInstagramUser",
    "readMultipleInstagramUsers",
    "writeMultipleInstagramUsers",
    "writeInstagramConversation",
    "readMultipleInstagramConversations",
    "createInstagramPendingMessage",
    "readInstagramPendingMessages",
    "resolveInstagramPendingMessages",
    "readInstagramCampaigns",
    "createInstagramCampaign",
    "setInstagramCampaignStatus",
    "nextInstagramMessage",
    "updateInstagramCampaignStatus",
    "increaseInstagramSentMessagesCount",
    "deleteExpiredCampaigns",
    "writeZapierWebhook",
    "readZapierWebhookConfig",
    "deleteZapierWebhook",
    "markMessagesAsIgnored",
    "createMessageTemplate",
    "skipCampaignMessage",
    "deleteMessageTemplate",
    "updateMessageTemplate",
    "readScheduledMessagesDailyCount",
    "sendPasswordChangedEmailOnCall",
    "createDiscovery",
    "nextDiscoveryAction",
    "saveActiveDiscoveryTab",
    "saveDiscoveryPosts",
    "saveDiscoveryTargets",
    "getDiscoveryData",
    "fetchDiscovery",
    "describeInterestFromOpenAI",
    "scrapeInstagramUser",
    "createCampaignFromDiscovery",
    "nextFollowUpMessage",
    "updateFollowUpMessageStatus",
    "notifySlackAboutCampaignErrors",
    "saveUserLogs",
    "updateCampaignData",
    "triggerCampaignsCoolDown",
    "generateMagicDM",
    "getInstagramUserById",
    "blockTarget",
    "getBlockedTargets",
    "uploadInstagramUserAvatar",
    "pingBackendForDiscovery",
    "getUserFeatures",
    "updateDiscoverySession",
    "saveDiscoveryReport",
    "generateTestingTargets",
    "generateTestingMessages",
    "saveMonitoringLog",
    "shouldProceedWithMonitoring",
    "trackAnalyticEventFrontend",
    "createList",
    "fetchUserLists",
    "addLeadsToList",
    "fetchUserLeads",
    "fetchUserListBySlug",
    "createCampaignFromLeads",
    "saveUnprocessedLeads",
    "fetchUnprocessedLeads",
    "getLeadsCampaign",
    "deleteDiscoveryTarget",
];
export const cloudFunctionName = Object.fromEntries(cloudFunctionNames.map((key) => [key, key]));
