import {isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {InstagramUser} from "./InstagramUser";
import {TwitterUser} from "./TwitterUser";
import {isSocialNetworkHandle, SocialNetworkHandle} from "./SocialNetwork";
import {TargetCollectionOrigin} from "./Campaign";
import {Time} from "./time";
import {Post} from "./InstagramEndpoints";

export type SocialNetworkUser = {
  avatar: NonEmptyString;
  name: string;
  handle: SocialNetworkHandle;
  id?: NonEmptyString;
  bio?: NonEmptyString;
  collectionOrigin?: Array<{
    handle: NonEmptyString;
    type: TargetCollectionOrigin;
    url: NonEmptyString;
    createdAt: Time
  }>;
  followers?: NonEmptyString;
  following?: NonEmptyString;
  posts?: NonEmptyString;
  is_private?: boolean;
  is_verified?: boolean;
  bio_links?: Array<{
    title: NonEmptyString;
    url: NonEmptyString;
  }>;
};

export type SocialNetworkUserWithPostDetails = SocialNetworkUser & {
  postsWithDetails: Post[];
}

export const isSocialNetworkUser = objectTypeGuard<SocialNetworkUser>(
  ({avatar, name, handle, id, bio}) =>
    isNonEmptyString(avatar) &&
    typeof name === "string" &&
    isSocialNetworkHandle(handle) &&
    id === undefined
      ? true
      : isNonEmptyString(id) &&
    (bio === undefined ? true : typeof bio === "string"),
);

export const instagramUserToSocialNetworkUser = ({
  profile_pic_url,
  full_name,
  username,
  pk,
}: Pick<
  InstagramUser,
  "profile_pic_url" | "full_name" | "username" | "pk"
>): SocialNetworkUser => ({
  avatar: profile_pic_url,
  name: full_name,
  handle: username,
  id: pk,
});

export const twitterUserToSocialNetworkUser = ({
  profile_image_url_https,
  name,
  screen_name,
  verified
}: Pick<
  TwitterUser,
  "profile_image_url_https" | "name" | "screen_name" | "verified"
>): SocialNetworkUser => ({
  avatar: profile_image_url_https,
  name,
  handle: screen_name,
  is_verified: verified
});
