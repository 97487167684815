import {ReactNode, useCallback, useMemo} from "react";
import {Box, Flex, Link, Text} from "@chakra-ui/react";
import {allPricingPlans, isPricingPlanName, PricingPlanName,} from "@workspace/firebase-datamodel";
import {components} from "react-select";
import {Button, ButtonProps} from "./Button";
import {Select, SelectOption, SelectProps} from "./Select";
import {Skeleton} from "./Skeleton";
import {Icon} from "./icons";

export const PRICING_PLAN_NAME_LABELS: Partial<Record<PricingPlanName, string>> = {
  basic: "Starter",
  basic_yearly: "Starter",
  popular: "Pro",
  popular_yearly: "Pro",
  pro: "Ultimate",
  pro_yearly: "Ultimate",
};

const PRICING_PAGE = "https://sayhowdy.co/pricing";

type Props = {
  price: string;
  description: ReactNode;
  buttonProps: ButtonProps;
  isLoading?: boolean;
  options: SelectProps["options"];
  selectedPlanName?: PricingPlanName;
  onPlanChange: (newSelectedPlanName: PricingPlanName) => void;
  onSubmit?: () => void;
};

export const PricingCard = ({
  price,
  description,
  buttonProps,
  options,
  selectedPlanName = "basic",
  onPlanChange: _onPlanChange,
  isLoading,
  onSubmit,
}: Props) => {
  const [integerPart, decimalPart] = useMemo(() => price?.split(".") ?? [], [price]);

  const onPlanChange = useCallback(
    (_option: unknown | null) => {
      const option = _option as SelectOption;
      if (!option || !isPricingPlanName(option.value)) return;

      _onPlanChange(option.value);
    },
    [_onPlanChange],
  );

  return (
    <Box background="white" borderRadius="16px" p="8" height="100%">
      {isLoading ? (
        <Flex flexDir="column">
          <Skeleton width="74px" height="40px" mb="2" />
          <Skeleton width="178px" height="10px" mb="2" />
          <Skeleton width="178px" height="10px" mb="10" />
          <Skeleton width="100%" height="48px" mb="2" />
          <Skeleton width="100%" height="48px" mb="8" />
          <Skeleton width="178px" height="10px" />
        </Flex>
      ) : (
        <>
          <Text fontWeight="bold" fontSize="20px" mb="2">
            {PRICING_PLAN_NAME_LABELS[selectedPlanName]}
          </Text>
          <Flex alignItems="start" gap="2px" mb="20px">
            <Text fontWeight="bold" fontSize="20px" color="gray.500">
              $
            </Text>
            <Text fontWeight="bold" color="gray.900" fontSize="40px" lineHeight="40px">
              {integerPart}
            </Text>
            <Text fontWeight={400} fontSize="14px" color="gray.500">
              {decimalPart ? `.${decimalPart} USD` : ""}
            </Text>
          </Flex>
          <Box mb="32px">
            <Text color="gray.600" fontSize="14px" lineHeight="20px">
              {description}
            </Text>
          </Box>
          <Box mb="3">
            <Select
              value={selectedPlanName}
              options={options}
              components={{
                Option: ({data: _data, ...props}) => {
                  const data = _data as {label: string; value: PricingPlanName};

                  return (
                    <components.Option data={data} {...props}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        height="36px"
                      >
                        <Text color="gray.900" fontSize="14px" fontWeight="500">
                          {data.label}
                        </Text>
                        {isPricingPlanName(data.value) && (
                          <Text color="primary.500" fontSize="14px" fontWeight="500">
                            ${allPricingPlans[data.value].price?.split(".")[0]}
                          </Text>
                        )}
                      </Box>
                    </components.Option>
                  );
                },
              }}
              dropdownIcon="select"
              onChange={onPlanChange}
            />
          </Box>
          <Box mb="4">
            <Button
              {...buttonProps}
              width="100%"
              size="lg"
              variant="primary"
              onClick={onSubmit}
            />
          </Box>
          <Link href={PRICING_PAGE} target="_blank">
            <Button variant="ghost" width="100%">
              <Flex justifyContent="center" alignItems="center" gap="2">
                <Text fontWeight="500" fontFamily="Inter">
                  Compare plans
                </Text>
                <Box pt="1px" color="#3733E5">
                  <Icon name="arrowUpRight" size="8" />
                </Box>
              </Flex>
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};
