import {
  CreationTime,
  Day, InstagramPendingMessage,
  isDay,
  isSocialNetworkHandle,
  isTime,
  Operation,
  SocialNetworkHandle,
  Time
} from "@workspace/models";
import {
  arrayTypeGuard,
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  NonNegativeInteger,
  objectTypeGuard,
} from "@workspace/type-utils";
import {isZapierMessageResponseRow, ZapierMessageResponseRow} from "./zapierWebhooks";

export const isInstagramPendingMessage = objectTypeGuard<InstagramPendingMessage>(
  ({threadId, messageId, whenCreated}) => isTime(whenCreated) && isNonEmptyString(threadId) && isNonEmptyString(messageId),
);

export type CreateInstagramPendingMessage = Operation<
  {sender: SocialNetworkHandle} & InstagramPendingMessage,
  void
>;

export const isCreateInstagramPendingMessage = objectTypeGuard<
  CreateInstagramPendingMessage["in"]
>(
  ({sender, ...thread}) =>
    isSocialNetworkHandle(sender) && isInstagramPendingMessage(thread),
);

export const isCreateInstagramPendingMessageInput = objectTypeGuard<
  CreateInstagramPendingMessage["in"]
>(isCreateInstagramPendingMessage);

export type ReadInstagramPendingMessage = Operation<
  {sender: SocialNetworkHandle},
  InstagramPendingMessage[]
>;

export const isReadInstagramPendingMessagesInput = objectTypeGuard<
  ReadInstagramPendingMessage["in"]
>(({sender}) => isSocialNetworkHandle(sender));

export const isReadInstagramPendingMessagesOutput = arrayTypeGuard<InstagramPendingMessage>(
  isInstagramPendingMessage,
);

export type ResolveInstagramPendingMessageItem = {
  messageId: NonEmptyString;
  responseTime: Time;
  zapierData: ZapierMessageResponseRow
}

export const isResolvePendingMessageInputItem = objectTypeGuard<ResolveInstagramPendingMessageItem>(
  ({ messageId, responseTime, zapierData }) =>
    isNonEmptyString(messageId) &&
    isTime(responseTime) &&
    isZapierMessageResponseRow(zapierData),
)

const isResolvePendingMessageInputMessages = arrayTypeGuard<ResolveInstagramPendingMessageItem>(isResolvePendingMessageInputItem)

export type ResolveInstagramPendingMessages = Operation<
  {sender: SocialNetworkHandle; messages: ResolveInstagramPendingMessageItem[]},
  void
>;

export const isResolveInstagramPendingMessageInput = objectTypeGuard<
  ResolveInstagramPendingMessages["in"]
>(
  ({sender, messages}) =>
    isSocialNetworkHandle(sender) &&
    isResolvePendingMessageInputMessages(messages),
);

export type MarkMessagesAsIgnored = Operation<
  {sender: SocialNetworkHandle; messages: NonEmptyString[]},
  void
>;

const isMarkMessageAsIgnoredInputMessages = arrayTypeGuard<NonEmptyString>(isNonEmptyString)

export const isMarkMessageAsIgnoredInput = objectTypeGuard<
  MarkMessagesAsIgnored["in"]
>(
  ({sender, messages}) =>
    isSocialNetworkHandle(sender) &&
    isMarkMessageAsIgnoredInputMessages(messages),
);

export type InstagramRepliedMessage = CreationTime & {
  threadId: NonEmptyString;
  responseTime: Time;
};

export const isInstagramRepliedMessage =
  objectTypeGuard<InstagramRepliedMessage>(
    ({whenCreated, responseTime, threadId}) =>
      isTime(whenCreated) && isTime(responseTime) && isNonEmptyString(threadId),
  );

export type InstagramThreadResponseRate = {
  numRequests: NonNegativeInteger;
  numResponses: NonNegativeInteger;
};

export const isInstagramThreadResponseRate = objectTypeGuard<InstagramThreadResponseRate>(
  ({numRequests, numResponses}) =>
    isNonNegativeInteger(numRequests) && isNonNegativeInteger(numResponses),
);

export type InstagramThreadDailyResponseRate = InstagramThreadResponseRate & {
  day: Day;
};

export const isInstagramThreadDailyResponseRate =
  objectTypeGuard<InstagramThreadDailyResponseRate>(
    ({day, ...responseRate}) => isDay(day) && isInstagramThreadResponseRate(responseRate),
  );

export const isInstagramThreadDailyResponseRates =
  arrayTypeGuard<InstagramThreadDailyResponseRate>(isInstagramThreadDailyResponseRate);
