import React, {FC, ReactNode, useContext, useEffect, useState} from "react";
import InnerAppLayout from "../../components/inner-app-layout";
import whatNextImage from "../../assets/images/discovery-what-next.png";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  ListItem as ChakraListItem,
  OrderedList,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {IoArrowBackOutline} from "react-icons/io5";
import {FiExternalLink} from "react-icons/fi";
import {BsCheck2} from "react-icons/bs";
import {Button, Input} from "@workspace/ui";
import {CHROME_EXTENSION_DOWNLOAD_URL} from "../../modules/integration/helpers/constants";
import {CAMPAIGN_STATUS, isCampaign, isSocialNetworkUser, socialNetworkHostname} from "@workspace/models";
import {DiscoveryDataContext, useFirebaseUser} from "@workspace/react";
import {firebaseFirestore, firebaseFunctions} from "@workspace/firebase-app";
import {collectionName, subcollectionName} from "@workspace/firebase-datamodel";
import {cloudFunctionName} from "@workspace/firebase-functions/@workspace/firebase-definitions";
import {searchInstagramByUsername} from "../../utils/searchInstagramByUsername";

const ListItem = ({
  heading,
  description,
  cta,
  isActive,
  index,
  fullWidthDescription,
}: {
  heading: string;
  description: ReactNode | string;
  cta: ReactNode;
  isActive?: boolean;
  index: number;
  fullWidthDescription?: boolean;
}) => {
  return (
    <Flex p={6} fontFamily="Inter" alignItems="center" opacity={isActive ? 1 : 0.4}>
      <Text
        color="primary.400"
        fontWeight="500"
        mr={2}
        alignSelf="flex-start"
        fontSize="14px"
      >
        {index}.
      </Text>
      <Box width="100%">
        <Text fontWeight="700" fontSize="14px" color="gray.900" mb={1}>
          {heading}
        </Text>
        {isActive && (
          <Box
            fontSize="14px"
            maxWidth={fullWidthDescription ? undefined : "420px"}
            color="alpha.60"
            mb={1}
          >
            {description}
          </Box>
        )}
      </Box>
      {isActive && (
        <Box ml="auto" flexShrink={0}>
          {cta}
        </Box>
      )}
    </Flex>
  );
};

const STAGES = {
  INSTALL_EXTENSION: 1,
  ADD_INSTAGRAM_HANDLE: 2,
  CREATE_CAMPAIGN: 3,
  SEND_MESSAGES: 4,
} as const;

type STAGE = (typeof STAGES)[keyof typeof STAGES];

type Cookie = {
  name: string;
  value: string;
}

interface Props {
  extensionIsInstalled: boolean | undefined;
  handleClickBack: () => void;
  onCampaignStarted: () => void;
  isCampaignCreatedAndNotStarted: boolean | undefined;
  campaignId: string | undefined;
}

export const WhatNext: FC<Props> = ({
  extensionIsInstalled,
  handleClickBack,
  onCampaignStarted,
  isCampaignCreatedAndNotStarted,
  campaignId: _campaignId = null,
}) => {
  const {userInfo} = useFirebaseUser();

  let initialStage;
  if (isCampaignCreatedAndNotStarted) {
    initialStage = STAGES.SEND_MESSAGES;
  } else {
    initialStage = extensionIsInstalled
      ? STAGES.ADD_INSTAGRAM_HANDLE
      : STAGES.INSTALL_EXTENSION;
  }

  const [activeStage, setActiveStage] = React.useState<STAGE>(initialStage);
  const [campaignId, setCampaignId] = useState<string | null>(_campaignId);

  const [isSavingInstagramHandle, setIsSavingInstagramHandle] = useState(false);

  const {isCreatingCampaign, createCampaign, instagramData, setInstagramData} =
    useContext(DiscoveryDataContext);

  useEffect(() => {
    if (extensionIsInstalled && activeStage === STAGES.INSTALL_EXTENSION) {
      setActiveStage(STAGES.ADD_INSTAGRAM_HANDLE);
    }
  }, [extensionIsInstalled, activeStage]);

  useEffect(() => {
    let unsub: Function;

    if (campaignId) {
      unsub = firebaseFirestore
        .collection(collectionName.instagramCampaignsQueue)
        .doc(userInfo?.uid)
        .collection(subcollectionName.instagramCampaigns)
        .doc(campaignId)
        .onSnapshot(async (snapshot) => {
          const campaign = snapshot.data();

          if (isCampaign(campaign) && campaign.status === CAMPAIGN_STATUS.RUNNING) {
            onCampaignStarted();
          }
        });
    }

    return () => {
      unsub?.();
    };
  }, [campaignId, isCampaignCreatedAndNotStarted, onCampaignStarted, userInfo?.uid]);

  const handleClickCreateCampaign = async () => {
    const campaign = await createCampaign();

    if (campaign) {
      setActiveStage(STAGES.SEND_MESSAGES);
      setCampaignId(campaign.id);
    }
  };

  const handleClickSaveInstagramHandle = async () => {
    setIsSavingInstagramHandle(true);

    try {
      const handle = instagramData.handle.trim();
      if (handle) {
        const userData = await searchInstagramByUsername(handle);
        if(!isSocialNetworkUser(userData)) {
          throw new Error("User not found")
        }

        const response = await firebaseFunctions.httpsCallable(
          cloudFunctionName.uploadInstagramUserAvatar,
        )({
          avatarUrl: userData.avatar,
          instagramId: userData.id,
        });

        setInstagramData({
          handle: userData.handle,
          avatarUrl: response.data,
          id: userData.id!,
        })
        setActiveStage(STAGES.CREATE_CAMPAIGN);
      }

    }
    catch (e) {
      //@ts-ignore
      alert(e.message)
    }
    setIsSavingInstagramHandle(false);
  };

  const renderExtensionCTA = () => {
    if (extensionIsInstalled || activeStage > STAGES.INSTALL_EXTENSION) {
      return (
        <Flex gap={2} alignItems="center" color="green.500">
          <BsCheck2 size={20} />
          <Text fontSize="14px" fontWeight="600">
            Installed
          </Text>
        </Flex>
      );
    }

    return (
      <a href={CHROME_EXTENSION_DOWNLOAD_URL} target="_blank" rel="noreferrer">
        <Button variant="primary">Install Chrome Extension</Button>
      </a>
    );
  };

  const renderCreateCampaignCTA = () => {
    if (activeStage !== STAGES.SEND_MESSAGES) {
      return (
        <Button
          variant="primary"
          disabled={isCreatingCampaign}
          onClick={handleClickCreateCampaign}
        >
          {isCreatingCampaign ? (
            <>
              <Spinner size="sm" emptyColor="#FFFFFF30" color="#FFFFFF" mr={2} />
              Creating...
            </>
          ) : (
            "Create campaign"
          )}
        </Button>
      );
    }

    return (
      <Flex gap={2} alignItems="center" color="green.500">
        <BsCheck2 size={20} />
        <Text fontSize="14px" fontWeight="600">
          Campaign ready
        </Text>
      </Flex>
    );
  };

  const renderGetInstagramHandleCTA = () => {
    if (activeStage > STAGES.ADD_INSTAGRAM_HANDLE) {
      return (
        <Flex gap={2} alignItems="center" color="green.500">
          <BsCheck2 size={20} />
          <Text fontSize="14px" fontWeight="600">
            Done
          </Text>
        </Flex>
      );
    }

    return null;
  };

  const renderInstagramHandleDescription = () => {
    let bottomContent;

    if (activeStage > STAGES.ADD_INSTAGRAM_HANDLE) {
      bottomContent = (
        <Flex align="center">
          <Image alt={instagramData.handle} width="24px" height="24px" borderRadius="50%" mr={2} src={instagramData.avatarUrl}/>
          <Text fontSize="h6" color="gray.900">
            {instagramData.handle}
          </Text>
          <Button
            padding={0}
            ml={2}
            variant="ghost"
            size="sm"
            onClick={() => setActiveStage(STAGES.ADD_INSTAGRAM_HANDLE)}
          >
            Edit
          </Button>
        </Flex>
      );
    } else {
      bottomContent = (
        <Flex align="center" gap={4} width="100%">
          <Input
            flexGrow={1}
            value={instagramData.handle}
            onChange={(e) => setInstagramData({ ...instagramData, handle: e.target.value })}
          />
          <Button height="40px" isLoading={isSavingInstagramHandle} onClick={handleClickSaveInstagramHandle} width="88px">
            Save
          </Button>
        </Flex>
      );
    }

    return (
      <>
        <Text mb={4}>Enter the Instagram handle you’ll use for this campaign</Text>
        {bottomContent}
      </>
    );
  };

  return (
    <InnerAppLayout hasBasicHeader>
      <Flex gridTemplateColumns="1.4fr 1fr">
        <Box flexGrow={1} pt={16} pl={14} pr="120px">
          <Flex gap={2} color="primary.400" mb={6} onClick={handleClickBack} as="button">
            <IoArrowBackOutline size={20} />
            <Text fontSize="14px" fontWeight={600}>
              Back
            </Text>
          </Flex>
          <Heading fontSize="32px" fontWeight={700} color="gray.900" mb={2}>
            What to do next
          </Heading>
          <Text fontSize="16px" color="gray.900" mb={10} fontFamily="Inter">
            Follow these steps to send DMs to all selected prospects
          </Text>
          <Box border="1px solid rgba(0, 0, 0, 0.1)" borderRadius="16px">
            <ListItem
              heading="Install Howdy Chrome Extension"
              description="Use Chrome Extension to send messages"
              cta={renderExtensionCTA()}
              isActive
              index={STAGES.INSTALL_EXTENSION}
            />
            <Divider color="rgba(0, 0, 0, 0.1)" />
            <ListItem
              heading="Add Instagram handle"
              description={renderInstagramHandleDescription()}
              cta={renderGetInstagramHandleCTA()}
              isActive={activeStage >= STAGES.ADD_INSTAGRAM_HANDLE}
              index={STAGES.ADD_INSTAGRAM_HANDLE}
              fullWidthDescription
            />
            <Divider color="rgba(0, 0, 0, 0.1)" />
            <ListItem
              heading="Create campaign"
              description="Creating the campaign will make it available for use in the Howdy Chrome Extension"
              cta={renderCreateCampaignCTA()}
              isActive={activeStage >= STAGES.CREATE_CAMPAIGN}
              index={STAGES.CREATE_CAMPAIGN}
            />
            <Divider color="rgba(0, 0, 0, 0.1)" />
            <ListItem
              heading="Send messages"
              description={
                <OrderedList color="alpha.60">
                  <ChakraListItem>Go to Instagram</ChakraListItem>
                  <ChakraListItem>Run Howdy Chrome Extension</ChakraListItem>
                  <ChakraListItem>Go to menu &gt; Campaigns</ChakraListItem>
                  <ChakraListItem>
                    Under “Discovered prospects”, press{" "}
                    <Text display="inline-block" fontWeight="700">
                      Start campaign
                    </Text>
                  </ChakraListItem>
                  <ChakraListItem>
                    Voila! Your DMs have been magically sent.
                  </ChakraListItem>
                </OrderedList>
              }
              cta={
                <a
                  href={`https://${socialNetworkHostname.Instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="primary"
                    rightIcon={<FiExternalLink color="white" size={20} />}
                  >
                    Open Instagram
                  </Button>
                </a>
              }
              isActive={activeStage === STAGES.SEND_MESSAGES}
              index={STAGES.SEND_MESSAGES}
            />
          </Box>
        </Box>
        <Image
          src={whatNextImage}
          alt="What next"
          zIndex="2"
          position="relative"
          flexShrink={0}
          width="600px"
        />
      </Flex>
    </InnerAppLayout>
  );
};
