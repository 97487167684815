import {
  Campaign,
  CampaignInfo,
  CampaignMessage,
  CampaignMessageError,
  isCampaignInfo,
  isCampaignMessage,
  isCampaignMessages,
  isCampaignsInfo,
  isCampaignStatus,
  isItem,
  isTargetCollectionOrigin,
  Operation,
} from "@workspace/models";
import {
  arrayTypeGuard,
  isLiteralType,
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  objectTypeGuard
} from "@workspace/type-utils";

export type CreateInstagramCampaign = Operation<
  Pick<Campaign, "messages" | "targetCollectionOrigin" | "instagramId" | "instagramHandle" | "instagramAvatar" | "followUpMessage" | "followUpInterval" | "sourceList">,
  CampaignInfo
>;

export const isCreateInstagramCampaignInput = objectTypeGuard<
  CreateInstagramCampaign["in"]
>(
  ({instagramId, messages, targetCollectionOrigin, instagramHandle}) =>
    isCampaignMessages(messages) && isNonEmptyString(instagramId) && isTargetCollectionOrigin(targetCollectionOrigin) && isNonEmptyString(instagramHandle),
);

export const isCreateInstagramCampaignOutput =
  objectTypeGuard<CreateInstagramCampaign["out"]>(isCampaignInfo);

export type ReadInstagramCampaigns = Operation<
  {instagramId: NonEmptyString},
  CampaignInfo[]
>;

export const isReadInstagramCampaignsInput = objectTypeGuard<
  ReadInstagramCampaigns["in"]
>(({instagramId}) => isNonEmptyString(instagramId));

export const isReadInstagramCampaignsOutput = isCampaignsInfo

export type UpdateInstagramCampaignStatus = {campaignId: Campaign["id"]; status: Campaign["status"];}

const isUpdateInstagramCampaignStatus = objectTypeGuard<UpdateInstagramCampaignStatus>(
  ({campaignId, status}) =>
    isNonEmptyString(campaignId) &&
    isCampaignStatus(status)
);

export const isUpdateInstagramCampaignsStatusesInput = arrayTypeGuard<UpdateInstagramCampaignStatus>(isUpdateInstagramCampaignStatus);

export type NextInstagramMessage = Operation<
  {instagramId: NonEmptyString; schedulerId: NonEmptyString},
  { message: CampaignMessage, campaignId: string } | null
>;

export const isNextInstagramMessageInput = objectTypeGuard<NextInstagramMessage["in"]>(
  ({instagramId, schedulerId}) =>
    isNonEmptyString(instagramId) && isNonEmptyString(schedulerId),
);

export type IncreaseSentMessages = Operation<
  {campaignId: Campaign["id"], schedulerId: NonEmptyString, messageId: NonEmptyString, instagramId: NonEmptyString, threadId: NonEmptyString, extensionVersion: NonEmptyString},
  void | null
>;

export const isIncreaseSentMessagesInput = objectTypeGuard<IncreaseSentMessages["in"]>(
  ({campaignId, schedulerId, messageId, threadId, instagramId, extensionVersion }) =>
    isNonEmptyString(campaignId) && isNonEmptyString(schedulerId) && isNonEmptyString(messageId) && isNonEmptyString(threadId) && isNonEmptyString(instagramId) && isNonEmptyString(extensionVersion),
);

export const isNextInstagramMessageOutput =
  objectTypeGuard<Exclude<NextInstagramMessage["out"], null>>(({campaignId, message}) => isCampaignMessage(message) && isItem({id: campaignId}));

export const isSkipCampaignMessageInput = objectTypeGuard<{
  campaignId: Campaign['id'];
  schedulerId: string;
}>(
  ({ campaignId, schedulerId }) =>
    isNonEmptyString(campaignId) &&
    isNonEmptyString(schedulerId),
);

export type NotifySlackAboutCampaignErrors = Operation<Array<CampaignMessageError>, void>

export const isCampaignMessageError = objectTypeGuard<CampaignMessageError>(
  ({message, timestamp, campaignId}) => isNonEmptyString(message) && isNonNegativeInteger(timestamp) && isNonEmptyString(campaignId),
);

export const isNotifySlackAboutCampaignErrorsInput = objectTypeGuard<{ errors: CampaignMessageError[], extensionVersion: string }>(
  ({ errors, extensionVersion }) => arrayTypeGuard<CampaignMessageError>(isCampaignMessageError)(errors) && isNonEmptyString(extensionVersion),
);

const cooldownTypes =  [
  'frequentErrors',
  'instagramErrors',
  'genericCooldown',
] as const;

export type CooldownType = typeof cooldownTypes[number];

export const cooldownType = cooldownTypes.reduce((obj, key) => ({...obj, [key]: key}), {} as Record<CooldownType, CooldownType>) ;

export const isCooldownType = isLiteralType<CooldownType>(cooldownTypes);

export const isTriggerCampaignsCoolDownInput = objectTypeGuard<{ extensionVersion: string; cooldownType: CooldownType }>(
  ({ extensionVersion, cooldownType }) => isNonEmptyString(extensionVersion) && isCooldownType(cooldownType),
);

export const isUpdateCampaignDataInput = objectTypeGuard<CampaignInfo>(isCampaignInfo)
