"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.isInstagramUserInfoBioLinks = exports.isInstagramUserInfoBioLink = exports.isInstagramUserInfo = void 0;
var type_utils_1 = require("@workspace/type-utils");
var InstagramUser_1 = require("./InstagramUser");
exports.isInstagramUserInfo = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var follower_count = _a.follower_count, following_count = _a.following_count, media_count = _a.media_count, bio_links = _a.bio_links, user = __rest(_a, ["follower_count", "following_count", "media_count", "bio_links"]);
    return (0, InstagramUser_1.isInstagramUser)(user) &&
        (0, exports.isInstagramUserInfoBioLinks)(bio_links);
});
exports.isInstagramUserInfoBioLink = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var url = _a.url;
    return typeof url === "string";
});
exports.isInstagramUserInfoBioLinks = (0, type_utils_1.arrayTypeGuard)(exports.isInstagramUserInfoBioLink);
