import {Buffer} from 'buffer';
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FirebaseAuthResponse, logout, signInWithIdToken,} from "src/modules/auth/auth-service";

import {CHROME_EXTENSION_ID} from "../modules/integration/helpers/constants";
import {useAppDispatch} from "./useAppDispatch";
import {useAppSelector} from "./useAppSelector";

const AUTH_COOKIE_NAME = "firebase_auth_token";

export type AuthCookieValue = {
  token: string;
  uid: string;
  src: "CE" | "WA";
};

const parseAuthCookieValue = (value: string): AuthCookieValue | false => {
  try {
    const buffer = Buffer.from(value, "base64");
    const parsedValue = JSON.parse(buffer.toString()) as unknown;

    if (!parsedValue || typeof parsedValue !== "object") return false;

    const {token, uid} = parsedValue as Partial<AuthCookieValue>;

    return typeof token === "string" && typeof uid === "string"
      ? (parsedValue as AuthCookieValue)
      : false;
  } catch {
    return false;
  }
};

export const useDsmChromeExtension = (): {
  /** The `extensionIsInstalled` is initialized as `undefined`, then it is always `false` is browser is not Chrome. */
  extensionIsInstalled: boolean | undefined;
  /** Send a message to call `firebase.auth.signInWithEmailAndPassword()` on Chrome extension. */
  extensionSignIn: (arg: {uid: string; token: string}) => void;
  /** Send a message to call `firebase.auth.signOut()` on Chrome extension. */
  extensionSignOut: () => void;
  refreshExtensionIsInstalled: () => Promise<boolean>;
} => {
  const [extensionIsInstalled, setExtensionIsInstalled] = useState<boolean | undefined>();

  const isChrome = useMemo(() => typeof chrome !== "undefined", []);

  const extensionSignIn = useCallback(
    ({token, uid}: {token: string; uid: string}) => {
      try {
        if (!isChrome) return;
        if (!extensionIsInstalled) return;

        Cookies.set(
          AUTH_COOKIE_NAME,
          Buffer.from(
            JSON.stringify({
              token,
              uid,
              src: "WA",
            }),
          ).toString("base64"),
          {
            secure: true,
          },
        );
      } catch (error) {
        console.error(error);
      }
    },
    [extensionIsInstalled, isChrome],
  );

  const extensionSignOut = useCallback(() => {
    try {
      if (!isChrome) return;
      if (!extensionIsInstalled) return;

      Cookies.remove(AUTH_COOKIE_NAME);
      chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {command: "SIGN_OUT"});
    } catch (error) {
      console.error(error);
    }
  }, [extensionIsInstalled, isChrome]);

  const refreshExtensionIsInstalled = useCallback((): Promise<boolean> => {
    return new Promise(resolve => {
      try {
        if (!isChrome) {
          setExtensionIsInstalled(false);
          resolve(false);
        }
        chrome.runtime.sendMessage(CHROME_EXTENSION_ID, {command: "VERSION"}, () => {
          setExtensionIsInstalled(true);
          resolve(true);
        });
      } catch (_ignore) {
        setExtensionIsInstalled(false);
        resolve(false);
      }
    })
  }, [isChrome])

  useEffect(() => {
    refreshExtensionIsInstalled();
  }, [refreshExtensionIsInstalled]);

  return {
    extensionSignIn,
    extensionSignOut,
    extensionIsInstalled,
    refreshExtensionIsInstalled
  };
};

export const useWatchAuthCookieChange = (): {
  checkIsSynced: () => Promise<FirebaseAuthResponse | undefined> | undefined;
} => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  const handleCookieChange = useCallback(
    (event: CookieStoreChangeEvent) => {
      try {
        const {changed, deleted} = event;

        const deletedCookie = deleted.find((cookie) => cookie.name === AUTH_COOKIE_NAME);

        if (deletedCookie) {
          logout(dispatch);
          return;
        }
        const changedCookie = changed.find((cookie) => cookie.name === AUTH_COOKIE_NAME);

        if (changedCookie) {
          const parsedValue = parseAuthCookieValue(changedCookie.value);

          if (!parsedValue || parsedValue.src === "WA") return;

          const {uid, token} = parsedValue;

          if (uid === user?.id) return;

          logout(dispatch);
          signInWithIdToken(token);
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw e;
      }
    },
    [user, dispatch],
  );

  const checkIsSynced = useCallback(() => {
    const authCookie = Cookies.get(AUTH_COOKIE_NAME);

    if (!authCookie) return;

    const parsedValue = parseAuthCookieValue(authCookie);

    if (!parsedValue || parsedValue.src !== "CE") return;

    const {token} = parsedValue;

    logout(dispatch);
    return signInWithIdToken(token);
  }, [dispatch]);

  useEffect(() => {
    window.cookieStore?.addEventListener("change", handleCookieChange);

    return () => {
      window.cookieStore?.removeEventListener("change", handleCookieChange);
    };
  }, [handleCookieChange]);

  return {
    checkIsSynced,
  };
};
