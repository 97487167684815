import {
  arrayTypeGuard,
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  NonNegativeInteger,
  objectTypeGuard,
} from "@workspace/type-utils";
import {LastPostDate, Lead, List, ListCampaign, NewLead} from "@workspace/models";

export const isValidLead = objectTypeGuard<Lead>(
  ({name, handle, followers, following, posts, id}) =>
    typeof name === "string" &&
    isNonEmptyString(handle) &&
    isNonEmptyString(id) &&
    isNonNegativeInteger(followers) &&
    isNonNegativeInteger(following) &&
    isNonNegativeInteger(posts),
);

export const isValidLeads = arrayTypeGuard<Lead>(isValidLead);

export const isValidNewLead = objectTypeGuard<NewLead>((newLead) => isValidLead({
  ...newLead,
  bio: "",
  searchString: "",
}));

export const isValidNewLeads = arrayTypeGuard<NewLead>(isValidNewLead);

export const isValidList = objectTypeGuard<List>(
  ({name, userId, whenCreated, id}) =>
    isNonEmptyString(name) &&
    isNonEmptyString(userId) &&
    isNonEmptyString(id) &&
    isNonNegativeInteger(whenCreated),
);

export const isFetchUserListBySlugInput = objectTypeGuard<{
  slug: string
}>(({slug}) => isNonEmptyString(slug));

export const isCreateListInput = objectTypeGuard<{
  name: string
}>(({name}) => isNonEmptyString(name));

export const isAddLeadsToListInput = objectTypeGuard<{
  listId: string;
  leads: NewLead[]
}>(
  ({listId, leads}) => isNonEmptyString(listId) && isValidNewLeads(leads));


export const isCreateCampaignFromLeadsInput = objectTypeGuard<{slug: string; leads: Lead[] }>(
  ({slug, leads}) => isNonEmptyString(slug) && isValidLeads(leads));

export const isGetLeadsCampaignInput = objectTypeGuard<{
  campaignId: string;
}>(
  ({campaignId}) => isNonEmptyString(campaignId));

export const isNonEmptyStringArray = arrayTypeGuard(isNonEmptyString);

export const isRemovedLeadsFromListInput = objectTypeGuard<{
  listId: string;
  leadIds: string[]
}>(
  ({listId, leadIds}) => isNonEmptyString(listId) && isNonEmptyStringArray(leadIds),
);

export type ListRangeFilter = {
  max?: NonNegativeInteger;
  min?: NonNegativeInteger;
}

export type FetchUserLeadsFilters = {
  followers?: ListRangeFilter;
  following?: ListRangeFilter;
  posts?: ListRangeFilter;
  lastPostDate?: LastPostDate;
  keyword?: string;
}

export const isFetchUserLeadsSlug = objectTypeGuard<{slug: NonEmptyString}>(({slug}) => isNonEmptyString(slug));

export const isFetchUserListsOutput = arrayTypeGuard<List>(isValidList);

export const isCreateListOutput = objectTypeGuard<List>(isValidList);

export const isFetchUserLeadsOutput = objectTypeGuard<{
  list: List,
  leads: Lead[]
}>(({list, leads}) => (
  isValidList(list) && isValidLeads(leads)
));

export const isListCampaign = objectTypeGuard<ListCampaign>((
  {
    targets,
    listId,
    userId,
    id,
  }) => isValidLeads(targets) && isNonEmptyString(listId) && isNonEmptyString(userId) && isNonEmptyString(id));

export const isSaveUnprocessedLeadsInput = objectTypeGuard<{leads: Lead[]; listId: string}>(({
                                                                                               leads,
                                                                                               listId,
                                                                                             }) => isValidLeads(leads) && isNonEmptyString(listId));

export const isFetchUnprocessedLeadsOutput = objectTypeGuard<{leads: Lead[]; listId: string}>(({
                                                                                                 leads,
                                                                                                 listId,
                                                                                               }) => isValidLeads(leads) && isNonEmptyString(listId));

export const isGetLeadsCampaignOutput = objectTypeGuard<{campaign: ListCampaign, list: List}>(({
                                                                                                 campaign,
                                                                                                 list,
                                                                                               }) => isListCampaign(campaign) && isValidList(list));
