import {createAsyncThunk} from '@reduxjs/toolkit';

import {dsmToast} from '../../components/dsm-toast';
import {HttpsCallableResult} from '../../services/firebase';

import {
  deleteScheduledMessageOnCall,
  scheduleMessageToTwitterUserOnCall,
  setScheduledMessageOnCall,
} from './scheduled-messages-service';
import {ResponseErrorCode, ScheduledMessage} from './types';

export const setScheduledMessage = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {conversationId: string; text: string; dateLabel: string; sendAt: number}
>('scheduledMessages/setScheduledMessage', async ({dateLabel, ...data}) => {
  try {
    const response = await setScheduledMessageOnCall(data);

    dsmToast(`Message scheduled for ${dateLabel}`, 'success');

    return response;
  } catch (e) {
    let failureMessage = `Could not schedule message for ${dateLabel}. Please try again.`;
    const {code, message} = e as {code: string; message: string};

    if (code === ResponseErrorCode.RESOURCE_EXHAUSTED) {
      failureMessage = message;
    }

    dsmToast(failureMessage, 'error');
    throw e;
  }
});

export const deleteScheduledMessage = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {
    conversationId: string;
    scheduledMessage: ScheduledMessage;
    dateLabel: string;
  }
>(
  'scheduledMessages/deleteScheduledMessage',
  async ({conversationId, scheduledMessage, dateLabel}) => {
    const closeSuccessToast = dsmToast(
      `Scheduled message for ${dateLabel} deleted.`,
      'success'
    );

    try {
      return await deleteScheduledMessageOnCall({
        conversationId,
        scheduledMessageId: scheduledMessage.id,
      });
    } catch (e) {
      closeSuccessToast();
      dsmToast(
        `Could not delete scheduled message for ${dateLabel}. Please try again.`,
        'error'
      );
      throw e;
    }
  }
);

export const scheduleMessageToTwitterUser = createAsyncThunk<
  {result: ScheduledMessage},
  {
    dateLabel: string;
    twitterUserId: string;
    text: string;
    sendAt: number;
  }
>(
  'scheduledMessages/scheduleMessageToTwitterUser',
  async ({dateLabel, ...data}) => {
    try {
      const response = await scheduleMessageToTwitterUserOnCall(data);

      dsmToast(`Message scheduled for ${dateLabel}`, 'success');

      return response;
    } catch (e) {
      dsmToast(
        `Could not schedule message for ${dateLabel}. Please try again.`,
        'error'
      );
      throw e;
    }
  }
);
