import {
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  chakra,
  Text,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import {FC, useEffect, useMemo, useState} from 'react';

import {useAppDispatch, useAppSelector} from 'src/hooks';
import { IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY } from 'src/modules/integration/pages/integrations';
import {ReactComponent as TwitterReAuthFailureIcon} from '../../../../assets/icons/twitter-re-auth-failure.svg';
import {DsmOutlinedButton} from '../../../../components/buttons';
import {DsmExternalSolidLink} from '../../../../components/links';
import useMixpanel from '../../../../helpers/hooks/use-mixpanel';
import {getIdToken, logout} from '../../../auth/auth-service';
import {setShowModalAuthFailure} from '../../../user/user-slice';

const StyledTwitterReAuthFailureIcon = chakra(TwitterReAuthFailureIcon);

export const ReAuthFailureModal: FC = () => {
  const mixpanel = useMixpanel();
  const dispatch = useAppDispatch();
  const [idToken, setIdToken] = useState<string>();
  const {onClose} = useDisclosure();

  const showModal = useAppSelector((state) => state.user.showModalAuthFailure);

  const isOpen = useMemo(() => !!showModal, [showModal]);

  const authTwitterHref = `${
    process.env.REACT_APP_FIREBASE_FUNC_DOMAIN
  }${encodeURI('/twitterWebhooks/oauth/init')}?userIdToken=${idToken}`;

  const logoutHandler = async () => {
    try {
      await logout(dispatch);
      mixpanel?.track('web.general.auth.logout');
      mixpanel?.reset();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getIdToken()
      .then((token) => {
        if (token) {
          setIdToken(token);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(
    () => () => {
      dispatch(setShowModalAuthFailure(false));
    },
    [dispatch]
  );

  useEffect(() => {
    try{
      localStorage.removeItem(IS_CONNECTING_TWITTER_LOCAL_STORAGE_KEY);
    }catch{ 

    }
  }, [])

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        py={6}
        mx={5}
        textAlign="center"
        minW={{base: 'auto', md: '613px'}}
        mt="85px"
      >
        <Container maxW="450px">
          <StyledTwitterReAuthFailureIcon mx="auto" />
          <ModalHeader
            fontWeight="bold"
            lineHeight={1.5}
            fontSize={{base: 'md', md: 'xl'}}
            color="gray.900"
          >
            Sorry, we couldn’t connect your account to Twitter
          </ModalHeader>
          <ModalBody
            lineHeight={1.5}
            color="gray.800"
            fontSize={{base: 'baseMinor', md: 'base'}}
          >
            <Text>
              We had trouble re-authenticating your twitter account, please try
              again or contacts us at{' '}
              <Link
                type="email"
                color="primary.500"
                href="mailto:help@dsm.network"
                _focus={{
                  boxShadow: 'none',
                }}
              >
                help@dsm.network
              </Link>
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="center" px={{md: 6}}>
            <DsmExternalSolidLink
              py={2}
              mr={5}
              display="block"
              fontSize={{base: 'baseMinor', md: 'baseMajor'}}
              href={authTwitterHref}
            >
              Re-Authenticate Twitter
            </DsmExternalSolidLink>
            <DsmOutlinedButton onClick={logoutHandler}>
              Logout
            </DsmOutlinedButton>
          </ModalFooter>
        </Container>
      </ModalContent>
    </Modal>
  );
};
