import {dsmToast} from '../../components/dsm-toast';
import {HttpsCallableResult} from '../../services/firebase';
import {AppThunk} from '../../store/store';
import {Bucket} from '../conversation/types';

import {
  addTagsToFriendOnCall,
  markFriendBucketOnCall,
  removeTagsFromFriendOnCall,
} from './friend-service';

export const markFriendBucket =
  ({
    followerId,
    bucket,
  }: {
    followerId: string;
    bucket: Bucket;
  }): AppThunk<Promise<string>> =>
  async () =>
    await markFriendBucketOnCall({follower: followerId, bucket});

export const addTagsToFriend =
  (data: {
    friendId: string;
    tags: string[];
  }): AppThunk<Promise<HttpsCallableResult>> =>
  async () => {
    const closeSuccessToast = dsmToast(
      `${data.tags[0]} added.`,
      'success'
    );

    try {
      return await addTagsToFriendOnCall(data);
    } catch (e) {
      closeSuccessToast();
      dsmToast(
        `Unable to add ${data.tags[0]} Please try again.`,
        'error'
      );
      throw e;
    }
  };

export const removeTagsFromFriend =
  (data: {
    friendId: string;
    tags: string[];
  }): AppThunk<Promise<HttpsCallableResult>> =>
  async () => {
    const closeSuccessToast = dsmToast(
      `${data.tags[0]} removed.`,
      'success'
    );
    try {
      return await removeTagsFromFriendOnCall(data);
    } catch (e) {
      closeSuccessToast();
      dsmToast(
        `Unable to remove ${data.tags[0]}. Please try again.`,
        'error'
      );
      throw e;
    }
  };
