import {firebaseAuth} from "@workspace/firebase-app";
import pickBy from "lodash/pickBy";
import mixpanel, {OverridedMixpanel} from "mixpanel-browser";
import {createContext, FC, PropsWithChildren, useEffect, useMemo} from "react";
import {useAppSelector} from "src/hooks";

export const MixpanelContext = createContext<OverridedMixpanel | undefined>(undefined);

export const MixpanelProvider: FC<PropsWithChildren> = ({children}) => {
  const mixpanelInstance = useMemo(() => {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: false,

        // Explain: This was removed in version 2.38.0 on June 12th, 2020.
        // Todo: Reimplement pageview tracking if needed.
        // track_pageview: false,
      });
      return mixpanel;
    }
  }, []);

  const user = useAppSelector((state) => state.user.user);
  const firebaseUser = firebaseAuth.currentUser;

  useEffect(() => {
    if (mixpanelInstance && user && firebaseUser) {
      const userData = {
        $created: user.createdAt,
        $email: user.email,
        $distinct_id: user.id,
        isAdmin: user.isAdmin,
        $name: firebaseUser.displayName,
        $avatar: user.avatarUrl,
        integrations: user.integrations?.map((integration) => integration.id) || [],
        isInboxBuilt: user.isInboxBuilt,
        $timezone: user.timezone,
      };
      const userDataNoUndefined = pickBy(userData, (v) => typeof v !== "undefined");
      mixpanelInstance.people.set(userDataNoUndefined);
    }
  }, [user, firebaseUser, mixpanelInstance]);

  return (
    <MixpanelContext.Provider value={mixpanelInstance}>
      {children}
    </MixpanelContext.Provider>
  );
};
