import React, {FC, useRef} from 'react';
import {Box, Flex, ListItem as ChakraListItem, OrderedList, Text} from "@chakra-ui/react";
import {StepSectionTitle, StepTitle} from "../styled";
import {getInstagramCookies, getInstagramUserDetails} from "../../../../utils/getInstagramCookies";
import {isSocialNetworkUser, SocialNetworkUser} from "@workspace/firebase-functions/@workspace/models";
import {useDsmChromeExtension} from "../../../../hooks";
import {Button, Icon} from "@workspace/ui";
import {CHROME_EXTENSION_DOWNLOAD_URL} from "../../../integration/helpers/constants";
import {cloudFunctionName} from "@workspace/firebase-functions/@workspace/firebase-definitions";
import {firebaseFunctions} from "@workspace/firebase-app";
import {useAppSelector} from "../../../../app-hooks";

enum ConnectionErrors {
  LOGGED_OUT = "LOGGED_OUT",
  NO_EXTENSION = "NO_EXTENSION",
  INVALID_SESSION = "INVALID_SESSION",
}

const errorToTextMap = {
  [ConnectionErrors.NO_EXTENSION]: `Please install Howdy Chrome Extension to connect your Instagram account`,
  [ConnectionErrors.LOGGED_OUT]: `It looks like you’re logged out of Instagram. Please login to your account and try again.`,
  [ConnectionErrors.INVALID_SESSION]: `You instagram session is expired, please reconnect your Instagram account`
}

const errorButtonTextMap = {
  [ConnectionErrors.NO_EXTENSION]: `Install Howdy Chrome Extension`,
  [ConnectionErrors.LOGGED_OUT]: `Try again`,
  [ConnectionErrors.INVALID_SESSION]: `Reconnect Instagram account`
}

type Props = {
  userData: SocialNetworkUser | null;
  setUserData: (userData: SocialNetworkUser & { session: string }) => void;
  isRefreshingSession?: boolean;
}

export const InstagramSession: FC<Props> = ({userData, setUserData, isRefreshingSession}) => {
  const [error, setError] = React.useState<ConnectionErrors | null>(null);

  const [isLoading, setIsLoading] = React.useState(true);

  const user = useAppSelector((state) => state.user.user);

  const {extensionIsInstalled, refreshExtensionIsInstalled} = useDsmChromeExtension();

  const intervalRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const _refreshExtensionIsInstalled = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      refreshExtensionIsInstalled().then((isInstalled) => {
        if (isInstalled) {
          if (error === ConnectionErrors.NO_EXTENSION) {
            setError(null)
            getLoggedInUser();
          }

          clearInterval(intervalRef.current!);
        }
      });
    }, 5000);
  };

  React.useEffect(() => {
    getLoggedInUser();

    _refreshExtensionIsInstalled();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasFetched = useRef(false);

  const getLoggedInUser = async () => {
    if (hasFetched.current) return;

    setIsLoading(true)

    if (extensionIsInstalled === false) {
      setError(ConnectionErrors.NO_EXTENSION)
      setIsLoading(false)
      return
    }

    try {
      const cookies = await getInstagramCookies();

      let sessionString = "";

      const userCookieId = cookies.find(cookie => cookie.name === "ds_user_id")?.value;
      const userSessionId = cookies.find(cookie => cookie.name === "sessionid")?.value;

      if (!userCookieId || !userSessionId) {
        setError(ConnectionErrors.LOGGED_OUT)
        setIsLoading(false)
        return
      }
      cookies.forEach((cookie: { name: string, value: string }) => {
        sessionString += `${cookie.name}=${cookie.value}; `
      })

      const userDetails = await getInstagramUserDetails();


      if (!isSocialNetworkUser(userDetails)) {
        setError(ConnectionErrors.INVALID_SESSION)
        setIsLoading(false)
      } else {
        const response = await firebaseFunctions.httpsCallable(
          cloudFunctionName.uploadInstagramUserAvatar,
        )({
          avatarUrl: userDetails.avatar,
          instagramId: userDetails.id,
        });

        setUserData({
          ...userDetails,
          avatar: response.data,
          session: sessionString
        })

        setIsLoading(false)
      }
    } catch (e) {
      setError(ConnectionErrors.NO_EXTENSION)
      setIsLoading(false)
    }
  }

  const handleClickButton = () => {
    if (error === ConnectionErrors.NO_EXTENSION) {
      window.open(CHROME_EXTENSION_DOWNLOAD_URL, "_blank")
      return
    } else {
      hasFetched.current = false
      getLoggedInUser()
    }
  }

  const renderBody = () => {
    if (isLoading) {
      return (
        <Box border="1px solid" borderColor="alpha.10" borderRadius="12px" width="100%" p={8}>
          <Flex align="center" justify="space-between" mb="6">
            <Icon name="connectInstagram"/>
            <Text px={2} py={1} bg="orange.50" color="orange.600" borderRadius="6px" fontSize="12px" fontWeight="600">
              Waiting for connection
            </Text>
          </Flex>
          <Text fontSize="14px" fontWeight="600" color="gray.900" mb={3}>
            How to connect
          </Text>
          <OrderedList color="alpha.60">
            <ChakraListItem>Install Howdy Chrome Extension.</ChakraListItem>
            <ChakraListItem>Log in with your Howdy account.</ChakraListItem>
            <ChakraListItem>Go to Instagram, make sure you’re logged in.</ChakraListItem>
            <ChakraListItem>Open the Howdy extension.</ChakraListItem>
            <ChakraListItem>That’s it</ChakraListItem>
          </OrderedList>
        </Box>
      )
    }

    if (error) {
      return (
        <Flex border="1px solid" borderColor="alpha.10" borderRadius="12px" p={4} align="center" width="100%">
          <Icon name="errorOutlined" size={24}/>
          <Text ml={4} fontSize="14px" fontWeight="600">
            {errorToTextMap[error]}
          </Text>
        </Flex>
      )
    }

    if (userData) {
      return (
        <>
          <Flex border="1px solid" borderColor="alpha.10" borderRadius="12px" p={4} align="center" width="100%">
            <img src={userData.avatar} alt={userData.name} width="40px" height="40px" style={{borderRadius: "50%"}}/>
            <Box ml={2} color="gray.900">
              <Text fontSize="14px" fontWeight="600">
                {userData.name}
              </Text>
              <Text fontSize="14px">
                {userData.handle}
              </Text>
            </Box>
            <Text ml="auto" px={2} py={1} bg="green.50" color="green.700" borderRadius="6px" fontSize="12px" fontWeight="600">
              Connected
            </Text>
          </Flex>
        </>
      )
    }
  }

  const renderDescription = () => {
    if(error){
      return null
    }

    if(userData){
      let nameString = user?.firstName ? ` ${user.firstName},` : ','

      return `Thank you${nameString} we have all the information we need. We’ll send you an email when a new list of leads is ready for you. It usually takes up to 24 hours.`
    }

    return isRefreshingSession ? "You were logged out of instagram while collecting targets" : "By connecting your Instagram account, Howdy is be able to find and collect new leads based on your preferences."
  }

  const renderActionButton = () => {
    if(!error) return null

    return (
      <Button
        width="fit-content"
        py="3"
        px="8"
        onClick={handleClickButton}
        borderRadius="99px"
        mt={4}
      >
        {errorButtonTextMap[error]}
      </Button>
    )
  }

  return (
    <Box>
      <StepTitle>{userData ? "You’re all set!" : "Connect to Instagram"}</StepTitle>
      <StepSectionTitle>
        {renderDescription()}
      </StepSectionTitle>
      {renderBody()}
      {renderActionButton()}
    </Box>
  );
};

