import {Box, Flex, Spinner, Tag, Text} from "@chakra-ui/react";
import {components, GroupBase, SelectInstance} from "react-select";
import AsyncSelect from "react-select/async";
import {FC, useCallback, useRef} from "react";
import {StepSectionSubtext, StepTitle} from "../styled";
import {CityOption, LocationStepProps} from "./types";
import {colorHex, Icon} from "@workspace/ui";
import {useDebouncedCallback} from "@workspace/react";
import {
  getOpenDataSoftUrl,
  getUniqueCitiesByState,
  isOpenDataSoftUrlResponse,
  MIN_CHARACTERS_FOR_SEARCH,
} from "./consts";

const CustomValueContainer: typeof components.ValueContainer<
  CityOption,
  true,
  GroupBase<CityOption>
> = (props) => (
  <components.ValueContainer {...props}>
    {props.children}
    {(!props.selectProps.menuIsOpen || !props.selectProps.inputValue) && (
      <Text
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        pl="18px"
        left="0"
        pointerEvents="none"
        color="alpha.40"
      >
        e.g. New York...
      </Text>
    )}
  </components.ValueContainer>
);

export const LocationStep: FC<LocationStepProps> = ({selectedCities, onChange}) => {
  const selectRef = useRef<SelectInstance<CityOption, true>>(null);
  const handleLoadOptions = useDebouncedCallback(
    (inputValue: string, callback: (newOptions: Array<{
      label: string,
      options: CityOption[]
    }>) => void) => {
      if (inputValue.length < MIN_CHARACTERS_FOR_SEARCH) return;

      fetch(getOpenDataSoftUrl(inputValue)).then((response) =>
        response.json().then((response) => {
          if (!isOpenDataSoftUrlResponse(response)) return;

          callback(getUniqueCitiesByState(response.records));
        }),
      );
    },
    500,
  );

  const handleChange = useCallback(
    (newSelectedCities: readonly CityOption[]) => {
      onChange(newSelectedCities);
      selectRef.current?.focus();
    },
    [onChange],
  );

  const handleRemoveOption = useCallback(
    (value: CityOption["value"]) => {
      onChange(selectedCities.filter((city) => city.value !== value));
    },
    [selectedCities, onChange],
  );

  return (
    <Box>
      <StepTitle mb="10">Where are your customers located?</StepTitle>
      <StepSectionSubtext>Select locations</StepSectionSubtext>
      <Box mb="4">
        <AsyncSelect
          autoFocus
          cacheOptions
          placeholder=""
          ref={selectRef}
          hideSelectedOptions={false}
          styles={{
            option: (styles, {isSelected}) => ({
              ...styles,
              px: 12,
              py: 8,
              borderRadius: "12px",
              cursor: isSelected ? "default" : "pointer",
              color: "gray.900",
              background: isSelected ? colorHex.primary50 : colorHex.white,
              ":hover": {
                background: isSelected ? colorHex.primary50 : colorHex.alpha05,
              },
            }),
            control: (styles) => ({
              ...styles,
              borderColor: colorHex.alpha10,
              boxShadow: "none",
              borderRadius: "8px",
              minHeight: "44px",
            }),
            menuList: (styles) => ({
              ...styles,
              padding: "4px",
            }),
            valueContainer: (styles) => ({
              ...styles,
              padding: "8px 16px",
            }),
          }}
          value={selectedCities}
          loadOptions={handleLoadOptions}
          onChange={handleChange}
          menuIsOpen
          components={{
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            LoadingIndicator: props => {
              if(props.selectProps.inputValue.length < MIN_CHARACTERS_FOR_SEARCH){
                return null
              }else{
                return <Spinner size="sm" mr="4" />
              }
            },
            MultiValue: () => null,
            MultiValueRemove: () => null,
            ValueContainer: CustomValueContainer,
            NoOptionsMessage: () => (
              <Box px="3" py="2">
                <Text color="alpha.50" fontSize="h6" lineHeight="20px">
                  No results found
                </Text>
              </Box>
            ),
            Menu: (props) =>
              props.selectProps.inputValue.length >= MIN_CHARACTERS_FOR_SEARCH ? (
                <components.Menu {...props} />
              ) : null,
            Option: ({data, ...props}) => {
              return (
                <components.Option
                  data={data}
                  {...(props as any)}
                  _hover={{
                    bg: "alpha.05",
                    cursor: "pointer",
                  }}
                >
                  <Flex gap="3">
                    <Icon size={20} name={data.type === "state" ? "discoveryState" : "discoveryCity"}/>
                    {data.label}
                  </Flex>
                </components.Option>
              );
            },
          }}
          isMulti
        />
      </Box>
      <Flex gap="2" flexWrap="wrap">
        {selectedCities.map((cityOption) => (
          <Tag bg="alpha.05" key={cityOption.value} px="4" py="3" width="100%">
            <Flex alignItems="center" fontWeight="400" color="gray.900" width="100%">
              <Icon size={20} name={cityOption.type === "state" ? "discoveryState" : "discoveryCity"}/>
              <Text fontSize="h6" fontWeight="500" color="gray.900" ml={3} mr={2}>
                {cityOption.label}
              </Text>
              <Text fontSize="h6" color="alpha.60">
                {cityOption.type === "state" ? "State" : "City"}
              </Text>
              <Box
                color="alpha.50"
                cursor="pointer"
                onClick={() => handleRemoveOption(cityOption.value)}
                ml="auto"
              >
                <Icon name="x" size="16" />
              </Box>
            </Flex>
          </Tag>
        ))}
      </Flex>
    </Box>
  );
};
