import { captureException } from "@sentry/browser";
import { firebaseFirestore, firebaseFunctions as functions } from "@workspace/firebase-app";
import { cloudFunctionName } from "@workspace/firebase-definitions";
import { isCloudFunctionOutput, isSubscription, } from "@workspace/firebase-datamodel";
import { isTime, now, TIME } from "@workspace/models";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FirebaseUserContext } from "../contexts";
export const useSubscription = () => {
    const { isAuthenticated, userInfo } = useContext(FirebaseUserContext);
    const [isPending, setIsPending] = useState();
    const [subscription, setSubscription] = useState();
    const readSubscription = useCallback(async () => {
        try {
            if (isPending)
                return;
            setIsPending(true);
            const { data } = await functions.httpsCallable(cloudFunctionName.readSubscription)();
            setIsPending(false);
            if (isCloudFunctionOutput.readSubscription(data)) {
                setSubscription(data);
            }
            else {
                setSubscription(null);
            }
        }
        catch (error) {
            setIsPending(false);
            captureException(error);
        }
    }, [isPending]);
    useEffect(() => {
        if (!isAuthenticated) {
            setSubscription(undefined);
            return;
        }
        if (isPending)
            return;
        if (subscription === undefined) {
            readSubscription();
        }
    }, [isAuthenticated, isPending, readSubscription, subscription]);
    const trialState = useMemo(() => {
        if (subscription === undefined)
            return undefined;
        if (!isSubscription(subscription))
            return "notStarted";
        const { trialEnd } = subscription;
        if (trialEnd === undefined)
            return "notStarted";
        if (trialEnd === null)
            return "hadBefore";
        if (trialEnd < Date.now())
            return "expired";
        return "started";
    }, [subscription]);
    const { hasSubscription, pricingPlanName, subscriptionIsCanceled } = useMemo(() => {
        if (subscription === null) {
            return {
                hasSubscription: false,
                pricingPlanName: null,
                subscriptionIsCanceled: undefined,
            };
        }
        if (isSubscription(subscription)) {
            const pricingPlanName = subscription.pricingPlan;
            const hasSubscription = subscription.cancelAt
                ? subscription.cancelAt > now()
                : true;
            const subscriptionIsCanceled = isTime(subscription.canceledAt);
            return {
                hasSubscription,
                pricingPlanName,
                subscriptionIsCanceled,
            };
        }
        return {
            hasSubscription: undefined,
            pricingPlanName: undefined,
            subscriptionIsCanceled: undefined,
        };
    }, [subscription]);
    const remainingTrialDays = useMemo(() => {
        if (!subscription)
            return undefined;
        const { trialEnd } = subscription;
        if (typeof trialEnd !== "number")
            return undefined;
        const now = Date.now();
        if (trialEnd < now)
            return 0;
        return Math.ceil((trialEnd - now) / TIME.ONE_DAY);
    }, [subscription]);
    useEffect(() => {
        if (userInfo) {
            firebaseFirestore
                .collection("subscriptions")
                .doc(userInfo.uid)
                .onSnapshot((snapshot) => {
                if (!snapshot.exists)
                    return;
                const data = snapshot.data();
                if (isCloudFunctionOutput.readSubscription(data)) {
                    setSubscription(data);
                }
            });
        }
    }, [userInfo]);
    return {
        hasSubscription,
        subscriptionIsCanceled,
        pricingPlanName,
        readSubscriptionIsPending: isPending,
        remainingTrialDays,
        subscription,
        trialState,
    };
};
