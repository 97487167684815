import { useCallback, useEffect, useRef } from "react";
export function useDebouncedCallback(callback, wait) {
    const argsRef = useRef();
    const timeout = useRef(null);
    const cleanup = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
    };
    useEffect(() => cleanup, []);
    return useCallback((...args) => {
        argsRef.current = args;
        cleanup();
        timeout.current = setTimeout(() => {
            if (argsRef.current) {
                callback(...argsRef.current);
            }
        }, wait);
    }, [wait, callback]);
}
