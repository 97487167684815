import {progressAnatomy} from "@chakra-ui/anatomy";
import {
  Progress as ChakraProgress,
  createMultiStyleConfigHelpers,
  ProgressProps as ChakraProgressProps,
} from "@chakra-ui/react";
import {FC} from "react";
import {ColorName, colorName} from "../colors";

const {defineMultiStyleConfig, definePartsStyle} = createMultiStyleConfigHelpers(
  progressAnatomy.keys,
);

export const progressStyleConfig = defineMultiStyleConfig({
  baseStyle: definePartsStyle((props) => {
    return {
      filledTrack: !!props["data-filled-track-bg"]
        ? {
            bg: props["data-filled-track-bg"],
          }
        : {},
      track: !!props["data-track-bg"]
        ? {
            bg: props["data-track-bg"],
          }
        : {},
      label: {
        color: colorName.black,
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "16px",
      },
    };
  }),
  sizes: {
    xl: {
      track: {
        height: "6",
      },
    },
  },
});

type ProgressProps = ChakraProgressProps & {
  filledTrackBg?: ColorName;
  trackBg?: ColorName;
};

export const Progress: FC<ProgressProps> = ({
  filledTrackBg = colorName.green300,
  trackBg = colorName.green100,
  ...props
}) => (
  <ChakraProgress
    {...props}
    data-filled-track-bg={filledTrackBg}
    data-track-bg={trackBg}
  />
);

export {ProgressLabel} from "@chakra-ui/react";
