import {switchAnatomy} from "@chakra-ui/anatomy";
import {createMultiStyleConfigHelpers} from "@chakra-ui/react";
import {ChangeEventHandler} from "react";
import {colorName} from "../colors";

export {Switch} from "@chakra-ui/react";
export type SwitchChangeEventHandler = ChangeEventHandler<HTMLInputElement>;

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(
  switchAnatomy.keys,
);

const baseStyle = definePartsStyle({
  container: {},
  thumb: {
    bg: colorName.white,
  },
  track: {
    bg: colorName.gray200,
    _checked: {
      bg: colorName.primary400,
    },
    _disabled: {
      bg: colorName.gray50,
      opacity: 1,
      _checked: {
        bg: colorName.primary200,
        opacity: 0.4
      }
    }
  },
});

export const switchStyleConfig = defineMultiStyleConfig({baseStyle});
