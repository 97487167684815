import {createAsyncThunk} from "@reduxjs/toolkit";

import {ReduxState} from "../../store/store";
import {deselectAllRecipients} from "../bulk-send/bulk-send-slice";
import {Friend} from "../friend/types";
import {Message} from "../message/types";

import {
  searchFriendsOnCall,
  searchElasticsearchOnCall,
  searchTwitterUsersOnCall,
} from "./search-service";
import {
  FriendsSearchData,
  MessageSearchFilters,
  MessageSearchSortOrder,
  TwitterUserWithCanDm,
} from "./types";

export const searchMessages = createAsyncThunk<
  {messages: Message[]},
  {
    filters: MessageSearchFilters;
    sortOrder: MessageSearchSortOrder;
  }
>(
  "search/searchMessages",
  async (
    params: {
      filters: MessageSearchFilters;
      sortOrder: MessageSearchSortOrder;
    },
    {getState},
  ) => {
    const actingUserId = (getState() as ReduxState).actingUser.user?.id;

    return await searchElasticsearchOnCall({
      ...params,
      actingUserId,
    });
  },
);

export const searchFriends = createAsyncThunk<{followers: Friend[]}, FriendsSearchData>(
  "search/searchFriends",
  async (searchData, {dispatch}) => {
    dispatch(deselectAllRecipients());

    try {
      const {followers} = await searchFriendsOnCall(searchData);
      const transformedFollowersData = followers.map(
        (item: Omit<Friend, "createdAt"> & {createdAt: string}) => ({
          ...item,
          createdAt: Date.parse(item.createdAt) as number,
        }),
      );

      return {followers: transformedFollowersData};
    } catch (e) {
      throw e;
    }
  },
);

export const searchTwitterUsers = createAsyncThunk<
  {users: TwitterUserWithCanDm[]},
  string
>("search/searchTwitterUsers", async (query) => await searchTwitterUsersOnCall(query));
