"use strict";
exports.__esModule = true;
exports.isReadInstagramConversationOutput = exports.isInstagramConversation = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isInstagramConversation = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var receiver = _a.receiver, responseTime = _a.responseTime, responseText = _a.responseText;
    return (0, type_utils_1.isNonEmptyString)(receiver) && (0, models_1.isTime)(responseTime) && (0, type_utils_1.isNonEmptyString)(responseText);
});
exports.isReadInstagramConversationOutput = (0, type_utils_1.objectTypeGuard)(function (instagramConversation) { return (0, exports.isInstagramConversation)(instagramConversation); });
