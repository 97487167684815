import {FlexProps, Flex, Text, Box} from "@chakra-ui/react";
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef} from "react";
import {colorName} from "../colors";
import {Icon} from "./icons";

const defaultLifetime = 3000;

export type ToastVariant = "default" | "error" | "success";

type ToastProps = FlexProps & {
  lifetime?: number | false;
  label?: string;
  variant?: ToastVariant;
  onHide?: () => void;
  onUndo?: () => void;
};

const variantToIconMap: Partial<Record<ToastVariant, ReactNode>> = {
  success: (
    <Box color="green.400">
      <Icon name="check" size="20" />
    </Box>
  ),
  error: (
    <Box color="red.400">
      <Icon name="x" size="20" />
    </Box>
  ),
};

export const Toast: FC<ToastProps> = ({
  label,
  lifetime = defaultLifetime,
  variant = "default",
  onHide,
  onUndo,
  ...props
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>(null);

  const icon = variantToIconMap[variant];

  const showHideIcon = useMemo(() => lifetime !== false || !!onHide, [lifetime, onHide]);

  const handleHide = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);

    onHide?.();
  }, [onHide]);

  const actions = useMemo(() => {
    if (onUndo)
      return (
        <Text cursor="pointer" color={colorName.primary100} onClick={onUndo}>
          Undo
        </Text>
      );

    if (showHideIcon)
      return (
        <Icon
          name="x"
          size="20px"
          cursor="pointer"
          color={colorName.white}
          onClick={handleHide}
        />
      );

    return null;
  }, [showHideIcon, handleHide, onUndo]);

  useEffect(() => {
    if (lifetime === false) return undefined;
    // @ts-ignore
    timeoutRef.current = setTimeout(() => {
      onHide?.();
    }, lifetime);

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [onHide, lifetime]);

  return (
    <Flex
      px="4"
      py="3.5"
      gap="4"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="12px"
      fontWeight="600"
      fontSize="14px"
      lineHeight="20px"
      bg={colorName.primary800}
      color={colorName.white}
      {...props}
    >
      <Flex gap="2" alignItems="center">
        {icon}
        <Text>{label}</Text>
      </Flex>
      {actions}
    </Flex>
  );
};
