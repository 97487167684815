import {Text} from "@chakra-ui/react";
import {FC, useCallback} from "react";

import InnerAppLayout from "../../../components/inner-app-layout";
import Layout from "../components/layout";

import {DsmInternalSolidLink, OutlinedBox} from "src/components";
import {useDsmChromeExtension} from "src/hooks";
import {routes} from "src/router";
import {colorHex, Heading} from "@workspace/ui";

const GetStarted: FC = () => {
  const {extensionIsInstalled} = useDsmChromeExtension();

  const handleClick = useCallback(() => {}, []);

  return (
    <InnerAppLayout hasBasicHeader showTrialInfo>
      <Layout>
        <OutlinedBox>
          <Heading as="h1" variant="h3" color={colorHex.gray900} mb={4}>
            Welcome to Howdy 👋
            <br />
            we’re so happy you’re here
          </Heading>

          <Text mb={8} fontSize="baseMinor" color={colorHex.gray900}>
            Let’s start growing your newsletter, sponsorships, sales, or superstar hires
            through personalized DMs at scale
          </Text>

          <DsmInternalSolidLink to={routes.ChromeExt.route} cursor="pointer">
            Get Started
          </DsmInternalSolidLink>
        </OutlinedBox>
      </Layout>
    </InnerAppLayout>
  );
};

export default GetStarted;
