import throttle from "lodash/throttle";
import { useEffect, useMemo, useState } from "react";

const useWindowWidth = (delay = 500) => {
  const [isMounted, setIsMounted] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const throttledHandleResize = useMemo(
    () =>
      throttle(
        () => {
          if (isMounted) {
            setWidth(window.outerWidth);
          }
        },
        delay,
        {
          leading: false,
        }
      ),
    [delay, isMounted]
  );

  useEffect(() => {
    window.addEventListener("resize", throttledHandleResize);

    return () => {
      setIsMounted(false);
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [throttledHandleResize]);

  return width;
};

export default useWindowWidth;
