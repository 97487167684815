"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
exports.__esModule = true;
exports.ErrorInvalidDate = exports.isInvalidDate = void 0;
var isInvalidDate = function (arg) { return arg.toString() === "Invalid Date"; };
exports.isInvalidDate = isInvalidDate;
var ErrorInvalidDate = /** @class */ (function (_super) {
    __extends(ErrorInvalidDate, _super);
    function ErrorInvalidDate() {
        return _super.call(this, ErrorInvalidDate.message) || this;
    }
    ErrorInvalidDate.message = "Invalid Date";
    return ErrorInvalidDate;
}(Error));
exports.ErrorInvalidDate = ErrorInvalidDate;
