import {Menu, MenuList} from "@chakra-ui/react";
import {Box, IconKey, MenuItem, Text} from "../../index";
import {ChangeEvent, FC, KeyboardEvent, useCallback, useRef, useState} from "react";
import getCaretCoordinates from "textarea-caret";
import {CHARACTERS_TO_TEMPLATE_CLUE, updateTemplateString} from "./util";
import {MessageTemplateVariable} from "@workspace/firebase-functions/@workspace/models";
import {usePrevious} from "@workspace/react";
import {firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {Textarea} from "@workspace/ui";

const COMMAND_OPTIONS: {icon: IconKey; label: string; name: MessageTemplateVariable}[] = [
  {
    label: "First Name",
    icon: "user",
    name: MessageTemplateVariable.FIRST_NAME,
  },
  {
    label: "Handle",
    icon: "handle",
    name: MessageTemplateVariable.HANDLE,
  },
];

const COMMAND_MENU_TRIGGER = "/";

const COMMAND_MENU_WIDTH = 200;

type Props = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export const MessageTemplateInput: FC<Props> = ({ onChange, value, onBlur, onFocus }) => {
  const [cursorOffset, setCursorOffset] = useState<{top: number; left: number}>({
    top: 0,
    left: 0,
  });
  const [showCommandMenu, setShowCommandMenu] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const prevShowCommandMenu = usePrevious(showCommandMenu);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    setShowCommandMenu(e.nativeEvent.key === COMMAND_MENU_TRIGGER);

    if(e.nativeEvent.key === COMMAND_MENU_TRIGGER){
      firebaseFunctions.httpsCallable(cloudFunctionName.trackAnalyticEventFrontend)({
        event: "Triggered Options Menu (/)",
      });
    }

    if (!inputRef.current) return;

    const coordinates = getCaretCoordinates(
      inputRef.current,
      inputRef.current.selectionEnd,
    );

    setCursorOffset({
      left: Math.min(
        coordinates.left + 4,
        inputRef.current.clientWidth - COMMAND_MENU_WIDTH,
      ),
      top: coordinates.top + 14,
    });
  };

  const handleBlur = useCallback(() => {
    if (prevShowCommandMenu !== undefined && showCommandMenu !== prevShowCommandMenu)
      inputRef.current?.focus();

    onBlur?.()
  }, [onBlur, prevShowCommandMenu, showCommandMenu]);

  const handleMessageTemplateTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const text = e.target.value;
      onChange(text);
    },
    [onChange],
  );

  const handleCommandSelect = useCallback(
    (commandName: MessageTemplateVariable) => {
      const position =
        inputRef.current?.selectionStart ?? value.length;
      const updatedMessage = updateTemplateString(
        value,
        commandName,
        position,
      );

      onChange(updatedMessage);

      setShowCommandMenu(false);

      inputRef.current?.focus();

      // calculate new position of cursor to move it in the end of templateClue
      const newPosition = position + commandName.length + CHARACTERS_TO_TEMPLATE_CLUE;

      inputRef.current?.setSelectionRange(newPosition, newPosition);
    },
    [value, onChange],
  );

  return (
    <Box position="relative">
      <Textarea
        fontSize="sm"
        mb="3"
        placeholder="Type '/' for variables..."
        px="4"
        py="3"
        h="auto"
        borderRadius="12px"
        minH="84px"
        color="gray.900"
        borderColor="alpha.10"
        backgroundColor="alpha.05"
        onChange={handleMessageTemplateTextChange}
        onKeyDown={handleKeyDown}
        onKeyUp={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        onFocus={onFocus}
        onBlur={handleBlur}
        ref={inputRef}
        resize="vertical"
        value={value}
        maxRows={10}
        w="100%"
      />

      <Box
        position="absolute"
        left={`${cursorOffset.left}px`}
        top={`${cursorOffset.top}px`}
      >
        <Menu isOpen={showCommandMenu}>
          <MenuList
            minWidth="auto"
            border="1px solid rgba(11, 13, 14, 0.1)"
            width={COMMAND_MENU_WIDTH}
            borderRadius="12px"
            padding="4px"
            maxWidth={COMMAND_MENU_WIDTH}
          >
            {COMMAND_OPTIONS.map((command) => (
              <MenuItem
                key={command.name}
                color="gray.300"
                onClick={() => handleCommandSelect(command.name)}
                width="100%"
                leftIcon={command.icon}
              >
                <Text color="gray.900">{command.label}</Text>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Box>
  )
}
