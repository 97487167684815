"use strict";
exports.__esModule = true;
exports.isReadSubscriptionOutput = exports.isSubscription = exports.TRIAL_PERIOD_IN_DAYS = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
var MessagesDailyQuota_1 = require("./MessagesDailyQuota");
var PricingPlan_1 = require("./PricingPlan");
exports.TRIAL_PERIOD_IN_DAYS = 14;
var TRIAL_STATE = ["notStarted", "started", "expired", "hadBefore"];
exports.isSubscription = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var pricingPlan = _a.pricingPlan, maxDailyMessages = _a.maxDailyMessages, trialEnd = _a.trialEnd, cancelAt = _a.cancelAt, canceledAt = _a.canceledAt;
    return (0, PricingPlan_1.isPricingPlanKey)({ pricingPlan: pricingPlan }) &&
        (0, MessagesDailyQuota_1.isMessagesDailyQuota)({ maxDailyMessages: maxDailyMessages }) &&
        (cancelAt === undefined ? true : (0, models_1.isTime)(cancelAt)) &&
        (canceledAt === undefined ? true : (0, models_1.isTime)(canceledAt)) &&
        (trialEnd === undefined ? true : trialEnd === null ? true : (0, models_1.isTime)(trialEnd));
});
exports.isReadSubscriptionOutput = (0, type_utils_1.objectTypeGuard)(exports.isSubscription);
