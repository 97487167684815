import { useCallback, useEffect, useRef, useState } from "react";
import { firebaseFirestore, firebaseFunctions as functions } from "@workspace/firebase-app";
import { cloudFunctionName } from "@workspace/firebase-definitions";
import { collectionName, isCloudFunctionOutput, isDiscovery, isDiscoveryData, } from "@workspace/firebase-datamodel";
import { DISCOVERY_IDLE_TIME, isCampaignInfo, now } from "@workspace/models";
import { useFirebaseUser } from "./useFirebaseUser";
import { convertTemplateToPreview } from "@workspace/client-utils";
export const useDiscoveryData = () => {
    const [isFetchingDiscoveryData, setIsFetchingDiscoveryData] = useState(true);
    const [discoveryData, setDiscoveryData] = useState(undefined);
    const [messageTemplates, setMessageTemplates] = useState(undefined);
    const [messageTemplateText, setMessageTemplateText] = useState("");
    const [targets, setTargets] = useState([]);
    const [instagramData, setInstagramData] = useState({
        handle: "",
        avatarUrl: "",
        id: "",
    });
    const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
    const { isAuthenticated, userInfo } = useFirebaseUser();
    const lastFetchTimeRef = useRef(now());
    useEffect(() => {
        let unsub;
        if (isAuthenticated && userInfo) {
            unsub = firebaseFirestore
                .collection("users")
                .doc(userInfo?.uid)
                .onSnapshot((snapshot) => {
                if (!snapshot.exists)
                    return;
                const userDoc = snapshot.data();
                if (!userDoc)
                    return;
                setMessageTemplates(userDoc.messageTemplates);
            });
        }
        return unsub;
    }, [isAuthenticated, userInfo]);
    const getTargetMessage = (message, target) => {
        const { name, handle, collectionOrigin, isVerified, isUsed, ...rest } = target;
        return convertTemplateToPreview(message, { fullName: name, username: handle, ...rest });
    };
    const handleTargetMessageChange = (targetId, newMessage) => {
        setTargets((targets) => targets.map((target) => {
            if (target.id === targetId) {
                return {
                    ...target,
                    message: getTargetMessage(newMessage, target),
                };
            }
            return target;
        }));
    };
    const handleMessageTemplateTextChange = useCallback((newMessageTemplateMessage) => {
        setMessageTemplateText(newMessageTemplateMessage);
        setTargets((targets) => targets.map((target) => ({
            ...target,
            message: getTargetMessage(newMessageTemplateMessage, target),
        })));
    }, []);
    const handleRemoveTarget = async (targetId) => {
        const { data } = await functions.httpsCallable(cloudFunctionName.deleteDiscoveryTarget)({
            targetId,
            discoveryId: discoveryData?.discoveryId,
            startAfter: targets[targets.length - 1]?.id
        });
        if (isCloudFunctionOutput.deleteDiscoveryTarget(data)) {
            setTargets(targets => targets.filter((target) => target.id !== targetId).concat(data));
        }
        else {
            setTargets((targets) => targets.filter((target) => target.id !== targetId));
        }
    };
    const fetchDiscoveryData = useCallback(async () => {
        lastFetchTimeRef.current = now();
        setIsFetchingDiscoveryData(true);
        try {
            const { data } = await functions.httpsCallable(cloudFunctionName.getDiscoveryData)();
            if (!isDiscoveryData(data))
                return;
            if (data.targets) {
                setTargets(data.targets
                    .map((target) => ({
                    ...target,
                    message: getTargetMessage(messageTemplateText, target),
                })));
            }
            setDiscoveryData(data);
            setInstagramData({ ...instagramData, handle: data.campaignInstagramHandle || "" });
        }
        finally {
            setIsFetchingDiscoveryData(false);
        }
    }, [instagramData, messageTemplateText]);
    const createCampaign = async () => {
        if (!instagramData.handle || !discoveryData || !isAuthenticated || !userInfo)
            return false;
        setIsCreatingCampaign(true);
        const targetIds = targets.map((target) => target.id || "");
        const messages = targets.map((target) => ({
            text: target.message || "",
            username: target.handle,
        }));
        const campaignData = {
            instagramHandle: instagramData.handle,
            instagramAvatar: instagramData.avatarUrl,
            instagramId: instagramData.id,
            messages,
            targetIds,
            discoveryId: discoveryData.discoveryId,
        };
        try {
            const { data } = await functions.httpsCallable(cloudFunctionName.createCampaignFromDiscovery)(campaignData);
            if (isCampaignInfo(data)) {
                setIsCreatingCampaign(false);
                return data;
            }
            else {
                //show error
            }
        }
        catch (e) { }
        setIsCreatingCampaign(false);
        return false;
    };
    useEffect(() => {
        let unsub;
        if (isAuthenticated && userInfo) {
            unsub = firebaseFirestore
                .collection(collectionName.discoveries)
                .doc(userInfo.uid)
                .onSnapshot((snapshot) => {
                if (!snapshot.exists)
                    return;
                const discovery = snapshot.data();
                if (isDiscovery(discovery)) {
                    if (!discovery.lastPing)
                        return;
                    if (discoveryData?.status === "IDLE") {
                        const differenceInTime = now() - discovery.lastPing;
                        if (differenceInTime < DISCOVERY_IDLE_TIME) {
                            setDiscoveryData({
                                ...discoveryData,
                                status: "COLLECTING"
                            });
                        }
                    }
                }
            });
        }
        return unsub;
    }, [isAuthenticated, userInfo, fetchDiscoveryData, discoveryData]);
    return {
        isFetchingDiscoveryData,
        discoveryData,
        setDiscoveryData,
        fetchDiscoveryData,
        messageTemplates,
        targets,
        messageTemplateText,
        handleMessageTemplateTextChange,
        handleTargetMessageChange,
        handleRemoveTarget,
        createCampaign,
        isCreatingCampaign,
        instagramData,
        setInstagramData
    };
};
