import {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "src/hooks";
import {routes} from "src/router";
import {setIntegrationSuccess, setShowModalAuthSuccess} from "../../user/user-slice";

export const TwitterIntegrationSuccess: React.FC = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.user.showModalAuthSuccess);
  const [target, setTarget] = useState("");

  useEffect(() => {
    dispatch(setIntegrationSuccess(true));
    dispatch(setShowModalAuthSuccess(true));
  }, [dispatch]);

  useEffect(() => {
    if (showModal === false) setTarget(routes.Integrations.route);
  }, [showModal]);

  if (target) return <Redirect to={target} />;

  return null;
};
