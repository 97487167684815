import {isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {
  CampaignsQueue,
  FollowUpMessage,
  isFollowUpMessageStatus,
  isTime,
  isUpdateTime,
  Operation
} from "@workspace/models";

export const isFollowUpMessage = objectTypeGuard<FollowUpMessage>(
  ({ id, initialMessageId, status, followUpTime, message, userId, userInstagramId, username, campaignId }) =>
    isNonEmptyString(id) &&
    isNonEmptyString(initialMessageId) &&
    isFollowUpMessageStatus(status) &&
    isTime(followUpTime) &&
    isNonEmptyString(message) &&
    isNonEmptyString(userId) &&
    isNonEmptyString(userInstagramId) &&
    isNonEmptyString(username) &&
    isNonEmptyString(campaignId),
)

export type NextFollowUpMessage = Operation<
  {instagramId: NonEmptyString; schedulerId: NonEmptyString},
  FollowUpMessage | null
>;

export const isNextFollowUpMessageInput = objectTypeGuard<NextFollowUpMessage["in"]>(
  ({instagramId, schedulerId}) =>
    isNonEmptyString(instagramId) && isNonEmptyString(schedulerId),
);

export const isNextFollowUpMessageOutput = objectTypeGuard<Exclude<NextFollowUpMessage["out"], null>>(isFollowUpMessage)

export type UpdateFollowUpMessageStatus = Operation<{id: FollowUpMessage["id"]; status: FollowUpMessage["status"]}, null>

export const isUpdateFollowUpMessageStatusInput = objectTypeGuard<UpdateFollowUpMessageStatus["in"]>(
  ({id, status}) =>
    isNonEmptyString(id) && isFollowUpMessageStatus(status),
);

export const isFollowUpMessagesQueue = objectTypeGuard<CampaignsQueue>(
  ({activeSchedulerId, whenUpdated}) =>
    isUpdateTime({whenUpdated}) && activeSchedulerId === undefined
      ? true
      : isNonEmptyString(activeSchedulerId),
);


