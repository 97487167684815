export const pubsubTopics = [
  "generateDiscoveryPosts",
  "generateDiscoveryTargets",
  "validateDiscoveryTargets",
] as const;

export type PubsubTopic = (typeof pubsubTopics)[number];

export const pubsubTopic: Record<PubsubTopic, string> = pubsubTopics.reduce((topics, topic: PubsubTopic) => {
  return {
    ...topics,
    [topic]: topic
  }
}, {} as Record<PubsubTopic, string>)
