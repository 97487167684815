import {arrayTypeGuard, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {CityOption} from "./types";

export type OpenDataSoftUrlResponse = {
  records: {
    fields: {
      city: string;
      state: string;
      state_code: string;
    };
  }[];
};

export const isOpenDataSoftUrlResponse = objectTypeGuard<OpenDataSoftUrlResponse>(
  ({records}) =>
    arrayTypeGuard<OpenDataSoftUrlResponse["records"][number]>(
      objectTypeGuard<OpenDataSoftUrlResponse["records"][number]>(({fields}) =>
        objectTypeGuard<OpenDataSoftUrlResponse["records"][number]["fields"]>(
          ({city, state}) => isNonEmptyString(city) && isNonEmptyString(state),
        )(fields),
      ),
    )(records),
);

export const MIN_CHARACTERS_FOR_SEARCH = 3;

export const getOpenDataSoftUrl = (query: string) =>
  `https://data.opendatasoft.com/api/records/1.0/search/?dataset=us-cities-demographics%40public&q=${query}&rows=100`;

export const getUniqueCitiesByState = (records: OpenDataSoftUrlResponse["records"]) => {
  return records.reduce<{
    seen: Record<string, boolean>;
    result: Array<{
      label: string,
      options: CityOption[]
    }>;
  }>(
    (acc, {fields}) => {
      if(!acc.seen[fields.state]){
        acc.result[0].options.push({
          value: fields.state,
          label: fields.state,
          type: "state",
        })

        acc.seen[fields.state] = true;
      }

      const cityLabel = `${fields.city}, ${fields.state_code}`;

      if(!acc.seen[cityLabel]){
        acc.result[1].options.push({
          value: cityLabel,
          label: cityLabel,
          type: "city",
        })

        acc.seen[cityLabel] = true;
      }

      return acc;
    },
    {
      seen: {},
      result: [
        {
          label: "State",
          options: []
        },
        {
          label: "City",
          options: []
        },
      ],
    },
  ).result;
};
