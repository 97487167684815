import React from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import {Field, Input} from "@workspace/ui";
import {ListRangeFilter} from "@workspace/firebase-datamodel";

type Props = {
  title: string,
  range?: ListRangeFilter,
  onChange: (key: 'min' | 'max', value: string) => void
}

export const RangeInput = ({title, range, onChange}: Props) => {
  const handleInputChange = (key: 'min' | 'max') => (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(key, e.target.value)
  }

  return (
    <Box>
      <Text mb={4} color='#858586' fontSize='14px'>
        {title}
      </Text>
      <Flex gap={4}>
        <Field label='Min' smallLabel>
          <Input value={range?.min || ""} onChange={handleInputChange('min')}/>
        </Field>
        <Field label='Max' smallLabel>
          <Input value={range?.max || ""} onChange={handleInputChange('max')}/>
        </Field>
      </Flex>
    </Box>
  )
}
