export type MessageTemplate = {
  /**
   * Id of the message template
   */
  id: string;

  /**
   * Name of the message template
   */
  name: string;

  /**
   * The actual message template that will be sent to users
   */
  message: string;


  /**
   * Identifies the template as the default template in order to prevent it from deletion
   */
  isDefaultTemplate?: boolean;
};

export const MAX_MESSAGE_TEMPLATES = 5;

export const MAX_MESSAGE_TEMPLATE_TITLE_LENGTH = 30;

// Regex for a english alphabet string starting with a letter and consisting of spaces and characters.
// Maximum length 30 characters
export const MESSAGE_TEMPLATE_NAME_REGEX = /[a-zA-Z][a-zA-Z ]{0,29}$/;
