"use strict";
exports.__esModule = true;
exports.isCloudFunctionOutput = void 0;
var BillingPortalSession_1 = require("./BillingPortalSession");
var CheckoutSession_1 = require("./CheckoutSession");
var Conversation_1 = require("./Conversation");
var instagramCampaigns_1 = require("./instagramCampaigns");
var MessagesDailyCount_1 = require("./MessagesDailyCount");
var MessagesDailyQuota_1 = require("./MessagesDailyQuota");
var Subscription_1 = require("./Subscription");
var InstagramUser_1 = require("./InstagramUser");
var InstagramPendingMessages_1 = require("./InstagramPendingMessages");
var discovery_1 = require("./discovery");
var FollowUpMessage_1 = require("./FollowUpMessage");
var MagicDM_1 = require("./MagicDM");
var List_1 = require("./List");
/**
 * Type-guards for cloud-function outputs.
 */
exports.isCloudFunctionOutput = {
    createBillingPortalSession: BillingPortalSession_1.isCreateBillingPortalSessionOutput,
    createCheckoutSession: CheckoutSession_1.isCreateCheckoutSessionOutput,
    createInstagramCampaign: instagramCampaigns_1.isCreateInstagramCampaignOutput,
    readSubscription: Subscription_1.isReadSubscriptionOutput,
    readConversation: Conversation_1.isReadConversationOutput,
    readMessagesDailyCount: MessagesDailyCount_1.isReadMessagesDailyCountOutput,
    readMessagesDailyQuota: MessagesDailyQuota_1.isReadMessagesDailyQuotaOutput,
    readInstagramUser: InstagramUser_1.isReadInstagramUserOutput,
    readInstagramCampaigns: instagramCampaigns_1.isReadInstagramCampaignsOutput,
    nextInstagramMessage: instagramCampaigns_1.isNextInstagramMessageOutput,
    readInstagramPendingMessages: InstagramPendingMessages_1.isReadInstagramPendingMessagesOutput,
    nextDiscovery: discovery_1.isNextDiscoveryActionOutput,
    scrapeInstagramUser: InstagramUser_1.isScrapeInstagramUserOutput,
    nextFollowUpMessage: FollowUpMessage_1.isNextFollowUpMessageOutput,
    generateMagicDM: MagicDM_1.isGenerateMagicDMOutput,
    fetchUserLists: List_1.isFetchUserListsOutput,
    createList: List_1.isCreateListOutput,
    fetchUserLeads: List_1.isFetchUserLeadsOutput,
    fetchUnprocessedLeads: List_1.isFetchUnprocessedLeadsOutput,
    getLeadsCampaign: List_1.isGetLeadsCampaignOutput,
    deleteDiscoveryTarget: discovery_1.isDeleteDiscoveryTargetOutput
};
