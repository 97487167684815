import {CreationTime, isCreationTime} from "@workspace/models";
import {arrayTypeGuard, objectTypeGuard} from "@workspace/type-utils";
import {Subscription, isSubscription} from "./Subscription";

export type SubscriptionHistoryItem = Subscription & CreationTime;

export const isSubscriptionHistoryItem = objectTypeGuard<SubscriptionHistoryItem>(
  ({whenCreated, ...subscription}) =>
    isSubscription(subscription) && isCreationTime({whenCreated}),
);

export type SubscriptionHistory = SubscriptionHistoryItem[];
export const isSubscriptionHistory = arrayTypeGuard<SubscriptionHistoryItem>(
  isSubscriptionHistoryItem,
);
