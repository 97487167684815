"use strict";
exports.__esModule = true;
exports.MessageTemplateVariable = void 0;
var MessageTemplateVariable;
(function (MessageTemplateVariable) {
    MessageTemplateVariable["FIRST_NAME"] = "first name";
    // LOCATION = 'location',
    MessageTemplateVariable["HANDLE"] = "@handle";
    MessageTemplateVariable["NAME"] = "name";
})(MessageTemplateVariable = exports.MessageTemplateVariable || (exports.MessageTemplateVariable = {}));
