import {Box, BoxProps, Text, TextProps} from "@chakra-ui/react";
import {FC, PropsWithChildren, ReactNode} from "react";

export type FieldProps = PropsWithChildren<{
  label?: ReactNode;
  smallLabel?: boolean;
  helpText?: string;
  isError?: boolean;
  labelProps?: TextProps;
}> &
  BoxProps;

export const Field: FC<FieldProps> = ({
  label,
  smallLabel,
  children,
  helpText,
  isError,
  labelProps = {},
  ...props
}) => {
  return (
    <Box {...props}>
      {label && (
        <Text
          fontWeight={smallLabel ? "400" : "600"}
          fontSize={smallLabel ? "12px" : "14px"}
          color="gray.900"
          mb="2"
          {...labelProps}
        >
          {label}
        </Text>
      )}
      {children}
      {helpText && (
        <Text mt="2" fontSize="12px" color={isError ? "red.500" : "gray.500"}>
          {helpText}
        </Text>
      )}
    </Box>
  );
};
