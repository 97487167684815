import {Box, Flex, FlexProps} from "@chakra-ui/react";
import {FC} from "react";

type Props = FlexProps & {
  colors: string[];
};

export const BlurredBackground: FC<Props> = ({colors, ...flexProps}) => (
  <Flex
    flexDirection="column"
    position="absolute"
    width="100%"
    height="100%"
    top="0"
    left="0"
    opacity="0.2"
    zIndex="0"
    filter="blur(116px)"
    {...flexProps}
  >
    {colors.map((color) => (
      <Box key={color} flexGrow="1" w="100%" bg={color} />
    ))}
  </Flex>
);
