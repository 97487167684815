import {
  NonEmptyString,
  isLiteralType,
  isNonEmptyString,
  arrayTypeGuard,
} from "@workspace/type-utils";

export const socialNetworks = ["Instagram", "Twitter"] as const;
export type SocialNetwork = (typeof socialNetworks)[number];

export const isSocialNetwork = isLiteralType<SocialNetwork>(socialNetworks);

export const socialNetworkHostname: Record<SocialNetwork, string> = {
  Instagram: "www.instagram.com",
  Twitter: "twitter.com",
};

export const isSocialNetworkUrl = (socialNetwork: SocialNetwork, href: string) => {
  try {
    const url = new URL(href);
    return url.hostname.includes(socialNetworkHostname[socialNetwork])
  } catch (_ignore) {
    return false;
  }
};

export const socialNetworkByHostname = (hostname: string): SocialNetwork => {
  for (let socialNetwork of socialNetworks)
    if (hostname.includes(socialNetworkHostname[socialNetwork])) return socialNetwork;
  throw new Error("Could not detect SocialNetwork");
};

/** Social Network handle, e.g. `@elonmusk`. */
export type SocialNetworkHandle = NonEmptyString;

export const isSocialNetworkHandle = (arg: unknown): arg is SocialNetworkHandle =>
  isNonEmptyString(arg);

export const isSocialNetworkHandles =
  arrayTypeGuard<SocialNetworkHandle>(isSocialNetworkHandle);

export const normalizeSocialNetworkHandle = (
  handle: SocialNetworkHandle,
): SocialNetworkHandle => handle.toLowerCase().replace(/ /g, "");
