import { process_display_name } from "./processDisplayName";
import { MessageTemplateVariable } from "@workspace/models";
export const convertTemplateToPreview = (template, recipientUser, customPlaceholders) => {
    var _a, _b;
    let replacementRules = [
        {
            placeholder: MessageTemplateVariable.NAME,
            value: recipientUser.fullName
                ? (_a = process_display_name(recipientUser.fullName)) !== null && _a !== void 0 ? _a : ''
                : recipientUser.username,
        },
        {
            placeholder: MessageTemplateVariable.FIRST_NAME,
            value: recipientUser.fullName
                ? (_b = process_display_name(recipientUser.fullName.split(" ").length > 1
                    ? recipientUser.fullName.split(" ")[0]
                    : recipientUser.fullName.split("_")[0])) !== null && _b !== void 0 ? _b : ''
                : recipientUser.username,
        },
        {
            placeholder: MessageTemplateVariable.HANDLE,
            value: `@${recipientUser.username}`,
        },
    ];
    if (Boolean(customPlaceholders === null || customPlaceholders === void 0 ? void 0 : customPlaceholders.length)) {
        const validCustomPlaceholders = customPlaceholders.filter(placeholder => ![MessageTemplateVariable.NAME, MessageTemplateVariable.FIRST_NAME, MessageTemplateVariable.HANDLE].includes(placeholder));
        replacementRules = replacementRules.concat(validCustomPlaceholders.map((placeholder) => ({
            placeholder: placeholder,
            value: recipientUser[placeholder],
        })));
    }
    const res = replacementRules.reduce((inProgressText, rule) => inProgressText.replace(new RegExp(`{{${rule.placeholder}}}`, "g"), rule.value), template);
    return res;
};
