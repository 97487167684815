import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {FacebookPage} from "../integration/types";

import {getFacebookPages, integrateInstagramAccount} from "./thunks";
import {User} from "./types";

export type TwitterHistoryUploadStatus =
  | {code: "started"}
  | {code: "completed"}
  | {code: "error"; message: string};

export interface TwitterHistoryUploadState {
  filename: string;
  status: TwitterHistoryUploadStatus;
}

export interface UserState {
  user?: User;
  isFetchingUser: boolean;
  userIsNotFound: boolean;
  userDataError: boolean;
  showModalAuthSuccess?: boolean | undefined;
  showModalAuthFailure?: boolean | undefined;
  integrationSuccess: boolean;
  isUpdatingAutoresponder: boolean;
  isTurningOffAutoresponder: boolean;
  isOptimisticallySavedAutoresponder: boolean;
  isUpdatingTimezone: boolean;
  isOptimisticallySavedTimezone: boolean;
  facebookPages?: FacebookPage[];
  isFetchingFacebookPages: boolean;
  isIntegratingInstagramAccount: boolean;
  isRemovingIntegration: boolean;
  twitterHistoryUploadState?: TwitterHistoryUploadState;
}

export const initialState: UserState = {
  isFetchingUser: false,
  userIsNotFound: false,
  userDataError: false,
  integrationSuccess: false,
  isUpdatingAutoresponder: false,
  isTurningOffAutoresponder: false,
  isOptimisticallySavedAutoresponder: false,
  isUpdatingTimezone: false,
  isOptimisticallySavedTimezone: false,
  isRemovingIntegration: false,
  //Defaults to true because we don't want to show the first frame where the Facebook page isn't found
  isFetchingFacebookPages: true,
  isIntegratingInstagramAccount: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.userDataError = false;
    },
    setUserDataError: () => ({...initialState, userDataError: true}),
    setShowModalAuthSuccess: (state, action) => {
      state.showModalAuthSuccess = action.payload;
    },
    setShowModalAuthFailure: (state, action) => {
      state.showModalAuthFailure = action.payload;
    },
    setIntegrationSuccess: (state, action) => {
      state.integrationSuccess = action.payload;
    },
    updateAutoresponder: (state, action) => {
      const {integrationId, enabled, message} = action.payload;
      const index = state.user?.integrations.findIndex(
        (integration) => integration.id === integrationId,
      );

      if (typeof index !== "undefined" && state.user) {
        state.user.integrations[index].autoresponder = {enabled, message};
      }

      state.isOptimisticallySavedAutoresponder = true;
    },
    setIsUpdatingAutoresponder: (state, action) => {
      state.isUpdatingAutoresponder = action.payload;
    },
    setIsTurningOffAutoresponder: (state, action) => {
      state.isUpdatingAutoresponder = action.payload;
    },
    setIsOptimisticallySavedAutoresponder: (state, action) => {
      state.isOptimisticallySavedAutoresponder = action.payload;
    },
    setIsRemovingIntegration: (state, action: PayloadAction<boolean>) => {
      state.isRemovingIntegration = action.payload;
    },
    setIsFetchingUser: (state, action: PayloadAction<boolean>) => {
      state.isFetchingUser = action.payload;
    },
    updateTimezone: (state, action: PayloadAction<string | undefined>) => {
      if (typeof state.user !== "undefined") {
        state.user.timezone = action.payload;
        state.isOptimisticallySavedTimezone = true;
      }
    },
    setIsUpdatingTimezone: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingTimezone = action.payload;
    },
    setIsOptimisticallySavedTimezone: (state, action: PayloadAction<boolean>) => {
      state.isOptimisticallySavedTimezone = action.payload;
    },
    addFacebookPages: (state, action) => {
      state.facebookPages = action.payload;
    },
    startTwitterHistoryUpload: (state, action: PayloadAction<{filename: string}>) => {
      state.twitterHistoryUploadState = {
        filename: action.payload.filename,
        status: {code: "started"},
      };
    },
    setTwitterHistoryUploadStatus: (
      state,
      action: PayloadAction<TwitterHistoryUploadStatus>,
    ) => {
      if (state.twitterHistoryUploadState) {
        state.twitterHistoryUploadState.status = action.payload;
      }
    },
    resetApp: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getFacebookPages.fulfilled, (state, action) => {
      state.facebookPages = action.payload.accounts as FacebookPage[];
      state.isFetchingFacebookPages = false;
    });
    builder.addCase(getFacebookPages.rejected, (state) => {
      state.isFetchingFacebookPages = false;
    });
    builder.addCase(integrateInstagramAccount.pending, (state) => {
      state.isIntegratingInstagramAccount = true;
    });
    builder.addCase(integrateInstagramAccount.fulfilled, (state) => {
      state.isIntegratingInstagramAccount = false;
    });
    builder.addCase(integrateInstagramAccount.rejected, (state) => {
      state.isIntegratingInstagramAccount = false;
    });
  },
});

export const {
  setUser,
  setUserDataError,
  setShowModalAuthFailure,
  setShowModalAuthSuccess,
  setIntegrationSuccess,
  updateAutoresponder,
  setIsUpdatingAutoresponder,
  setIsTurningOffAutoresponder,
  setIsOptimisticallySavedAutoresponder,
  setIsRemovingIntegration,
  updateTimezone,
  setIsUpdatingTimezone,
  setIsOptimisticallySavedTimezone,
  startTwitterHistoryUpload,
  setTwitterHistoryUploadStatus,
  resetApp,
  setIsFetchingUser,
} = userSlice.actions;

export const reducer = userSlice.reducer;
