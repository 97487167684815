import {defineStyleConfig} from "@chakra-ui/react";

export {Breadcrumb, BreadcrumbLink, BreadcrumbItem} from "@chakra-ui/react";

export const breadcrumbStyleConfig = defineStyleConfig({
  baseStyle: {
    container: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600"
    },
    link: {
      color: "primary.400",
      "&[aria-current=page]": {
        color: "gray.500",
      },
    },
    separator: {
      borderColor: "gray.300",
    },
  },
});
