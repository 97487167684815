import {FirebaseUserContext, RemoteConfigContext, SubscriptionContext,} from "@workspace/react";
import {FC, useContext, useMemo} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import Action from "../modules/auth/pages/action";
import CreateAccount from "../modules/auth/pages/create-account";
import ForgotPassword from "../modules/auth/pages/forgot-password";
import Login from "../modules/auth/pages/login";
import Personalization from "../modules/bulk-send/pages/personalization";
import {ReAuthFailureModal} from "../modules/conversation/components/re-auth-modals/re-auth-failure-modal";
import {ReAuthSuccessModal} from "../modules/conversation/components/re-auth-modals/re-auth-success-modal";
import ShouldReAuthModal from "../modules/conversation/components/re-auth-modals/should-re-auth-modal";
import ChromeExt from "../modules/integration/pages/chrome-ext";
import FacebookIntegrationFailure from "../modules/integration/pages/facebook-integration-failure";
import FacebookIntegrationSuccess from "../modules/integration/pages/facebook-integration-success";
import GetStarted from "../modules/integration/pages/get-started";
import InstagramIntegrationFailure from "../modules/integration/pages/instagram-integration-failure";
import {Integrations} from "../modules/integration/pages/integrations";
import {TwitterIntegrationFailure} from "../modules/integration/pages/twitter-integration-failure";
import {TwitterIntegrationSuccess} from "../modules/integration/pages/twitter-integration-success";
import {Platform} from "../modules/integration/types";
import AutoresponderToastHoc from "../modules/user/components/autoresponder-toast-hoc";
import Settings from "../modules/user/pages/settings";
import ConditionalRoute from "./conditional-route";
import {useAppSelector, useLandingFlowData} from "src/hooks";
import {UserAuthContext} from "src/contexts";
import {HomePage, PricingPlansPage} from "src/pages";
import {routes} from "src/router";
import {TrialExpired} from "src/modules/trial/pages/trial-expired";
import {firebaseAuth} from "@workspace/firebase-app";
import {isAdmin} from "@workspace/client-utils";
import {envIsProd} from "@workspace/env";
import {GeneralLoading} from "src/components";
import {Discovery} from "../pages/Discovery";
import {VerifyEmail} from "src/modules/auth/pages/verify-email";
import ViewLists from "../pages/Lists/ViewLists";
import ViewLeads from "../pages/Lists/ViewLeads";

const VISIBLE_ROUTES_ON_TRIAL_EXPIRED = [
  routes.Settings.route,
  routes.PaymentPlans.route,
];

const AppRoutes: FC = () => {
  const userAuth = useContext(UserAuthContext);
  const {remainingTrialDays, hasSubscription, readSubscriptionIsPending} =
    useContext(SubscriptionContext);
  const {isAuthenticated} = useContext(FirebaseUserContext);
  const {messagesPerDay, billingFrequency} = useLandingFlowData();

  const user = useAppSelector((state) => state.user.user);
  const isInboxBuilt = !!user?.isInboxBuilt;
  const location = useLocation();

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const isRedirectedFromLanding = !!(billingFrequency && messagesPerDay);

  const {isFirebaseFeatureEnabledForUser} = useContext(RemoteConfigContext);

  const isDiscoveryEnabled = isFirebaseFeatureEnabledForUser(
    "DISCOVERY_ENABLED_USERS",
    userAuth?.email,
  );

  const integrations = useAppSelector((state) => state.user.user?.integrations);

  const hasIntegrations = useMemo(() => {
    if (integrations && integrations.length) {
      return integrations.some(
        (integration) =>
          integration.type !== Platform.Facebook || integration.isSubscribedToWebhooks,
      );
    } else {
      return false;
    }
  }, [integrations]);

  const shouldReAuthTwitter =
    integrations?.[0]?.type === Platform.Twitter && integrations?.[0]?.shouldReAuth;

  const isTrialExpired = useMemo(() => remainingTrialDays === 0, [remainingTrialDays]);

  /*
 TODO PE-1754 remove this code if unused
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (pathname === routes.Conversations.route) {
      mixpanel?.track("web.inbox.general.open");
    } else if (pathname === routes.Conversations.composeNewRoute) {
      mixpanel?.track("web.inbox.compose.open");
    } else if (pathname === routes.Followers.route) {
      mixpanel?.track("web.community.general.open");
    }
  }, [pathname, mixpanel]);

  useSoundNotification();

  useEffect(() => {
    const username = integrations?.[0]?.username;
    const actingUserUsername = actingUser?.integrations[0]?.username;
    const currentUsername = actingUserUsername ? actingUserUsername : username;
    const unreadCountNotification = unreadCount > 0 ? `(${unreadCount}) ` : "";

    document.title = route?.title
      ? route.title
      : currentUsername
      ? `${unreadCountNotification}@${currentUsername} | Howdy`
      : `${unreadCountNotification}Howdy`;
  }, [actingUser?.integrations, integrations, route?.title, unreadCount]);
  */

  const actionRedirectTo = useMemo(() => {
    if (!hasSubscription) return routes.PaymentPlans.route;

    return routes.Settings.route;
  }, [hasSubscription]);

  const isLoadingSubscriptionOrAuth =
    isAuthenticated === undefined ||
    (isAuthenticated && (!!readSubscriptionIsPending || hasSubscription === undefined));

  if (isLoadingSubscriptionOrAuth) {
    return <GeneralLoading />;
  }

  if (
    (queryParams.get("oobCode") && queryParams.get("mode")) ||
    queryParams.get("action_success")
  )
    return <Action redirectTo={actionRedirectTo} />;
  if (
    userAuth &&
    isTrialExpired &&
    !VISIBLE_ROUTES_ON_TRIAL_EXPIRED.includes(location.pathname)
  ) {
    return <TrialExpired />;
  }

  if (
    userAuth &&
    !firebaseAuth.currentUser?.emailVerified &&
    (!isAdmin(userAuth.email!) || envIsProd)
  ) {
    return <VerifyEmail />;
  }

  return (
    <>
      {/*
    TODO gianluca@pubgenius.io restore favicon
      <Favicon
        url={logo}
        renderOverlay={unreadCount > 0 ? renderBadgeOverlay : undefined}
      />
    */}
      <ShouldReAuthModal isOpen={!!shouldReAuthTwitter} />
      <ReAuthFailureModal />
      <ReAuthSuccessModal />

      <AutoresponderToastHoc>
        <Switch>
          <ConditionalRoute
            condition={!userAuth}
            path={routes.Login.route}
            redirectPath={routes.Home.route}
          >
            <Login />
          </ConditionalRoute>

          <ConditionalRoute
            condition={!userAuth}
            path={routes.CreateAccount.route}
            redirectPath={
              isRedirectedFromLanding && hasSubscription
                ? routes.Settings.route
                : routes.PaymentPlans.route
            }
          >
            <CreateAccount />
          </ConditionalRoute>

          <ConditionalRoute
            condition={!userAuth}
            path={routes.ForgotPassword.route}
            redirectPath={routes.Integrations.route}
          >
            <ForgotPassword />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth && !hasIntegrations}
            path={routes.Integrations.facebookIntegrationSuccess}
            redirectPath={!userAuth ? routes.Login.route : routes.Integrations.route}
          >
            <FacebookIntegrationSuccess />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth && !isInboxBuilt}
            path={routes.Integrations.facebookIntegrationFailure}
            redirectPath={routes.Login.route}
          >
            <FacebookIntegrationFailure />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth}
            path={routes.Integrations.twitterIntegrationSuccess}
            redirectPath={routes.Login.route}
          >
            <TwitterIntegrationSuccess />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth}
            path={routes.Integrations.twitterIntegrationFailure}
            redirectPath={routes.Login.route}
          >
            <TwitterIntegrationFailure />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth && !isInboxBuilt}
            path={routes.Integrations.instagramIntegrationFailure}
            redirectPath={routes.Login.route}
          >
            <InstagramIntegrationFailure />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth}
            path={routes.Lists.route}
            redirectPath={routes.Login.route}
          >
            <ViewLists />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            condition={!!userAuth}
            path={routes.Lists.listDetails}
            redirectPath={routes.Login.route}
          >
            <ViewLeads />
          </ConditionalRoute>

          <ConditionalRoute
            exact
            // Explain: in PE-862, 'isInboxBuilt' toggled to always 'true' and
            // with previous conditions it crashes application with rerenders.
            // condition={!!userAuth && !isInboxBuilt && !hasIntegrations}
            condition={!!userAuth}
            path={routes.Integrations.route}
            redirectPath={routes.Login.route}
          >
            <Integrations />
          </ConditionalRoute>

          <Route exact path={routes.ChromeExt.route}>
            <ChromeExt />
          </Route>

          <Route path={routes.GetStarted.route}>
            <GetStarted />
          </Route>

          <ConditionalRoute
            path={routes.Discovery.route}
            condition={isDiscoveryEnabled}
            redirectPath={routes.Integrations.route}
          >
            <Discovery />
          </ConditionalRoute>

          {/*
TODO PE-1754 remove this code if not used
          <ConditionalRoute
            condition={!!userAuth && !isInboxBuilt}
            path={routes.BuildingInboxWall.route}
            redirectPath={!userAuth ? routes.Login.route : routes.Conversations.route}
          >
            <BuildingInboxWall />
          </ConditionalRoute>

          <ConditionalRoute
            condition={!!userAuth}
            path={`${routes.Conversations.route}/:slug?`}
            redirectPath={routes.Login.route}
          >
            <Conversations />
          </ConditionalRoute>

          <ConditionalRoute
            condition={!!userAuth && hasIntegrations && isInboxBuilt}
            path={routes.Followers.route}
            redirectPath={
              !userAuth
                ? routes.Login.route
                : !hasIntegrations
                ? routes.Integrations.route
                : routes.BuildingInboxWall.route
            }
          >
            <Followers />
          </ConditionalRoute>
*/}

          <ConditionalRoute
            condition={!!userAuth && hasIntegrations && isInboxBuilt}
            path={routes.Campaigns.bulkSendRoute}
            redirectPath={
              !userAuth
                ? routes.Login.route
                : !hasIntegrations
                ? routes.Integrations.route
                : routes.BuildingInboxWall.route
            }
          >
            <Personalization />
          </ConditionalRoute>

          <ConditionalRoute
            condition={!!userAuth}
            path={routes.Settings.route}
            redirectPath={routes.Login.route}
          >
            <Settings />
          </ConditionalRoute>

          <ConditionalRoute
            condition={!!userAuth}
            path={routes.PaymentPlans.route}
            redirectPath={routes.Login.route}
          >
            <PricingPlansPage />
          </ConditionalRoute>

          <Route path="/payments">
            <Redirect to={routes.PaymentPlans.route} />
          </Route>

          <Route path={routes.Home.route}>
            <HomePage />
          </Route>
        </Switch>
      </AutoresponderToastHoc>
    </>
  );
};

export default AppRoutes;
