import {Operation} from "@workspace/models";
import {
  objectTypeGuard,
  NonNegativeInteger,
  isNonNegativeInteger,
} from "@workspace/type-utils";

export type MessagesDailyQuota = {
  maxDailyMessages: NonNegativeInteger;
};

export const isMessagesDailyQuota = objectTypeGuard<MessagesDailyQuota>(
  ({maxDailyMessages}) => isNonNegativeInteger(maxDailyMessages),
);

export type ReadMessagesDailyQuota = Operation<void, MessagesDailyQuota>;
export const isReadMessagesDailyQuotaOutput = objectTypeGuard<
  ReadMessagesDailyQuota["out"]
>((arg) => isMessagesDailyQuota(arg));
