import {arrayTypeGuard, objectTypeGuard} from "@workspace/type-utils";
import {InstagramUser, isInstagramUser} from "./InstagramUser";

/**
`InstagramUserInfo` is the `{ user }` object returned by Instagram API endpoint: `https://www.instagram.com/api/v1/users/${id}/info/`.

@example
```
https://www.instagram.com/api/v1/users/15249284/info/
```

@example
```json
{
    "pk": "1363076317",
    "username": "marianthikasda",
    "full_name": "Marianthi Kalatzaki-Kasdagli",
    "is_private": false,
    "pk_id": "1363076317",
    "profile_pic_url": "https://instagram.fmxp5-1.fna.fbcdn.net/v/t51.2885-19/275222783_136862052162422_7976887023309988957_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fmxp5-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=4247Xh-F0bUAX_NuMCv&tn=E45BIHLVumjdlyMQ&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfCw41R2Ti139f6wOklLGNHFfyrZZccnkeXPuAfu_YPwAg&oe=636FAA9E&_nc_sid=a9513d",
    "profile_pic_id": "2785749920822478702_1363076317",
    "is_verified": true,
    "follow_friction_type": 0,
    "has_anonymous_profile_picture": false,
    "media_count": 427,
    "follower_count": 98415,
    "following_count": 597,
    "following_tag_count": 0,
    "is_supervision_features_enabled": false,
    "biography": "🌴Survivor 2021🥥\nCaptain Class Α-Merchant Marine🇬🇷🚢\nex-Professional Swimmer 🏊‍♂️\n👩‍🎓PG Professional Studies in Shipping\n📌Lifeguard\n🚴‍♀️Power Cycling",
    "external_url": "https://sportlifesaving.blogspot.com/2021/09/kasdagli-glsa-supporter-survivor-greece.html?m=1",
    "external_lynx_url": "https://l.instagram.com/?u=https%3A%2F%2Fsportlifesaving.blogspot.com%2F2021%2F09%2Fkasdagli-glsa-supporter-survivor-greece.html%3Fm%3D1&e=ATPyOssjUm1whA3dyp4ncwUdYiUVkPzO7HB_j5VlHzQOqOEQn1qO8Nv1UaJha8CIG59eyBD6uf7Zh8KWeTl-XZXV8YgSbCzrbSaa_G8&s=1",
    "show_fb_link_on_profile": false,
    "primary_profile_link_type": 0,
    "total_igtv_videos": 4,
    "has_igtv_series": false,
    "has_videos": true,
    "total_clips_count": 1,
    "total_ar_effects": 0,
    "usertags_count": 510,
    "is_favorite": false,
    "is_interest_account": true,
    "hd_profile_pic_versions": [
        {
            "width": 320,
            "height": 320,
            "url": "https://instagram.fmxp5-1.fna.fbcdn.net/v/t51.2885-19/275222783_136862052162422_7976887023309988957_n.jpg?stp=dst-jpg_s320x320&_nc_ht=instagram.fmxp5-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=4247Xh-F0bUAX_NuMCv&tn=E45BIHLVumjdlyMQ&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfB6Ug8feH8raCjoauqzjcdnuw73prpk4IJ3PaLNOKiUhQ&oe=636FAA9E&_nc_sid=a9513d"
        },
        {
            "width": 640,
            "height": 640,
            "url": "https://instagram.fmxp5-1.fna.fbcdn.net/v/t51.2885-19/275222783_136862052162422_7976887023309988957_n.jpg?stp=dst-jpg_s640x640&_nc_ht=instagram.fmxp5-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=4247Xh-F0bUAX_NuMCv&tn=E45BIHLVumjdlyMQ&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfDGAm1sOldgOSAONK10MPivjvnfiOjlSeEqbPJDqCuh0A&oe=636FAA9E&_nc_sid=a9513d"
        }
    ],
    "hd_profile_pic_url_info": {
        "url": "https://instagram.fmxp5-1.fna.fbcdn.net/v/t51.2885-19/275222783_136862052162422_7976887023309988957_n.jpg?_nc_ht=instagram.fmxp5-1.fna.fbcdn.net&_nc_cat=1&_nc_ohc=4247Xh-F0bUAX_NuMCv&tn=E45BIHLVumjdlyMQ&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfCw5clxJEIngfY9u0s2saGh4pvj9C35VN96uv9gTWtTqQ&oe=636FAA9E&_nc_sid=a9513d",
        "width": 1080,
        "height": 1080
    },
    "mutual_followers_count": 1,
    "profile_context": "Followed by sandra.cvjet",
    "profile_context_links_with_user_ids": [
        {
            "start": 12,
            "end": 24,
            "username": "sandra.cvjet"
        }
    ],
    "profile_context_mutual_follow_ids": [
        1433901581
    ],
    "profile_context_facepile_users": [
        {
            "pk": "1433901581",
            "username": "sandra.cvjet",
            "full_name": "𝕾𝖆𝖓𝖉𝖗𝖆 𝕮𝖛𝖏𝖊𝖙𝖎𝖈𝖆𝖓𝖎𝖓",
            "is_private": true,
            "pk_id": "1433901581",
            "profile_pic_url": "https://instagram.fmxp5-1.fna.fbcdn.net/v/t51.2885-19/219781122_947092819189609_7565128596778848674_n.jpg?stp=dst-jpg_s150x150&_nc_ht=instagram.fmxp5-1.fna.fbcdn.net&_nc_cat=101&_nc_ohc=0gOrt9ryu1EAX87iU0U&edm=AEF8tYYBAAAA&ccb=7-5&oh=00_AfAp-PhBKoQSysqdEFxBniYOjk6DXuBTBzKngpxl649Tzw&oe=636EBE35&_nc_sid=a9513d",
            "profile_pic_id": "2622107354309105557_1433901581",
            "is_verified": false
        }
    ],
    "has_highlight_reels": true,
    "has_guides": false,
    "show_shoppable_feed": false,
    "shoppable_posts_count": 0,
    "merchant_checkout_style": "none",
    "seller_shoppable_feed_type": "none",
    "num_visible_storefront_products": 0,
    "storefront_attribution_username": null,
    "has_active_affiliate_shop": false,
    "creator_shopping_info": {
        "linked_merchant_accounts": []
    },
    "is_eligible_for_smb_support_flow": true,
    "is_eligible_for_lead_center": false,
    "is_experienced_advertiser": false,
    "lead_details_app_id": "com.bloks.www.ig.smb.services.lead_gen.all_leads",
    "displayed_action_button_partner": null,
    "smb_delivery_partner": null,
    "smb_support_delivery_partner": null,
    "displayed_action_button_type": "",
    "direct_messaging": "UNKNOWN",
    "fb_page_call_to_action_id": "",
    "address_street": "",
    "business_contact_method": "UNKNOWN",
    "category": "Personal blog",
    "category_id": "2700",
    "city_id": "0",
    "city_name": "",
    "contact_phone_number": "",
    "is_call_to_action_enabled": false,
    "is_profile_audio_call_enabled": false,
    "latitude": 0,
    "longitude": 0,
    "public_email": "",
    "public_phone_country_code": "",
    "public_phone_number": "",
    "zip": "",
    "instagram_location_id": "",
    "is_business": true,
    "professional_conversion_suggested_account_type": 3,
    "account_type": 2,
    "can_hide_category": true,
    "can_hide_public_contacts": true,
    "should_show_category": true,
    "is_category_tappable": true,
    "should_show_public_contacts": true,
    "interop_messaging_user_fbid": 115260049869242,
    "bio_links": [
        {
            "link_id": "17968429960879182",
            "url": "https://sportlifesaving.blogspot.com/2021/09/kasdagli-glsa-supporter-survivor-greece.html?m=1",
            "lynx_url": "https://l.instagram.com/?u=https%3A%2F%2Fsportlifesaving.blogspot.com%2F2021%2F09%2Fkasdagli-glsa-supporter-survivor-greece.html%3Fm%3D1&e=ATPyOssjUm1whA3dyp4ncwUdYiUVkPzO7HB_j5VlHzQOqOEQn1qO8Nv1UaJha8CIG59eyBD6uf7Zh8KWeTl-XZXV8YgSbCzrbSaa_G8&s=1",
            "link_type": "external",
            "title": "",
            "group_id": "0",
            "open_external_url_with_in_app_browser": true
        }
    ],
    "can_add_fb_group_link_on_profile": false,
    "is_facebook_onboarded_charity": false,
    "has_active_charity_business_profile_fundraiser": false,
    "charity_profile_fundraiser_info": {
        "pk": "1363076317",
        "is_facebook_onboarded_charity": false,
        "has_active_fundraiser": false,
        "consumption_sheet_config": {
            "can_viewer_donate": false,
            "currency": null,
            "donation_url": null,
            "privacy_disclaimer": null,
            "donation_disabled_message": "We're having trouble with connecting at the moment. Please try making your donation another time.",
            "donation_amount_config": {
                "donation_amount_selector_values": [],
                "default_selected_donation_value": null,
                "minimum_donation_amount": null,
                "maximum_donation_amount": null,
                "prefill_amount": null,
                "user_currency": null
            },
            "you_donated_message": null,
            "profile_fundraiser_id": null,
            "has_viewer_donated": null
        }
    },
    "transparency_product_enabled": false,
    "account_badges": [],
    "whatsapp_number": "",
    "pronouns": [],
    "fan_club_info": {
        "fan_club_id": null,
        "fan_club_name": null,
        "is_fan_club_referral_eligible": null,
        "fan_consideration_page_revamp_eligiblity": null,
        "is_fan_club_gifting_eligible": null
    },
    "has_nft_posts": false,
    "has_music_on_profile": false,
    "include_direct_blacklist_status": true,
    "is_potential_business": false,
    "request_contact_enabled": false,
    "robi_feedback_source": null,
    "is_memorialized": false,
    "open_external_url_with_in_app_browser": true,
    "has_exclusive_feed_content": false,
    "has_fan_club_subscriptions": false,
    "pinned_channels_info": {
        "pinned_channels_list": [],
        "has_public_channels": false
    },
    "remove_message_entrypoint": false,
    "chaining_results": null,
    "is_bestie": false,
    "show_account_transparency_details": false,
    "existing_user_age_collection_enabled": true,
    "show_post_insights_entry_point": true,
    "has_public_tab_threads": true,
    "auto_expand_chaining": false,
    "is_new_to_instagram": false,
    "highlight_reshare_disabled": false
}
```
*/
export type InstagramUserInfo = InstagramUser & {
  bio_links: InstagramUserInfoBioLink[];
  follower_count: number | string;
  biography: string;
  following_count: number | string;
  /** Number of posts. */
  media_count: number | string;
};

export const isInstagramUserInfo = objectTypeGuard<InstagramUserInfo>(
  ({follower_count, following_count, media_count, bio_links, ...user}) =>
    isInstagramUser(user) &&
    isInstagramUserInfoBioLinks(bio_links),
);

/**
@example
```json
{
    "link_id": "17968429960879182",
    "url": "https://sportlifesaving.blogspot.com/2021/09/kasdagli-glsa-supporter-survivor-greece.html?m=1",
    "lynx_url": "https://l.instagram.com/?u=https%3A%2F%2Fsportlifesaving.blogspot.com%2F2021%2F09%2Fkasdagli-glsa-supporter-survivor-greece.html%3Fm%3D1&e=ATPyOssjUm1whA3dyp4ncwUdYiUVkPzO7HB_j5VlHzQOqOEQn1qO8Nv1UaJha8CIG59eyBD6uf7Zh8KWeTl-XZXV8YgSbCzrbSaa_G8&s=1",
    "link_type": "external",
    "title": "",
    "group_id": "0",
    "open_external_url_with_in_app_browser": true
}
```
*/
export type InstagramUserInfoBioLink = {
  url: string;
};

export const isInstagramUserInfoBioLink = objectTypeGuard<InstagramUserInfoBioLink>(
  ({url}) => typeof url === "string",
);

export const isInstagramUserInfoBioLinks = arrayTypeGuard<InstagramUserInfoBioLink>(
  isInstagramUserInfoBioLink,
);
