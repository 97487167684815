import {Button, InputGroup, InputRightElement} from "@chakra-ui/react";
import React, {useState} from "react";
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";
import {Field, FieldProps, Input} from "@workspace/ui";

interface PasswordInputProps {
  label: string;
  value: string;
  saveValue: (value: string) => void;
  fieldProps?: Partial<FieldProps>
}

const PasswordInput: React.FC<PasswordInputProps> = ({
    label,
    value,
    saveValue,
    fieldProps = {}
  }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value.trim();

    saveValue(password);
  };

  return (
    <Field
      {...fieldProps}
      label={label}
      mb={4}
    >
      <InputGroup>
        <Input
          pr="4.5rem"
          value={value}
          fontSize="base"
          type={showPassword ? "text" : "password"}
          onChange={changeHandler}
          data-cy="password"
          // Explain: To prevent Platform-wide native keyboard shortcuts from triggering on typing.
          onKeyDown={(evt) => evt.stopPropagation()}
          isInvalid={!!fieldProps.isError}
        />
        <InputRightElement>
          <Button
            p="0"
            w="32px"
            color="gray.400"
            bg="transparent"
            _hover={{bg: "transparent"}}
            _active={{outline: "none"}}
            _focus={{outline: "none"}}
            onClick={toggleShowPassword}
          >
            {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </Button>
        </InputRightElement>
      </InputGroup>
    </Field>
  )
};

export default PasswordInput;
