import React, {useCallback, useEffect, useState} from 'react';
import {Box, Flex, InputGroup, InputLeftElement, Link, List, ListItem, Text, InputRightElement} from "@chakra-ui/react";
import {Button, Field, Heading, Icon, IconButton, Input, Skeleton} from "@workspace/ui";
import {chakra} from "@chakra-ui/system";
import {firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {ZAPIER_WEBHOOK_TYPES, isZapierWebhookConfig} from "@workspace/firebase-datamodel";

const StyledList = chakra(List, {
  baseStyle: {
    paddingLeft: "22px",
    li: {
      color: 'black',
      fontSize: 'sm',
      mb: "2",
      '&::marker': {
        color: 'gray.500'
      }
    },
    fontFamily: "Inter"
  }
})

const WebhookURL = () => {
  const [webhookURL, setWebhookURL] = useState('')
  const [errorText, setErrorText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasZapierWebhook, setHasZapierWebhook] = useState(false);
  const [isFetchingWebhookURL, setIsFetchingWebhookURL] = useState(false);

  const readZapierWebhookURL = useCallback(
    async () => {
      setIsFetchingWebhookURL(true);

      const data = await firebaseFunctions.httpsCallable(cloudFunctionName.readZapierWebhookConfig)();
      if(isZapierWebhookConfig(data.data)){
        if(data.data.MessageResponse){
           setHasZapierWebhook(true);
           setWebhookURL(data.data.MessageResponse.url);
        }
      }

      setIsFetchingWebhookURL(false);
    },
    []
  )

  useEffect(() => {
    readZapierWebhookURL()
  }, [readZapierWebhookURL])

  const handleChangeWebhookURL = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWebhookURL(e.target.value)
    setErrorText('')
  }

  const handleClickSubmit = async () => {
    try {
      new URL(webhookURL)
    } catch (e) {
      setErrorText("Kindly input a valid Zapier URL")
      return;
    }

    if (!new RegExp("https://hooks.zapier.com/hooks/catch/\\d+/\\w+").test(webhookURL)) {
      setErrorText("Kindly input a valid Zapier URL")
      return;
    }

    setIsLoading(true);

    try {
      await fetch(webhookURL)
    } catch (e) {
      setIsLoading(false);
      setErrorText("Kindly input a valid Zapier URL")
      return;
    }

    try {
      await firebaseFunctions.httpsCallable(cloudFunctionName.writeZapierWebhook)({
        url: webhookURL,
        type: ZAPIER_WEBHOOK_TYPES.MessageResponse
      });
    } catch (e) {
      setErrorText("Something went wrong while saving your zapier URL. Kindly try again.")
      setIsLoading(false);
      return;
    }

    setHasZapierWebhook(true);
  }

  const handleClickDelete = async () => {
    try{
      setIsLoading(true)
      await firebaseFunctions.httpsCallable(cloudFunctionName.deleteZapierWebhook)({
        type: ZAPIER_WEBHOOK_TYPES.MessageResponse
      });

      setHasZapierWebhook(false);
      setWebhookURL('');
    }catch (e){
      setErrorText("Something went wrong while deleting your zapier URL. Kindly try again.")
    }

    setIsLoading(false)
  }

  const renderAction = () => {
    if(isFetchingWebhookURL) return null;

    if (hasZapierWebhook) {
      return (
        <Box
          width="fit-content"
          borderRadius="8px"
          height="36px"
          px={4}
          display="flex"
          alignItems="center"
          gap={2}
          color="green.600"
          border="2px solid"
          borderColor="green.500"
          fontWeight={600}
        >
          Ready to use
          <Icon name='check' size={20}/>
        </Box>
      )
    }

    return (
      <Button
        size='md'
        variant='primary'
        onClick={handleClickSubmit}
        disabled={isLoading}
      >
        {isLoading ? 'Testing...' : 'Send test'}
      </Button>
    )
  }

  const renderInput = () => {
    if(isFetchingWebhookURL){
      return <Skeleton height='36px'></Skeleton>
    }

    if(hasZapierWebhook){
      return (
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
          >
            <Icon name='webhook' size={20}/>
          </InputLeftElement>
          <Input
            pl={10}
            pr={10}
            value={webhookURL}
            isDisabled
            color='gray.500'
            opacity="1 !important"
          />
          <InputRightElement
          >
            <IconButton isDisabled={isLoading} onClick={handleClickDelete} iconName="trash" variant="ghost" size="sm" iconProps={{size: 20}}/>
          </InputRightElement>
        </InputGroup>
      )
    }


    return (
      <InputGroup>
        <InputLeftElement
          pointerEvents='none'
        >
          <Icon name='webhook' size={20}/>
        </InputLeftElement>
        <Input
          placeholder='https://'
          pl={10}
          value={webhookURL}
          onChange={handleChangeWebhookURL}
        />
      </InputGroup>
    )
  }

  return (
    <Box mt={6} mb={6}>
      <Field
        label='Your webhook URL'
        mb={4}
        helpText={errorText}
        isError
      >
        {renderInput()}
      </Field>
      {renderAction()}
    </Box>
  )
}

const ZapierWebhooks = () => {
  return (
    <Flex gap="4" fontFamily="Inter">
      <Box width="40%">
        <Heading variant="h3" color="gray.900" mb="2" display="flex" alignItems="center">
          <Text mr={1.5}>Webhooks</Text><Icon width="76px" height="26px" name='zapier'/>
        </Heading>
        <Text color="gray.600" fontSize="14px" lineHeight="20px">
          Integrate Howdy with your Zapier webhook to automate tasks, save more time, and manage your leads.
          <br/>
          <br/>
          Note: Zapier requires that you be on one of their paid plans to use Webhooks by Zapier.
        </Text>
      </Box>
      <Box
        p="8"
        width="60%"
        borderRadius="md"
        border="1px solid rgba(0, 0, 0, 0.08)"
        boxShadow="0px 5px 12px rgba(0, 0, 0, 0.05)"
      >
        <Text color="gray.900" fontWeight="bold" fontSize="md" mb="6">
          How to get started
        </Text>
        <StyledList styleType="decimal" stylePosition="outside">
          <ListItem>
            Log in to your Zapier account and click{" "}
            <Link
              isExternal
              color="primary.400"
              href="https://zapier.com/webintent/create-zap?entry-point-method=make_a_zap_call_to_action"
              target="_blank"
              fontWeight="bold"
              textDecoration="underline"
            >
              Create Zap.
            </Link>
          </ListItem>
          <ListItem>
            Select &quot;Webhooks&quot; for the trigger, choose “Catch Hook” from the Event dropdown,
            and click Continue to generate your webhook URL.
          </ListItem>
          <ListItem>
            Copy webhook URL from your Zap, and paste it below.
          </ListItem>
          <ListItem>
            <span>
              Click “Send test” to connect Howdy with Zapier.
            </span>
            <WebhookURL/>
          </ListItem>
          <ListItem>
            Head back to Zapier and click Test and Continue.
          </ListItem>
          <ListItem>
            That’s it, you’re now ready to integrate Howdy with thousands of apps.
            If you need any help, <Text textDecoration="underline" display="inline">contact support.</Text>
          </ListItem>
        </StyledList>
      </Box>
    </Flex>
  );
};

export default ZapierWebhooks;
