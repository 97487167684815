import {isLiteralObjectType} from "@workspace/type-utils";

export const MagicDMActions = {
  SOUND_LIKE_HUMAN: "SOUND_LIKE_HUMAN",
  POSITIVE_TONE: "POSITIVE_TONE",
  FIX_GRAMMAR: "FIX_GRAMMAR",
  SIMPLIFY_MESSAGE: "SIMPLIFY_MESSAGE",
  ASK_ANYTHING: "ASK_ANYTHING",
} as const satisfies Record<string, string>

export type MagicDMAction = (typeof MagicDMActions)[keyof typeof MagicDMActions];


export const isMagicDMAction = isLiteralObjectType<MagicDMAction>(MagicDMActions);

  export const MagicActionText: Partial<Record<MagicDMAction, string>> = {
  SOUND_LIKE_HUMAN: "Sound like a human",
  POSITIVE_TONE: "Rewrite in a positive tone",
  FIX_GRAMMAR: "Fix grammar",
  SIMPLIFY_MESSAGE: "Simplify message",
}
