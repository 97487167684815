import {Box, Grid, GridItem} from "@chakra-ui/react";
import {Selling, SELLINGS} from "@workspace/models";
import {FC} from "react";
import {useAppSelector} from "src/hooks";
import {Chip} from "../Chip";
import {StepSectionSubtext, StepSectionTitle, StepTitle} from "../styled";
import {WelcomeStepProps} from "./types";
import {Field, IconKey, Input} from "@workspace/ui";

const sellingIcons: Record<Selling, IconKey> = {
  "Coaching": "coaching",
  "Real estate": "realEstate",
  "Financial services": "financialServices",
  "Consulting": "consulting",
  "Personal training": "personalTraining",
  "Legal": "legal",
  "Other": "otherQuestionMark",
}

export const WelcomeStep: FC<WelcomeStepProps> = ({selectedSelling, onChange, onChangeOtherService, otherService}) => {
  const {user} = useAppSelector((state) => state.user);

  if (!user) return null;

  return (
    <Box>
      <StepTitle>Welcome to Howdy Discover</StepTitle>
      <StepSectionTitle mb="10" fontSize="h6" color="gray.900">
        Let’s set your prospect preferences easily in a few simple steps
      </StepSectionTitle>

      <StepSectionSubtext mb="4">What are you selling?</StepSectionSubtext>
      <Grid templateColumns="repeat(3,1fr)" gap="3" flexWrap="wrap">
        {SELLINGS.map((selling) => {
          const isSelected = selectedSelling === selling;

          return (
            <GridItem key={selling}>
              <Chip
                isSelected={isSelected}
                onClick={() => onChange(isSelected ? null : selling)}
                icon={sellingIcons[selling]}
              >
                {selling}
              </Chip>
            </GridItem>
          );
        })}
      </Grid>
      {
        (selectedSelling === "Other") && (
          <Field mt="10" label="What’s the other service you provide?" w="full">
            <Input value={otherService} onChange={({target}) => onChangeOtherService(target.value)}/>
          </Field>
        )
      }
    </Box>
  );
};
