"use strict";
exports.__esModule = true;
exports.normalizeSocialNetworkHandle = exports.isSocialNetworkHandles = exports.isSocialNetworkHandle = exports.socialNetworkByHostname = exports.isSocialNetworkUrl = exports.socialNetworkHostname = exports.isSocialNetwork = exports.socialNetworks = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.socialNetworks = ["Instagram", "Twitter"];
exports.isSocialNetwork = (0, type_utils_1.isLiteralType)(exports.socialNetworks);
exports.socialNetworkHostname = {
    Instagram: "www.instagram.com",
    Twitter: "twitter.com"
};
var isSocialNetworkUrl = function (socialNetwork, href) {
    try {
        var url = new URL(href);
        return url.hostname.includes(exports.socialNetworkHostname[socialNetwork]);
    }
    catch (_ignore) {
        return false;
    }
};
exports.isSocialNetworkUrl = isSocialNetworkUrl;
var socialNetworkByHostname = function (hostname) {
    for (var _i = 0, socialNetworks_1 = exports.socialNetworks; _i < socialNetworks_1.length; _i++) {
        var socialNetwork = socialNetworks_1[_i];
        if (hostname.includes(exports.socialNetworkHostname[socialNetwork]))
            return socialNetwork;
    }
    throw new Error("Could not detect SocialNetwork");
};
exports.socialNetworkByHostname = socialNetworkByHostname;
var isSocialNetworkHandle = function (arg) {
    return (0, type_utils_1.isNonEmptyString)(arg);
};
exports.isSocialNetworkHandle = isSocialNetworkHandle;
exports.isSocialNetworkHandles = (0, type_utils_1.arrayTypeGuard)(exports.isSocialNetworkHandle);
var normalizeSocialNetworkHandle = function (handle) { return handle.toLowerCase().replace(/ /g, ""); };
exports.normalizeSocialNetworkHandle = normalizeSocialNetworkHandle;
