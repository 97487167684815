import React, {FC, useState} from "react";
import {WhatNext} from "./WhatNext";
import {Prospects} from "./Prospects";
import {useDsmChromeExtension} from "../../hooks";
import {DiscoveryData} from "@workspace/models";

type NextStepProps = {
  discoveryData: DiscoveryData;
  onCampaignStarted: () => void;
}

export const NextStep: FC<NextStepProps> = ({discoveryData, onCampaignStarted}) => {
  const [showWhatNext, setShowWhatNext] = useState(
    discoveryData.isCampaignCreatedAndNotStarted,
  );

  const {extensionIsInstalled, refreshExtensionIsInstalled} = useDsmChromeExtension();

  const intervalRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

  const _refreshExtensionIsInstalled = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      refreshExtensionIsInstalled().then((isInstalled) => {
        if (isInstalled) {
          clearInterval(intervalRef.current!);
        }
      });
    }, 5000);
  };

  React.useEffect(() => {
    _refreshExtensionIsInstalled();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showWhatNext) {
    return (
      <WhatNext
        handleClickBack={() => setShowWhatNext(false)}
        extensionIsInstalled={extensionIsInstalled}
        onCampaignStarted={onCampaignStarted}
        isCampaignCreatedAndNotStarted={discoveryData.isCampaignCreatedAndNotStarted}
        campaignId={discoveryData.campaignId}
      />
    );
  }

  return <Prospects moveToWhatNext={() => setShowWhatNext(true)} />;
};
