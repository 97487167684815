import {Link} from "@chakra-ui/react";
import {FC, PropsWithChildren, MouseEventHandler} from "react";
import Linkify from "react-linkify";

type LinkifyWrapperProps = {
  onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
};

const LinkifyWrapper: FC<PropsWithChildren<LinkifyWrapperProps>> = ({
  children,
  onLinkClick,
}) => (
  <Linkify
    componentDecorator={(decoratedHref, decoratedText, key) => (
      <Link
        target="_blank"
        rel="noopener"
        href={decoratedHref}
        key={key}
        textDecor="underline"
        onClick={onLinkClick}
      >
        {decoratedText}
      </Link>
    )}
  >
    {children}
  </Linkify>
);

export default LinkifyWrapper;
