import {arrayTypeGuard, isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {Campaign, nextCampaignMessage} from "./Campaign";
import {isTime, isUpdateTime, TIME, UpdateTime} from "./time";
import {Time, updatedNow} from "@workspace/models";
import {isAfter, sub} from "date-fns";

export const MAX_MESSAGES_BEFORE_COOL_DOWN = 60;
export const COOL_DOWN_WINDOW = TIME.ONE_HOUR * 14;
export const COOL_DOWN_PERIOD = TIME.ONE_HOUR * 1.5;

export type CampaignsQueue = UpdateTime & {
  activeSchedulerId?: NonEmptyString;
  coolDownTill: Time | undefined;
  recentMessageTimestamps: Array<Time>
};

const isRecentMessageTimestampsArray = arrayTypeGuard<Time>(isTime);

export const isCampaignsQueue = objectTypeGuard<CampaignsQueue>(
  ({activeSchedulerId, whenUpdated, coolDownTill, recentMessageTimestamps}) =>
    isUpdateTime({whenUpdated}) &&
    (activeSchedulerId === undefined
      ? true
      : isNonEmptyString(activeSchedulerId)) &&
  (coolDownTill === undefined ? true : isTime(coolDownTill)) &&
  recentMessageTimestamps === undefined ? true : isRecentMessageTimestampsArray(recentMessageTimestamps),
);

export const isActiveScheduler = (
  schedulerId: NonEmptyString,
  activeSchedulerId: CampaignsQueue["activeSchedulerId"],
) => {
  // There is no current active scheduler, input `activeSchedulerId` becomes active,
  if (activeSchedulerId === undefined) return true;
  // otherwise check if ids are the same.
  return activeSchedulerId === schedulerId;
};

export const nextActiveCampaign = ({campaigns, instagramId}: { campaigns: Campaign[], instagramId: string }): Campaign | undefined =>
  // First campaign with a "next message" found is next active campaign.
  campaigns.find((campaign) => (campaign.instagramId === instagramId) && !campaign.isDeleted && !!nextCampaignMessage(campaign));

export const filterMessagesToFitInterval = (recentMessageTimestamps: CampaignsQueue["recentMessageTimestamps"]) => {
  return recentMessageTimestamps.filter(time => {
    const cooldownStart = sub(new Date(), {
      seconds: COOL_DOWN_WINDOW / 1000
    })

    return isAfter(new Date(time), cooldownStart)
  })
}

export const newCampaignQueue = (): CampaignsQueue => ({
  ...updatedNow(),
  coolDownTill: undefined,
  recentMessageTimestamps: [],
})
