"use strict";
exports.__esModule = true;
exports.newItem = exports.createId = exports.isItem = void 0;
var type_utils_1 = require("@workspace/type-utils");
var uuid_1 = require("uuid");
exports.isItem = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id;
    return (0, type_utils_1.isNonEmptyString)(id);
});
exports.createId = uuid_1.v4;
var newItem = function () { return ({
    id: (0, exports.createId)()
}); };
exports.newItem = newItem;
