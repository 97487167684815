import {
  CreationTime,
  SocialNetworkHandle,
  SocialNetwork,
  Operation,
  isSocialNetwork,
  createdNow,
  isCreationTime,
  isSocialNetworkHandle,
  isSocialNetworkHandles,
} from "@workspace/models";
import {objectTypeGuard} from "@workspace/type-utils";

/**
 * Dual conversation between our customer socialNetwork handle and another participant.
 */
export type Conversation = CreationTime & {
  /**
   * Social network handle involved in the chat.
   */
  participant: SocialNetworkHandle;
};

export const isConversation = objectTypeGuard<Conversation>(
  ({participant, ...creationTime}) =>
    isSocialNetworkHandle(participant) && isCreationTime(creationTime),
);

export const newConversation = ({
  participant,
}: Pick<Conversation, "participant">): Conversation => ({
  participant,
  ...createdNow(),
});

export type CreateConversation = Operation<
  {
    handle: SocialNetworkHandle;
    participant: SocialNetworkHandle;
    socialNetwork: SocialNetwork;
  },
  null
>;

export const isCreateConversationInput = objectTypeGuard<CreateConversation["in"]>(
  ({handle, participant, socialNetwork}) =>
    isSocialNetwork(socialNetwork) &&
    isSocialNetworkHandle(handle) &&
    isSocialNetworkHandle(participant),
);

export type ReadConversation = Operation<
  {
    handle: SocialNetworkHandle;
    participant: SocialNetworkHandle;
    socialNetwork: SocialNetwork;
  },
  Conversation
>;

export const isReadConversationInput = objectTypeGuard<ReadConversation["in"]>(
  ({handle, participant, socialNetwork}) =>
    isSocialNetwork(socialNetwork) &&
    isSocialNetworkHandle(handle) &&
    isSocialNetworkHandle(participant),
);

export const isReadConversationOutput =
  objectTypeGuard<ReadConversation["out"]>(isConversation);

/**
 * Return list of dual conversations, i.e. a conversation between our customer handle and (only one) participant.
 */
export type SelectConversations = Operation<
  {
    handle: SocialNetworkHandle;
    /** Set of participants to filter our result set. */
    participants: SocialNetworkHandle[];
    socialNetwork: SocialNetwork;
  },
  Conversation[]
>;

export const isSelectConversationsInput = objectTypeGuard<SelectConversations["in"]>(
  ({handle, participants, socialNetwork}) =>
    isSocialNetwork(socialNetwork) &&
    isSocialNetworkHandle(handle) &&
    isSocialNetworkHandles(participants),
);
