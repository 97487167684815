import numbro from 'numbro';

export const getFormatOptions = (num: number): numbro.Format | undefined => {
  if (num > 1000) {
    return {
      mantissa: 1,
      trimMantissa: true,
      forceAverage: num < 1000000 ? 'thousand' : 'million',
      roundingFunction: Math.floor,
    };
  }
};

export const formatNumber = (num: number, options?: numbro.Format): string => {
  const optionsToUse = options ?? getFormatOptions(num);

  return numbro(num).format(optionsToUse);
};
