import React, {FC, useContext} from "react";
import {Flex, Text} from "@chakra-ui/react";
import {Link, useLocation} from "react-router-dom";
import {UserAuthContext} from "src/contexts";
import {RemoteConfigContext} from "@workspace/react";
import {Icon, IconKey} from "@workspace/ui";

type Props = {
  icon: IconKey;
  isActive?: boolean;
  activeColor: string;
  text: string;
  to: string;
}

const NavLink: FC<Props> = ({ icon, activeColor, isActive, to, text }) => {
  return (
    <Flex
      as={Link}
      to={to}
      color={isActive ? activeColor : "gray.800"}
      position="relative"
      align="center"
      gap={2}
      px={2.5}
      py={1.5}
      backgroundColor={isActive ? "#0B0D0E0D" : "transparent"}
      borderRadius="8"
      height="fit-content"
    >
      <Icon name={icon} size={20}/>
      <Text fontSize="14px" fontWeight="500" color="gray.800">{text}</Text>
    </Flex>
  )
}

export const NavLinks = () => {
  const router = useLocation();
  const userAuth = useContext(UserAuthContext);
  const {isFirebaseFeatureEnabledForUser} = useContext(RemoteConfigContext);

  const discoveryIsActive = router.pathname.includes("/discovery");
  const integrationsIsActive = router.pathname.includes("/integrations");
  const listsIsActive = router.pathname.includes("/lists");

  const isDiscoveryEnabled = isFirebaseFeatureEnabledForUser(
    "DISCOVERY_ENABLED_USERS",
    userAuth?.email,
  );

  if (!isDiscoveryEnabled) return null;

  return (
    <Flex
      gap={2}
      height="100%"
      position="absolute"
      top="50%"
      left="50%"
      fontFamily="Inter"
      fontSize="h6"
      fontWeight="500"
      transform="translate(-50%, -50%)"
      align="center"
    >
      <NavLink
        icon="discovery"
        isActive={discoveryIsActive}
        activeColor="primary.400"
        to='/discovery'
        text="Discovery"
      />
      <NavLink
        icon="lists"
        isActive={listsIsActive}
        activeColor="green.600"
        to='/lists'
        text="Lists"
      />
      <NavLink
        icon="integrations"
        isActive={integrationsIsActive}
        activeColor="primary.400"
        to='/integrations'
        text="Integrations"
      />
    </Flex>
  );
};
