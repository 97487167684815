"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.isInstagramThreadDailyResponseRates = exports.isInstagramThreadDailyResponseRate = exports.isInstagramThreadResponseRate = exports.isInstagramRepliedMessage = exports.isMarkMessageAsIgnoredInput = exports.isResolveInstagramPendingMessageInput = exports.isResolvePendingMessageInputItem = exports.isReadInstagramPendingMessagesOutput = exports.isReadInstagramPendingMessagesInput = exports.isCreateInstagramPendingMessageInput = exports.isCreateInstagramPendingMessage = exports.isInstagramPendingMessage = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
var zapierWebhooks_1 = require("./zapierWebhooks");
exports.isInstagramPendingMessage = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var threadId = _a.threadId, messageId = _a.messageId, whenCreated = _a.whenCreated;
    return (0, models_1.isTime)(whenCreated) && (0, type_utils_1.isNonEmptyString)(threadId) && (0, type_utils_1.isNonEmptyString)(messageId);
});
exports.isCreateInstagramPendingMessage = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var sender = _a.sender, thread = __rest(_a, ["sender"]);
    return (0, models_1.isSocialNetworkHandle)(sender) && (0, exports.isInstagramPendingMessage)(thread);
});
exports.isCreateInstagramPendingMessageInput = (0, type_utils_1.objectTypeGuard)(exports.isCreateInstagramPendingMessage);
exports.isReadInstagramPendingMessagesInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var sender = _a.sender;
    return (0, models_1.isSocialNetworkHandle)(sender);
});
exports.isReadInstagramPendingMessagesOutput = (0, type_utils_1.arrayTypeGuard)(exports.isInstagramPendingMessage);
exports.isResolvePendingMessageInputItem = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var messageId = _a.messageId, responseTime = _a.responseTime, zapierData = _a.zapierData;
    return (0, type_utils_1.isNonEmptyString)(messageId) &&
        (0, models_1.isTime)(responseTime) &&
        (0, zapierWebhooks_1.isZapierMessageResponseRow)(zapierData);
});
var isResolvePendingMessageInputMessages = (0, type_utils_1.arrayTypeGuard)(exports.isResolvePendingMessageInputItem);
exports.isResolveInstagramPendingMessageInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var sender = _a.sender, messages = _a.messages;
    return (0, models_1.isSocialNetworkHandle)(sender) &&
        isResolvePendingMessageInputMessages(messages);
});
var isMarkMessageAsIgnoredInputMessages = (0, type_utils_1.arrayTypeGuard)(type_utils_1.isNonEmptyString);
exports.isMarkMessageAsIgnoredInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var sender = _a.sender, messages = _a.messages;
    return (0, models_1.isSocialNetworkHandle)(sender) &&
        isMarkMessageAsIgnoredInputMessages(messages);
});
exports.isInstagramRepliedMessage = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var whenCreated = _a.whenCreated, responseTime = _a.responseTime, threadId = _a.threadId;
    return (0, models_1.isTime)(whenCreated) && (0, models_1.isTime)(responseTime) && (0, type_utils_1.isNonEmptyString)(threadId);
});
exports.isInstagramThreadResponseRate = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var numRequests = _a.numRequests, numResponses = _a.numResponses;
    return (0, type_utils_1.isNonNegativeInteger)(numRequests) && (0, type_utils_1.isNonNegativeInteger)(numResponses);
});
exports.isInstagramThreadDailyResponseRate = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var day = _a.day, responseRate = __rest(_a, ["day"]);
    return (0, models_1.isDay)(day) && (0, exports.isInstagramThreadResponseRate)(responseRate);
});
exports.isInstagramThreadDailyResponseRates = (0, type_utils_1.arrayTypeGuard)(exports.isInstagramThreadDailyResponseRate);
