import {Box, Flex, Grid, GridItem, Heading, useDisclosure} from "@chakra-ui/react";
import React, {useState, useCallback, useMemo, useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../app-hooks";
import AppLayout from "../../../components/app-layout";
import InnerAppLayout from "../../../components/inner-app-layout";
import {MAX_MOBILE_RESOLUTION} from "../../../helpers/constants";
import useMixpanel from "../../../helpers/hooks/use-mixpanel";
import useWindowWidth from "../../../helpers/hooks/use-window-width";
import {Friend} from "../../friend/types";
import {
  clearBulkSendData,
  removeTemplateById,
  resetCurrentRecipient,
  setCurrentRecipient,
  deselectRecipient,
} from "../bulk-send-slice";
import BulkSendButton from "../components/bulk-send";
import MinimizedRecipientsList from "../components/minimized-recipients-list";
import MobileRecipientsList from "../components/mobile-recipients-list";
import RecipientsList from "../components/recipients-list";
import TemplateTabs, {TabIndex} from "../components/template-tabs";
import generateBulkSendBtnLabel from "../helpers/generate-bulk-send-btn-label";
import getConfirmationMessage from "../helpers/get-confirmation-message";
import prepareMembersDataForBulkSend from "../helpers/prepare-members-data-for-bulk-send";
import {bulkSendMessage} from "../thunks";

const Personalization: React.FC = () => {
  const dispatch = useAppDispatch();
  const {templatesById, template, selectedRecipients, isSending, currentRecipient} =
    useAppSelector((state) => state.bulkSend);
  const [activeTabIndex, setActiveTabIndex] = useState(TabIndex.MESSAGE_TEMPLATE);
  const mixpanel = useMixpanel();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MAX_MOBILE_RESOLUTION;
  const recipientsCount = selectedRecipients.length;
  const {isOpen, onClose, onOpen} = useDisclosure();
  const showRecipientsOnMobile = isMobile && selectedRecipients.length > 0;

  const personalizedTemplatesCount = useMemo(
    () => Object.keys(templatesById).length,
    [templatesById],
  );
  const btnLabel = useMemo(
    () => generateBulkSendBtnLabel(selectedRecipients.length, personalizedTemplatesCount),
    [personalizedTemplatesCount, selectedRecipients.length],
  );

  const updateActiveTabIndex = useCallback(
    (index = TabIndex.MESSAGE_TEMPLATE) => setActiveTabIndex(index),
    [],
  );

  const openPersonalizationHandler = useCallback(
    // @ts-ignore
    (recipient) => {
      const currentRecipientId =
        currentRecipient?.twitterId || currentRecipient?.instagramId;

      // see details in the TemplateTabs
      if (currentRecipient && templatesById[currentRecipientId as string] === "") {
        dispatch(removeTemplateById(currentRecipient.twitterId));
      }

      dispatch(setCurrentRecipient(recipient));
      setActiveTabIndex(TabIndex.PERSONALIZATIONS);
    },
    [currentRecipient, dispatch, templatesById],
  );

  const removeRecipient = useCallback(
    (e: Event, recipient: Friend) => {
      e.stopPropagation();
      dispatch(deselectRecipient(recipient));

      if (currentRecipient?.id === recipient.id) {
        updateActiveTabIndex(TabIndex.MESSAGE_TEMPLATE);
        dispatch(resetCurrentRecipient());
      }
    },
    [currentRecipient?.id, dispatch, updateActiveTabIndex],
  );

  const bulkSendHandler = useCallback(() => {
    if (!template || !recipientsCount) return;

    const confirmation = confirm(getConfirmationMessage(recipientsCount));

    if (confirmation) {
      const members = prepareMembersDataForBulkSend(selectedRecipients, templatesById);

      dispatch(bulkSendMessage({template, members}));
      mixpanel?.track("web.community.bulk-send.send");
    }
  }, [dispatch, recipientsCount, selectedRecipients, template, templatesById, mixpanel]);

  useEffect(
    () => () => {
      dispatch(clearBulkSendData());
    },
    [dispatch],
  );

  return (
    <AppLayout maxContainerWidth="1440px">
      <InnerAppLayout>
        <Box
          h="100%"
          pr={{md: 0}}
          px={{base: 2.5, md: 6}}
          borderWidth="1px"
          borderColor="gray.150"
          borderTop="none"
        >
          <Flex
            px={{base: 0, md: 2}}
            mb={{base: 0, md: 6}}
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Heading as="h1" color="gray.600" fontSize="xl" mr={6} my={4}>
              Bulk send
            </Heading>

            {!isMobile && (
              <Box pt={4}>
                <BulkSendButton
                  label={btnLabel}
                  isLoading={isSending}
                  alignment="right"
                  disabled={!recipientsCount || isSending}
                  onClick={bulkSendHandler}
                />
              </Box>
            )}
          </Flex>

          <Grid
            gridColumnGap={6}
            gridTemplateColumns={{base: "1fr", md: "minmax(auto, 380px) 1fr"}}
          >
            {showRecipientsOnMobile && (
              <MinimizedRecipientsList
                recipients={selectedRecipients}
                viewAllHandler={onOpen}
              />
            )}
            {!isMobile && (
              <RecipientsList
                recipients={selectedRecipients}
                // @ts-ignore
                removeRecipient={removeRecipient}
                onClick={openPersonalizationHandler}
              />
            )}
            <GridItem gridColumn={{md: "2/3"}} pb={10}>
              <TemplateTabs
                activeTabIndex={activeTabIndex}
                updateActiveTabIndex={updateActiveTabIndex}
              />
              {showRecipientsOnMobile && (
                <Box mt={4}>
                  <BulkSendButton
                    label={btnLabel}
                    isLoading={isSending}
                    disabled={!recipientsCount || isSending}
                    onClick={bulkSendHandler}
                  />
                </Box>
              )}
            </GridItem>

            {showRecipientsOnMobile && (
              <MobileRecipientsList
                isOpen={isOpen}
                onClose={onClose}
                recipients={selectedRecipients}
                // @ts-ignore
                removeRecipient={removeRecipient}
                onClick={openPersonalizationHandler}
              />
            )}
          </Grid>
        </Box>
      </InnerAppLayout>
    </AppLayout>
  );
};

export default Personalization;
