"use strict";
exports.__esModule = true;
exports.isLiteralObjectType = exports.isLiteralType = void 0;
/**
 * Type guard helper for literal types.
 *
 * @example
 * ```ts
 * // Define a literal type.
 * export const myItems = ["foo", "bar"] as const;
 * export type  MyItem = typeof myItems[number];
 *
 * // Then create a type guard.
 * export const isMyItem = isLiteralType<MyItem>(myItems);
 * ```
 */
var isLiteralType = function (list) {
    return function (arg) {
        return typeof arg === "string" && list.includes(arg);
    };
};
exports.isLiteralType = isLiteralType;
/**
 * Type guard helper for literal object types.
 *
 * @example
 * ```ts
 * // Define a literal type.
 * export const MY_ITEM = { foo: "foo", bar: "bar" } as const satisfies Record<any, any>
 * export type  MyItem = typeof myItems[keyof typeof MY_ITEM];
 *
 * // Then create a type guard.
 * export const isMyItem = isLiteralObjectType<MyItem>(MY_ITEM);
 * ```
 */
var isLiteralObjectType = function (list) {
    return function (arg) {
        return !!list && typeof list === "object" && Object.values(list).includes(arg);
    };
};
exports.isLiteralObjectType = isLiteralObjectType;
