import {Platform} from '../../integration/types';

export const buildTwitterProfileLink = (platformUserId: string): string =>
  `https://twitter.com/i/user/${platformUserId}`;

export const buildInstagramProfileLink = (platformUsername: string): string =>
  `https://www.instagram.com/${platformUsername}`;

export const buildProfileLinkBasedOnPlatform = ({
  platform,
  friendTwitterId,
  platformUsername,
}: {
  platform: string;
  friendTwitterId?: string;
  platformUsername?: string;
}): string => {
  if (platform === Platform.Instagram && platformUsername) {
    return buildInstagramProfileLink(platformUsername);
  }

  if (platform === Platform.Twitter && friendTwitterId) {
    return buildTwitterProfileLink(friendTwitterId);
  }

  return '';
};

export const buildLinkTitleBasedOnPlatform = (platform: string): string => {
  // can add username later
  return `Go to ${
    (platform === Platform.Instagram && 'Instagram') ||
    (platform === Platform.Twitter && 'Twitter')
  } profile`;
};
