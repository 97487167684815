import {Checkbox, CheckboxProps} from '@chakra-ui/react';
import React from 'react';

const DsmCheckbox: React.FC<CheckboxProps & {checkboxColor?: string}> = ({
  checkboxColor = 'gray.500',
  ...props
}) => (
  <Checkbox
    sx={{
      '.chakra-checkbox__control': {
        borderColor: checkboxColor,
        _focus: {
          outline: 'none',
          boxShadow: 'none',
          borderColor: 'primary.500',
          borderWidth: '1px',
        },
        _disabled: {
          borderColor: 'gray.200',
        },
        '&[data-checked]': {
          borderColor: 'transparent',
        },
        '.chakra-checkbox__label': {
          fontSize: 'base',
        },
      },
    }}
    {...props}
  />
);

export default DsmCheckbox;
