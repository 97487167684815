import {Operation} from "@workspace/models";
import {NonEmptyString, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";

export type CreateBillingPortalSession = Operation<
  void,
  {
    /**
     * The short-lived URL of the session that gives customers access to the customer portal.
     */
    url: NonEmptyString;
  }
>;

export const isCreateBillingPortalSessionOutput = objectTypeGuard<
  CreateBillingPortalSession["out"]
>(({url}) => isNonEmptyString(url));
