import {Button} from '@chakra-ui/react';
import {chakra} from '@chakra-ui/system';
import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {IoAdd} from 'react-icons/io5';

const StyledIoAdd = chakra(IoAdd);

interface AddTagButtonProps {
  containerId: string;
  isLabelShown: boolean;
  addTagHandler: () => void;
}

const AddTagButton: React.FC<AddTagButtonProps> = ({
  containerId,
  isLabelShown,
  addTagHandler,
}) => {
  const containerRef = useRef<Element | undefined>();
  const [key, setKey] = useState('0');

  useEffect(() => {
    if (!containerRef.current) {
      const parent = document.getElementById(`${containerId}`);

      if (parent) {
        containerRef.current = parent.querySelectorAll('.tagify')[0];
        setKey((Number(key) + 1).toString());
      }
    }
  }, [containerId, key]);

  if (!containerRef.current) return <></>;

  return ReactDOM.createPortal(
    <Button
      p={2}
      aria-label="Add new tag"
      w="auto"
      h="auto"
      minW="24px"
      leftIcon={<StyledIoAdd color="primary.500" size={12} mr={0} />}
      bgColor="transparent"
      fontSize="xs"
      fontWeight="regular"
      order={2}
      _focus={{outline: 'none'}}
      _hover={{
        bgColor: 'transparent',
      }}
      onClick={addTagHandler}
      sx={{
        '.chakra-button__icon': {
          mr: 0,
        },
      }}
    >
      {isLabelShown && (
        <chakra.span color="gray.500" ml={1}>
          Add tag
        </chakra.span>
      )}
    </Button>,
    containerRef.current,
    key
  );
};

export default AddTagButton;
