import {Flex, Avatar, Box, IconButton, chakra, Text} from '@chakra-ui/react';
import {decode} from 'html-entities';
import React from 'react';
import {IoBook, IoCloseCircleSharp} from 'react-icons/io5';

import getAvatarSrc from '../../conversation/helpers/get-avatar-src';
import {Friend} from '../../friend/types';

const StyledIoCloseCircleSharp = chakra(IoCloseCircleSharp);
const StyledIoBook = chakra(IoBook);

interface RecipientRowProps {
  recipient: Friend;
  isCurrent: boolean;
  isPersonalized: boolean;
  deleteHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClick?: () => void;
}

const RecipientRow: React.FC<RecipientRowProps> = ({
  recipient,
  isCurrent,
  isPersonalized,
  deleteHandler,
  onClick,
}) => (
  <Flex
    pl={4}
    pr={2}
    py={3}
    cursor="pointer"
    borderBottomColor="gray.150"
    borderBottomWidth="1px"
    alignItems="flex-start"
    tabIndex={0}
    bgColor={isCurrent ? 'gray.200' : 'transparent'}
    _hover={{bgColor: isCurrent ? 'gray.200' : 'gray.50'}}
    _active={{bgColor: 'gray.200'}}
    _last={{borderBottom: 'none'}}
    onClick={onClick}
  >
    <Avatar size="md" mr={3} src={getAvatarSrc(recipient.profileImageUrl)} />
    <Box flexGrow={1}>
      <Text fontSize="base">{decode(recipient.name)}</Text>
      <Text fontSize="baseMinor" color="gray.500">
        {recipient.username && `@${decode(recipient.username)}`}
      </Text>
    </Box>
    <StyledIoBook
      mt={3}
      size="16px"
      color={isPersonalized ? 'green.400' : 'gray.100'}
    />
    <IconButton
      aria-label="Remove a recipient"
      icon={
        <StyledIoCloseCircleSharp
          color="gray.150"
          size="24px"
          transition="color 0.2s ease"
        />
      }
      bgColor="transparent"
      _focus={{outline: 'none'}}
      _hover={{
        bgColor: 'transparent',
        svg: {
          color: 'red.500',
        },
      }}
      onClick={(e) => deleteHandler(e)}
    />
  </Flex>
);

export default RecipientRow;
