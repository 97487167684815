import {Flex, FlexProps} from "@chakra-ui/react";
import {FC} from "react";

export const CircularButton: FC<FlexProps & {isSelected?: boolean}> = ({isSelected, children, ...props}) => (
  <Flex
    color={isSelected ? "#FFFFFF" : "#0B0D0E"}
    backgroundColor={isSelected ? "#384047" : "transparent"}
    borderRadius="99px"
    px="4"
    py="2"
    fontSize="h6"
    fontFamily="Inter"
    fontStyle="normal"
    fontWeight={isSelected ? "500" : "normal"}
    lineHeight="20px"
    borderWidth="1px"
    flexDirection="column"
    rowGap="3"
    align="center"
    borderSize="2px"
    borderStyle="solid"
    borderColor={isSelected ? "#384047" : "rgba(11, 13, 14, 0.10)"}
    cursor={isSelected ? "auto" : "pointer"}
    {...props}
  >
    {children}
  </Flex>
);
