export enum ShortcutKeyKeyboardKey {
  NextConversation = 'j',
  PreviousConversation = 'k',
  MarkDone = 'e',
  MarkTodo = 'shift+e',
  AddTag = 't',
  ToggleShortcutKeysHelp = '?',
  MarkUnread = 'u',
  MarkRead = 'u',
  Reply = 'r',
  BackToNavigation = 'Esc',
  SendAndDone = 'command+enter',
}
