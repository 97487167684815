import {Avatar, Box, Button, Center, chakra, Spinner, Text,} from '@chakra-ui/react';
import {Heading} from "@workspace/ui";

import {unwrapResult} from '@reduxjs/toolkit';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';

import {useAppDispatch, useAppSelector} from '../../../app-hooks';
import routes from '../../../router/routes';
import LogoutButton from '../../auth/components/logout-button';
import {getFacebookPages, integrateInstagramAccount} from '../../user/thunks';
import {setIntegrationSuccess} from '../../user/user-slice';
import Layout from '../components/layout';
import {Platform} from '../types';

import {OutlinedBox} from 'src/components';

const StyledButton = chakra(Button, {
  baseStyle: {
    py: 2.5,
    px: 5,
    mb: 4,
    w: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    bgColor: 'transparent',
    borderWidth: '1px',
    borderColor: 'gray.200',
    borderRadius: '6px',
    _hover: {
      bgColor: 'transparent',
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
});

const FacebookIntegrationSuccess: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {integrationSuccess} = useAppSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState<string>();
  const {
    facebookPages,
    isFetchingFacebookPages,
    isIntegratingInstagramAccount,
  } = useAppSelector((state) => state.user);
  const facebookIntegrationId = useAppSelector(
    (state) =>
      state.user.user?.integrations.find(
        (integration) => integration.type === Platform.Facebook
      )?.id
  );

  const clickHandler = async (id: string) => {
    if (errorMessage) setErrorMessage(undefined);

    if (facebookIntegrationId) {
      try {
        const result = await dispatch(
          integrateInstagramAccount({facebookIntegrationId, facebookPageId: id})
        );
        unwrapResult(result);
        dispatch(setIntegrationSuccess(true));
      } catch (e) {
        history.push(`${routes.Integrations.instagramIntegrationFailure}`);
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (facebookIntegrationId) {
      dispatch(getFacebookPages(facebookIntegrationId));
    }
  }, [dispatch, facebookIntegrationId]);

  if (integrationSuccess) {
    return (
      <Layout>
        <Center pt="80px">
          <Spinner size="xl" />
        </Center>
      </Layout>
    );
  }

  return (
    <Layout>
      <OutlinedBox>
        <Heading as="h1" variant="h3" color="gray.900" mb={6}>
          Almost done
        </Heading>
        <Text mb={10}>Pick a Facebook page to integrate.</Text>
        {errorMessage && (
          <Box mb={3} fontSize="sm" color="red.500">
            {errorMessage}
          </Box>
        )}
        {isFetchingFacebookPages || isIntegratingInstagramAccount ? (
          <Spinner size="lg" />
        ) : (
          facebookPages &&
          facebookPages.length > 0 &&
          facebookPages.map((page) => (
            <StyledButton
              alignItems="center"
              key={page.id}
              onClick={() => clickHandler(page.id)}
            >
              <Avatar src={page.picture} mr={4} w="28px" h="28px" />
              <Box>
                <Text fontWeight={500} fontSize="sm" lineHeight="1.1">
                  {page.name}
                </Text>
                <Text fontSize="xxs" color="gray.500" lineHeight="1.1">
                  {page.username}
                </Text>
              </Box>
            </StyledButton>
          ))
        )}

        {/* If we resolve all promises but facebookPages still does not exist */}
        {!isFetchingFacebookPages &&
          !isIntegratingInstagramAccount &&
          !facebookPages?.length && (
            <Box>There are no connected Facebook pages</Box>
          )}
      </OutlinedBox>

      <Center mt={8}>
        <LogoutButton />
      </Center>
    </Layout>
  );
};

export default FacebookIntegrationSuccess;
