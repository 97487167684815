import {Flex, FlexProps} from "@chakra-ui/react";
import {FC} from "react";
import {Icon, IconKey} from "@workspace/ui";

export const Chip: FC<FlexProps & {isSelected?: boolean, icon?: IconKey}> = ({isSelected, icon, children, ...props}) => (
  <Flex
    color={isSelected ? "#FFFFFF" : "#0B0D0E"}
    backgroundColor={isSelected ? "#384047" : "transparent"}
    borderRadius="16px"
    px="4"
    py="6"
    fontSize="h6"
    fontFamily="Inter"
    fontStyle="normal"
    lineHeight="20px"
    borderWidth="1px"
    width="100%"
    flexDirection="column"
    rowGap="3"
    align="center"
    borderStyle="solid"
    borderColor={isSelected ? "#384047" : "rgba(11, 13, 14, 0.10)"}
    cursor={isSelected ? "auto" : "pointer"}
    {...props}
  >
    {icon && <Icon name={icon} size={32} color={isSelected ? "#FFFFFF": "#384047"}/>}
    {children}
  </Flex>
);
