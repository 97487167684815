import {LRUCache} from "@workspace/client-utils";
import {
  MAX_MESSAGE_TEMPLATE_TITLE_LENGTH,
  MAX_MESSAGE_TEMPLATES,
  MessageTemplate as MessageTemplateType
} from "@workspace/firebase-datamodel";
import {Badge, Box, Flex, Icon} from "@workspace/ui";
import {Spinner} from "@chakra-ui/react";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {FC, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState,} from "react";
import {isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {firebaseFunctions} from "@workspace/firebase-app";
import {
  StyledControl,
  StyledIndicatorsContainer,
  StyledMenu,
  StyledOption,
  StyledSelect,
  StyledSelectContainer,
  StyledSingleValue,
  StyledValueContainer,
} from "./styles";
import {CreateMessageTemplateModal} from "./CreateMessageTemplateModal";
import {DeleteMessageTemplateModal} from "./DeleteMessageTemplateModal";
import {
  FollowUpTemplateStateContext,
  MessageTemplateStateContext,
  ShadowRootContext,
  useDebounce
} from "@workspace/react";
import {TIME} from "@workspace/models";
import {DiscardChangesModal} from "./DiscardChangesModal";
import {MessageTemplateInput} from "./MessageTemplateInput";

export const MESSAGE_TEMPLATE_ID_KEY = "MESSAGE_TEMPLATE_ID";

export const messageTemplateLookup = new LRUCache<string>("MESSAGE_TEMPLATE");

const CREATE_NEW_TEMPLATE_MOCK_ID = "create new";

const MESSAGE_TEMPLATE_TEXT_CHANGE_DEBOUNCE = TIME.ONE_SECOND / 2;
const MESSAGE_TEMPLATE_SAVED_SHOW_TIME = TIME.THREE_SECONDS;

const MOCK_MESSAGE_TEMPLATE_ID = "mock-id";

type MessageTemplateOption = {
  label: MessageTemplateType["name"];
  value: MessageTemplateType["id"];
};

const isMessageTemplateOption = objectTypeGuard<MessageTemplateOption>(
  ({label, value}) =>
    isNonEmptyString(label) &&
    label.length <= MAX_MESSAGE_TEMPLATE_TITLE_LENGTH &&
    isNonEmptyString(value),
);

type MessageTemplateProps = {
  shouldShowDiscardModal?: boolean;
  disableCreateNewTemplate?: boolean;
  onMessageTemplateTextChange: (text: string) => void;
  messageTemplates?: MessageTemplateType[] | undefined;
  isFollowUp?: boolean;
  isFrontend?: boolean;
};

export const MessageTemplate: FC<MessageTemplateProps> = ({
  shouldShowDiscardModal,
  onMessageTemplateTextChange,
  messageTemplates,
  disableCreateNewTemplate,
  isFollowUp = false,
  isFrontend = false
}) => {
  const containerRef = useRef<HTMLElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const didSetMessageTemplateRef = useRef<boolean>(false);

  const Context = isFollowUp ? FollowUpTemplateStateContext : MessageTemplateStateContext;

  const shadowRoot = useContext(ShadowRootContext);

  const { messageTemplate, setMessageTemplate, messageTemplateData, setMessageTemplateData } = useContext(Context);

  const [showMessageTemplateSaved, setShowMessageTemplateSaved] =
    useState<boolean>(false);
  const [isCreating, setIsCreating] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState<MessageTemplateOption | false>(
    false,
  );
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [nextSelectedMessageTemplate, setNextSelectedMessageTemplate] =
    useState<MessageTemplateType | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<false | undefined>(undefined);

  const debouncedMessageTemplateData = useDebounce(
    messageTemplateData,
    MESSAGE_TEMPLATE_TEXT_CHANGE_DEBOUNCE,
  );

  const clearTimer = () => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };

  const options = useMemo(() => {
    const mainOptions =
      messageTemplates?.map((template) => ({
        value: template.id,
        label: template.name,
      })) ?? [];


    return (mainOptions.length < MAX_MESSAGE_TEMPLATES && !disableCreateNewTemplate)
      ? mainOptions.concat({
        label: "Create new...",
        value: CREATE_NEW_TEMPLATE_MOCK_ID,
      })
      : mainOptions;
  }, [messageTemplates, disableCreateNewTemplate]);

  const handleShowCreateNew = useCallback((e: MouseEvent) => {
    e.preventDefault();

    setShowCreateModal(true);
  }, []);

  const handleSetMessageTemplateText = useCallback(
    (text: string) => {
      setMessageTemplateData({
        ...messageTemplate,
        message: text,
      });
      onMessageTemplateTextChange(text);
    },
    [messageTemplate, setMessageTemplateData, onMessageTemplateTextChange],
  );

  const handleCreate = useCallback(async (templateName: string) => {
    try {
      setIsCreating(true);
      await firebaseFunctions.httpsCallable(cloudFunctionName.createMessageTemplate)({
        name: templateName,
        message: "",
        isFollowUp
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
      setShowCreateModal(false);
    }
  }, [isFollowUp]);

  const handleSetMessageTemplate = useCallback(
    (newMessageTemplate: MessageTemplateType) => {
      onMessageTemplateTextChange(newMessageTemplate.message);
      setMessageTemplateData(newMessageTemplate);
      setMessageTemplate(newMessageTemplate);

      messageTemplateLookup.set(MESSAGE_TEMPLATE_ID_KEY, newMessageTemplate.id);
    },
    [onMessageTemplateTextChange, setMessageTemplate, setMessageTemplateData],
  );

  const handleMessageTemplateChange = useCallback(
    (newMessageTemplateOption: unknown) => {
      if (!isMessageTemplateOption(newMessageTemplateOption)) return;

      const newMessageTemplate =
        messageTemplates?.find(
          (template) => template.id === newMessageTemplateOption.value,
        ) ?? null;
      if (!newMessageTemplate) return;

      if (shouldShowDiscardModal) {
        setShowDiscardModal(true);
        setNextSelectedMessageTemplate(newMessageTemplate);
        return;
      }

      handleSetMessageTemplate(newMessageTemplate);
    },
    [messageTemplates, shouldShowDiscardModal, handleSetMessageTemplate],
  );

  const handleConfirmDiscardChanges = useCallback(() => {
    if (nextSelectedMessageTemplate) {
      handleSetMessageTemplate(nextSelectedMessageTemplate);
      setNextSelectedMessageTemplate(null);
    }
    setShowDiscardModal(false);
  }, [nextSelectedMessageTemplate, handleSetMessageTemplate]);

  const handleDeleteConfirm = useCallback(async () => {
    if (isDeleting || !deletingTemplate) return;

    try {
      setIsDeleting(true);

      await firebaseFunctions.httpsCallable(cloudFunctionName.deleteMessageTemplate)({
        id: deletingTemplate.value,
        isFollowUp
      });
      setDeletingTemplate(false);
    } catch {
    } finally {
      setIsDeleting(false);
    }
  }, [deletingTemplate, isDeleting, isFollowUp]);

  const handleDelete = useCallback(
    (e: MouseEvent, msgTemplate: MessageTemplateOption) => {
      e.stopPropagation();

      setDeletingTemplate(msgTemplate);
    },
    [],
  );

  useEffect(() => {
    if (
      didSetMessageTemplateRef.current ||
      !messageTemplate ||
      messageTemplate.id === MOCK_MESSAGE_TEMPLATE_ID
    )
      return;
    didSetMessageTemplateRef.current = true;

    onMessageTemplateTextChange(messageTemplate.message);
    setMessageTemplateData(messageTemplate);
  }, [messageTemplate, onMessageTemplateTextChange, setMessageTemplateData]);

  useEffect(() => {
    if(isFrontend){
      // @ts-ignore
      containerRef.current = document.body;
    }else{
      const rootElement = shadowRoot?.getElementById('howdy-slide') as HTMLDivElement;
      if(rootElement){
        // @ts-ignore
        containerRef.current = rootElement;
      }
    }
  }, [isFrontend, shadowRoot]);

  useEffect(() => {
    if (
      messageTemplate?.id !== debouncedMessageTemplateData?.id ||
      messageTemplate?.message === debouncedMessageTemplateData?.message ||
      isSaving
    )
      return;

    const saveMessageTemplate = async () => {
      try {
        setIsSaving(true);
        setShowMessageTemplateSaved(false);
        clearTimer();

        await firebaseFunctions.httpsCallable(cloudFunctionName.updateMessageTemplate)({
          id: messageTemplate.id,
          message: debouncedMessageTemplateData.message,
          isFollowUp
        });

        setMessageTemplate({
          ...messageTemplate,
          message: debouncedMessageTemplateData.message,
        });

        setShowMessageTemplateSaved(true);
      } finally {
        setIsSaving(false);
      }
    };

    saveMessageTemplate();
  }, [isSaving, debouncedMessageTemplateData, messageTemplate, setMessageTemplate, isFollowUp]);

  useEffect(() => {
    if (showMessageTemplateSaved) {
      clearTimer();

      timeoutRef.current = setTimeout(() => {
        setShowMessageTemplateSaved(false);
      }, MESSAGE_TEMPLATE_SAVED_SHOW_TIME);
    }

    return () => {
      clearTimer();
    };
  }, [showMessageTemplateSaved]);

  return (
    <Box>
      <CreateMessageTemplateModal
        isLoading={isCreating}
        isOpen={showCreateModal}
        onClose={() => !isCreating && setShowCreateModal(false)}
        onConfirm={handleCreate}
        portalProps={{
          containerRef,
        }}
      />
      {deletingTemplate && (
        <DeleteMessageTemplateModal
          isLoading={isDeleting}
          name={deletingTemplate.label}
          onClose={() => !isDeleting && setDeletingTemplate(false)}
          onConfirm={handleDeleteConfirm}
          portalProps={{
            containerRef,
          }}
          isOpen
        />
      )}

      <DiscardChangesModal
        onClose={() => setShowDiscardModal(false)}
        onConfirm={handleConfirmDiscardChanges}
        isOpen={showDiscardModal}
        portalProps={{
          containerRef,
        }}
      />

      <Flex mb="3" alignItems="center" gap="1">
        <StyledSelect
          height="24px"
          value={messageTemplate?.id}
          options={options}
          menuIsOpen={!!deletingTemplate || isMenuOpen}
          onChange={handleMessageTemplateChange}
          components={{
            Control: (props) => (
              <StyledControl {...props}>
                <Flex alignItems="center" fontWeight="bold">
                  <Icon name="template" size="20"/>
                  {props.children}
                </Flex>
              </StyledControl>
            ),
            SingleValue: ({data, ...props}) => (
              <StyledSingleValue data={data} {...props}>
                {isMessageTemplateOption(data) ? data.label : props.children}
              </StyledSingleValue>
            ),
            Option: ({data, innerProps, ...props}) => {
              if (!isMessageTemplateOption(data))
                return <StyledOption data={data} innerProps={innerProps} {...props} />;

              const isCreateNew = data.value === CREATE_NEW_TEMPLATE_MOCK_ID;
              const isSelected = data.value === messageTemplate.id;
              const isDefaultTemplate = messageTemplates?.find(template => template.id === data.value)?.isDefaultTemplate;

              return (
                <Box data-group="group">
                  <StyledOption
                    data={data}
                    bg="white"
                    _hover={
                      isSelected ? {bg: "white", cursor: "default"} : {bg: "alpha.05"}
                    }
                    {...props}
                    innerProps={{
                      ...innerProps,
                      onClick: isCreateNew ? handleShowCreateNew : innerProps.onClick,
                    }}
                  >
                    <Flex justifyContent="space-between" alignItems="center" gap="3">
                      <Flex gap="2" alignItems="center">
                        <Box color={isSelected ? "primary.400" : "gray.300"}>
                          <Icon name={isCreateNew ? "plus" : "template"} size="20"/>
                        </Box>
                        <Box color={isSelected ? "primary.500" : "gray.900"} textOverflow="ellipsis" maxWidth="180px">
                          {data.label}
                        </Box>
                      </Flex>
                      {!isSelected && !isCreateNew && !isDefaultTemplate && (
                        <Box
                          display="none"
                          _groupHover={{
                            display: "block",
                          }}
                          color="gray.300"
                          _hover={{
                            color: "red.500",
                          }}
                          onClick={(e) => handleDelete(e, data)}
                        >
                          <Icon name="trash" size="20"/>
                        </Box>
                      )}
                    </Flex>
                  </StyledOption>
                </Box>
              );
            },
            ValueContainer: StyledValueContainer,
            IndicatorsContainer: StyledIndicatorsContainer,
            SelectContainer: StyledSelectContainer,
            Menu: StyledMenu,
          }}
        />
        {isSaving && (
          <Badge variant="passive" _hover={{}}>
            <Flex alignItems="center" gap="1">
              Saving <Spinner size="xs"/>
            </Flex>
          </Badge>
        )}

        {!isSaving && showMessageTemplateSaved && <Badge variant="passive">Saved</Badge>}
      </Flex>
      <MessageTemplateInput
        value={messageTemplateData.message}
        onChange={handleSetMessageTemplateText}
        onBlur={() => setIsMenuOpen(undefined)}
        onFocus={() => setIsMenuOpen(false)}
      />
    </Box>
  );
};
