import {Box, Flex, Heading, Image, Text} from "@chakra-ui/react";
import {Icon} from "@workspace/ui";
import {FC} from "react";
import {ReactComponent as LogoWhite} from "../assets/icons/logo-white.svg";
import authBannerIllustrationImage from "../assets/images/auth-banner-illustration.png";
import noiseImage from "../assets/images/auth-banner-noise.png";

type AuthBannerFeature = {title: string; description: string};

const AUTH_BANNER_FEATURES = [
  {
    title: "Generate a list of target prospects in seconds",
    description: "Reach customers where they’re most passionate and engaged",
  },
  {
    title: "Integrate your list of users",
    description: "Upload your .csv files with curated list of users",
  },
  {
    title: "Flood inbox with booked appointments",
    description: "Craft, schedule, and send dozens of personalized DMs in minutes",
  },
] as const satisfies readonly AuthBannerFeature[];

const AuthBannerFeatureItem: FC<AuthBannerFeature> = ({title, description}) => (
  <Flex>
    <Box mr="2.5" color="primary.300">
      <Icon name="checkAlt" size="20px" />
    </Box>
    <Box>
      <Heading as="h4" fontSize="h4" color="white" fontWeight="bold" mb="1">
        {title}
      </Heading>
      <Text fontSize="h6" color="white" opacity="0.6">
        {description}
      </Text>
    </Box>
  </Flex>
);

export const AuthBanner: FC = () => {
  return (
    <Flex
      pt="96px"
      bg="black"
      h="100%"
      flexDir="column"
      justifyContent="space-between"
      overflow="hidden"
      position="relative"
    >
      <Box position="absolute" top="0" left="0" w="100%" h="100vh" opacity="0.3">
        <Image src={noiseImage} alt="noise" width="100%" h="100%" />
      </Box>
      <Box
        px={{
          base: "20px",
          lg: "48px",
          xl: "96px",
        }}
      >
        <Box mb="88px">
          <LogoWhite />
        </Box>
        <Flex flexDir="column" gap="10" position="relative" zIndex="2">
          {AUTH_BANNER_FEATURES.map((feature) => (
            <AuthBannerFeatureItem key={feature.title} {...feature} />
          ))}
        </Flex>
      </Box>
      <Flex justifyContent="center" position="relative">
        <Image
          src={authBannerIllustrationImage}
          alt="illustration"
          zIndex="2"
          maxWidth="400px"
          position="relative"
        />
        <Box
          filter="blur(160px)"
          position="absolute"
          bg="#512FB2"
          w="60%"
          h="100%"
          bottom="0"
          left="-30px"
        />
        <Box
          filter="blur(160px)"
          position="absolute"
          bg="#2F35B2"
          w="60%"
          h="100%"
          bottom="0"
          right="-30px"
        />
      </Flex>
    </Flex>
  );
};
