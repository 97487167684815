// Number of chars to add to the length of templateClue to move cursor at its end
// It includes next characters, which are added to templateClue: {{}} and ' '
export const CHARACTERS_TO_TEMPLATE_CLUE = 5;

const getMessagePart = (
  messageTemplate: string,
  startPosition: number,
  endPosition: number,
): string => messageTemplate.slice(startPosition, endPosition).trim() ?? "";

export const updateTemplateString = (
  messageTemplateText: string,
  templateClue: string,
  positionToInsert: number,
): string => {
  const messageBeforeTemplateClue = getMessagePart(
    messageTemplateText,
    0,
    positionToInsert,
  );
  const messageAfterTemplateClue = getMessagePart(
    messageTemplateText,
    positionToInsert,
    messageTemplateText.length,
  );

  // Slice is done to remove the '/' character at the end of the string
  return `${messageBeforeTemplateClue.slice(
    0,
    messageBeforeTemplateClue.length - 1,
  )} {{${templateClue}}} ${messageAfterTemplateClue}`.trimStart();
};
