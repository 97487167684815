"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.newCampaignQueue = exports.filterMessagesToFitInterval = exports.nextActiveCampaign = exports.isActiveScheduler = exports.isCampaignsQueue = exports.COOL_DOWN_PERIOD = exports.COOL_DOWN_WINDOW = exports.MAX_MESSAGES_BEFORE_COOL_DOWN = void 0;
var type_utils_1 = require("@workspace/type-utils");
var Campaign_1 = require("./Campaign");
var time_1 = require("./time");
var models_1 = require("@workspace/models");
var date_fns_1 = require("date-fns");
exports.MAX_MESSAGES_BEFORE_COOL_DOWN = 60;
exports.COOL_DOWN_WINDOW = time_1.TIME.ONE_HOUR * 14;
exports.COOL_DOWN_PERIOD = time_1.TIME.ONE_HOUR * 1.5;
var isRecentMessageTimestampsArray = (0, type_utils_1.arrayTypeGuard)(time_1.isTime);
exports.isCampaignsQueue = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var activeSchedulerId = _a.activeSchedulerId, whenUpdated = _a.whenUpdated, coolDownTill = _a.coolDownTill, recentMessageTimestamps = _a.recentMessageTimestamps;
    return (0, time_1.isUpdateTime)({ whenUpdated: whenUpdated }) &&
        (activeSchedulerId === undefined
            ? true
            : (0, type_utils_1.isNonEmptyString)(activeSchedulerId)) &&
        (coolDownTill === undefined ? true : (0, time_1.isTime)(coolDownTill)) &&
        recentMessageTimestamps === undefined ? true : isRecentMessageTimestampsArray(recentMessageTimestamps);
});
var isActiveScheduler = function (schedulerId, activeSchedulerId) {
    // There is no current active scheduler, input `activeSchedulerId` becomes active,
    if (activeSchedulerId === undefined)
        return true;
    // otherwise check if ids are the same.
    return activeSchedulerId === schedulerId;
};
exports.isActiveScheduler = isActiveScheduler;
var nextActiveCampaign = function (_a) {
    var campaigns = _a.campaigns, instagramId = _a.instagramId;
    // First campaign with a "next message" found is next active campaign.
    return campaigns.find(function (campaign) { return (campaign.instagramId === instagramId) && !campaign.isDeleted && !!(0, Campaign_1.nextCampaignMessage)(campaign); });
};
exports.nextActiveCampaign = nextActiveCampaign;
var filterMessagesToFitInterval = function (recentMessageTimestamps) {
    return recentMessageTimestamps.filter(function (time) {
        var cooldownStart = (0, date_fns_1.sub)(new Date(), {
            seconds: exports.COOL_DOWN_WINDOW / 1000
        });
        return (0, date_fns_1.isAfter)(new Date(time), cooldownStart);
    });
};
exports.filterMessagesToFitInterval = filterMessagesToFitInterval;
var newCampaignQueue = function () { return (__assign(__assign({}, (0, models_1.updatedNow)()), { coolDownTill: undefined, recentMessageTimestamps: [] })); };
exports.newCampaignQueue = newCampaignQueue;
