import {isSocialNetworkUser, Operation, SocialNetworkHandle, SocialNetworkUser,} from "@workspace/models";
import {arrayTypeGuard, isNonEmptyString, objectTypeGuard} from "@workspace/type-utils";

export type ReadInstagramUser = Operation<SocialNetworkHandle, SocialNetworkUser | null>;

export type ReadInstagramUsers = Operation<SocialNetworkHandle, Array<SocialNetworkUser>>;

export const isReadInstagramUserOutput = objectTypeGuard<
  Exclude<ReadInstagramUser["out"], null>
>((socialNetworkUser) => isSocialNetworkUser(socialNetworkUser));

export type WriteInstagramUser = Operation<SocialNetworkUser, void>;

export const isWriteInstagramUserInput = objectTypeGuard<WriteInstagramUser["in"]>(
  (socialNetworkUser) => isSocialNetworkUser(socialNetworkUser),
);


export type ScrapeInstagramUser = Operation<{ handles: Array<SocialNetworkHandle>, session: string, shouldDownloadAvatar?: boolean }, Record<string, SocialNetworkUser>>

export const isScrapeInstagramUserInput = objectTypeGuard<ScrapeInstagramUser['in']>(
  ({ handles, session}) => {
    return arrayTypeGuard<SocialNetworkHandle>(isNonEmptyString)(handles) && isNonEmptyString(session)
  }
)

export const isScrapeInstagramUserOutput = objectTypeGuard<ScrapeInstagramUser['out']>(
  (scrapedUsers) => {
    return Object.values(scrapedUsers).every(isSocialNetworkUser)
  })

export type GetInstagramUserByID = Operation<{ userId: string, session: string }, SocialNetworkUser | null>

export const isGetInstagramUserByIDInput = objectTypeGuard<GetInstagramUserByID['in']>(
  ({ userId, session}) => {
    return isNonEmptyString(userId) && isNonEmptyString(session)
  })

export type UploadInstagramAvatar = Operation<{ instagramId: string, avatarUrl: string }, string | null>

export const isUploadInstagramAvatarInput = objectTypeGuard<UploadInstagramAvatar['in']>(
  ({ instagramId, avatarUrl}) => {
    return isNonEmptyString(instagramId) && isNonEmptyString(avatarUrl)
  })
