"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.isGetLeadsCampaignOutput = exports.isFetchUnprocessedLeadsOutput = exports.isSaveUnprocessedLeadsInput = exports.isListCampaign = exports.isFetchUserLeadsOutput = exports.isCreateListOutput = exports.isFetchUserListsOutput = exports.isFetchUserLeadsSlug = exports.isRemovedLeadsFromListInput = exports.isNonEmptyStringArray = exports.isGetLeadsCampaignInput = exports.isCreateCampaignFromLeadsInput = exports.isAddLeadsToListInput = exports.isCreateListInput = exports.isFetchUserListBySlugInput = exports.isValidList = exports.isValidNewLeads = exports.isValidNewLead = exports.isValidLeads = exports.isValidLead = void 0;
var type_utils_1 = require("@workspace/type-utils");
exports.isValidLead = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var name = _a.name, handle = _a.handle, followers = _a.followers, following = _a.following, posts = _a.posts, id = _a.id;
    return typeof name === "string" &&
        (0, type_utils_1.isNonEmptyString)(handle) &&
        (0, type_utils_1.isNonEmptyString)(id) &&
        (0, type_utils_1.isNonNegativeInteger)(followers) &&
        (0, type_utils_1.isNonNegativeInteger)(following) &&
        (0, type_utils_1.isNonNegativeInteger)(posts);
});
exports.isValidLeads = (0, type_utils_1.arrayTypeGuard)(exports.isValidLead);
exports.isValidNewLead = (0, type_utils_1.objectTypeGuard)(function (newLead) { return (0, exports.isValidLead)(__assign(__assign({}, newLead), { bio: "", searchString: "" })); });
exports.isValidNewLeads = (0, type_utils_1.arrayTypeGuard)(exports.isValidNewLead);
exports.isValidList = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var name = _a.name, userId = _a.userId, whenCreated = _a.whenCreated, id = _a.id;
    return (0, type_utils_1.isNonEmptyString)(name) &&
        (0, type_utils_1.isNonEmptyString)(userId) &&
        (0, type_utils_1.isNonEmptyString)(id) &&
        (0, type_utils_1.isNonNegativeInteger)(whenCreated);
});
exports.isFetchUserListBySlugInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var slug = _a.slug;
    return (0, type_utils_1.isNonEmptyString)(slug);
});
exports.isCreateListInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var name = _a.name;
    return (0, type_utils_1.isNonEmptyString)(name);
});
exports.isAddLeadsToListInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var listId = _a.listId, leads = _a.leads;
    return (0, type_utils_1.isNonEmptyString)(listId) && (0, exports.isValidNewLeads)(leads);
});
exports.isCreateCampaignFromLeadsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var slug = _a.slug, leads = _a.leads;
    return (0, type_utils_1.isNonEmptyString)(slug) && (0, exports.isValidLeads)(leads);
});
exports.isGetLeadsCampaignInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var campaignId = _a.campaignId;
    return (0, type_utils_1.isNonEmptyString)(campaignId);
});
exports.isNonEmptyStringArray = (0, type_utils_1.arrayTypeGuard)(type_utils_1.isNonEmptyString);
exports.isRemovedLeadsFromListInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var listId = _a.listId, leadIds = _a.leadIds;
    return (0, type_utils_1.isNonEmptyString)(listId) && (0, exports.isNonEmptyStringArray)(leadIds);
});
exports.isFetchUserLeadsSlug = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var slug = _a.slug;
    return (0, type_utils_1.isNonEmptyString)(slug);
});
exports.isFetchUserListsOutput = (0, type_utils_1.arrayTypeGuard)(exports.isValidList);
exports.isCreateListOutput = (0, type_utils_1.objectTypeGuard)(exports.isValidList);
exports.isFetchUserLeadsOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var list = _a.list, leads = _a.leads;
    return ((0, exports.isValidList)(list) && (0, exports.isValidLeads)(leads));
});
exports.isListCampaign = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var targets = _a.targets, listId = _a.listId, userId = _a.userId, id = _a.id;
    return (0, exports.isValidLeads)(targets) && (0, type_utils_1.isNonEmptyString)(listId) && (0, type_utils_1.isNonEmptyString)(userId) && (0, type_utils_1.isNonEmptyString)(id);
});
exports.isSaveUnprocessedLeadsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var leads = _a.leads, listId = _a.listId;
    return (0, exports.isValidLeads)(leads) && (0, type_utils_1.isNonEmptyString)(listId);
});
exports.isFetchUnprocessedLeadsOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var leads = _a.leads, listId = _a.listId;
    return (0, exports.isValidLeads)(leads) && (0, type_utils_1.isNonEmptyString)(listId);
});
exports.isGetLeadsCampaignOutput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var campaign = _a.campaign, list = _a.list;
    return (0, exports.isListCampaign)(campaign) && (0, exports.isValidList)(list);
});
