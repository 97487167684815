"use strict";
exports.__esModule = true;
exports.monitoringEmails = void 0;
exports.monitoringEmails = [
    /enxhi/,
    "nino.gorgiashvili@pubgenius.io",
    "nina.gorgiashvili@yahoo.com",
    "erol@dsm.network",
    "brandon@sayhowdy.co",
    "deyeminifemi@gmail.com",
];
