import {Box, Flex, Image, Text} from "@chakra-ui/react";
import {FC} from "react";
import InnerAppLayout from "src/components/inner-app-layout";
import {BlurredBackground} from "src/components/BlurredBackground";
import PendingScreenGif from "../../assets/images/discovery-pending.gif";
import {colorName} from "@workspace/ui";

export const PendingScreenAnimation: FC<{isIdle?: boolean}> = () => {
  return (
    <InnerAppLayout hasBasicHeader showTrialInfo>
      <Flex pt="76px" justifyContent="center" fontFamily="Inter">
        <Box position="relative">
          <BlurredBackground colors={["#E1306C", "#1DA1F2"]} />
          <Flex
            m="8"
            p="16"
            zIndex="2"
            position="relative"
            borderRadius="16px"
            boxShadow="0px 1px 3px 0px #0A094421;0px 3px 14px 0px #0A094408;0px 8px 32px 0px #0A094412;0px 30px 84px 0px #0A094414;"
            backgroundColor={colorName.white}
            alignItems="center"
            flexDirection="column"
          >
            <Box mt={8} mb="8">
              <Image width="256px" src={PendingScreenGif} alt="Pending animation" />
            </Box>
            <Text fontSize="h2" color="gray.900" mb="2" fontFamily="Cabinet Grotesk" fontWeight="800">
              Come back tomorrow
            </Text>
            <Text textAlign="center" fontSize="h6" color="gray.900">
              We’re working on getting you fresh leads, and we’ll send you <br/>
              an email when they’re ready. Stay tuned!
            </Text>
          </Flex>
        </Box>
      </Flex>
    </InnerAppLayout>
  );
};
