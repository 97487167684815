import {Box, chakra, Heading} from '@chakra-ui/react';
import React from 'react';

import convertTemplateToPreview from '../helpers/convert-template-to-preview';

interface TemplatePreviewProps {
  template: string;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({template}) => {
  return (
    <>
      <Heading as="h2" px={5} py={3} mb={2} fontSize="base" fontWeight="normal">
        Message Preview
      </Heading>
      <Box borderRadius="6px" borderWidth="1px" borderColor="gray.200">
        <chakra.pre
          p={3}
          minH="150px"
          maxH="220px"
          overflowY="auto"
          wordBreak="break-all"
          whiteSpace="pre-wrap"
          fontSize="baseMinor"
          color="gray.700"
          fontFamily="body"
        >
          {convertTemplateToPreview(template)}
        </chakra.pre>
      </Box>
    </>
  );
};

export default TemplatePreview;
