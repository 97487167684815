export enum Platform {
  Twitter = 'twitter',
  Facebook = 'facebook',
  Instagram = 'instagram',
}

export type Integration = {
  externalUserId: string;
  id: string;
  type: Platform;
  username: string;
  autoresponder: {
    enabled: boolean;
    message: string;
  };
  isSubscribedToWebhooks?: boolean;
  shouldReAuth?: boolean;
};

export type FacebookPage = {
  id: string;
  name: string;
  username: string;
  picture: string;
};
