import {Friend} from '../../friend/types';

import {TemplateVariable} from './helpers-variables';

const convertTemplateToPreview = (
  template: string,
  recipient?: Friend
): string => {
  const name = recipient ? recipient.name || '' : 'Annette Black';
  const firstName = recipient
    ? recipient.name?.split(' ', 1)[0] || ''
    : 'Annette';
  const location = recipient
    ? recipient.location || ''
    : 'San Francisco, Bay Area';

  return template
    .replaceAll(TemplateVariable.NAME, name)
    .replaceAll(TemplateVariable.FIRST_NAME, firstName)
    .replaceAll(TemplateVariable.LOCATION, location);
};

export default convertTemplateToPreview;
