import {SocialNetwork} from "@workspace/models";

export const collectionKeys = [
  "instagram",
  "instagramCampaignsQueue",
  "instagramConversation",
  "instagramThreads",
  "twitter",
  "twitterCampaignsQueue",
  "twitterConversation",
  "messagesDailyCount",
  "pricingPlans",
  "subscriptions",
  "stripeCustomers",
  "stripeSubscriptions",
  "subscriptionsHistory",
  "users",
  "zapierWebhooks",
  "commissions",
  "scheduledMessagesDailyCount",
  "discoveriesQueue",
  "followUpMessagesQueue",
  "userLogs",
  "discoveries",
  "blockedTargets",
  "discoveryReports",
  "monitoring",
  "lists",
  "prospectiveListCampaigns",
] as const;

export type CollectionKey = (typeof collectionKeys)[number];

export const collectionName: Record<CollectionKey, string> = {
  instagram: "instagram",
  instagramConversation: "instagramConversation",
  instagramThreads: "instagramThreads",
  twitter: "twitter",
  twitterConversation: "twitterConversation",
  messagesDailyCount: "messagesDailyCount",
  pricingPlans: "pricing_plans",
  subscriptions: "subscriptions",
  subscriptionsHistory: "subscriptionsHistory",
  stripeCustomers: "stripeCustomers",
  stripeSubscriptions: "stripeSubscriptions",
  users: "users",
  twitterCampaignsQueue: "twitterCampaignsQueue",
  instagramCampaignsQueue: "instagramCampaignsQueue",
  zapierWebhooks: "zapierWebhooks",
  commissions: "commissions",
  scheduledMessagesDailyCount: "scheduledMessagesDailyCount",
  userLogs: "userLogs",
  discoveriesQueue: "discoveriesQueue",
  followUpMessagesQueue: "followUpMessagesQueue",
  discoveries: "discoveries",
  blockedTargets: "blockedTargets",
  discoveryReports: "discoveryReports",
  monitoring: "monitoring",
  lists: "lists",
  prospectiveListCampaigns: "prospectiveListCampaigns",
};

export const conversationCollectionName = (socialNetwork: SocialNetwork): string => {
  const collectionRecord: Record<SocialNetwork, string> = {
    Instagram: collectionName.instagramConversation,
    Twitter: collectionName.twitterConversation,
  };
  return collectionRecord[socialNetwork];
};

export const socialNetworkCampaignsQueue = (socialNetwork: SocialNetwork): string => {
  const collectionRecord: Record<SocialNetwork, string> = {
    Instagram: collectionName.instagramCampaignsQueue,
    Twitter: collectionName.twitterCampaignsQueue,
  };
  return collectionRecord[socialNetwork];
};

export const socialNetworkUserCollectionName = (socialNetwork: SocialNetwork): string => {
  const collectionRecord: Record<SocialNetwork, string> = {
    Instagram: collectionName.instagram,
    Twitter: collectionName.twitter,
  };
  return collectionRecord[socialNetwork];
};
