export enum FirebaseErrorCode {
  AuthEmailAlreadyUsed = 'auth/email-already-in-use',
  AuthInvalidEmail = 'auth/invalid-email',
  AuthWeakPassword = 'auth/weak-password',
  AuthNotAllowed = 'auth/operation-not-allowed',
  AuthWrongPassword = 'auth/wrong-password',
  AuthUserNotFound = 'auth/user-not-found',
  AuthUserDisabled = 'auth/user-disabled',
  AuthTooManyRequests = 'auth/too-many-requests',
  AuthExpiredActionCode = 'auth/expired-action-code',
  AuthInvalidActionCode = 'auth/invalid-action-code',
}

export type ErrorLabelsMap = {
  [code: string]: string | JSX.Element;
};
