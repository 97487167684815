"use strict";
exports.__esModule = true;
exports.twitterUserProfileUrl = exports.isTwitterUser = void 0;
var type_utils_1 = require("@workspace/type-utils");
var SocialNetwork_1 = require("./SocialNetwork");
exports.isTwitterUser = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, name = _a.name, profile_image_url_https = _a.profile_image_url_https, screen_name = _a.screen_name;
    return (0, type_utils_1.isNonEmptyString)(id) &&
        (0, type_utils_1.isNonEmptyString)(name) &&
        (0, type_utils_1.isNonEmptyString)(profile_image_url_https) &&
        (0, SocialNetwork_1.isSocialNetworkHandle)(screen_name);
});
var twitterUserProfileUrl = function (arg) {
    return "https://".concat(SocialNetwork_1.socialNetworkHostname["Twitter"], "/").concat(arg, "/");
};
exports.twitterUserProfileUrl = twitterUserProfileUrl;
