import {Box, Flex, Input, Spinner, Text} from "@chakra-ui/react";
import {Icon} from "@workspace/ui";
import React, {useState} from "react";
import {useHistory} from "react-router";
import {routes} from "src/router";
import {DsmSolidButton} from "../../../components/buttons";
import {sendResetPasswordLink} from "../auth-service";
import {AuthFormWrapper, AuthTitle, AuthWrapper, StyledLabel,} from "../components/auth-layout-components";

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [emailWasSent, setEmailWasSent] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const resetPasswordHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) return;

    setIsSending(true);

    try {
      await sendResetPasswordLink(email);
      setEmailWasSent(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <AuthWrapper>
      <AuthFormWrapper>
        <Flex flexDir="column" alignItems="center" mb="6">
          <Box mb="6">
            <Icon
              size={emailWasSent ? 64 : 48}
              name={emailWasSent ? "emailSent" : "lock"}
            />
          </Box>
          <AuthTitle title={emailWasSent ? "Check your email" : "Reset password"} />
          <Text color="gray.700" fontSize="baseMinor" textAlign="center">
            {emailWasSent
              ? `We sent an email to ${email}`
              : "Just enter the email address you registered with and we’ll send you a link to reset your password."}
          </Text>
        </Flex>

        {emailWasSent ? (
          <DsmSolidButton
            w="100%"
            type="button"
            fontSize="baseMinor"
            fontWeight="600"
            borderRadius="4px"
            disabled={isSending}
            onClick={() => {
              history.push(routes.Login.route);
            }}
          >
            Back to login
          </DsmSolidButton>
        ) : (
          <form onSubmit={resetPasswordHandler}>
            <StyledLabel htmlFor="email-input">Email</StyledLabel>
            <Input
              mb={6}
              pr="4.5rem"
              id="email-input"
              type="email"
              value={email}
              fontSize="base"
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            <DsmSolidButton
              w="100%"
              type="submit"
              fontSize="baseMinor"
              fontWeight="600"
              borderRadius="4px"
              disabled={isSending || !email}
            >
              {isSending && <Spinner size="sm" mr={3} />} Send password reset link
            </DsmSolidButton>
          </form>
        )}
      </AuthFormWrapper>
    </AuthWrapper>
  );
};

export default ForgotPassword;
