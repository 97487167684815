import {Box, Flex, Link, Text} from "@chakra-ui/react";
import React from "react";

import {ReactComponent as ChromeIcon} from "../../../assets/icons/chrome-icon.svg";
import {ReactComponent as ChromeColorfulIcon} from "../../../assets/icons/chrome-installation.svg";
import {DsmSolidButton} from "../../../components/buttons";
import InnerAppLayout from "../../../components/inner-app-layout";
import Layout from "../components/layout";
import {CHROME_EXTENSION_DOWNLOAD_URL} from "../helpers/constants";

import {BlurredBackground, OutlinedBox} from "src/components";
import {colorHex, Heading} from "@workspace/ui";

const ChromeExtInstallation: React.FC = () => {
  return (
    <InnerAppLayout hasBasicHeader showTrialInfo>
      <Layout gridTemplateColumns="1fr minmax(auto, 564px) 1fr">
        <Box position="relative" display="inline-block">
          <BlurredBackground colors={["#DF574B", "#FFCF48", "#22A565"]} />
          <OutlinedBox m="8" bg="white" zIndex="2" position="relative">
            <Flex mb="8" justifyContent="center">
              <ChromeColorfulIcon />
            </Flex>
            <Heading as="h1" variant="h3" color={colorHex.gray900} mb="4">
              Install Chrome Extension
            </Heading>
            <Text mb={8} color={colorHex.gray900} fontSize="baseMinor">
              Let’s start growing your newsletter, sponsorships, sales, or superstar hires
              through personalized DMs at scale
            </Text>
            <Link
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              href={CHROME_EXTENSION_DOWNLOAD_URL}
            >
              <DsmSolidButton
                backgroundColor={"green.500"}
                aria-label="Chrome Extension Download"
              >
                <ChromeIcon style={{marginRight: 10}} /> Install Chrome Extension
              </DsmSolidButton>
            </Link>
          </OutlinedBox>
        </Box>
      </Layout>
    </InnerAppLayout>
  );
};

export default ChromeExtInstallation;
