import {FlexProps, Flex} from "@chakra-ui/react";
import {ReactComponent as HowdyLoadingIcon} from "../assets/images/howdy-loading.svg";

import {FC} from "react";

export const GeneralLoading: FC<FlexProps> = (props) => (
  <Flex
    position="fixed"
    top="0"
    left="0"
    zIndex="9999"
    justifyContent="center"
    alignItems="center"
    w="100vw"
    h="100vh"
    {...props}
  >
    <HowdyLoadingIcon width="128px" height="128px" />
  </Flex>
);
