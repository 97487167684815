import {isValid, differenceInSeconds} from 'date-fns';

import {OptimisticUpdate} from '../types';

export function evictOptimisticUpdatesById(optimisticUpdatesById: {
  [id: string]: OptimisticUpdate;
}): {[id: string]: OptimisticUpdate} {
  // 5 seconds should be enough time for API calls to wrap up and there should
  // be no more need for an optimistic update as the conversations in the snapshot
  // should be up to date
  const ELAPSED_SECONDS_TO_EVICT = 5;
  const now = new Date();
  for (const id in optimisticUpdatesById) {
    const optimisticUpdates = optimisticUpdatesById[id];
    const previousUpdatedAt =
      optimisticUpdates.updatedAt && new Date(optimisticUpdates.updatedAt);

    // Check to see how old it is, if it's old, evict
    if (
      previousUpdatedAt && // needed for typing
      isValid(previousUpdatedAt) &&
      differenceInSeconds(now, previousUpdatedAt) > ELAPSED_SECONDS_TO_EVICT
    ) {
      // evict
      delete optimisticUpdatesById[id];
    }
  }

  return optimisticUpdatesById;
}
