"use strict";
exports.__esModule = true;
exports.socialNetworkCampaigns = exports.subcollectionName = exports.subcollectionKeys = void 0;
exports.subcollectionKeys = [
    "conversations",
    "instagramCampaigns",
    "instagramPendingThreads",
    "instagramFirstResponseThreads",
    "twitterCampaigns",
    "pendingMessages",
    "repliedMessages",
    "ignoredMessages",
    "discoveryTargets",
    "discoveries",
    "messages",
    "userLogs",
    "discoveryUsers",
    "leads"
];
exports.subcollectionName = {
    conversations: "conversations",
    instagramCampaigns: "instagramCampaigns",
    instagramPendingThreads: "instagramPendingThreads",
    instagramFirstResponseThreads: "instagramFirstResponseThreads",
    twitterCampaigns: "twitterCampaigns",
    pendingMessages: "pendingMessages",
    repliedMessages: "repliedMessages",
    ignoredMessages: "ignoredMessages",
    userLogs: "userLogs",
    discoveryTargets: "discoveryTargets",
    discoveries: "discoveries",
    messages: "messages",
    discoveryUsers: "discoveryUsers",
    leads: "leads"
};
var socialNetworkCampaigns = function (socialNetwork) {
    var subcollectionRecord = {
        Instagram: exports.subcollectionName.instagramCampaigns,
        Twitter: exports.subcollectionName.twitterCampaigns
    };
    return subcollectionRecord[socialNetwork];
};
exports.socialNetworkCampaigns = socialNetworkCampaigns;
