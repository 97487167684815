import {createAsyncThunk} from '@reduxjs/toolkit';

import 'firebase/functions';
import {dsmToast} from '../../components/dsm-toast';
import {HttpsCallableResult} from '../../services/firebase';
import {AppThunk} from '../../store/store';

import {
  markConversationStageOnCall,
  fetchConversations,
  fetchNewConversation,
  markConversationUnreadOnCall,
  updateConversationOnCall,
} from './conversations-service';
import buildConversationName from './helpers/build-conversation-name';
import {Conversation, ConversationStage} from './types';

export const setConversationsListener =
  ({userId}: {userId: string}): AppThunk =>
  async (dispatch, getState) =>
    await fetchConversations(dispatch, userId, getState);

export const getNewConversation = createAsyncThunk<
  Promise<Conversation>,
  {conversationId: string; userId: string}
>(
  'conversations/getNewConversation',
  async (data) => await fetchNewConversation(data)
);

export const markConversationStage = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {
    conversation: Conversation;
    stage: ConversationStage;
  }
>('conversations/markConversationStage', async ({conversation, stage}) => {
  const conversationName = buildConversationName(conversation);
  const successToastMessage =
    stage === ConversationStage.TODO
      ? `Marked ${conversationName} todo.`
      : `Marked ${conversationName} done.`;

  const closeSuccessToast = dsmToast(successToastMessage, 'success');

  try {
    return await markConversationStageOnCall({
      conversationId: conversation.id,
      stage,
    });
  } catch (e) {
    closeSuccessToast();

    const errorToastMessage =
      stage === ConversationStage.TODO
        ? `Unable to mark ${conversationName} todo. Please try again.`
        : `Unable to mark ${conversationName} done. Please try again.`;

    dsmToast(errorToastMessage, 'error');
    throw e;
  }
});

export const markConversationUnread = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {
    conversation: Conversation;
  }
>('conversations/markConversationUnread', async ({conversation}) => {
  const conversationName = buildConversationName(conversation);
  const closeSuccessToast = dsmToast(
    `Marked ${conversationName} unread.`,
    'success'
  );

  try {
    return await markConversationUnreadOnCall(conversation.id);
  } catch (e) {
    closeSuccessToast();
    dsmToast(
      `Unable to mark ${conversationName} unread. Please try again.`,
      'error'
    );
    throw e;
  }
});

export const updateBlockedStatus = createAsyncThunk<
  Promise<HttpsCallableResult>,
  {
    conversation: Conversation;
    isBlocked: boolean;
  }
>('conversations/updateBlockedStatus', async ({conversation, isBlocked}) => {
  const conversationName = buildConversationName(conversation);
  const successToastLabel = isBlocked
    ? `${conversationName} blocked`
    : `${conversationName} unblocked`;
  const closeSuccessToast = dsmToast(successToastLabel, 'success');

  try {
    return await updateConversationOnCall({
      conversationId: conversation.id,
      isBlocked,
    });
  } catch (e) {
    const failureToastLabel = isBlocked ? 'Block failed.' : 'Unblock failed.';
    closeSuccessToast();
    dsmToast(failureToastLabel, 'error');
    throw e;
  }
});
