import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {GeneralLoading} from "src/components";

interface ConditionalRouteProps {
  condition: boolean;
  path: string;
  loading?: boolean;
  redirectPath: string;
  redirectState?: Object;
}

const ConditionalRoute: React.FC<ConditionalRouteProps & RouteProps> = ({
  condition,
  redirectPath,
  children,
  loading,
  redirectState = {},
  ...rest
}) => (
  <Route
    //@ts-ignore
    render={({location}) =>
      loading ? (
        <GeneralLoading />
      ) : condition ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: redirectPath,
            state: {...redirectState, from: location.pathname},
          }}
        />
      )
    }
    {...rest}
  />
);

export default ConditionalRoute;
