import {TwitterFriendshipConnection} from '../../conversation/types';
import {Friend} from '../types';

export function buildConnectionsLabel(friend: Friend): string {
  const connections = friend.connections ?? [];

  switch (true) {
    case connections.includes(TwitterFriendshipConnection.FOLLOWED_BY) &&
      connections.includes(TwitterFriendshipConnection.FOLLOWING):
      return 'You follow each other';

    case connections.includes(TwitterFriendshipConnection.FOLLOWED_BY):
      return 'Follows you';

    default:
      return '';
  }
}
