import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

import {setActingUser} from '../acting-user/acting-user-slice';
import {resetApp, setUserDataError} from '../user/user-slice';

import {evictOptimisticUpdatesById} from './helpers/evict-optimistic-updates-by-id';
import {getInitialInboxOrder} from './helpers/get-initial-inbox-order';
import updateOptimisticUpdates from './helpers/update-optimistic-updates';
import {
  getNewConversation,
  markConversationStage,
  markConversationUnread,
  updateBlockedStatus,
} from './thunks';
import {
  Conversation,
  ConversationStage,
  ConversationStatus,
  InboxSortingOrder,
  OptimisticUpdate,
} from './types';

export interface ConversationState {
  conversationIds: string[];
  conversationsById: {[id: string]: Conversation};
  currentConversationId?: string;
  dbConversationsById: {[id: string]: Conversation}; // no optimistic updates
  loading: boolean;
  newConversationLoading: boolean;
  todoConversationsIds: string[];
  lastActivityTimestampToNotifyUser?: string;
  isChangingStageByConversationId: Record<string, boolean>;
  optimisticUpdatesById: {
    [id: string]: OptimisticUpdate;
  };
  tabId?: string;
  messageSoundKey: number;
  inboxSortingOrder: InboxSortingOrder;
}

export const initialState: ConversationState = {
  conversationsById: {},
  conversationIds: [],
  currentConversationId: '',
  dbConversationsById: {},
  // loading is set to true when conversations are added to state
  // (addConversations); while it won't set it to true if there are no
  // conversations, let's assume users will all have at least 1 conversation
  //
  // this isn't perfect as the "done" conversations may be loaded first,
  // prompting the "all done" state while in the todo tab, but it's better
  // than having the "all done" state pop up every time the app loads bc there
  // no todo conversations (yet) in state
  loading: true,
  newConversationLoading: false,
  todoConversationsIds: [],
  isChangingStageByConversationId: {},
  // Todo need to evict cache
  optimisticUpdatesById: {},
  messageSoundKey: 0,
  inboxSortingOrder: getInitialInboxOrder(),
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    addConversations: (state, action) => {
      state.loading = false;
      state.dbConversationsById = _.keyBy(action.payload, 'id');
      state.optimisticUpdatesById = evictOptimisticUpdatesById({
        ...state.optimisticUpdatesById,
      });
      state.conversationsById = _(action.payload) // using db conversations
        .map((conversation) => ({
          ...conversation,
          // optimistic updates, this means that any updates from another browser
          // will not take effect which is okay for now
          ...state.optimisticUpdatesById[conversation.id],
        }))
        .keyBy('id')
        .value();
      state.conversationIds = _.map(action.payload, 'id');
      state.todoConversationsIds = state.conversationIds.filter(
        (id) => state.conversationsById[id].stage === ConversationStage.TODO
      );
    },
    addNewConversation: (state, action) => {
      const {id} = action.payload;

      state.conversationsById = {
        ...state.conversationsById,
        [id]: {...action.payload, ...state.optimisticUpdatesById[id]},
      };
      state.conversationIds = _.uniq([id, ...state.conversationIds]);
      state.todoConversationsIds = state.conversationIds.filter(
        (id) => state.conversationsById[id].stage === ConversationStage.TODO
      );
    },
    clearConversations: (state) => {
      state.conversationsById = {};
      state.conversationIds = [];
    },
    setConversationBucket: (state, action) => {
      state.conversationsById[
        action.payload.currentConversationId
      ].attributes.bucket = action.payload.bucket;
    },
    playMessageSound: (state) => {
      state.messageSoundKey = state.messageSoundKey + 1;
    },
    setTabId: (state, action) => {
      state.tabId = action.payload;
    },
    onConversationOpen: (state, action) => {
      state.currentConversationId = action.payload;
    },
    clearCurrentConversationId: (state) => {
      state.currentConversationId = undefined;
    },
    setConversationsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLastActivityTimestampToNotifyUser: (state, action) => {
      state.lastActivityTimestampToNotifyUser = action.payload;
    },
    markAsRead: (state, action) => {
      const {conversationId} = action.payload;

      const optimisticUpdates = {
        ...state.optimisticUpdatesById[conversationId],
      };
      state.optimisticUpdatesById[conversationId] = updateOptimisticUpdates(
        optimisticUpdates,
        {
          status: ConversationStatus.READ,
        }
      );
      state.conversationsById[conversationId] = {
        ...state.dbConversationsById[conversationId], // use the latest version from db
        ...state.optimisticUpdatesById[conversationId],
      };
    },
    // see markMessageRead for details
    markAsReadRejected: (state, action) => {
      const {conversationId} = action.payload;

      if (
        state.optimisticUpdatesById[conversationId]?.status ===
        ConversationStatus.READ
      ) {
        delete state.optimisticUpdatesById[conversationId].status;
      }

      if (state.conversationsById[conversationId]) {
        state.conversationsById[conversationId].status =
          ConversationStatus.UNREAD;
      }
    },
    setInboxSortingOrder: (state, action) => {
      state.inboxSortingOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewConversation.pending, (state) => {
        state.newConversationLoading = true;
      })
      .addCase(getNewConversation.fulfilled, (state, action) => {
        state.newConversationLoading = false;
        conversationsSlice.caseReducers.addNewConversation(state, action);
      })
      .addCase(getNewConversation.rejected, (state) => {
        state.newConversationLoading = false;
      })
      .addCase(markConversationStage.pending, (state, action) => {
        const {conversation, stage} = action.meta.arg;

        state.isChangingStageByConversationId[conversation.id] = true;
        const optimisticUpdates = {
          ...state.optimisticUpdatesById[conversation.id],
        };
        state.optimisticUpdatesById[conversation.id] = updateOptimisticUpdates(
          optimisticUpdates,
          {stage}
        );

        state.dbConversationsById[conversation.id]
          ? (state.conversationsById[conversation.id] = {
              ...state.dbConversationsById[conversation.id], // use the latest version from db
              ...state.optimisticUpdatesById[conversation.id],
            })
          : (state.conversationsById[conversation.id] = {
              ...state.conversationsById[conversation.id],
              ...state.optimisticUpdatesById[conversation.id],
            });

        if (stage === ConversationStage.TODO) {
          state.todoConversationsIds = _.uniq([
            conversation.id,
            ...state.todoConversationsIds,
          ]);
        } else {
          // mark as done
          state.todoConversationsIds = state.todoConversationsIds.filter(
            (id) => id !== conversation.id
          );
        }
      })
      .addCase(markConversationStage.fulfilled, (state, action) => {
        const {conversation} = action.meta.arg;
        state.isChangingStageByConversationId[conversation.id] = false;
      })
      .addCase(markConversationStage.rejected, (state, action) => {
        const {conversation} = action.meta.arg;
        state.isChangingStageByConversationId[conversation.id] = false;
      })
      .addCase(markConversationUnread.pending, (state, action) => {
        const {id} = action.meta.arg.conversation;

        const optimisticUpdates = {
          ...state.optimisticUpdatesById[id],
        };
        state.optimisticUpdatesById[id] = updateOptimisticUpdates(
          optimisticUpdates,
          {
            status: ConversationStatus.UNREAD,
          }
        );

        state.conversationsById[id] = {
          ...state.dbConversationsById[id], // use the latest version from db
          ...state.optimisticUpdatesById[id],
        };
      })
      .addCase(markConversationUnread.fulfilled, (state, action) => {
        const id = action.meta.arg.conversation.id;
        if (
          state.optimisticUpdatesById[id]?.status === ConversationStatus.UNREAD
        ) {
          delete state.optimisticUpdatesById[id]?.status;
        }
      })
      .addCase(markConversationUnread.rejected, (state, action) => {
        const id = action.meta.arg.conversation.id;
        if (
          state.optimisticUpdatesById[id]?.status === ConversationStatus.UNREAD
        ) {
          delete state.optimisticUpdatesById[id]?.status;
        }
      })
      .addCase(updateBlockedStatus.pending, (state, action) => {
        const {id} = action.meta.arg.conversation;
        const isBlocked = action.meta.arg.isBlocked;
        state.conversationsById[id].isBlocked = isBlocked;
      })

      .addCase(updateBlockedStatus.rejected, (state, action) => {
        const {id} = action.meta.arg.conversation;
        const isBlocked = action.meta.arg.isBlocked;
        //if status is rejected, change isBlocked to opposite value
        state.conversationsById[id].isBlocked = !isBlocked;
      })
      .addCase(setActingUser, () => initialState)
      .addCase(setUserDataError, () => initialState)
      .addCase(resetApp, () => initialState);
  },
});

export const {
  addConversations,
  clearConversations,
  setConversationBucket,
  onConversationOpen,
  clearCurrentConversationId,
  setConversationsLoading,
  markAsRead,
  markAsReadRejected,
  setLastActivityTimestampToNotifyUser,
  playMessageSound,
  setTabId,
  setInboxSortingOrder,
} = conversationsSlice.actions;

export const reducer = conversationsSlice.reducer;
