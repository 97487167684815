"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.pubsubTopic = exports.pubsubTopics = void 0;
exports.pubsubTopics = [
    "generateDiscoveryPosts",
    "generateDiscoveryTargets",
    "validateDiscoveryTargets",
];
exports.pubsubTopic = exports.pubsubTopics.reduce(function (topics, topic) {
    var _a;
    return __assign(__assign({}, topics), (_a = {}, _a[topic] = topic, _a));
}, {});
