import {createSlice} from '@reduxjs/toolkit';
import {isEqual} from 'lodash';

import {Friend} from '../friend/types';
import {Message} from '../message/types';

import createFriendsQueryHashKey from './helpers/create-friends-query-hash-key';
import {searchFriends, searchMessages, searchTwitterUsers} from './thunks';
import {
  MessageSearchFilters,
  MessageSearchSortOrder,
  TwitterUserWithCanDm,
} from './types';

export interface MessageSearchResponse extends Message {
  name?: string;
  username?: string;
}

export interface SearchState {
  inbox: {
    result?: MessageSearchResponse[];
    filters?: MessageSearchFilters;
    sortOrder?: MessageSearchSortOrder;
    isSearching: boolean;
  };
  byFollowers: {
    queries: string[];
    results: Record<string, Friend[]>;
    isSearching: boolean;
  };
  byUsername: {
    queries: string[];
    results: Record<string, TwitterUserWithCanDm[]>;
  };
}

export const initialState: SearchState = {
  inbox: {
    isSearching: false,
  },
  byFollowers: {
    queries: [],
    results: {},
    isSearching: false,
  },
  byUsername: {
    queries: [],
    results: {},
  },
};

const saveFollowersQueryInCache = (
  cache: typeof initialState.byFollowers,
  query: string
) => {
  const {queries, results} = cache;

  if (queries.includes(query)) {
    const index = queries.findIndex((el) => el === query);
    // moving already cached query to the end of the list to make it the most recent
    cache.queries.push(cache.queries.splice(index, 1)[0]);
  } else {
    queries.push(query);

    if (queries.length > 10) {
      const evictedQuery = queries.shift();

      delete results[evictedQuery as string];
    }
  }

  return cache;
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearInboxSearchData: (state) => {
      state.inbox = {
        isSearching: false,
      };
    },
    clearFollowersSearchData: (state) => {
      state.byFollowers = {
        queries: [],
        results: {},
        isSearching: false,
      };
    },
    clearUsernameSearchData: (state) => {
      state.byUsername = {
        queries: [],
        results: {},
      };
    },
    setFilters: (state, action) => {
      state.inbox.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchMessages.pending, (state, action) => {
      state.inbox.isSearching = true;
      state.inbox.filters = action.meta.arg.filters;
      state.inbox.sortOrder = action.meta.arg.sortOrder;
    });
    builder.addCase(searchMessages.fulfilled, (state, action) => {
      const {messages} = action.payload;

      if (
        state.inbox.sortOrder == action.meta.arg.sortOrder &&
        isEqual(state.inbox.filters, action.meta.arg.filters)
      ) {
        state.inbox.result = messages;
        state.inbox.isSearching = false;
      }
    });
    builder.addCase(searchFriends.pending, (state, action) => {
      const searchData = action.meta.arg;
      const hashedQuery = createFriendsQueryHashKey(searchData);

      saveFollowersQueryInCache(state.byFollowers, hashedQuery);
      state.byFollowers.isSearching = true;
    });
    builder.addCase(searchFriends.fulfilled, (state, action) => {
      const {followers} = action.payload;
      const searchData = action.meta.arg;
      const hashedQuery = createFriendsQueryHashKey(searchData);
      const currentQuery =
        state.byFollowers.queries[state.byFollowers.queries.length - 1];

      state.byFollowers.results = {
        ...state.byFollowers.results,
        [hashedQuery]: followers,
      };

      if (currentQuery === hashedQuery) {
        state.byFollowers.isSearching = false;
      }
    });
    builder.addCase(searchFriends.rejected, (state, action) => {
      const searchData = action.meta.arg;
      const hashedQuery = createFriendsQueryHashKey(searchData);
      const currentQuery =
        state.byFollowers.queries[state.byFollowers.queries.length - 1];

      if (currentQuery === hashedQuery) {
        state.byFollowers.isSearching = false;
      }
    });
    builder.addCase(searchTwitterUsers.pending, (state, action) => {
      const query = action.meta.arg;
      const {queries} = state.byUsername;

      if (queries.length >= 5) {
        const evictedQuery = queries.shift();
        delete state.byUsername.results[evictedQuery as string];
      }

      queries.push(query);
    });
    builder.addCase(searchTwitterUsers.fulfilled, (state, action) => {
      const {users} = action.payload;
      const query = action.meta.arg;

      if (state.byUsername.queries.includes(query)) {
        state.byUsername.results[query] = users;
      }
    });
  },
});

export const {
  clearInboxSearchData,
  clearFollowersSearchData,
  clearUsernameSearchData,
  setFilters,
} = searchSlice.actions;

export const reducer = searchSlice.reducer;
