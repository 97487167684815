import {Friend} from '../friend/types';
import {Platform} from '../integration/types';
import {User} from '../user/types';

export enum InboxSortingOrder {
  RECENT_FIRST_ORDER = 'recent',
  UNREAD_FIRST_ORDER = 'unread',
  FOLLOWERS_HIGH_TO_LOW_ORDER = 'followers_high_to_low',
}

// There are two types of buckets for the conversations on the backend.
// The Bucket enum is used to exactly match that values.
export enum Bucket {
  Other = 'other',
  Important = 'important',
}

export enum ConversationStage {
  DONE = 'done',
  TODO = 'todo',
}

export enum ConversationStatus {
  UNREAD = 'unread',
  READ = 'read',
  READ_BY_FRIEND = 'read_by_friend',
  SENT = 'sent',
}

export enum TwitterFriendshipConnection {
  FOLLOWING = 'following',
  FOLLOWING_REQUESTED = 'following_requested',
  FOLLOWED_BY = 'followed_by',
  NONE = 'none',
  BLOCKING = 'blocking',
  MUTING = 'muting',
}

export interface ConversationFriendship {
  connections: TwitterFriendshipConnection[];
  updatedAt?: number;
}

export interface Conversation {
  attributes: {
    bucket: Bucket;
    followersCount: number;
    verified: boolean;
  };
  id: string;
  userId: string;
  messagesCount: number;
  hashKey?: string;
  integrationId: string;
  isLastActivityByUser: boolean;
  lastActivityDisplay: string;
  markedAt: number;
  markedBy: string;
  platform: Platform;
  platformUserId: string;
  sentMessagesCount: number;
  status: ConversationStatus;
  stage: ConversationStage;
  createdAt: number;
  friend?: Friend;
  friendId: string;
  friendships?: string[];
  friendshipsById?: {
    [k: string]: ConversationFriendship;
  };
  lastActivityAt: number;
  hasDirectMessages: boolean;
  hasScheduledMessages: boolean;
  isBlocked?: boolean;
}

export type Listener = () => void;

export interface OptimisticUpdate {
  stage?: ConversationStage;
  status?: ConversationStatus;
  updatedAt?: string; // Date
}

/**
 * Generates a conversation id from the current user object and platform friend
 * id (friend's twitter id).
 *
 * The conversation id follows the following format:
 *     `{user id}::{platform}::{twitter user id}::{twitter friend id}`
 *
 * The id generated on the back end by the `generateId` method in
 * {@link firebase/functions/src/core/common/entities/conversation.entity.ts}
 *
 * @param user the current user object
 * @param platformFriendId the twitter id of the friend
 */
export const generateConversationId = (
  user: User,
  platformFriendId: string
): string | undefined => {
  const integration = user.integrations.find(
    (integration) => integration.type == Platform.Twitter
  );
  if (integration) {
    return `${user.id}::twitter::${integration.externalUserId}::${platformFriendId}`;
  }
};
