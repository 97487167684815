"use strict";
exports.__esModule = true;
exports.newUserDiscoveryReport = exports.newDiscoveryReport = void 0;
var time_1 = require("./time");
var newDiscoveryReport = function () { return ({
    whenCreated: (0, time_1.now)(),
    totalTargetsGenerated: 0,
    totalTargetsCollected: 0,
    totalCollectionErrors: 0
}); };
exports.newDiscoveryReport = newDiscoveryReport;
var newUserDiscoveryReport = function () { return ({
    whenCreated: (0, time_1.now)(),
    targetsGenerated: 0,
    targetsCollected: 0,
    targetCollectionErrors: 0,
    errors: []
}); };
exports.newUserDiscoveryReport = newUserDiscoveryReport;
