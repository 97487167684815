"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.isGetUserLogsByEmailInput = exports.isUserLog = exports.isSaveUserLogsInput = exports.isLogEvents = exports.isLogEvent = exports.LogEventType = void 0;
var type_utils_1 = require("@workspace/type-utils");
// Explain: Here we don't capitalize values, because they will be read, when analyzing logs; and normal.
var LogEventType;
(function (LogEventType) {
    LogEventType["BULK_SEND"] = "Bulk send";
    LogEventType["ERROR"] = "Error";
    LogEventType["REQUEST"] = "Request";
    LogEventType["RESPONSE"] = "Response";
    LogEventType["CLICK"] = "Click";
})(LogEventType = exports.LogEventType || (exports.LogEventType = {}));
exports.isLogEvent = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var time = _a.time, name = _a.name;
    return (0, type_utils_1.isNonEmptyString)(time) && (0, type_utils_1.isNonEmptyString)(name);
});
exports.isLogEvents = (0, type_utils_1.arrayTypeGuard)(exports.isLogEvent);
exports.isSaveUserLogsInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var tabId = _a.tabId, events = _a.events, extensionVersion = _a.extensionVersion;
    return (0, type_utils_1.isNonEmptyString)(tabId) && (0, exports.isLogEvents)(events) && (0, type_utils_1.isNonEmptyString)(extensionVersion);
});
exports.isUserLog = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var tabId = _a.tabId, userId = _a.userId, extensionVersion = _a.extensionVersion, rest = __rest(_a, ["tabId", "userId", "extensionVersion"]);
    return (0, type_utils_1.isNonEmptyString)(tabId) && (0, type_utils_1.isNonEmptyString)(userId) && (0, type_utils_1.isNonEmptyString)(extensionVersion) && (0, exports.isLogEvent)(rest);
});
exports.isGetUserLogsByEmailInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var email = _a.email;
    return (0, type_utils_1.isNonEmptyString)(email);
});
