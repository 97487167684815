import { Pricing } from "@workspace/models";

export const PRICING_LABELS = {
  under500: "Under $500",
  between500and1000: "$500 - $1000",
  between1000and2500: "$1000 - $2500",
  between2500and10000: "$2500 - $10000",
  over10000: "Over $10000",
} as const satisfies Record<Pricing, string>;

export type PricingStepProps = {
  selectedPricing: Pricing | null;
  onChange: (pricing: Pricing | null) => void;
};
