import {chakra, Spinner} from "@chakra-ui/react";
import React, {useState} from "react";

import {DsmSolidButton} from "../../../components/buttons";
import {DsmInternalSolidLink} from "../../../components/links";
import routes from "../../../router/routes";
import {changePassword} from "../auth-service";
import AuthAlert from "../components/auth-alert";
import {
  AuthWrapper,
  AuthTitle,
  AuthFormWrapper,
} from "../components/auth-layout-components";
import PasswordInput from "../components/password-input";
import {changePasswordErrorLabelsMap as errorLabels} from "../helpers/validation";
import {FirebaseErrorCode} from "../types";

const StyledLink = chakra(DsmInternalSolidLink, {
  baseStyle: {
    mt: 6,
    w: "100%",
    display: "inline-block",
    textAlign: "center",
    fontSize: "md",
    fontWeight: "bold",
    borderRadius: "4px",
  },
});

const codesWithLinkToForgotPassword = [
  FirebaseErrorCode.AuthExpiredActionCode,
  FirebaseErrorCode.AuthInvalidActionCode,
];
interface ChangePasswordProps {
  code: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({code}) => {
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [errorLabel, setErrorLabel] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasLinkToForgotPassword, setHasLinkToForgotPassword] = useState(false);
  const [passwordWasChanged, setPasswordWasChanged] = useState<boolean | undefined>();
  const savePassword = (value: string) => setPassword(value);
  const saveConfirmedPassword = (value: string) => setConfirmedPassword(value);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!code || password !== confirmedPassword) return;

    setIsProcessing(true);
    setErrorLabel("");

    const response = await changePassword(code, password);

    if (response && response.code) {
      setErrorLabel(errorLabels[response.code]);
      setIsProcessing(false);

      // Not to hide the form to allow the user create a new password one more time
      if (response.code !== FirebaseErrorCode.AuthWeakPassword) {
        setPasswordWasChanged(false);
      }

      if (codesWithLinkToForgotPassword.includes(response.code as FirebaseErrorCode)) {
        setHasLinkToForgotPassword(true);
      }
    } else {
      setPasswordWasChanged(true);
      setIsProcessing(false);
    }
  };

  return (
    <AuthWrapper>
      <AuthFormWrapper>
        <AuthTitle title="Change password" mb="6" />
        {passwordWasChanged && (
          <>
            <AuthAlert status="success" header="Password changed successfully">
              Click the button below to sign in.
            </AuthAlert>

            <StyledLink to={routes.Conversations.route}>Sign in</StyledLink>
          </>
        )}

        {passwordWasChanged === false && (
          <>
            <AuthAlert status="error" header="Error">
              {errorLabel}
            </AuthAlert>

            {hasLinkToForgotPassword && (
              <StyledLink to={routes.ForgotPassword.route}>Resend email</StyledLink>
            )}
          </>
        )}

        {typeof passwordWasChanged === "undefined" && (
          <>
            {errorLabel && (
              <AuthAlert status="error" header="Error" mb={6}>
                {errorLabel}
              </AuthAlert>
            )}

            <form onSubmit={submitHandler}>
              <PasswordInput
                label="New Password"
                value={password}
                saveValue={savePassword}
              />
              <PasswordInput
                label="Confirm New Password"
                value={confirmedPassword}
                saveValue={saveConfirmedPassword}
              />
              <DsmSolidButton
                mt="2"
                w="100%"
                type="submit"
                fontSize="baseMinor"
                fontWeight="600"
                borderRadius="4px"
                disabled={isProcessing}
              >
                {isProcessing && <Spinner size="sm" mr={3} />} Set new password
              </DsmSolidButton>
            </form>
          </>
        )}
      </AuthFormWrapper>
    </AuthWrapper>
  );
};

export default ChangePassword;
