import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link as ChakraLink,
  Spinner,
} from "@chakra-ui/react";
import {FC, useCallback, useMemo} from "react";
import {MdMoreHoriz} from "react-icons/md";

import {useAppDispatch} from "../app-hooks";
import {useAppSelector} from "src/hooks";
import {SocialNetwork, socialNetworkHostname} from "@workspace/models";
import {ReactComponent as InstagramIcon} from "../assets/icons/instagram-bg-transparent.svg";
import {ReactComponent as TopRightArrowIcon} from "../assets/icons/top-right-arrow.svg";
import {ReactComponent as TwitterIcon} from "../assets/icons/twitter-bg-transparent.svg";
import {DsmExternalSolidLink} from "./links";
import {removeIntegration} from "../modules/user/thunks";
import {colorName, colorHex} from "@workspace/ui";

type Props = {
  connectionUrl: string;
  socialNetwork: SocialNetwork;
  onConnectClick?: () => void;
};

export const SocialIntegrationBox: FC<Props> = ({
  connectionUrl,
  socialNetwork,
  onConnectClick,
}) => {
  const dispatch = useAppDispatch();
  const {user, isRemovingIntegration, isFetchingUser} = useAppSelector(
    (state) => state.user,
  );

  const {hasTwitterIntegration} = useMemo(() => {
    const integrations = user?.integrations;
    if (!Array.isArray(integrations)) return {};
    const integration = integrations.find(({type}) => type === "twitter");
    return {
      hasTwitterIntegration: integration !== undefined,
      twitterUsername: integration?.username,
    };
  }, [user]);

  const onClickRemoveTwitter = useCallback(() => {
    dispatch(removeIntegration({type: "twitter"}));
  }, [dispatch]);

  const isInstagram = useMemo(() => socialNetwork === "Instagram", [socialNetwork]);
  const isTwitter = useMemo(() => socialNetwork === "Twitter", [socialNetwork]);

  const socialNetworkHomepage = useMemo(
    () => `https://${socialNetworkHostname[socialNetwork]}`,
    [socialNetwork],
  );

  const showConnectButton = useMemo(() => {
    return isTwitter && hasTwitterIntegration === false && !isFetchingUser && user;
  }, [isTwitter, hasTwitterIntegration, isFetchingUser, user]);

  const showUserLoadingSpinner = useMemo(
    () => isTwitter && (isFetchingUser || !user),
    [isTwitter, isFetchingUser, user],
  );

  return (
    <Flex
      minH="72px"
      bg={colorName.white}
      borderWidth="1px"
      borderRadius="12px"
      padding="5"
      borderColor={colorHex.alpha10}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <>
          {isInstagram && <InstagramIcon style={{display: "inline", marginRight: 15}} />}
          {isTwitter && <TwitterIcon style={{display: "inline", marginRight: 15}} />}
          <ChakraLink
            sx={{
              display: "flex",
              alignItems: "center",
              pointerEvents: showUserLoadingSpinner ? "none" : "auto",
              "&:hover": {textDecoration: "none"},
            }}
            target="_blank"
            href={socialNetworkHomepage}
          >
            <span>{socialNetwork}</span>
            <TopRightArrowIcon style={{marginLeft: 5}} />
          </ChakraLink>
        </>
      </Flex>

      {showConnectButton && (
        <DsmExternalSolidLink
          href={connectionUrl}
          onClick={onConnectClick}
          display="block"
          p="5px 15px"
          fontSize="sm"
          mb={4}
          sx={{
            float: "right",
          }}
          m={0}
        >
          Connect
        </DsmExternalSolidLink>
      )}

      {showUserLoadingSpinner ? (
        <Spinner size="sm" />
      ) : (
        isTwitter &&
        hasTwitterIntegration && (
          <>
            {isRemovingIntegration ? (
              <Spinner size="sm" />
            ) : (
              <Menu placement="bottom-start">
                <MenuButton aria-label="twitter dropdown">
                  <MdMoreHoriz />
                </MenuButton>
                <MenuList fontSize="sm" minWidth="fit-content" py={0} zIndex={10}>
                  <MenuItem
                    bg="white"
                    minH="44px"
                    borderRadius="md"
                    onClick={onClickRemoveTwitter}
                    disabled={isRemovingIntegration}
                  >
                    Remove Account
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </>
        )
      )}
    </Flex>
  );
};
