import {Box, Flex, Text} from "@chakra-ui/react";
import {ButtonProps, Heading, PricingCard, PRICING_PLAN_NAME_LABELS} from "@workspace/ui";

import {captureException} from "@sentry/browser";
import {firebaseFunctions as functions} from "@workspace/firebase-app";
import {
  BillingFrequency,
  isBillingFrequency,
  isCloudFunctionInput,
  isCloudFunctionOutput,
  isMonthlyPricingPlan,
  isPricingPlanName,
  isYearlyPricingPlan,
  monthlyPricingPlans,
  monthlyToYearlyPlans,
  PricingPlanDetails,
  PricingPlanName,
  yearlyPricingPlans,
  yearlyToMonthlyPlans,
} from "@workspace/firebase-datamodel";
import {cloudFunctionName} from "@workspace/firebase-definitions";
import {SubscriptionContext} from "@workspace/react";
import {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {EnterprisePricingPlan, PricingPlanFeatures, TabsSwitch} from "src/components";
import InnerAppLayout from "../components/inner-app-layout";
import {useLandingFlowData, usePlanDetails} from "../hooks";
import {useLocation} from "react-router";

export const PricingPlansPage: FC = () => {
  const {
    hasSubscription,
    pricingPlanName: currentPlanName,
    remainingTrialDays,
  } = useContext(SubscriptionContext);
  const location = useLocation<{
    messagesPerDay?: string;
    billingFrequency?: BillingFrequency;
  }>();

  const {
    billingFrequency: billingFrequencyParam,
    messagesPerDay: messagesPerDayParam,
    clear,
  } = useLandingFlowData();

  const [createCheckoutSessionIsPending, setCreateCheckoutSessionIsPending] =
    useState(false);
  const [billingFrequency, setBillingFrequency] = useState<BillingFrequency>(
    isBillingFrequency(billingFrequencyParam) ? billingFrequencyParam : "monthly",
  );
  const [selectedPricingPlan, setSelectedPricingPlan] = useState<
    PricingPlanName | undefined
  >(() => {
    const billingFrequencyParam = location.state?.billingFrequency ?? "monthly";

    if (!messagesPerDayParam || !Number.isInteger(+messagesPerDayParam)) return "basic";

    const relevantPlans: Record<string, PricingPlanDetails> =
      billingFrequencyParam === "monthly" ? monthlyPricingPlans : yearlyPricingPlans;

    const relevantPlan = Object.entries(relevantPlans).find(
      ([, plan]) => plan.messagesPerDay === +messagesPerDayParam,
    );

    if (!relevantPlan || !isPricingPlanName(relevantPlan[0])) return "basic";

    return relevantPlan[0];
  });

  const onSubmitPlan = useCallback(async () => {
    try {
      const input = {pricingPlan: selectedPricingPlan};
      if (!isCloudFunctionInput.createCheckoutSession(input)) return;
      setCreateCheckoutSessionIsPending(true);
      const {data: checkoutSession} = await functions.httpsCallable(
        cloudFunctionName.createCheckoutSession,
      )(input);
      setCreateCheckoutSessionIsPending(false);
      if (isCloudFunctionOutput.createCheckoutSession(checkoutSession))
        window.open(checkoutSession.url, "_self");
    } catch (error) {
      captureException(error);
    } finally {
      setCreateCheckoutSessionIsPending(false);
    }
  }, [selectedPricingPlan]);

  const handleTabChange = useCallback(
    (tabId: string) => {
      if (createCheckoutSessionIsPending) return;
      if (!isBillingFrequency(tabId)) return;

      if (billingFrequency !== tabId) {
        if (isYearlyPricingPlan(selectedPricingPlan))
          setSelectedPricingPlan(yearlyToMonthlyPlans[selectedPricingPlan] ?? "basic");
        else if (isMonthlyPricingPlan(selectedPricingPlan))
          setSelectedPricingPlan(
            monthlyToYearlyPlans[selectedPricingPlan] ?? "basic_yearly",
          );
      }
      setBillingFrequency(tabId);
    },
    [createCheckoutSessionIsPending, selectedPricingPlan, billingFrequency],
  );

  useEffect(() => {
    if (isPricingPlanName(currentPlanName)) setSelectedPricingPlan(currentPlanName);
    if (isMonthlyPricingPlan(currentPlanName)) setBillingFrequency("monthly");
    if (isYearlyPricingPlan(currentPlanName)) setBillingFrequency("yearly");
  }, [currentPlanName]);

  const {currentPlanDetails, selectedPlanDetails, monthlyPlan, yearlyPlan} =
    usePlanDetails({
      selectedPlanName: selectedPricingPlan,
      billingFrequency,
      currentPlanName,
    });

  const getPricingCardOptions = (billingFrequency: string) =>
    (
      Object.keys(
        billingFrequency === "monthly" ? monthlyPricingPlans : yearlyPricingPlans,
      ) as PricingPlanName[]
    ).map((planName) => ({
      label: PRICING_PLAN_NAME_LABELS[planName] ?? "",
      value: planName,
    }));

  const buttonProps: Partial<ButtonProps> = useMemo(() => {
    let buttonProps = {children: "", disabled: false};

    if (hasSubscription === false) {
      buttonProps.children = "Try free for 14 days";
    } else {
      if (currentPlanName === selectedPricingPlan) {
        if (remainingTrialDays === 0) {
          buttonProps.children = "Upgrade";
        } else {
          buttonProps.children = "Current plan";
          buttonProps.disabled = true;
        }
      } else {
        const currentPlanPrice =
          currentPlanDetails === undefined ? undefined : Number(currentPlanDetails.price);
        const selectedPlanPrice =
          selectedPlanDetails === undefined
            ? undefined
            : Number(selectedPlanDetails.price);

        if (
          typeof currentPlanPrice === "number" &&
          typeof selectedPlanPrice === "number"
        ) {
          const currentPlanTotalPrice = isMonthlyPricingPlan(currentPlanName)
            ? currentPlanPrice
            : currentPlanPrice * 12;
          const selectedPlanTotalPrice = isMonthlyPricingPlan(selectedPricingPlan)
            ? selectedPlanPrice
            : selectedPlanPrice * 12;
          if (currentPlanTotalPrice < selectedPlanTotalPrice) {
            buttonProps.children = "Upgrade";
          } else {
            buttonProps.children = "Downgrade";
          }
        }
      }
    }

    return buttonProps;
  }, [
    currentPlanDetails,
    currentPlanName,
    hasSubscription,
    remainingTrialDays,
    selectedPlanDetails,
    selectedPricingPlan,
  ]);

  const renderPricingCardDescription = () => {
    if (billingFrequency === "yearly" && monthlyPlan?.price) {
      return (
        <>
          per month, billed annually <br />
          or ${monthlyPlan.price.split(".")[0]} USD month-to month
        </>
      );
    }

    if (billingFrequency === "monthly" && yearlyPlan?.price) {
      return (
        <>
          per month, billed monthly <br /> or ${yearlyPlan.price.split(".")[0]} USD
          /month, billed anually
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    clear();
  }, [clear]);

  return (
    <InnerAppLayout shouldRenderSwitchToDesktop={false} hasBasicHeader showTrialInfo>
      <Box py={10} px={{base: "0", md: "60px"}} h="100%" bg="#F4F4F9">
        <Box maxWidth="736px" mx="auto">
          <Flex
            flexDirection={{base: "column", lg: "row"}}
            alignItems="center"
            mb="16px"
            justifyContent="space-between"
          >
            <Heading
              as="h1"
              variant="h1"
              mb={{
                base: "4",
                md: "0",
              }}
            >
              Choose your plan
            </Heading>
            <TabsSwitch
              activeTabId={billingFrequency}
              onChange={handleTabChange}
              disabled={hasSubscription === undefined}
            >
              <TabsSwitch.Tab tabId="monthly" width="96px">
                Monthly
              </TabsSwitch.Tab>
              <TabsSwitch.Tab tabId="yearly" width="96px">
                Yearly
              </TabsSwitch.Tab>
            </TabsSwitch>
          </Flex>
          <Flex justifyContent={{base: "center", md: "flex-end"}} mb="32px">
            <Text fontWeight={450} fontSize="13px" color="gray.600">
              Get{" "}
              <Text fontWeight="bold" color="gray.800" display="inline">
                2 months free
              </Text>{" "}
              when you pay yearly
            </Text>
          </Flex>
          <Box
            display="flex"
            flexDirection={{
              base: "column",
              md: "row",
            }}
            alignItems={{
              base: "center",
              md: "none",
            }}
            px={{
              base: 4,
              md: 0,
            }}
            gap="4"
            mb="4"
          >
            <Box width={{base: "100%", md: "50%"}}>
              <PricingCard
                price={selectedPlanDetails?.price || ""}
                description={renderPricingCardDescription()}
                buttonProps={{...buttonProps, isLoading: createCheckoutSessionIsPending}}
                selectedPlanName={selectedPricingPlan}
                onPlanChange={setSelectedPricingPlan}
                options={getPricingCardOptions(billingFrequency)}
                onSubmit={onSubmitPlan}
                isLoading={hasSubscription === undefined}
              />
            </Box>
            <Box width={{base: "100%", md: "50%"}}>
              <PricingPlanFeatures
                planName={selectedPricingPlan ?? "basic"}
                messagesPerDay={selectedPlanDetails?.messagesPerDay ?? 0}
              />
            </Box>
          </Box>
          <Box
            px={{
              base: 4,
              md: 0,
            }}
          >
            <EnterprisePricingPlan />
          </Box>
        </Box>
      </Box>
    </InnerAppLayout>
  );
};
