"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
exports.__esModule = true;
exports.PRICING_PLANS_SUPERIORITY_ORDER = exports.PRICING_PLAN_FEATURES = exports.isFeatureName = exports.FEATURE_NAMES = exports.yearlyToMonthlyPlans = exports.monthlyToYearlyPlans = exports.allPricingPlans = exports.yearlyPricingPlans = exports.monthlyPricingPlans = exports.isPricingPlanDetails = exports.isYearlyPricingPlan = exports.isMonthlyPricingPlan = exports.isPricingPlanKey = exports.isPricingPlan = exports.isPricingPlanName = exports.pricingPlanNames = void 0;
var type_utils_1 = require("@workspace/type-utils");
var MessagesDailyQuota_1 = require("./MessagesDailyQuota");
var monthlyPlanNames = ["basic", "popular", "pro"];
var yearlyPlanNames = ["basic_yearly", "popular_yearly", "pro_yearly"];
// TODO should be pricingPlanIds, monthlyPlanIds, etc.
exports.pricingPlanNames = __spreadArray(__spreadArray(__spreadArray([], monthlyPlanNames, true), yearlyPlanNames, true), [
    "enterprise",
    "starter",
], false);
exports.isPricingPlanName = (0, type_utils_1.isLiteralType)(exports.pricingPlanNames);
exports.isPricingPlan = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var priceId = _a.priceId, maxDailyMessages = _a.maxDailyMessages, id = _a.id;
    return (0, type_utils_1.isNonEmptyString)(priceId) &&
        (0, MessagesDailyQuota_1.isMessagesDailyQuota)({ maxDailyMessages: maxDailyMessages }) &&
        (0, exports.isPricingPlanName)(id);
});
exports.isPricingPlanKey = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var pricingPlan = _a.pricingPlan;
    return (0, exports.isPricingPlanName)(pricingPlan);
});
var isMonthlyPricingPlan = function (arg) {
    return (0, exports.isPricingPlanName)(arg) && !arg.endsWith("_yearly") && arg !== "enterprise";
};
exports.isMonthlyPricingPlan = isMonthlyPricingPlan;
var isYearlyPricingPlan = function (arg) {
    return (0, exports.isPricingPlanName)(arg) && arg.endsWith("_yearly");
};
exports.isYearlyPricingPlan = isYearlyPricingPlan;
exports.isPricingPlanDetails = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var description = _a.description, messagesPerDay = _a.messagesPerDay, price = _a.price;
    return typeof description === "string" &&
        typeof messagesPerDay === "number" &&
        typeof price === "string";
});
exports.monthlyPricingPlans = {
    basic: {
        description: "For users that want to try",
        messagesPerDay: 20,
        price: "67.00"
    },
    popular: {
        description: "For users that want to try",
        messagesPerDay: 50,
        price: "137.00"
    },
    pro: {
        description: "For users that want to try",
        messagesPerDay: 100,
        price: "267.00"
    }
};
exports.yearlyPricingPlans = {
    basic_yearly: {
        description: "For users that want to try",
        messagesPerDay: 20,
        price: "57.00"
    },
    popular_yearly: {
        description: "For users that want to try",
        messagesPerDay: 50,
        price: "117.00"
    },
    pro_yearly: {
        description: "For users that want to try",
        messagesPerDay: 100,
        price: "217.00"
    }
};
exports.allPricingPlans = __assign(__assign(__assign({}, exports.monthlyPricingPlans), exports.yearlyPricingPlans), { starter: {
        description: "For users that want to try",
        messagesPerDay: 5,
        price: ""
    }, enterprise: {
        description: "For users that want to try",
        messagesPerDay: 99999,
        price: ""
    } });
exports.monthlyToYearlyPlans = {
    basic: "basic_yearly",
    popular: "popular_yearly",
    pro: "pro_yearly"
};
exports.yearlyToMonthlyPlans = {
    basic_yearly: "basic",
    popular_yearly: "popular",
    pro_yearly: "pro"
};
exports.FEATURE_NAMES = [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
    "list_upload",
    "magic_dm",
    "follow_up_messages",
    "scheduling_for_the_next_day",
];
exports.isFeatureName = (0, type_utils_1.isLiteralType)(exports.FEATURE_NAMES);
exports.PRICING_PLAN_FEATURES = {
    basic: [
        "collect_leads_from_engagement",
        "custom_message_templates",
        "manage_campaigns",
    ],
    basic_yearly: [
        "collect_leads_from_engagement",
        "custom_message_templates",
        "manage_campaigns",
    ],
    popular: [
        "collect_leads_from_engagement",
        "custom_message_templates",
        "manage_campaigns",
        "list_upload",
        "magic_dm",
    ],
    popular_yearly: [
        "collect_leads_from_engagement",
        "custom_message_templates",
        "manage_campaigns",
        "list_upload",
        "magic_dm",
    ],
    pro: [
        "collect_leads_from_engagement",
        "custom_message_templates",
        "manage_campaigns",
        "list_upload",
        "magic_dm",
        "follow_up_messages",
        "scheduling_for_the_next_day",
    ],
    pro_yearly: [
        "collect_leads_from_engagement",
        "custom_message_templates",
        "manage_campaigns",
        "list_upload",
        "magic_dm",
        "follow_up_messages",
        "scheduling_for_the_next_day",
    ],
    starter: [],
    enterprise: []
};
exports.PRICING_PLANS_SUPERIORITY_ORDER = [
    ["basic", "basic_yearly"],
    ["popular", "popular_yearly"],
    ["pro", "pro_yearly"],
];
