import {Box, Flex} from "@chakra-ui/react";
import {AUDIENCES} from "@workspace/models";
import {Textarea} from "@workspace/ui";
import React, {FC} from "react";
import {useAppSelector} from "src/hooks";
import {StepSectionSubtext, StepTitle} from "../styled";
import {AudienceStepProps} from "./types";
import {CircularButton} from "../CircularButton";

export const AudienceStep: FC<AudienceStepProps> = ({
  selectedAudience,
  customerDescription,
  onAudienceChange,
  onCustomerDescriptionChange,
}) => {
  const {user} = useAppSelector((state) => state.user);

  if (!user) return null;

  return (
    <Box>
      <StepTitle mb="12">Tell us about your customers</StepTitle>
      <StepSectionSubtext mb={3}>What’s your target audience</StepSectionSubtext>
      <Flex gap="3" flexWrap="wrap" mb="12">
        {AUDIENCES.map((audience) => {
          const isSelected = selectedAudience === audience;

          return (
            <CircularButton
              key={audience}
              isSelected={isSelected}
              onClick={() => onAudienceChange(isSelected ? null : audience)}
            >
              {audience}
            </CircularButton>
          );
        })}
      </Flex>
      <StepSectionSubtext mb={3}>Describe your customer</StepSectionSubtext>
      <Textarea
        maxH="200px"
        placeholder="Add as much information as you think is needed..."
        p="4"
        fontSize="h6"
        color="gray.900"
        background="alpha.05"
        borderRadius="12px"
        resize="vertical"
        value={customerDescription}
        onChange={(e) => onCustomerDescriptionChange(e.target.value)}
      />
    </Box>
  );
};
