import {FC, PropsWithChildren} from "react";
import {Box, BoxProps, chakra, keyframes} from "@chakra-ui/react";

const animate = keyframes`
 from { background-position: -400px 0; }
 to { background-position: +400px 0; } 
`

const StyledBox = chakra(Box, {
  baseStyle: {
    borderRadius: "12px",
    height: "100%",
    width: "100%",
    background: "linear-gradient(90deg, #F1F2F4 0%, #F1F2F4 25%, #FFFFFF 50%, #F1F2F4 75%, #F1F2F4 100%)",
    backgroundColor: "#F1F2F4",
    backgroundRepeat: "no-repeat",
    backgroundSize: "600px auto",
    animationFillMode: "forwards"
  }
})

type Props = BoxProps & PropsWithChildren<{
  height?: string | number;
  width?: string | number;
  isLoading?: boolean;
  borderRadius?: string;
}>

export const Skeleton: FC<Props> = ({ height= "", width = "", isLoading = true, children, borderRadius, ...props }) => {
  const animation = `${animate} infinite 2s linear`;

  return (
    <StyledBox
      animation={isLoading ? animation : ""}
      height={height}
      width={width}
      backgroundColor={isLoading ? "gray.50" : ""}
      borderRadius={borderRadius || "12px"}
      {...props}
    >
      {!isLoading && children}
    </StyledBox>
  )
}

type CircleProps = Props & {
  size: number
}

export const SkeletonCircle: FC<CircleProps> = ({size, ...props}) => {
  return <Skeleton borderRadius={"50%"} {...props} width={`${size}px`} height={`${size}px`}/>
}
