import {Heading as ChakraHeading, HeadingProps as ChakraHeadingProps} from "@chakra-ui/react";
import {FC} from "react";

export type HeadingProps = ChakraHeadingProps & {
  variant?: ChakraHeadingProps['as']
}

export const Heading: FC<HeadingProps> = ({ variant = 'h1', ...props }) => {
  return (
    <ChakraHeading
      as={variant}
      letterSpacing={variant}
      fontSize={variant}
      lineHeight={variant}
      fontFamily='Circular'
      {...props}
    />
  );
};
