import {Box, Flex, Text, FlexProps, Heading} from "@chakra-ui/react";
import {FC} from "react";
import {Icon} from "./icons";

export const VerifyEmailBlock: FC<FlexProps & {email: string}> = ({
  email,
  children,
  ...flexProps
}) => (
  <Flex flexDir="column" alignItems="center" {...flexProps}>
    <Box padding="16px" borderRadius="100%" bg="green.50" display="inline-block" mb="6">
      <Icon name="checkEmail" size="32px" />
    </Box>
    <Heading mb="4" variant="h1" fontSize="h1" color="gray.900" textAlign="center">
      Verify your email
    </Heading>
    <Text fontSize="h6" textAlign="center" color="gray.900">
      We sent an activation link to <br />
      <strong>{email}</strong> <br /> <br /> Please check your inbox to activate account
    </Text>
    {children}
  </Flex>
);
