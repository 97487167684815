import React, {FC} from 'react';
import InnerAppLayout from "../../components/inner-app-layout";
import {Box, Flex, Image, Text} from "@chakra-ui/react";
import welcomeNoiseImage from "../../assets/images/welcome-noise.png";
import discoveryWelcomeStepImage from "../../assets/images/discovery-welcome-step.svg";
import {Button} from "@workspace/ui";
import {InstagramSession} from "../../modules/Discovery/components/InstagramSession";
import {SocialNetworkUser} from "@workspace/firebase-functions/@workspace/models";
import {firebaseFunctions} from "@workspace/firebase-app";
import {cloudFunctionName} from "@workspace/firebase-functions/@workspace/firebase-definitions";

type Props = {
  onRefreshSession: () => void;
}

const LoggedOut: FC<Props> = ({ onRefreshSession }) => {
  const [instagramUserData, setInstagramUserData] = React.useState<(SocialNetworkUser & { session: string }) | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickComplete = async () => {
    if(!instagramUserData) return;

    setIsLoading(true);
    try {
      await firebaseFunctions.httpsCallable(
        cloudFunctionName.updateDiscoverySession,
      )({
        instagramSession: instagramUserData.session,
      });

      onRefreshSession()
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <InnerAppLayout hasBasicHeader showTrialInfo>
      <Flex height="100%" fontFamily="Inter">
        <Box
          px={{
            base: "48px",
            md: "64px",
            lg: "96px",
            xl: "142px",
          }}
          pt="104px"
          w="50%"
        >
          <Box mb="12">
            <InstagramSession isRefreshingSession userData={instagramUserData} setUserData={setInstagramUserData}/>
          </Box>
          <Button
            width="100%"
            py="3"
            px="8"
            onClick={handleClickComplete}
            isLoading={isLoading}
            disabled={!instagramUserData}
          >
            Complete
          </Button>
        </Box>
        <Flex
          w="50%"
          alignItems="center"
          height="100%"
          overflow="hidden"
          position="relative"
          background="linear-gradient(180deg, rgba(51, 218, 229, 0) 0%, #514FC6 100%);"
        >
          <Image
            position="absolute"
            zIndex="2"
            src={welcomeNoiseImage}
            alt="noise"
            width="100%"
            h="100%"
          />
          <Flex
            position="relative"
            width="100%"
            justifyContent="flex-end"
            pl={{
              base: "6",
              xl: "12",
            }}
          >
            <Image
              position="relative"
              zIndex="3"
              display="block"
              left="0"
              top="0"
              width="100%"
              src={discoveryWelcomeStepImage}
              alt="Illustration"
            />
            <Box
              filter="blur(160px)"
              bg="#512FB2"
              height="calc(100% - 16%)"
              width="55.555%"
              position="absolute"
              top="8%"
              left="0"
            />
            <Box
              filter="blur(160px)"
              bg="#2F35B2"
              height="calc(100% - 16%)"
              width="55.555%"
              position="absolute"
              top="8%"
              right="0"
            />
          </Flex>
        </Flex>
      </Flex>
    </InnerAppLayout>

  );
};

export default LoggedOut;
