import {ModalBody, ModalContent, ModalFooter, ModalOverlay, Text} from "@chakra-ui/react";
import {Modal} from "@workspace/ui";
import {FC} from "react";

export const DeleteTargetModal: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}> = ({isOpen, onCancel, onConfirm, isLoading}) => {
  return (
    <>
      <Modal
        variant="error"
        title="Delete this user?"
        onClose={onCancel}
        contentProps={{
          width: "408px",
        }}
        isOpen={isOpen}
        onConfirm={onConfirm}
        confirmButtonProps={{
          children: "Yes, delete",
          variant: "danger",
          background: "red.500",
          isLoading
        }}
        closeButtonProps={{
          children: "Cancel",
        }}
        isCentered
      >
        <Text fontSize="h6" color="gray.900">
          Are you sure you want to delete this user from the list of leads? This action
          cannot be undone.
        </Text>
      </Modal>
    </>
  );
};
