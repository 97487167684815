import {background, Box, BoxProps, Flex, FlexProps} from "@chakra-ui/react";
import {colorName} from "@workspace/ui";
import {Children, cloneElement, FC, PropsWithChildren, useMemo} from "react";

const TabItem: FC<
  PropsWithChildren<FlexProps & {tabId: string; isActive?: boolean; disabled?: boolean}>
> = ({tabId, isActive, disabled, ...props}) => {
  const styles = useMemo<Partial<FlexProps>>(() => {
    if (disabled) {
      return {
        cursor: "default",
        backgroundColor: isActive ? "gray.100" : "white",
      };
    }

    if (isActive) {
      return {
        backgroundColor: "primary.400",
        color: "white",
      };
    }

    return {
      cursor: "pointer",
      color: "gray.900",
    };
  }, [disabled, isActive]);

  return (
    <Flex
      id={`tab-${tabId}`}
      justifyContent="center"
      alignItems="center"
      fontSize="14px"
      fontWeight="500"
      borderRadius="40px"
      py="8px"
      px="16px"
      {...styles}
      {...props}
    />
  );
};

type TabsSwitchProps = Omit<BoxProps, "onChange"> & {
  activeTabId: string;
  onChange: (activeTabId: string) => void;
  children: JSX.Element[];
  disabled?: boolean;
};

type TabsSwitchCompoennt = FC<TabsSwitchProps> & {
  Tab: typeof TabItem;
};

export const TabsSwitch: TabsSwitchCompoennt = ({
  children,
  activeTabId,
  disabled,
  onChange,
  ...props
}) => (
  <Box
    display="flex"
    borderRadius="100px"
    background="white"
    alignItems="center"
    padding="4px"
    borderWidth="1px"
    borderColor={colorName.alpha10}
    {...props}
  >
    {Children.map(children, (child) =>
      cloneElement(child, {
        ...child.props,
        isActive: child.props.tabId === activeTabId,
        onClick: () => onChange(child.props.tabId),
        disabled,
      }),
    )}
  </Box>
);

TabsSwitch.Tab = TabItem;
