import * as Sentry from "@sentry/browser";
import {firebaseFirestore, firebaseFunctions} from "@workspace/firebase-app";
import logo from "../../assets/icons/favicon-32x32.png";
import {getIsSoundEnabledValue} from "../../helpers/local-storage-helpers";
import parsePathnameForConversationId from "../../helpers/parse-pathname-for-conversation-id";
import shortenConversationId from "../../helpers/shorten-conversation-id";
import {HttpsCallableResult, DocumentData} from "../../services/firebase";
import {AppDispatch, ReduxState} from "../../store/store";

import {addConversations, playMessageSound} from "./conversations-slice";
import buildNotificationTitle from "./helpers/build-notification-title";
import {transformConversation} from "./helpers/transform-conversation";
import {Listener, Conversation, ConversationStage} from "./types";

let listeners: Listener[] = [];

export const releaseListeners = (): void => {
  listeners.map((listener) => listener());
  listeners = [];
};

/**
 * Due to marking all document changes as 'added' by Firestore on an initial load, we need
 * to check whether it is the initial load or a new conversation has been added,
 * to avoid triggering notifications on the initial load.
 * https://firebase.google.com/docs/firestore/query-data/listen
 */
const checkAddedChanges = (data: DocumentData, startListeningTimestamp: Date) =>
  data.lastActivityAt.toMillis() > startListeningTimestamp;

const checkModifiedChanges = (data: DocumentData, state: ReduxState) => {
  const {conversationsById} = state.conversations;
  const conversations = Object.values(conversationsById);
  const previousData = conversations.find((convo) => convo.id === data.id);
  const previousMessagesCount = previousData?.messagesCount || 0;
  const previousSentMessagesCount = previousData?.sentMessagesCount || 0;

  // check if the changes are actually a new message from a friend
  const isNewIncomingMessage =
    previousData &&
    previousMessagesCount < data.messagesCount &&
    previousSentMessagesCount === data.sentMessagesCount;

  return isNewIncomingMessage;
};

const showNotification = (data: DocumentData) => {
  const {friend} = data as Conversation;

  if (friend) {
    const text = data.lastActivityDisplay;
    const notificationTitle = buildNotificationTitle(friend);
    const notification = new Notification(notificationTitle, {
      icon: logo,
      body: text,
    });

    const timeoutId = setTimeout(() => {
      notification.close();
      clearTimeout(timeoutId);
    }, 3000);
  }
};

export const fetchConversations = async (
  dispatch: AppDispatch,
  userId: string,
  getState: () => ReduxState,
): Promise<Conversation[]> => {
  const startListeningTimestamp = new Date();

  return new Promise((resolve) =>
    listeners.push(
      firebaseFirestore
        .collection("users")
        .doc(userId)
        .collection("conversations")
        .orderBy("lastActivityAt", "desc")
        .onSnapshot(
          (querySnapshot) => {
            const savedTabId = localStorage.getItem("tabId");
            const isSoundEnabled = getIsSoundEnabledValue();
            const conversations: Conversation[] = [];
            const state = getState();
            const tabId = state.conversations.tabId;
            const isSoundNotificationAllowed = savedTabId === tabId && isSoundEnabled;
            const currentConversationId = parsePathnameForConversationId();

            querySnapshot.docChanges().forEach((snapshot) => {
              if (snapshot.type === "removed") return;

              const data = snapshot.doc.data();

              // all conversations with new incoming messages should be a todo,
              // but if a conversation is in done (might happen in the future
              // due to the feature of "mute"), do not show a notification
              if (data.stage !== ConversationStage.TODO) return;

              const isMessageInNewConversation =
                snapshot.type === "added" &&
                checkAddedChanges(data, startListeningTimestamp);

              const isMessageInExistingConversation =
                snapshot.type === "modified" && checkModifiedChanges(data, state);

              if (isMessageInNewConversation || isMessageInExistingConversation) {
                const isCurrentConversation =
                  shortenConversationId(data.id) === currentConversationId;

                // for current conversation we notify user only when a browser tab is hidden
                if (
                  !isCurrentConversation ||
                  (isCurrentConversation && document.hidden)
                ) {
                  showNotification(data);

                  if (isSoundNotificationAllowed) dispatch(playMessageSound());
                }
              }
            });

            querySnapshot.forEach((convo) => {
              const conversationData = {...convo.data()};
              const transformedConversation = transformConversation(conversationData);
              const isCurrentConversation =
                shortenConversationId(transformedConversation.id) ===
                currentConversationId;

              //Need to think about a better solution for filtering data from the database
              if (
                isCurrentConversation ||
                transformedConversation.hasDirectMessages ||
                transformedConversation.hasScheduledMessages
              ) {
                conversations.push(transformedConversation);
              }
            });

            dispatch(addConversations(conversations));
            resolve(conversations);
          },
          (e) => {
            console.log(e);
            Sentry.captureException(e);
          },
        ),
    ),
  );
};

export const fetchNewConversation = async ({
  conversationId,
  userId,
}: {
  conversationId: string;
  userId: string;
}): Promise<Conversation> => {
  return firebaseFirestore
    .collection("users")
    .doc(userId)
    .collection("conversations")
    .doc(conversationId)
    .get()
    .then((doc) => {
      if (!doc.exists) {
        throw new Error(
          `No conversation with id ${conversationId} for user ${userId} found`,
        );
      }

      const conversationData = {...doc.data()};
      const transformedConversation = transformConversation(conversationData);

      return transformedConversation;
    })
    .catch((e) => {
      Sentry.captureException(e);
      throw e;
    });
};

export const markConversationStageOnCall = async ({
  conversationId,
  stage,
}: {
  conversationId: string;
  stage: ConversationStage;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("markConversationStageOnCall")({
      conversationId,
      stage,
    });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const markConversationUnreadOnCall = async (
  conversationId: string,
): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("markConversationUnreadOnCall")({
      conversationId,
    });
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};

export const updateConversationOnCall = async (data: {
  conversationId: string;
  isBlocked: boolean;
}): Promise<HttpsCallableResult> => {
  try {
    return await firebaseFunctions.httpsCallable("updateConversationOnCall")(data);
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
