import {firebaseAuth, firebaseFunctions} from "@workspace/firebase-app";
import React, {useEffect, useRef} from "react";
import {Redirect, useHistory, useLocation} from "react-router";
import {dsmToast} from "src/components/dsm-toast";
import routes from "../../../router/routes";

import ChangePassword from "./change-password";

const ActionModeMap = {
  resetPassword: "resetPassword",
  verifyEmail: "verifyEmail",
};

type ActionProps = {
  redirectTo?: string;
};

const Action: React.FC<ActionProps> = ({redirectTo = routes.Integrations.route}) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const code = params.get("oobCode");
  const mode = params.get("mode");
  const actionSuccess = params.get("action_success");
  const isAppliedCodeRef = useRef<boolean>(false);
  const didAnnounceSuccessRef = useRef<boolean>(false);

  useEffect(() => {
    if (isAppliedCodeRef.current) return;
    if (!code || mode !== ActionModeMap.verifyEmail) return;

    const applyCode = async () => {
      try {
        await firebaseAuth.applyActionCode(code);
        await firebaseAuth.currentUser?.reload();
        firebaseFunctions.httpsCallable("postEmailVerificationActionsOnCall")();

        history.push(`${location.pathname}?action_success=${mode}`);
      } catch (error) {
        dsmToast("The link is invalid", "error");
      }
    };

    isAppliedCodeRef.current = true;

    applyCode();
  }, [code, mode, location, history]);

  useEffect(() => {
    if (!actionSuccess || didAnnounceSuccessRef.current) return;
    didAnnounceSuccessRef.current = true;
    if (actionSuccess !== ActionModeMap.verifyEmail) return;

    dsmToast("🎉  Your email is now verified", "default");
  }, [actionSuccess]);

  if (code && mode === ActionModeMap.resetPassword) return <ChangePassword code={code} />;

  return <Redirect to={redirectTo} />;
};

export default Action;
