import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import InnerAppLayout from "../../components/inner-app-layout";
import {Box, Flex, Grid, Image, Link, Text} from "@chakra-ui/react";
import {Button, Heading, Icon, IconButton, MessageTemplate, Textarea,} from "@workspace/ui";
import {DiscoveryDataContext} from "@workspace/react";
import {TbUsers} from "react-icons/tb";
import {RxAvatar} from "react-icons/rx";
import {DeleteTargetModal} from "./DeleteTargetModal";

interface Props {
  moveToWhatNext: () => void;
}

export const Prospects: FC<Props> = ({moveToWhatNext}) => {
  const [activeTargetId, setActiveTargetId] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    messageTemplates,
    handleMessageTemplateTextChange,
    targets,
    handleTargetMessageChange,
    handleRemoveTarget,
  } = useContext(DiscoveryDataContext);

  const activeTargetData = targets.find((target) => target.id === activeTargetId);

  useEffect(() => {
    if (targets.length > 0 && !activeTargetId) {
      setActiveTargetId(targets[0]?.id || null);
    }
  }, [activeTargetId, targets]);

  const _handleRemoveTarget = useCallback(async () => {
    if (!activeTargetId) return;

    setIsDeleting(true);

    try{
      await handleRemoveTarget(activeTargetId);

      setShowDeleteModal(false);
      const targetIndex = targets.findIndex((target) => target.id === activeTargetId);

      let nextTargetId;

      if (targetIndex === targets.length - 1) {
        nextTargetId = targets[targetIndex - 1]?.id;
      } else if (targetIndex === 0) {
        nextTargetId = targets[1]?.id;
      } else {
        nextTargetId = targets[targetIndex + 1]?.id;
      }

      setActiveTargetId(nextTargetId || null);
    }catch {
      alert('Something went wrong while deleting target')
    }finally {
      setIsDeleting(false);
    }

  }, [activeTargetId, targets, handleRemoveTarget]);

  return (
    <InnerAppLayout hasBasicHeader>
      <Box py={10} px="168px">
        <DeleteTargetModal
          isOpen={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={_handleRemoveTarget}
          isLoading={isDeleting}
        />
        <Grid gridTemplateColumns="1.3fr 1fr" gap="32px" fontFamily="Inter">
          <Box>
            <MessageTemplate
              onMessageTemplateTextChange={handleMessageTemplateTextChange}
              messageTemplates={messageTemplates}
              isFrontend
            />
            <Flex justifyContent="space-between" alignItems="center" mt={10} mb={4}>
              <Flex alignItems="center" color="gray.900">
                <TbUsers />
                <Heading fontSize="h5" ml={2}>
                  {targets.length} prospects selected
                </Heading>
              </Flex>
              <Button
                variant="primary"
                ml="auto"
                disabled={targets.length === 0}
                onClick={moveToWhatNext}
              >
                <Flex alignItems="center" gap="2">
                  <Icon name="paperPlane" size="20" />
                  <Text>Send {targets.length} DMs</Text>
                </Flex>
              </Button>
            </Flex>
            {targets.map((target, index) => (
              <Flex
                cursor="pointer"
                key={target.id}
                p={4}
                pr={6}
                alignItems="center"
                backgroundColor={
                  index % 2 === 0 ? "rgba(11, 13, 14, 0.05)" : "transparent"
                }
                borderRadius="12px"
                onClick={() => setActiveTargetId(target.id || null)}
              >
                {target.avatar ? (
                  <Image
                    width="40px"
                    height="40px"
                    borderRadius="50%"
                    marginRight={2}
                    src={target.avatar}
                    alt={target.name}
                  />
                ) : (
                  <RxAvatar size={40} />
                )}
                <Box color="gray.900" marginRight="auto">
                  <Text fontWeight="600" fontSize="h6">
                    {target.name}
                  </Text>
                  <Text color="gray.900" fontSize="h6">
                    @{target.handle}
                  </Text>
                </Box>
                <Flex alignItems="center" gap="12px">
                  <Box color="gray.900" marginRight="auto" textAlign="center">
                    <Text fontWeight="600" fontSize="h6">
                      {target.posts}
                    </Text>
                    <Text color="gray.900" fontSize="h6">
                      posts
                    </Text>
                  </Box>
                  <Box color="gray.900" marginRight="auto" textAlign="center">
                    <Text fontWeight="600" fontSize="h6">
                      {target.followers}
                    </Text>
                    <Text color="gray.900" fontSize="h6">
                      followers
                    </Text>
                  </Box>
                  <Box color="gray.900" marginRight="auto" textAlign="center">
                    <Text fontWeight="600" fontSize="h6">
                      {target.following}
                    </Text>
                    <Text color="gray.900" fontSize="h6">
                      following
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            ))}
          </Box>
          {activeTargetData && (
            <Flex
              p={8}
              border="1px solid rgba(0, 0, 0, 0.1)"
              borderRadius="16px"
              height="fit-content"
              flexDirection="column"
              position="sticky"
              top="40px"
              key={activeTargetId}
            >
              <Flex width="100%" mb={6} justifyContent="space-between">
                <Link href={`https://www.instagram.com/${activeTargetData.handle}`}>
                  <Flex alignItems="center">
                    {activeTargetData.avatar ? (
                      <Image
                        width="56px"
                        height="56px"
                        borderRadius="50%"
                        marginRight={2}
                        src={activeTargetData.avatar}
                        alt={activeTargetData.name}
                      />
                    ) : (
                      <RxAvatar size={40} />
                    )}
                    <Box color="gray.900" marginRight="auto">
                      <Text fontWeight="600" fontSize="h6">
                        {activeTargetData.name}
                      </Text>
                      <Text color="gray.900" fontSize="h6">
                        @{activeTargetData.handle}
                      </Text>
                    </Box>
                  </Flex>
                </Link>
                <IconButton
                  size="sm"
                  variant="ghost"
                  color="gray.500"
                  _hover={{
                    color: "red.500",
                    background: "red.50",
                  }}
                  iconProps={{size: 20}}
                  iconName="trash"
                  onClick={() => setShowDeleteModal(true)}
                />
              </Flex>
              <Flex gap={8} alignItems="center" mb={4}>
                <Text fontSize="h6">
                  <Text fontWeight="600" display="inline">
                    {activeTargetData.posts}
                  </Text>{" "}
                  posts
                </Text>
                <Text fontSize="h6">
                  <Text fontWeight="600" display="inline">
                    {activeTargetData.followers}
                  </Text>{" "}
                  followers
                </Text>
                <Text fontSize="h6">
                  <Text fontWeight="600" display="inline">
                    {activeTargetData.following}
                  </Text>{" "}
                  following
                </Text>
              </Flex>
              {activeTargetData.bio && (
                <Text fontSize="h6" color="gray.900">
                  {activeTargetData.bio}
                </Text>
              )}
              <Box mt={6}>
                <Textarea
                  background="#0B0D0E0D"
                  border="none"
                  rows={10}
                  value={activeTargetData.message}
                  onChange={(e) =>
                    handleTargetMessageChange(activeTargetData.id || "", e.target.value)
                  }
                />
              </Box>
            </Flex>
          )}
        </Grid>
      </Box>
    </InnerAppLayout>
  );
};
