"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.campaignToCampaignInfo = exports.isCampaignsInfo = exports.isCampaignInfo = exports.canDeleteCampaign = exports.campaignIsDone = exports.nextCampaignMessage = exports.newCampaign = exports.isCampaigns = exports.isCampaign = exports.isCampaignMessages = exports.isCampaignMessage = exports.isCampaignStatus = exports.CAMPAIGN_TYPES = exports.CAMPAIGN_STATUS = exports.getNumberOfPassedMessages = exports.isTargetCollectionOrigin = exports.TargetCollectionOrigin = void 0;
var type_utils_1 = require("@workspace/type-utils");
var Item_1 = require("./Item");
var time_1 = require("./time");
var TargetCollectionOrigin;
(function (TargetCollectionOrigin) {
    TargetCollectionOrigin["IG_FOLLOWERS"] = "IG_FOLLOWERS";
    TargetCollectionOrigin["IG_FOLLOWING"] = "IG_FOLLOWING";
    TargetCollectionOrigin["IG_HASHTAG"] = "IG_HASHTAG";
    TargetCollectionOrigin["IG_POST"] = "IG_POST";
    TargetCollectionOrigin["IG_LOCATION"] = "IG_LOCATION";
    TargetCollectionOrigin["LIST_UPLOAD"] = "LIST_UPLOAD";
    TargetCollectionOrigin["NO_ORIGIN"] = "NO_ORIGIN";
    TargetCollectionOrigin["TWEET"] = "TWEET";
    TargetCollectionOrigin["TW_FOLLOWERS"] = "TW_FOLLOWERS";
    TargetCollectionOrigin["TW_FOLLOWING"] = "TW_FOLLOWING";
    TargetCollectionOrigin["USER_LIST"] = "USER_LIST";
})(TargetCollectionOrigin = exports.TargetCollectionOrigin || (exports.TargetCollectionOrigin = {}));
exports.isTargetCollectionOrigin = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var type = _a.type, url = _a.url;
    return (0, type_utils_1.isNonEmptyString)(type) && (0, type_utils_1.isNonEmptyString)(url);
});
var FINISHED_CAMPAIGN_TIME_TO_LIVE = time_1.TIME.ONE_MINUTE * 30;
var CANCELLED_CAMPAIGN_TIME_TO_LIVE = time_1.TIME.ONE_MINUTE * 3;
var getNumberOfPassedMessages = function (_a) {
    var _b;
    var numSentMessages = _a.numSentMessages, failedMessageIndexes = _a.failedMessageIndexes;
    return numSentMessages + ((_b = failedMessageIndexes === null || failedMessageIndexes === void 0 ? void 0 : failedMessageIndexes.length) !== null && _b !== void 0 ? _b : 0);
};
exports.getNumberOfPassedMessages = getNumberOfPassedMessages;
exports.CAMPAIGN_STATUS = {
    RUNNING: "RUNNING",
    PAUSED: "PAUSED",
    CANCELED: "CANCELED",
    FINISHED: "FINISHED",
    QUEUED: "QUEUED",
    ERROR: "ERROR",
    NOT_STARTED: "NOT_STARTED"
};
exports.CAMPAIGN_TYPES = {
    FROM_DISCOVERY: "FROM_DISCOVERY",
    OTHER: "OTHER"
};
exports.isCampaignStatus = (0, type_utils_1.isLiteralObjectType)(exports.CAMPAIGN_STATUS);
exports.isCampaignMessage = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var text = _a.text, username = _a.username;
    return (0, type_utils_1.isNonEmptyString)(text) && (0, type_utils_1.isNonEmptyString)(username);
});
exports.isCampaignMessages = (0, type_utils_1.arrayTypeGuard)(exports.isCampaignMessage);
exports.isCampaign = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, messages = _a.messages, numSentMessages = _a.numSentMessages, status = _a.status, whenUpdated = _a.whenUpdated, whenCreated = _a.whenCreated, targetCollectionOrigin = _a.targetCollectionOrigin, failedMessageIndexes = _a.failedMessageIndexes, instagramHandle = _a.instagramHandle;
    return (0, Item_1.isItem)({ id: id }) &&
        (0, time_1.isUpdateTime)({ whenUpdated: whenUpdated }) &&
        (0, time_1.isCreationTime)({ whenCreated: whenCreated }) &&
        (0, exports.isCampaignStatus)(status) &&
        (0, type_utils_1.isNonNegativeInteger)(numSentMessages) &&
        (0, exports.isCampaignMessages)(messages) &&
        (0, type_utils_1.isNonEmptyString)(instagramHandle) &&
        targetCollectionOrigin === undefined || (0, exports.isTargetCollectionOrigin)(targetCollectionOrigin) &&
        Array.isArray(failedMessageIndexes);
});
exports.isCampaigns = (0, type_utils_1.arrayTypeGuard)(exports.isCampaign);
var newCampaign = function (_a) {
    var messages = _a.messages, targetCollectionOrigin = _a.targetCollectionOrigin, instagramId = _a.instagramId, instagramHandle = _a.instagramHandle, instagramAvatar = _a.instagramAvatar, campaignType = _a.campaignType, status = _a.status, _b = _a.followUpMessage, followUpMessage = _b === void 0 ? null : _b, _c = _a.followUpInterval, followUpInterval = _c === void 0 ? null : _c, _d = _a.dataIsIncomplete, dataIsIncomplete = _d === void 0 ? false : _d, sourceList = _a.sourceList;
    return (__assign(__assign({ id: (0, Item_1.createId)(), numSentMessages: 0, failedMessageIndexes: [], status: status, messages: messages, targetCollectionOrigin: targetCollectionOrigin, instagramId: instagramId, instagramHandle: instagramHandle, instagramAvatar: instagramAvatar || "", isDeleted: false, campaignType: campaignType, followUpMessage: followUpMessage, followUpInterval: followUpInterval, dataIsIncomplete: dataIsIncomplete, sourceList: sourceList }, (0, time_1.updatedNow)()), (0, time_1.createdNow)()));
};
exports.newCampaign = newCampaign;
var nextCampaignMessage = function (_a) {
    var status = _a.status, numSentMessages = _a.numSentMessages, messages = _a.messages, failedMessageIndexes = _a.failedMessageIndexes;
    if (status !== exports.CAMPAIGN_STATUS.RUNNING)
        return undefined;
    return messages[(0, exports.getNumberOfPassedMessages)({ numSentMessages: numSentMessages, failedMessageIndexes: failedMessageIndexes })];
};
exports.nextCampaignMessage = nextCampaignMessage;
var campaignIsDone = function (_a) {
    var status = _a.status, numSentMessages = _a.numSentMessages, numMessages = _a.numMessages, failedMessageIndexes = _a.failedMessageIndexes;
    if (status === exports.CAMPAIGN_STATUS.FINISHED)
        return true;
    return ((0, exports.getNumberOfPassedMessages)({ numSentMessages: numSentMessages, failedMessageIndexes: failedMessageIndexes }) >= numMessages);
};
exports.campaignIsDone = campaignIsDone;
var canDeleteCampaign = function (_a) {
    var whenUpdated = _a.whenUpdated, status = _a.status, numSentMessages = _a.numSentMessages, numMessages = _a.numMessages, failedMessageIndexes = _a.failedMessageIndexes, isDeleted = _a.isDeleted;
    if (isDeleted)
        return false;
    if (status === exports.CAMPAIGN_STATUS.CANCELED) {
        return CANCELLED_CAMPAIGN_TIME_TO_LIVE + whenUpdated < (0, time_1.now)();
    }
    if ((0, exports.campaignIsDone)({ status: status, numSentMessages: numSentMessages, numMessages: numMessages, failedMessageIndexes: failedMessageIndexes }))
        return FINISHED_CAMPAIGN_TIME_TO_LIVE + whenUpdated < (0, time_1.now)();
    return false;
};
exports.canDeleteCampaign = canDeleteCampaign;
exports.isCampaignInfo = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var id = _a.id, status = _a.status, whenUpdated = _a.whenUpdated, numSentMessages = _a.numSentMessages, numMessages = _a.numMessages, failedMessageIndexes = _a.failedMessageIndexes;
    return (0, Item_1.isItem)({ id: id }) &&
        (0, exports.isCampaignStatus)(status) &&
        (0, type_utils_1.isNonNegativeInteger)(numSentMessages) &&
        (0, type_utils_1.isNonNegativeInteger)(numMessages) &&
        (0, time_1.isUpdateTime)({ whenUpdated: whenUpdated }) &&
        Array.isArray(failedMessageIndexes);
});
exports.isCampaignsInfo = (0, type_utils_1.arrayTypeGuard)(exports.isCampaignInfo);
var campaignToCampaignInfo = function (_a) {
    var campaign = __rest(_a, []);
    return (__assign(__assign({}, campaign), { numMessages: campaign.messages.length }));
};
exports.campaignToCampaignInfo = campaignToCampaignInfo;
