import {FC, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';

import {useAppDispatch, useAppSelector} from 'src/hooks';
import {routes} from 'src/router';
import {setShowModalAuthFailure} from '../../user/user-slice';

export const TwitterIntegrationFailure: FC = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.user.showModalAuthFailure);
  const [target, setTarget] = useState('');

  useEffect(() => {
    dispatch(setShowModalAuthFailure(true));
  }, [dispatch]);

  useEffect(() => {
    if (showModal === false) setTarget(routes.Integrations.route);
  }, [showModal]);

  if (target) return <Redirect to={target} />;

  return null;
};
