import {arrayTypeGuard, objectTypeGuard} from "@workspace/type-utils";

export type MonitorResponse = {
  status: 'SUCCESS' | 'ERROR' | 'INVALID_TYPE',
  errorMessage?: string,
  url: string,
}

export const monitoringEndpoints = [
  'FOLLOWING',
  'FOLLOWERS',
  'LIKERS',
  'COMMENTERS',
  'HASHTAG',
  'LOCATION'
] as const

export type MonitoringEndpoint = (typeof monitoringEndpoints)[number]

export const monitoringEndpoint: Record<MonitoringEndpoint, MonitoringEndpoint> = monitoringEndpoints.reduce((endpoints, endpoint: MonitoringEndpoint) => {
  return {
    ...endpoints,
    [endpoint]: endpoint
  }
}, {} as Record<MonitoringEndpoint, MonitoringEndpoint>)

export type MonitorResponseWithEndpoint = MonitorResponse & {
  endpointType: MonitoringEndpoint
};

export const isMonitorResponse = objectTypeGuard<MonitorResponseWithEndpoint>(({status, errorMessage, endpointType}) => (
  ['SUCCESS', 'ERROR', 'INVALID_TYPE'].includes(status as string) &&
  (status === 'ERROR' ? errorMessage !== undefined : true) &&
  monitoringEndpoints.includes(endpointType as MonitoringEndpoint)
))

export const isMonitorResponses = arrayTypeGuard<MonitorResponseWithEndpoint>(isMonitorResponse)
