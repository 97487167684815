import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useRef, useState, } from "react";
import { MESSAGE_TEMPLATE_ID_KEY, messageTemplateLookup } from "@workspace/ui";
import { useFirebaseUser } from "../hooks";
import { firebaseFirestore } from "@workspace/firebase-app";
export const MessageTemplateStateContext = createContext({});
export const FollowUpTemplateStateContext = createContext({});
const MOCK_MESSAGE_TEMPLATE_ID = "mock-id";
// (Context: Context<StateValue>)
export const MessageTemplateStateProviderTemplate = ({ children, isFollowUp = false }) => {
    const Context = isFollowUp ? FollowUpTemplateStateContext : MessageTemplateStateContext;
    const hasSetInitialMessageTemplate = useRef(false);
    const { isAuthenticated, userInfo } = useFirebaseUser();
    const [messageTemplate, setMessageTemplate] = useState({ message: "", name: "", id: MOCK_MESSAGE_TEMPLATE_ID });
    const [messageTemplateData, setMessageTemplateData] = useState(messageTemplate);
    useEffect(() => {
        let unsub;
        if (isAuthenticated && userInfo) {
            unsub = firebaseFirestore
                .collection("users")
                .doc(userInfo?.uid)
                .onSnapshot(async (snapshot) => {
                if (hasSetInitialMessageTemplate.current)
                    return;
                hasSetInitialMessageTemplate.current = true;
                if (!snapshot.exists) {
                    setMessageTemplate({ message: "", name: "", id: MOCK_MESSAGE_TEMPLATE_ID });
                    return;
                }
                const templateKey = isFollowUp ? "followUpMessageTemplates" : "messageTemplates";
                const userDoc = snapshot.data();
                if (!userDoc) {
                    setMessageTemplate({ message: "", name: "", id: MOCK_MESSAGE_TEMPLATE_ID });
                    return;
                }
                const cachedId = messageTemplateLookup.get(MESSAGE_TEMPLATE_ID_KEY);
                if (!cachedId) {
                    setMessageTemplate(userDoc[templateKey][0]);
                    return;
                }
                const cachedTemplate = userDoc[templateKey].find(({ id }) => id === cachedId);
                if (cachedTemplate) {
                    setMessageTemplate(cachedTemplate);
                }
            });
        }
        return unsub;
    }, [isAuthenticated, isFollowUp, userInfo]);
    return (_jsx(Context.Provider, { value: { messageTemplateData, setMessageTemplateData, messageTemplate, setMessageTemplate }, children: children }));
};
export const MessageTemplateStateProvider = ({ children }) => {
    return (_jsx(MessageTemplateStateProviderTemplate, { children: children }));
};
export const FollowUpMessageTemplateStateProvider = ({ children }) => {
    return (_jsx(MessageTemplateStateProviderTemplate, { isFollowUp: true, children: children }));
};
