import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  chakra,
  Kbd,
} from '@chakra-ui/react';
import React from 'react';

import {DsmIconButton} from '../buttons';

const StyledList = chakra('ul', {
  baseStyle: {
    mb: 8,
  },
});
const StyledListItem = chakra('li', {
  baseStyle: {
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 'baseMinor',
    color: 'gray.800',
  },
});
const StyledKbd = chakra(Kbd, {
  baseStyle: {
    fontSize: 'xs',
    lineHeight: '1.5',
    fontWeight: 'bold',
    color: 'gray.700',
  },
});
const StyledGroupTitle = chakra('h3', {
  baseStyle: {
    color: 'gray.600',
    fontSize: 'baseMajor',
    fontWeight: 'bold',
    lineHeight: 1.5,
    mb: 2,
  },
});

interface ShortcutKeysHelpProps {
  isOpen: boolean;
  onClose: () => void;
}

const ShortcutKeysHelp: React.FC<ShortcutKeysHelpProps> = ({
  isOpen,
  onClose,
}) => (
  <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton
        as={DsmIconButton}
        top={5}
        right={6}
        color="black"
        _focus={{outline: 'none'}}
      />
      <DrawerHeader pb={2}>Inbox Shortcut Keys</DrawerHeader>

      <DrawerBody>
        <StyledGroupTitle>Navigation</StyledGroupTitle>
        <StyledList>
          <StyledListItem>
            Next conversation <StyledKbd>J</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Previous conversation <StyledKbd>K</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Open shortcuts help <StyledKbd>?</StyledKbd>
          </StyledListItem>

          <StyledListItem>
            Close shortcuts help <StyledKbd>? or Esc</StyledKbd>
          </StyledListItem>
        </StyledList>

        <StyledGroupTitle>Conversation</StyledGroupTitle>
        <StyledList>
          <StyledListItem>
            Mark done <StyledKbd>E</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Mark todo <StyledKbd>⇧ E</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Reply <StyledKbd>R</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Mark read/unread <StyledKbd>U</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Add tag <StyledKbd>T</StyledKbd>
          </StyledListItem>
        </StyledList>

        <StyledGroupTitle>Reply</StyledGroupTitle>
        <StyledList>
          <StyledListItem>
            Send message <StyledKbd>↩</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Send message + mark done <StyledKbd>⌘ ↩</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Add new line <StyledKbd>⇧ ↩</StyledKbd>
          </StyledListItem>
          <StyledListItem>
            Back to navigation <StyledKbd>Esc</StyledKbd>
          </StyledListItem>
        </StyledList>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

export default ShortcutKeysHelp;
