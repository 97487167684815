import {chakra, Text} from "@chakra-ui/react";

export const StepTitle = chakra(Text, {
  baseStyle: {
    color: "gray.900",
    fontSize: "28px",
    mb: "1",
    fontWeight: "800",
    fontFamily: "Cabinet Grotesk"
  },
});

export const StepSectionTitle = chakra(Text, {
  baseStyle: {
    color: "gray.900",
    fontWeight: "400",
    mb: "3",
    fontSize: "h6",
  },
});

export const StepSectionSubtext = chakra(Text, {
  baseStyle: {
    color: "gray.900",
    fontWeight: "600",
    fontSize: "14px",
    mb: "2"
  }
})
