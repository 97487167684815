import {Box, Text} from "@chakra-ui/react";
import {PopupButton} from "@typeform/embed-react";
import {typeformSurveyFormId} from "@workspace/locators";
import {FC} from "react";

export const EnterprisePricingPlan: FC = () => {
  return (
    <Box px="8" py="6" borderRadius="16px" background="white">
      <Text fontWeight="bold" fontSize="lg">
        Enterprise
      </Text>
      <Text fontWeight={450}>
        Need a custom plan?{" "}
        <PopupButton id={typeformSurveyFormId}>
          <Text color="primary.500">Let’s get in touch</Text>
        </PopupButton>
        .
      </Text>
    </Box>
  );
};
