const redirectedHost = {
  dev: "https://app.dev.sayhowdy.co",
  prod: "https://app.sayhowdy.co",
};

export const redirect = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("dsm.network")) {
    `${window.open(
      hostname.includes("dev.") ? redirectedHost.dev : redirectedHost.prod,
    )}${window.location.pathname}`;
  }
};
