import {Box, Button, chakra, Flex, Icon, Spacer, useBreakpointValue, useDisclosure,} from "@chakra-ui/react";
import {colorHex} from "@workspace/ui";
import {FC, ReactNode, useMemo} from "react";
import {IoHelpCircle, IoMenuOutline} from "react-icons/io5";
import {Link} from "react-router-dom";

import {ReactComponent as Logo} from "../../assets/icons/logo.svg";
import {MAX_MOBILE_RESOLUTION} from "../../helpers/constants";
import useWindowWidth from "../../helpers/hooks/use-window-width";
import routes from "../../router/routes";
import {DsmIconButton} from "../buttons";

import {AccountInfo} from "./account-info";
import NavMenu, {NavbarRoute} from "./nav-menu";
import ShortcutKeysHelp from "./shortcut-keys-help";
import SideBarMenu from "./side-bar-menu";
import {TrialInfo} from "./TrialInfo";
import {NavLinks} from "./nav-links";

const StyledLogo = chakra(Logo);
const StyledIoHelpCircle = chakra(IoHelpCircle);

interface TopNavbarProps {
  activeRoute?: NavbarRoute;
  isBasicHeader?: boolean;
  showTrialInfo?: boolean;
  middleContent?: ReactNode;
}

export const TopNavbar: FC<TopNavbarProps> = ({
  activeRoute,
  isBasicHeader,
  showTrialInfo,
  middleContent,
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const order = useBreakpointValue({base: 6, sm: 0});
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < MAX_MOBILE_RESOLUTION;

  const sideBarMenu = useMemo(
    () => (
      <>
        {isMobile && (
          <Button
            onClick={onOpen}
            color="black"
            variant="link"
            _focus={{outline: "none"}}
            _active={{bgColor: "transparent"}}
            _hover={{bgColor: "transparent"}}
          >
            <Icon as={IoMenuOutline} fontSize="2xl" />
          </Button>
        )}
        {isMobile && <SideBarMenu isOpen={isOpen} onClose={onClose} />}

        {!isMobile && (
          <Flex>
            {!isBasicHeader && (
              <>
                <Box pl={4} borderLeftWidth="1px" borderLeftColor="gray.150">
                  <DsmIconButton
                    w={10}
                    icon={<StyledIoHelpCircle size="24px" color="gray.500" />}
                    aria-label="Shortcut help"
                    bgColor="transparent"
                    onClick={onOpen}
                  />
                </Box>
                <ShortcutKeysHelp isOpen={isOpen} onClose={onClose} />
              </>
            )}
            <AccountInfo />
          </Flex>
        )}
      </>
    ),
    [isMobile, isOpen, isBasicHeader, onClose, onOpen],
  );

  return (
    <Flex
      position="relative"
      zIndex="10"
      as="nav"
      px="6"
      py="2"
      bg="white"
      borderColor={colorHex.alpha05}
      borderBottomWidth="1px"
      height="64px"
      align="center"
    >
      <Flex
        align="center"
        justify={middleContent ? "" : "space-between"}
        wrap="wrap"
        maxWidth="1440px"
        mx="auto"
        flexGrow="1"
      >
        {isMobile && !isBasicHeader && (
          <Button
            onClick={onOpen}
            color="black"
            variant="link"
            _focus={{outline: "none"}}
            _active={{bgColor: "transparent"}}
            _hover={{bgColor: "transparent"}}
          >
            <Icon as={IoMenuOutline} fontSize="2xl" />
          </Button>
        )}

        <Link to={routes.Integrations.route}>
          <StyledLogo
            w={isMobile ? "102px" : "119px"}
            h="auto"
            mr={{base: "16px", md: "38px"}}
          />
        </Link>

        {!isMobile && !isBasicHeader && (
          <Box order={order} w={{base: "100%", sm: "auto"}}>
            <NavMenu activeRoute={activeRoute} />
          </Box>
        )}
        {!isMobile && !showTrialInfo && !middleContent && <Spacer />}
        {
          !middleContent ? (
            <NavLinks />
          ) : middleContent
        }
        {showTrialInfo ? (
          <Flex id="dadada">
            <TrialInfo />
            {sideBarMenu}
          </Flex>
        ) : (
          sideBarMenu
        )}
      </Flex>
    </Flex>
  );
};
