import {chakra} from "@chakra-ui/react";
import {Select} from "../Select";
import { components } from "react-select";

export const StyledSelect = chakra(Select, {
  baseStyle: {
    height: "24px",
  },
});

export const StyledSelectContainer = chakra(components.SelectContainer, {
  baseStyle: {
    display: "inline-block",
    width: "fit-content",
  },
});

export const StyledControl = chakra(components.Control, {
  baseStyle: {
    border: "none",
    display: "inline-flex",
    boxShadow: "none",
    cursor: "pointer",
    color: "gray.900",
    minHeight: "fit-content",
    height: "fit-content",
  },
});

export const StyledSingleValue = chakra(components.SingleValue, {
  baseStyle: {
    fontWeight: "bold",
    p: 0,
    m: 0,
    fontSize: "h5",
  },
});

export const StyledValueContainer = chakra(components.ValueContainer, {
  baseStyle: {
    p: 0,
    pl: 4,
    pr: 8,
  },
});

export const StyledIndicatorsContainer = chakra(components.IndicatorsContainer, {
  baseStyle: {
    "& > div": {
      p: 0,
    },
  },
});

export const StyledMenu = chakra(components.Menu, {
  baseStyle: {
    width: "288px",
    p: 4,
    borderRadius: 12,
  },
});

export const StyledOption = chakra(components.Option, {
  baseStyle: {
    px: 12,
    py: 8,
    borderRadius: 8,
  },
});
