import {Conversation} from '../conversation/types';
import {TwitterUser} from '../user/types';

export enum MessageSearchSortOrder {
  MOST_RECENT = 'mostRecent',
  MOST_FOLLOWERS = 'mostFollowers',
  HIGHEST_FOLLOW_RATIO = 'highestFollowRatio',
}

export enum SearchTagsMode {
  ALL = 'all',
  TAGGED = 'tagged',
  UNTAGGED = 'untagged',
}

export type MessageSearchFilters = {
  isRead?: boolean;
  followsUserOnly: boolean;
  followedByUserOnly: boolean;
  hasWords?: string;
  minFollowers?: number;
  maxFollowers?: number;
  verified?: boolean;
  tags?: {
    mode: SearchTagsMode;
    tags: string[];
  };
};

export type MessageSearchResult = {
  displayText: string;
  displayName?: string;
  displayUsername?: string;
  conversation?: Conversation;
};

export enum SearchType {
  MESSAGES = 'messages',
  FOLLOWERS = 'followers',
}

export enum SearchToken {
  Description = 'description',
  Location = 'location',
  MaxFollowers = 'max_followers',
  MinFollowers = 'min_followers',
  Name = 'name',
  Sort = 'sort',
  Tags = 'tags',
  Username = 'username',
  Verified = 'verified',
}

export const initialFiltersFormValues = {
  maxFollowers: '',
  minFollowers: '',
  verified: {
    isVerified: false,
    isUnverified: false,
  },
  location: '',
  username: '',
  name: '',
  tags: '',
  description: '',
};

export type FriendsSearchData = {
  query: string;
  filters?: FiltersValues;
  sortOrder?: FriendsSortOrder;
};

export type FiltersFormValues = typeof initialFiltersFormValues;

export type FiltersValues = Partial<
  Omit<FiltersFormValues, 'verified' | 'tags'> & {
    verified: boolean;
    tags: string[];
  }
>;

export enum FriendsSortOrder {
  RELEVANCE = 'relevance',
  MOST_FOLLOWERS = 'most_followers',
  LEAST_FOLLOWERS = 'least_followers',
  MOST_FOLLOWER_RATIO = 'most_follower_ratio',
  LEAST_FOLLOWER_RATIO = 'least_follower_ratio',
}

export const FriendsSortLabelsMap = {
  [FriendsSortOrder.RELEVANCE]: 'Relevance',
  [FriendsSortOrder.LEAST_FOLLOWERS]: 'Followers - Low to high',
  [FriendsSortOrder.MOST_FOLLOWERS]: 'Followers - High to low',
  [FriendsSortOrder.LEAST_FOLLOWER_RATIO]:
    'Follower/Following ratio - Low to high',
  [FriendsSortOrder.MOST_FOLLOWER_RATIO]:
    'Follower/Following ratio - High to low',
};

export interface TwitterUserWithCanDm extends TwitterUser {
  canDm?: boolean;
}
