import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {setActingUser, unsetActingUser} from '../acting-user/acting-user-slice';
import {resetApp} from '../user/user-slice';

import {AnalyticsCadence, AnalyticsData} from './types';

export type CadenceData =
  | {isLoading: true}
  | {isLoading: false; current: AnalyticsData; previous: AnalyticsData};

export interface AnalyticsState {
  selectedCadence: AnalyticsCadence;
  analyticsPerCadence: Partial<Record<AnalyticsCadence, CadenceData>>;
}

export const initialState: AnalyticsState = {
  selectedCadence: AnalyticsCadence.DAILY,
  analyticsPerCadence: {},
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setSelectedCadence: (state, action: PayloadAction<AnalyticsCadence>) => {
      state.selectedCadence = action.payload;
    },
    markLoading: (state, action: PayloadAction<AnalyticsCadence>) => {
      state.analyticsPerCadence[action.payload] = {isLoading: true};
    },
    setAnalyticsData: (
      state,
      action: PayloadAction<{
        cadence: AnalyticsCadence;
        current: AnalyticsData;
        previous: AnalyticsData;
      }>
    ) => {
      const {cadence, current, previous} = action.payload;
      state.analyticsPerCadence[cadence] = {
        isLoading: false,
        current,
        previous,
      };
    },
    resetAnalyticsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetApp, () => initialState);

    // Resetting analytics when acting user changes (due to admin impersonation)
    builder.addCase(setActingUser, () => initialState);
    builder.addCase(unsetActingUser, () => initialState);
  },
});

export const {
  setSelectedCadence,
  markLoading,
  setAnalyticsData,
  resetAnalyticsData,
} = analyticsSlice.actions;

export const reducer = analyticsSlice.reducer;
