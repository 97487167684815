import {isValid, differenceInSeconds} from 'date-fns';

import {
  OptimisticUpdate,
  ConversationStatus,
  ConversationStage,
} from '../types';

const updateOptimisticUpdates = (
  optimisticUpdates: OptimisticUpdate = {},
  newOptimisticUpdate:
    | Record<'status', ConversationStatus>
    | Record<'stage', ConversationStage>
): OptimisticUpdate => {
  // 5 seconds should be enough time for API calls to wrap up and there should
  // be no more need for an optimistic update as the conversations in the snapshot
  // should be up to date
  const ELAPSED_SECONDS_TO_EVICT = 5;
  const previousUpdatedAt =
    optimisticUpdates.updatedAt && new Date(optimisticUpdates.updatedAt);
  const now = new Date();

  // Check to see how old it is, if it's old, evict
  if (
    previousUpdatedAt && // needed for typing
    isValid(previousUpdatedAt) &&
    differenceInSeconds(now, previousUpdatedAt) > ELAPSED_SECONDS_TO_EVICT
  ) {
    // evict everything else and return the new update
    return {
      ...newOptimisticUpdate,
      updatedAt: now.toString(),
    };
  }
  return {
    ...optimisticUpdates,
    ...newOptimisticUpdate,
    updatedAt: now.toString(),
  };
};

export default updateOptimisticUpdates;
