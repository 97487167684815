import {Grid, GridItem, GridProps} from "@chakra-ui/react";
import {FC, PropsWithChildren} from "react";

const Layout: FC<PropsWithChildren<GridProps>> = ({children, ...props}) => (
  <Grid
    h="100vh"
    px={6}
    py={5}
    justifyItems="center"
    alignItems="start"
    gridTemplateColumns="1fr minmax(auto, 500px) 1fr"
    gridTemplateRows="68px 1fr"
    bg="#F4F4F9"
    {...props}
  >
    <GridItem gridColumn="2" gridRow="2/3" w="100%" h="100%">
      {children}
    </GridItem>
  </Grid>
);

export default Layout;
