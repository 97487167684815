import {Box} from "@chakra-ui/react";
import {Textarea} from "@workspace/ui";
import {FC} from "react";
import {StepSectionSubtext, StepSectionTitle, StepTitle} from "../styled";
import {InterestsStepProps} from "./types";

export const InterestsStep: FC<InterestsStepProps> = ({customerInterests, onChange}) => {
  return (
    <Box>
      <StepTitle>What do your customers like?</StepTitle>
      <StepSectionTitle mb="10">What are the interests of the people you are selling to?</StepSectionTitle>
      <StepSectionSubtext>Describe in detail</StepSectionSubtext>
      <Textarea
        minH="152px"
        maxH="200px"
        placeholder="Add as much information as you think is needed..."
        p="4"
        fontSize="h6"
        color="gray.900"
        background="alpha.05"
        borderRadius="12px"
        resize="vertical"
        value={customerInterests}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  );
};
