import {FC} from 'react';

import InnerAppLayout from '../../../components/inner-app-layout';
import ChromeExtInstallation from './chrome-ext-installation';
import ChromeExtInstallationSuccess from './chrome-ext-installation-success';
import {useDsmChromeExtension} from 'src/hooks';

const ChromeExt: FC = () => {
  const {extensionIsInstalled} = useDsmChromeExtension();

  if (extensionIsInstalled === undefined) {
    return <InnerAppLayout hasBasicHeader />;
  }

  return extensionIsInstalled ? (
    <ChromeExtInstallationSuccess />
  ) : (
    <ChromeExtInstallation />
  );
};

export default ChromeExt;
