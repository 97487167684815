/**
 * Creates shortened version of deterministic conversation id from
 * the database for using in URL
 */
const shortenConversationId = (id: string): string => {
  const [userId, platform, platformUserId, platformFriendId] = id.split('::');

  if (!userId || !platform || !platformUserId || !platformFriendId) {
    console.error('Wrong format of the conversation id');
    return id;
  }

  return `${platformUserId}-${platformFriendId}`;
};

export default shortenConversationId;
