import * as Sentry from '@sentry/browser';
import {Middleware} from 'redux';

export const sentryMiddleware: Middleware = () => (next) => (action) => {
  Sentry.addBreadcrumb({
    category: 'redux-action',
    message: action.type,
  });

  return next(action);
};
