import {createSlice} from '@reduxjs/toolkit';

import {User} from '../user/types';

export interface ActingUserState {
  user?: User;
}

export const initialState: ActingUserState = {
  user: undefined,
};

const actingUserSlice = createSlice({
  name: 'actingUser',
  initialState,
  reducers: {
    setActingUser: (state, action) => {
      state.user = action.payload;
    },
    unsetActingUser: () => initialState,
  },
});

export const {setActingUser, unsetActingUser} = actingUserSlice.actions;

export const reducer = actingUserSlice.reducer;
