// TODO
// NODE_ENV is used by extension, REACT_APP_ENVIRONMENT is used by frontend.
// Use another environment variable: DEPLOY_STAGE.
const NODE_ENV = process.env["NODE_ENV"];
const REACT_APP_ENVIRONMENT = process.env["REACT_APP_ENVIRONMENT"];
const FUNCTIONS_ENVIRONMENT = process.env["FUNCTIONS_ENVIRONMENT"];

export const envIsProd =
  typeof REACT_APP_ENVIRONMENT === "string"
    ? REACT_APP_ENVIRONMENT === "production"
    : typeof FUNCTIONS_ENVIRONMENT === "string"
    ? FUNCTIONS_ENVIRONMENT === "production"
    : NODE_ENV === "production";

// craco only respects environment variables that start with REACT_APP_
export const firebaseIsLocal =
  !!process.env["LOCAL_FIREBASE"] || !!process.env["REACT_APP_LOCAL_FIREBASE"];
