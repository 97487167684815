import {Day, Operation, isDay} from "@workspace/models";
import {
  objectTypeGuard,
  NonNegativeInteger,
  isNonNegativeInteger,
} from "@workspace/type-utils";

export type MessagesDailyCount = {
  day: Day;
  twitter: NonNegativeInteger;
  instagram: NonNegativeInteger;
  instagramSkipped: NonNegativeInteger;
};

export type ScheduledMessagesDailyCount = Omit<MessagesDailyCount, 'instagramSkipped'>;

export const isMessagesDailyCount = objectTypeGuard<MessagesDailyCount>(
  ({day, twitter, instagram, instagramSkipped}) =>
    isDay(day) &&
    isNonNegativeInteger(twitter) &&
    isNonNegativeInteger(instagram) &&
    isNonNegativeInteger(instagramSkipped),
);

export const isScheduledMessagesDailyCount = objectTypeGuard<ScheduledMessagesDailyCount>(
  ({day, twitter, instagram}) =>
    isDay(day) &&
    isNonNegativeInteger(twitter) &&
    isNonNegativeInteger(instagram)
);

export type ReadMessagesDailyCount = Operation<void, MessagesDailyCount>;
export const isReadMessagesDailyCountOutput = objectTypeGuard<
  ReadMessagesDailyCount["out"]
>(({twitter, instagram, day, instagramSkipped}) => isDay(day) && isNonNegativeInteger(twitter) && isNonNegativeInteger(instagram) && (instagramSkipped === undefined || isNonNegativeInteger(instagramSkipped)));
