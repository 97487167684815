import {IsNever} from "type-samurai";
import {
  NonEmptyString,
  isNonEmptyString,
  isLiteralType,
  objectTypeGuard,
} from "@workspace/type-utils";
import {MessagesDailyQuota, isMessagesDailyQuota} from "./MessagesDailyQuota";

const monthlyPlanNames = ["basic", "popular", "pro"] as const;
const yearlyPlanNames = ["basic_yearly", "popular_yearly", "pro_yearly"] as const;

// TODO should be pricingPlanIds, monthlyPlanIds, etc.
export const pricingPlanNames = [
  ...monthlyPlanNames,
  ...yearlyPlanNames,
  "enterprise",
  "starter",
] as const;
export type PricingPlanName = (typeof pricingPlanNames)[number];
export const isPricingPlanName = isLiteralType<PricingPlanName>(pricingPlanNames);

export type PricingPlan = MessagesDailyQuota & {
  /**
   * Stripe pricing plan metadata, stores this id.
   */
  id: PricingPlanName;
  /**
   * Stripe priceId.
   *
   * @example `price_1KLpBFLzNhu99u23vNlMGqrl`
   */
  priceId: NonEmptyString;
};

export const isPricingPlan = objectTypeGuard<PricingPlan>(
  ({priceId, maxDailyMessages, id}) =>
    isNonEmptyString(priceId) &&
    isMessagesDailyQuota({maxDailyMessages}) &&
    isPricingPlanName(id),
);

export type PricingPlanKey = {
  pricingPlan: PricingPlanName;
};
export const isPricingPlanKey = objectTypeGuard<PricingPlanKey>(({pricingPlan}) =>
  isPricingPlanName(pricingPlan),
);

type FilterYearlyPricingPlans<U = PricingPlanName> = U extends `${string}_yearly`
  ? U
  : never;

export type YearlyPricingPlan = FilterYearlyPricingPlans;
export type MonthlyPricingPlan = Exclude<
  PricingPlanName,
  YearlyPricingPlan | "enterprise" | "starter"
>;

export const isMonthlyPricingPlan = (arg: unknown): arg is MonthlyPricingPlan =>
  isPricingPlanName(arg) && !arg.endsWith("_yearly") && arg !== "enterprise";

export const isYearlyPricingPlan = (arg: unknown): arg is YearlyPricingPlan =>
  isPricingPlanName(arg) && arg.endsWith("_yearly");

export type PricingPlanDetails = {
  description: string;
  messagesPerDay: number;
  price: string;
};
export const isPricingPlanDetails = objectTypeGuard<PricingPlanDetails>(
  ({description, messagesPerDay, price}) =>
    typeof description === "string" &&
    typeof messagesPerDay === "number" &&
    typeof price === "string",
);

export const monthlyPricingPlans: Record<MonthlyPricingPlan, PricingPlanDetails> = {
  basic: {
    description: "For users that want to try",
    messagesPerDay: 20,
    price: "67.00",
  },
  popular: {
    description: "For users that want to try",
    messagesPerDay: 50,
    price: "137.00",
  },
  pro: {
    description: "For users that want to try",
    messagesPerDay: 100,
    price: "267.00",
  },
};

export const yearlyPricingPlans: Record<YearlyPricingPlan, PricingPlanDetails> = {
  basic_yearly: {
    description: "For users that want to try",
    messagesPerDay: 20,
    price: "57.00",
  },
  popular_yearly: {
    description: "For users that want to try",
    messagesPerDay: 50,
    price: "117.00",
  },
  pro_yearly: {
    description: "For users that want to try",
    messagesPerDay: 100,
    price: "217.00",
  },
};

export const allPricingPlans: Record<PricingPlanName, PricingPlanDetails> = {
  ...monthlyPricingPlans,
  ...yearlyPricingPlans,
  starter: {
    description: "For users that want to try",
    messagesPerDay: 5,
    price: "",
  },
  enterprise: {
    description: "For users that want to try",
    messagesPerDay: 99999,
    price: "",
  },
};

export const monthlyToYearlyPlans: Partial<
  Record<MonthlyPricingPlan, YearlyPricingPlan>
> = {
  basic: "basic_yearly",
  popular: "popular_yearly",
  pro: "pro_yearly",
};

export const yearlyToMonthlyPlans: Partial<
  Record<YearlyPricingPlan, MonthlyPricingPlan>
> = {
  basic_yearly: "basic",
  popular_yearly: "popular",
  pro_yearly: "pro",
};

export const FEATURE_NAMES = [
  "collect_leads_from_engagement",
  "custom_message_templates",
  "manage_campaigns",
  "list_upload",
  "magic_dm",
  "follow_up_messages",
  "scheduling_for_the_next_day",
] as const satisfies readonly string[];

export type FeatureName = (typeof FEATURE_NAMES)[number];

export const isFeatureName = isLiteralType<FeatureName>(FEATURE_NAMES);

export const PRICING_PLAN_FEATURES: Record<PricingPlanName, FeatureName[]> = {
  basic: [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
  ],
  basic_yearly: [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
  ],
  popular: [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
    "list_upload",
    "magic_dm",
  ],
  popular_yearly: [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
    "list_upload",
    "magic_dm",
  ],
  pro: [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
    "list_upload",
    "magic_dm",
    "follow_up_messages",
    "scheduling_for_the_next_day",
  ],
  pro_yearly: [
    "collect_leads_from_engagement",
    "custom_message_templates",
    "manage_campaigns",
    "list_upload",
    "magic_dm",
    "follow_up_messages",
    "scheduling_for_the_next_day",
  ],
  starter: [],
  enterprise: [],
};

// TODO Kamil replace
export type Permutation<T, U = T> = IsNever<T> extends true
  ? []
  : T extends T
  ? [T, ...Permutation<Exclude<U, T>>]
  : [];

type Zip<
  T extends readonly unknown[],
  K extends readonly unknown[],
  Result extends readonly (readonly unknown[])[] = [],
> = T extends readonly [infer TItem, ...infer TRest extends unknown[]]
  ? K extends readonly [infer KItem, ...infer KRest extends unknown[]]
    ? Zip<TRest, KRest, [...Result, [TItem, KItem]]>
    : Result
  : Result;

export const PRICING_PLANS_SUPERIORITY_ORDER: Zip<
  typeof monthlyPlanNames,
  typeof yearlyPlanNames
> = [
  ["basic", "basic_yearly"],
  ["popular", "popular_yearly"],
  ["pro", "pro_yearly"],
];
