import {Box, chakra} from '@chakra-ui/react';
import React from 'react';

export interface ConnectionsLabelProps {
  label: string;
}

const ConnectionsLabel: React.FC<ConnectionsLabelProps> = ({label}) => (
  <chakra.span ml={{base: 2, md: 0}} verticalAlign="top">
    <chakra.span
      mx="11px"
      backgroundColor="primary.100"
      display={{base: 'none', lg: 'inline-block'}}
      width="4px"
      height="4px"
      verticalAlign="middle"
      borderRadius={2}
    />
    <Box
      as="span"
      fontSize="xs"
      bgColor="gray.150"
      color="gray.600"
      px={2}
      borderRadius={8}
      lineHeight="22px"
      display="inline-block"
    >
      {label}
    </Box>
  </chakra.span>
);

export default ConnectionsLabel;
