/* eslint-disable no-console */
import { env_is } from "./env";
/**
 * @description: Prints to console in dev environment; accepts optional indents
 * @exampleInput: 'foo', 2
 * @exampleOutput: console.log('  foo')
 * @pure: false: depends on env_is, prints to console
 * @hasTests: false
 */
var PrinterTypes;
(function (PrinterTypes) {
    PrinterTypes["LOG"] = "LOG";
    PrinterTypes["WARN"] = "WARN";
    PrinterTypes["ERROR"] = "ERROR";
})(PrinterTypes || (PrinterTypes = {}));
export function devprint(textToPrint, relatedValue, indentsNum, type) {
    if (!env_is.local())
        return;
    const printer = {
        [PrinterTypes.LOG]: console.log,
        [PrinterTypes.WARN]: console.warn,
        [PrinterTypes.ERROR]: console.error,
    };
    if (!type) {
        // WAS_eslint-disable-next-line no-param-reassign
        type = PrinterTypes.LOG;
    }
    const tabs = Number.isFinite(indentsNum) ? " ".repeat(indentsNum) : "";
    // const tabs = indentsNum ? "\t".repeat(indentsNum) : ""; // Tabs
    printer[type](tabs + textToPrint, relatedValue instanceof Error ? JSON.stringify(relatedValue) : relatedValue !== null && relatedValue !== void 0 ? relatedValue : "");
}
/* WAS_eslint-disable functional/immutable-data */
devprint.warn = function warn(text, relatedValue, indentsNum = 0) {
    return devprint(text, relatedValue, indentsNum, PrinterTypes.WARN);
};
devprint.error = function error(text, relatedValue, indentsNum = 0) {
    return devprint(text, relatedValue, indentsNum, PrinterTypes.ERROR);
};
