import {tabsAnatomy} from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  useMultiStyleConfig,
  TabProps as ChakraTabProps,
  useTab,
  Button,
  TabListProps,
  Box,
  BoxProps,
  Flex,
  Tabs as ChakraTabs,
  TabsProps,
  ButtonProps,
  TabList as ChakraTabList,
} from "@chakra-ui/react";
import {Children, cloneElement, FC, forwardRef} from "react";
import {colorName} from "../colors";
import {Icon, IconKey} from "./icons";

export {TabPanel, TabPanels} from "@chakra-ui/react";

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(
  tabsAnatomy.keys,
);

const lineVariantBaseStyle = definePartsStyle({
  tab: {
    py: "2.5",
    fontSize: "14px",
    fontWeight: "600",
    _selected: {
      color: colorName.primary400,
      _hover: {
        color: colorName.primary400,
        cursor: "default",
      },
    },
    color: colorName.gray500,
    _hover: {
      color: colorName.gray800,
    },
  },
});

const outlinedVariantBaseStyle = definePartsStyle({
  root: {
    bg: "#F4F4F6",
    p: "3px",
    borderRadius: "8px",
  },
  tab: {
    fontSize: "13px",
    fontWeight: "500",
    flexGrow: 1,
    borderRadius: "6px",
    color: "alpha.60",
    padding: "4px 0",
    _selected: {
      boxShadow: "0px 5px 12px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.14)",
      color: "gray.900",
      bg: "white",
      height: "fit-content",
      _hover: {
        color: "gray.800",
        bg: "white",
        cursor: "default",
      },
    },
  },
});

export const tabsStyleConfig = defineMultiStyleConfig({
  variants: {
    line: lineVariantBaseStyle,
    outlined: outlinedVariantBaseStyle,
  },
});

type TabProps = ChakraTabProps & {
  amount?: number | string;
  icon?: IconKey;
};

export const Tab = forwardRef<HTMLElement, TabProps>(({amount, icon, ...props}, ref) => {
  const tabProps = useTab({...props, ref});
  const isSelected = !!tabProps["aria-selected"];

  const styles = useMultiStyleConfig("Tabs", tabProps);

  const amountStyles: Partial<BoxProps> = {
    fontSize: "11px",
    p: "1",
    borderRadius: "full",
    as: "span",
    fontWeight: "700",
    lineHeight: "12px",
    bg: colorName.gray50,
  };

  if (isSelected) {
    amountStyles.bg = colorName.primary50;
  }

  // Had to cast to prevent conflicts in the props such as string | undefined and string union
  return (
    <Button __css={styles["tab"] ?? {}} _active={{}} {...(tabProps as ButtonProps)}>
      <Flex alignItems="center" gap="1" justifyContent="center">
        {icon && <Icon name={icon} size="17.45px" />}
        {tabProps.children}
        {amount !== undefined && (
          <Box as="span" {...amountStyles}>
            {amount}
          </Box>
        )}
      </Flex>
    </Button>
  );
});

Tab.displayName = "Tab";

export type TabsVariant = TabsProps["variant"] | "outlined";

// The following cloning elements is done because the variant is not passed to the custom Tab
// and it doesn't apply the expected styles
export const Tabs: FC<
  Omit<TabsProps, "variant" | "children"> & {
    variant?: TabsVariant;
    children: JSX.Element;
  }
> = ({children, ...props}) => (
  <ChakraTabs {...props}>
    {cloneElement(
      children,
      {...children.props, variant: props.variant},
      children.props.children,
    )}
  </ChakraTabs>
);

export const TabList: FC<
  Omit<TabListProps, "children"> & {
    variant?: TabsVariant;
    children: JSX.Element[];
  }
> = (props) => (
  <ChakraTabList {...props}>
    {Children.map(props.children, (child) =>
      cloneElement(child, {...child.props, variant: props.variant}, child.props.children),
    )}
  </ChakraTabList>
);
