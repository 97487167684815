import {isInvalidDate, ErrorInvalidDate} from "./date";
import {TIME, Time} from "./time";

/** String with format `yyyy-mm-dd`. */
export type Day = string;

export const isDay = (arg: unknown): arg is Day => {
  if (typeof arg !== "string") return false;
  const date = new Date(arg);
  if (isInvalidDate(date)) return false;
  const day = date.toJSON().substring(0, 10);
  return day === arg;
};

/** Convert `Date` to `Day`. */
export const dateToDay = (date: Date): Day => {
  if (isInvalidDate(date)) throw new ErrorInvalidDate();
  return date.toJSON().substring(0, 10);
};

/** Convert `Time` to `Day`. */
export const timeToDay = (time: Time): Day => {
  const date = new Date(time);
  return dateToDay(date);
};

export const today = (): Day => dateToDay(new Date());

export const yesterday = (): Day => {
  const today = new Date();
  const yesterday = new Date(today.getTime() - TIME.ONE_DAY);
  return dateToDay(yesterday)
}
