import {
  Audience,
  AUDIENCES,
  BUSINESS_TYPE,
  BusinessType,
  Campaign,
  CampaignInfo,
  Discovery,
  DiscoveryData,
  DiscoveryPost,
  DiscoveryQueue,
  DiscoveryReport,
  DiscoveryStage,
  DiscoveryStages,
  DiscoveryTarget,
  isCampaignMessages,
  isSocialNetworkUser,
  isUpdateTime,
  NewDiscovery,
  Operation,
  Pricing,
  PRICINGS,
  Selling,
  SELLINGS
} from "@workspace/models";
import {
  arrayTypeGuard,
  isLiteralObjectType,
  isLiteralType,
  isNonEmptyString,
  isNonNegativeInteger,
  NonEmptyString,
  objectTypeGuard,
} from "@workspace/type-utils";

export type CreateDiscovery = Operation<NewDiscovery, void>;

export const isArrayOfStrings = arrayTypeGuard(isNonEmptyString);

export const isSellingLiteral = isLiteralType<Selling>(SELLINGS);
export const isAudienceLiteral = isLiteralType<Audience>(AUDIENCES);
export const isPricingLiteral = isLiteralType<Pricing>(PRICINGS);
export const isBusinessType = isLiteralType<BusinessType>(BUSINESS_TYPE);

export const isDiscoveryStage = isLiteralObjectType<DiscoveryStage>(DiscoveryStages);

export const isDiscoveryPost = objectTypeGuard<DiscoveryPost>(
  ({link}) =>
    isNonEmptyString(link)
);

const isDiscoveryPosts = arrayTypeGuard(isDiscoveryPost);

export const isCreateDiscoveryInput = objectTypeGuard<CreateDiscovery["in"]>(
  ({
    selling,
    descriptionOfMainClients,
    clientInterests,
    clientLocation,
    ICPExamples,
    audience,
    topInstagramPages,
    pricing,
    instagramSession
  }) =>
    isSellingLiteral(selling) &&
    isAudienceLiteral(audience) &&
    isNonEmptyString(descriptionOfMainClients) &&
    isNonEmptyString(clientInterests) &&
    isNonEmptyString(instagramSession) &&
    isArrayOfStrings(clientLocation) &&
    isPricingLiteral(pricing) &&
    isArrayOfStrings(ICPExamples) &&
    isArrayOfStrings(topInstagramPages),
);

export const isDiscovery = objectTypeGuard<Discovery>(
  ({
    userId,
    selling,
    audience,
    descriptionOfMainClients,
    clientLocation,
    clientInterests,
    ICPExamples,
    topInstagramPages,
    pricing,
    generatedPosts,
    activePost,
    activePage,
    currentStage,
  }) =>
    isNonEmptyString(userId) &&
    isSellingLiteral(selling) &&
    isAudienceLiteral(audience) &&
    isNonEmptyString(descriptionOfMainClients) &&
    isArrayOfStrings(clientLocation) &&
    isNonEmptyString(clientInterests) &&
    isArrayOfStrings(ICPExamples) &&
    isArrayOfStrings(topInstagramPages) &&
    isPricingLiteral(pricing) &&
    isDiscoveryPosts(generatedPosts) &&
    typeof activePost === "number" &&
    typeof activePage === "number" &&
    isDiscoveryStage(currentStage),
);

export type NextDiscoveryAction = Operation<
  void,
  {
    link: string;
    stage: Discovery["currentStage"];
    discoveryId: string;
    nextCursor?: string;
  } | null
>;

export const isNextDiscoveryActionOutput = objectTypeGuard<
  Exclude<NextDiscoveryAction["out"], null>
>(
  ({link, stage, discoveryId}) =>
    isNonEmptyString(link) && isNonEmptyString(stage) && isNonEmptyString(discoveryId),
);

export type SaveDiscoveryPostsAction = Operation<
  {posts: DiscoveryPost[]},
  void
>;

export const isSaveDiscoveryPostsInput = objectTypeGuard<SaveDiscoveryPostsAction["in"]>(
  ({posts}) => isDiscoveryPosts(posts));

export const isDiscoveryTarget = objectTypeGuard<DiscoveryTarget>(
  ({followers, following, posts, comment, id, ...rest}) =>
    isSocialNetworkUser(rest) &&
    isNonEmptyString(followers) &&
    isNonEmptyString(following) &&
    isNonEmptyString(posts) &&
    isNonEmptyString(comment) &&
    isNonEmptyString(id),
);

export const isDiscoveryTargets = arrayTypeGuard(isDiscoveryTarget);

export type SaveDiscoveryTargetsAction = Operation<
  {
    targets: DiscoveryTarget[];
    postUrl: string;
    nextCursor?: string;
    postCaption: string;
    activePost: number;
  },
  void
>;

export const isSaveDiscoveryTargetsInput = objectTypeGuard<
  SaveDiscoveryTargetsAction["in"]
>(({targets, postUrl, activePost}) => {
  return (
    isNonEmptyString(postUrl) &&
    isDiscoveryTargets(targets) &&
    isNonNegativeInteger(activePost)
  );
});

export const isDiscoveryQueue = objectTypeGuard<DiscoveryQueue>(
  ({activeSchedulerId, whenUpdated}) =>
    isUpdateTime({whenUpdated}) &&
    (activeSchedulerId === undefined ? true : isNonEmptyString(activeSchedulerId)),
);

type SaveActiveDiscoveryTabInput = {
  schedulerId: NonEmptyString;
};

export const isSaveActiveDiscoveryTabInput = objectTypeGuard<SaveActiveDiscoveryTabInput>(
  ({schedulerId}) => isNonEmptyString(schedulerId),
);

export type CreateCampaignFromDiscovery = Operation<
  Pick<Campaign, "messages" | "instagramHandle" | "instagramAvatar" | "instagramId"> & {
    targetIds: string[];
    discoveryId: string;
  },
  CampaignInfo | void
>;

export const isCreateCampaignFromDiscoveryInput = objectTypeGuard<
  CreateCampaignFromDiscovery["in"]
>(
  ({messages, instagramHandle, targetIds, discoveryId}) =>
    isCampaignMessages(messages) &&
    isNonEmptyString(instagramHandle) &&
    isArrayOfStrings(targetIds) &&
    isNonEmptyString(discoveryId),
);

export const isDiscoveryData = objectTypeGuard<DiscoveryData>(
  ({status, targets, discoveryId}) =>
    (targets === undefined || isDiscoveryTargets(targets)) &&
    isNonEmptyString(status) &&
    (discoveryId === undefined || isNonEmptyString(discoveryId)),
);

type DescribeInterestFromOpenAIInput = {
  selling: Selling;
  audience: Audience;
};

export const isDescribeInterestFromOpenAIInput =
  objectTypeGuard<DescribeInterestFromOpenAIInput>(
    ({selling, audience}) => isSellingLiteral(selling) && isAudienceLiteral(audience),
  );

export const isUpdateDiscoverySessionInput = objectTypeGuard<Partial<Discovery>>(
  ({
    instagramSession,
  }) =>
    isNonEmptyString(instagramSession),
);

export const isSaveDiscoveryReportInput = objectTypeGuard<DiscoveryReport>(
  ({
     currentCollectedCount,
     timestamp,
     errors,
     targetsCollected,
     action
  }) => (
    isNonNegativeInteger(currentCollectedCount) &&
    isNonNegativeInteger(timestamp) &&
    isArrayOfStrings(errors) &&
    isNonNegativeInteger(targetsCollected) &&
    isDiscoveryStage(action)
  )
)

type DeleteDiscoveryTargetInput = {
  discoveryId: string;
  targetId: string;
  startAfter: string;
}

export const isDeleteDiscoveryTargetInput = objectTypeGuard<DeleteDiscoveryTargetInput>((
  {discoveryId, targetId, startAfter}
) => (
  isNonEmptyString(discoveryId) &&
  isNonEmptyString(targetId) &&
  isNonEmptyString(startAfter)
))

export const isDeleteDiscoveryTargetOutput = isDiscoveryTarget
