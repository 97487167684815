import {Flex, Box, Link} from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import React, {useMemo} from 'react';

import {useAppDispatch} from '../../../../app-hooks';
import {DsmSolidButton} from '../../../../components/buttons';
import {DsmInternalSolidLink} from '../../../../components/links';
import useMixpanel from '../../../../helpers/hooks/use-mixpanel';
import shortenConversationId from '../../../../helpers/shorten-conversation-id';
import routes from '../../../../router/routes';
import {onConversationOpen} from '../../../conversation/conversations-slice';
import {buildTwitterProfileLink} from '../../../conversation/helpers/profile-link-helpers';
import {TwitterFriendshipConnection} from '../../../conversation/types';
import {buildConnectionsLabel} from '../../helpers/build-connections-label';
import {CanDm, Friend} from '../../types';
import ConnectionsLabel from '../connections-label';

interface FollowerToolbarProps {
  follower: Friend;
  showMessageButton?: boolean;
}

const FollowLink = ({friend}: {friend: Friend}) => {
  const isFollowing =
    friend.connections &&
    friend.connections.includes(TwitterFriendshipConnection.FOLLOWING);
  // should be improved for redirecting to the Instagram user profile
  const profileLink = buildTwitterProfileLink(friend.twitterId as string);

  return (
    <Link
      href={profileLink}
      title="Go to Twitter profile"
      target="_blank"
      color={isFollowing ? 'gray.400' : 'primary.400'}
      fontSize="base"
      _focus={{
        outline: 'none',
      }}
    >
      {isFollowing ? 'Following' : 'Follow'}
    </Link>
  );
};

const FollowerToolbar: React.FC<FollowerToolbarProps> = ({
  follower,
  showMessageButton,
}) => {
  const dispatch = useAppDispatch();
  const sendMessageLink = useMemo(() => {
    const {conversationId} = follower;

    if (!conversationId) {
      Sentry.captureException(
        `No 'conversationId' field on follower ${follower.id}`
      );
    }

    return `${routes.Conversations.route}/${shortenConversationId(
      conversationId
    )}`;
  }, [follower]);
  const connectionsLabel = buildConnectionsLabel(follower);
  const mixpanel = useMixpanel();
  const sendMessageLinkOnClick = () => {
    const {conversationId} = follower;
    dispatch(onConversationOpen(conversationId));
    mixpanel?.track('web.community.results.open-inbox');
  };

  return (
    <Flex
      flexDir="column"
      justifyContent={{base: 'flex-end', md: 'space-between'}}
      alignItems={{base: 'flex-start', md: 'flex-end'}}
      ml="auto"
      w={{base: '100%', md: 'auto'}}
    >
      <Flex mr={{base: 4, md: 0}}>
        {connectionsLabel && (
          <Box display={{base: 'block', md: 'none'}} mr="19px">
            <ConnectionsLabel label={connectionsLabel} />
          </Box>
        )}
        <FollowLink friend={follower} />
      </Flex>

      {showMessageButton &&
        (follower.canDm !== CanDm.TRUE ? (
          <DsmSolidButton
            bg="white"
            my={{base: 4, md: 0}}
            border="1px"
            borderColor="primary.500"
            color="primary.500"
            p="5px 12.5px"
            fontSize="baseMajor"
            fontWeight="medium"
            disabled={true}
            h="34px"
            _hover={{
              color: 'white',
              bg: 'primary.500',
            }}
          >
            Send message
          </DsmSolidButton>
        ) : (
          <DsmInternalSolidLink
            to={sendMessageLink}
            onClick={sendMessageLinkOnClick}
            fontSize="baseMajor"
            p="5px 12.5px"
            my={{base: 4, md: 0}}
            h="34px"
            _focus={{
              outline: 'none',
            }}
            bg="white"
            border="1px"
            borderColor="primary.500"
            color="primary.500"
            _hover={{
              color: 'white',
              bg: 'primary.500',
            }}
            title="Send message"
          >
            Send message
          </DsmInternalSolidLink>
        ))}
    </Flex>
  );
};

export default FollowerToolbar;
