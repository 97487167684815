import {colorHex, Modal, ModalProps, Text} from "@workspace/ui";
import {FC} from "react";

export const DiscardChangesModal: FC<
  Omit<ModalProps, "onConfirm" | "children"> & {
    onConfirm: () => void;
  }
> = ({confirmButtonProps, ...props}) => {
  return (
    <Modal
      variant="delete"
      title="Discard changes?"
      confirmButtonProps={{
        children: "Discard changes",
        variant: "danger",
        bg: "red.500",
        ...confirmButtonProps,
      }}
      closeButtonProps={{
        color: "gray.900",
        borderWidth: "1px",
        borderColor: colorHex.alpha15,
        children: "Cancel",
      }}
      overlayProps={{
        w: "100%",
      }}
      contentProps={{
        containerProps: {
          left: "50%",
          transform: "translateX(-50%)",
          w: "380px",
          top: "300px",
        },
      }}
      {...props}
    >
      <Text fontSize="h6">
        Are you sure you want to discard the changes you made and apply new template?
        <br />
        <br />
        This action cannot be undone.
      </Text>
    </Modal>
  );
};
