import {getUrlParamValue} from '../../../helpers/get-url-param-value';
import {clearLocalStorage} from '../../../helpers/local-storage-helpers';

import {EXTENSION_REDIRECT_PATH} from './constants';

// Explain: We need somehow temporary save data about the last extension tab,
// and I decided to use localStorage. We can't use Redux, because we have to go
// Integration page (tw or ig page), and it will reset redux state.
export const saveExtensionRedirectPath = (): void => {
  const registrationOriginUrl = getUrlParamValue(
    'registrationOriginUrl',
    undefined
  );

  if (registrationOriginUrl) {
    localStorage.setItem(EXTENSION_REDIRECT_PATH, registrationOriginUrl);
  }
};

export const redirectToLastExtensionTab = (): void => {
  const extensionRedirectPath = localStorage.getItem(EXTENSION_REDIRECT_PATH);

  if (extensionRedirectPath) {
    clearLocalStorage([EXTENSION_REDIRECT_PATH]);
    location.replace(extensionRedirectPath);
  }
};
