import {Center, Box} from "@chakra-ui/react";
import {FC, PropsWithChildren} from "react";
import {useLocation} from "react-router";

import {useAppSelector} from "../app-hooks";
import routes from "../router/routes";

interface AppLayoutProps {
  maxContainerWidth?: string;
}

const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = ({
  maxContainerWidth,
  children,
}) => {
  const location = useLocation();
  const isAdmin = useAppSelector((state) => state.user.user?.isAdmin);
  const maxWidth = maxContainerWidth
    ? maxContainerWidth
    : location.pathname.startsWith(routes.Conversations.route) && isAdmin
    ? "1325px"
    : "1080px";

  return (
    <Center h="100%">
      <Box maxW={maxWidth} w="100%" h="100%">
        {children}
      </Box>
    </Center>
  );
};

export default AppLayout;
