import {arrayTypeGuard, isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {CreationTime, Item} from "@workspace/models";


export type LogEvent = {
  time: string;
  durationS?: number;
  name: string;
  type?: LogEventType;
  url?: string;
  payload?: unknown;
  id?: NonEmptyString;
};

// Explain: Here we don't capitalize values, because they will be read, when analyzing logs; and normal.
export enum LogEventType {
  BULK_SEND = "Bulk send",
  ERROR = "Error",
  REQUEST = "Request",
  RESPONSE = "Response",
  CLICK = "Click"
}

export const isLogEvent = objectTypeGuard<LogEvent>(
  ({ time, name }) => isNonEmptyString(time) && isNonEmptyString(name),
);

export const isLogEvents = arrayTypeGuard<LogEvent>(isLogEvent);

export const isSaveUserLogsInput = objectTypeGuard<{ tabId: string, events: LogEvent[], extensionVersion: string }>(
  ({ tabId, events, extensionVersion }) => isNonEmptyString(tabId) && isLogEvents(events) && isNonEmptyString(extensionVersion),
)

export type UserLog = Item & CreationTime & LogEvent & {
  tabId: string;
  userId: string;
  extensionVersion: string;
}

export const isUserLog = objectTypeGuard<UserLog>(
  ({ tabId, userId, extensionVersion, ...rest }) => isNonEmptyString(tabId) && isNonEmptyString(userId) && isNonEmptyString(extensionVersion) && isLogEvent(rest)
)

export const isGetUserLogsByEmailInput = objectTypeGuard<{ email: string }>(
  ({ email }) => isNonEmptyString(email),
)
