/**
 * Type guard helper for literal types.
 *
 * @example
 * ```ts
 * // Define a literal type.
 * export const myItems = ["foo", "bar"] as const;
 * export type  MyItem = typeof myItems[number];
 *
 * // Then create a type guard.
 * export const isMyItem = isLiteralType<MyItem>(myItems);
 * ```
 */
export const isLiteralType =
  <Type>(list: readonly string[]) =>
  (arg: unknown): arg is Type =>
    typeof arg === "string" && list.includes(arg);

/**
 * Type guard helper for literal object types.
 *
 * @example
 * ```ts
 * // Define a literal type.
 * export const MY_ITEM = { foo: "foo", bar: "bar" } as const satisfies Record<any, any>
 * export type  MyItem = typeof myItems[keyof typeof MY_ITEM];
 *
 * // Then create a type guard.
 * export const isMyItem = isLiteralObjectType<MyItem>(MY_ITEM);
 * ```
 */
export const isLiteralObjectType =
  <Type>(list: Record<any, any>) =>
  (arg: unknown): arg is Type =>
    !!list && typeof list === "object" && Object.values(list).includes(arg);
