import {isNonEmptyString, NonEmptyString, objectTypeGuard} from "@workspace/type-utils";
import {isSocialNetworkHandle, SocialNetworkHandle, socialNetworkHostname,} from "./SocialNetwork";

export type TwitterUser = {
  id: NonEmptyString;
  name: NonEmptyString;
  verified: boolean;
  profile_image_url_https: NonEmptyString;
  /** Social Network handle, e.g. `@elonmusk`. */
  screen_name: SocialNetworkHandle;
};

export const isTwitterUser = objectTypeGuard<TwitterUser>(
  ({id, name, profile_image_url_https, screen_name}) =>
    isNonEmptyString(id) &&
    isNonEmptyString(name) &&
    isNonEmptyString(profile_image_url_https) &&
    isSocialNetworkHandle(screen_name),
);

export const twitterUserProfileUrl = (arg: TwitterUser["screen_name"]) =>
  `https://${socialNetworkHostname["Twitter"]}/${arg}/`;
