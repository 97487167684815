import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, {useMemo} from 'react';

import {Friend} from '../../friend/types';

const MIN_VISIBLE_AVATARS_NUMBER = 8;

interface MinimizedRecipientsListProps {
  recipients: Friend[];
  viewAllHandler: () => void;
}

const MinimizedRecipientsList: React.FC<MinimizedRecipientsListProps> = ({
  recipients,
  viewAllHandler,
}) => {
  const visibleAvatarsNumber = useBreakpointValue({base: 7, sm: 10});

  const visibleAvatars = useMemo(() => {
    const avatarsNumber = visibleAvatarsNumber || MIN_VISIBLE_AVATARS_NUMBER;

    return recipients.length > avatarsNumber
      ? recipients.slice(0, avatarsNumber)
      : recipients;
  }, [recipients, visibleAvatarsNumber]);

  return (
    <Box
      pt={2}
      pb={4}
      borderWidth="1px"
      borderColor="gray.150"
      borderLeft="none"
      borderRight="none"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Heading as="h2" fontSize="md" fontWeight="normal">
          Recipients ({recipients.length})
        </Heading>
        <Button
          p={2}
          bgColor="transparent"
          fontWeight="normal"
          color="primary.500"
          fontSize="base"
          onClick={viewAllHandler}
          _focus={{outline: 'none', boxShadow: 'none'}}
        >
          View all
        </Button>
      </Flex>
      <HStack
        /* todo: How to implement this?
          max={visibleAvatarsNumber}
        */
        alignItems="flex-end"
        py={4}
      >
        {visibleAvatars.map((recipient) => (
          <Avatar
            size="sm"
            key={recipient.id}
            src={recipient.profileImageUrl}
          />
        ))}
        <Button
          px={2}
          height="auto"
          fontSize="base"
          color="gray.500"
          fontWeight="normal"
          bgColor="transparent"
          textDecor="underline"
          _active={{bgColor: 'transparent'}}
          _focus={{bgColor: 'transparent', boxShadow: 'none'}}
          onClick={viewAllHandler}
        >
          more ...
        </Button>
      </HStack>
    </Box>
  );
};

export default MinimizedRecipientsList;
