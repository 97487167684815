import { captureException } from "@sentry/browser";
import { firebaseFirestore, firebaseFunctions as functions } from "@workspace/firebase-app";
import { cloudFunctionName } from "@workspace/firebase-definitions";
import { isCloudFunctionOutput, isMessagesDailyQuota } from "@workspace/firebase-datamodel";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFirebaseUser } from "./useFirebaseUser";
import { today } from "@workspace/models";
export const useMessagesCount = (props) => {
    const { isInstagram } = props || {};
    const { isAuthenticated, userInfo } = useFirebaseUser();
    const [dailyCountPerPlatform, setDailyCountPerPlatform] = useState({
        instagram: 0,
        twitter: 0,
        instagramSkipped: 0
    });
    const refetchTimeoutRef = useRef(undefined);
    const dailyCount = useMemo(() => {
        if (isInstagram === undefined || !isAuthenticated)
            return undefined;
        return isInstagram ? dailyCountPerPlatform.instagram : dailyCountPerPlatform.twitter;
    }, [dailyCountPerPlatform.instagram, dailyCountPerPlatform.twitter, isAuthenticated, isInstagram]);
    const skippedMessagesCount = dailyCountPerPlatform.instagramSkipped;
    const [dailyQuota, setDailyQuota] = useState();
    const [scheduledMessagesDailyCount, setScheduledMessagesDailyCount] = useState();
    const readMessagesDailyCount = useCallback(async () => {
        try {
            const { data } = await functions.httpsCallable(cloudFunctionName.readMessagesDailyCount)();
            if (isCloudFunctionOutput.readMessagesDailyCount(data)) {
                setDailyCountPerPlatform(data);
            }
        }
        catch (error) {
            captureException(error);
        }
        //Refetch readMessagesDailyCount at the end of the day
        refetchTimeoutRef.current && clearTimeout(refetchTimeoutRef.current);
        const secondsTillEndOfDay = 86400 - (Date.now() / 1000) % 86400;
        refetchTimeoutRef.current = setTimeout(readMessagesDailyCount, secondsTillEndOfDay * 1000);
    }, []);
    const readScheduledMessagesDailyCount = useCallback(async () => {
        try {
            const { data } = await functions.httpsCallable(cloudFunctionName.readScheduledMessagesDailyCount)();
            if (isCloudFunctionOutput.readMessagesDailyCount(data)) {
                setScheduledMessagesDailyCount(isInstagram ? data.instagram : data.twitter);
            }
        }
        catch (error) {
            captureException(error);
        }
    }, [isInstagram]);
    const readMessagesDailyQuota = useCallback(async () => {
        try {
            const { data } = await functions.httpsCallable(cloudFunctionName.readMessagesDailyQuota)();
            if (isCloudFunctionOutput.readMessagesDailyQuota(data)) {
                const { maxDailyMessages } = data;
                setDailyQuota(maxDailyMessages);
            }
        }
        catch (error) {
            captureException(error);
        }
    }, []);
    //Messages daily count listener
    useEffect(() => {
        let unsub;
        if (isAuthenticated) {
            readMessagesDailyCount();
            unsub = firebaseFirestore.collection("messagesDailyCount").doc(userInfo?.uid).onSnapshot(snapshot => {
                if (!snapshot.exists)
                    return;
                const data = snapshot.data();
                if (isCloudFunctionOutput.readMessagesDailyCount(data)) {
                    if (data.day === today()) {
                        setDailyCountPerPlatform(data);
                    }
                    else {
                        setDailyCountPerPlatform({
                            instagram: 0,
                            twitter: 0,
                            instagramSkipped: 0
                        });
                    }
                }
            });
        }
        return () => {
            unsub?.();
        };
    }, [isAuthenticated, isInstagram, readMessagesDailyCount, userInfo?.uid]);
    //Scheduled messages daily count listener
    useEffect(() => {
        let unsub;
        if (isAuthenticated) {
            readScheduledMessagesDailyCount();
            unsub = firebaseFirestore.collection("scheduledMessagesDailyCount").doc(userInfo?.uid).onSnapshot(snapshot => {
                if (!snapshot.exists)
                    return;
                const data = snapshot.data();
                if (isCloudFunctionOutput.readMessagesDailyCount(data)) {
                    if (data.day === today()) {
                        setScheduledMessagesDailyCount(isInstagram ? data.instagram : data.twitter);
                    }
                    else {
                        setScheduledMessagesDailyCount(0);
                    }
                }
            });
        }
        return () => {
            unsub?.();
        };
    }, [isAuthenticated, isInstagram, readMessagesDailyCount, readScheduledMessagesDailyCount, userInfo?.uid]);
    useEffect(() => {
        if (!isAuthenticated) {
            setDailyQuota(undefined);
            return;
        }
        if (dailyQuota === undefined) {
            readMessagesDailyQuota();
        }
    }, [isAuthenticated, dailyQuota, readMessagesDailyQuota]);
    // Max daily messages listener
    useEffect(() => {
        if (userInfo) {
            firebaseFirestore
                .collection("subscriptions")
                .doc(userInfo.uid)
                .onSnapshot((snapshot) => {
                if (!snapshot.exists)
                    return;
                const data = snapshot.data();
                if (isMessagesDailyQuota(data)) {
                    const { maxDailyMessages } = data;
                    setDailyQuota(maxDailyMessages);
                }
            });
        }
    }, [userInfo]);
    const dailyMessagesLeft = useMemo(() => {
        if (dailyQuota === undefined || dailyCount === undefined)
            return undefined;
        return Math.max(dailyQuota - dailyCount, 0);
    }, [dailyCount, dailyQuota]);
    return { dailyCount, dailyQuota, dailyMessagesLeft, dailyCountPerPlatform, scheduledMessagesDailyCount, skippedMessagesCount };
};
