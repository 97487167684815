"use strict";
exports.__esModule = true;
exports.isUploadInstagramAvatarInput = exports.isGetInstagramUserByIDInput = exports.isScrapeInstagramUserOutput = exports.isScrapeInstagramUserInput = exports.isWriteInstagramUserInput = exports.isReadInstagramUserOutput = void 0;
var models_1 = require("@workspace/models");
var type_utils_1 = require("@workspace/type-utils");
exports.isReadInstagramUserOutput = (0, type_utils_1.objectTypeGuard)(function (socialNetworkUser) { return (0, models_1.isSocialNetworkUser)(socialNetworkUser); });
exports.isWriteInstagramUserInput = (0, type_utils_1.objectTypeGuard)(function (socialNetworkUser) { return (0, models_1.isSocialNetworkUser)(socialNetworkUser); });
exports.isScrapeInstagramUserInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var handles = _a.handles, session = _a.session;
    return (0, type_utils_1.arrayTypeGuard)(type_utils_1.isNonEmptyString)(handles) && (0, type_utils_1.isNonEmptyString)(session);
});
exports.isScrapeInstagramUserOutput = (0, type_utils_1.objectTypeGuard)(function (scrapedUsers) {
    return Object.values(scrapedUsers).every(models_1.isSocialNetworkUser);
});
exports.isGetInstagramUserByIDInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var userId = _a.userId, session = _a.session;
    return (0, type_utils_1.isNonEmptyString)(userId) && (0, type_utils_1.isNonEmptyString)(session);
});
exports.isUploadInstagramAvatarInput = (0, type_utils_1.objectTypeGuard)(function (_a) {
    var instagramId = _a.instagramId, avatarUrl = _a.avatarUrl;
    return (0, type_utils_1.isNonEmptyString)(instagramId) && (0, type_utils_1.isNonEmptyString)(avatarUrl);
});
