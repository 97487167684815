import {colorHex, Flex, Modal, ModalProps, Spinner, Text} from "@workspace/ui";
import {FC, useCallback} from "react";

export const DeleteMessageTemplateModal: FC<
  Omit<ModalProps, "onConfirm" | "children"> & {
    onConfirm: () => void;
    isLoading: boolean;
    name: string;
  }
> = ({isLoading, confirmButtonProps, name, onConfirm, ...props}) => {
  const handleConfirm = useCallback(() => {
    if (isLoading) return;

    onConfirm();
  }, [isLoading, onConfirm]);

  return (
    <Modal
      variant="delete"
      title="Delete template"
      confirmButtonProps={{
        children: isLoading ? (
          <Flex alignItems="center" gap="1">
            Deleting <Spinner size="xs" />
          </Flex>
        ) : (
          "Yes, delete"
        ),
        variant: "danger",
        bg: "red.500",
        disabled: isLoading,
        ...confirmButtonProps,
      }}
      closeButtonProps={{
        color: "gray.900",
        borderWidth: "1px",
        borderColor: colorHex.alpha15,
        children: "Cancel",
      }}
      onConfirm={handleConfirm}
      overlayProps={{
        w: "100%",
      }}
      contentProps={{
        containerProps: {
          left: "50%",
          transform: "translateX(-50%)",
          w: "380px",
          top: "300px",
        },
      }}
      {...props}
    >
      <Text fontSize="h6">
        Are you sure you want to delete “{name}” template?
        <br />
        <br /> You can’t undo this action.
      </Text>
    </Modal>
  );
};
