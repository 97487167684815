import {
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {firebaseAuth} from "@workspace/firebase-app";
import {isFiniteString, isMaybeObject, isNonEmptyString} from "@workspace/type-utils";
import firebase from "firebase/compat/app";
import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {IoMdClose} from "react-icons/io";
import {DsmButton, DsmSolidButton} from "src/components/buttons";
import {useAppSelector} from "src/hooks";
import {
  changePasswordErrorLabelsMap,
  PASSWORD_REGEX,
} from "src/modules/auth/helpers/validation";
import {sendPasswordChangedEmailOnCall} from "../user-service";
import * as yup from "yup";
import PasswordInput from "src/modules/auth/components/password-input";
import {dsmToast} from "src/components/dsm-toast";

const schema = yup.object({
  confirmNewPassword: yup
    .string()
    .trim()
    .test(
      "passwords match",
      "Passwords must match",
      (value, context) => value === context.parent.newPassword,
    )
    .required("Confirm new password is required"),
  newPassword: yup
    .string()
    .trim()
    .test(
      "valid password",
      changePasswordErrorLabelsMap["auth/weak-password"],
      (value) => !!value && !PASSWORD_REGEX.test(value),
    )
    .required("New password is required"),
  currentPassword: yup.string().trim().required("Current password is required"),
});

const isChangePasswordField = (
  arg: unknown,
): arg is keyof yup.InferType<typeof schema> => {
  return isFiniteString(arg) && Object.keys(schema.fields).includes(arg);
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const isHasMessage = (error: unknown): error is {message: string} => {
  return (
    !!error &&
    typeof error === "object" &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

export const ChangePasswordModal: FC<Props> = (props) => {
  const {isOpen, onClose} = props;
  const user = useAppSelector((state) => state.user.user);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState<
    Partial<Record<keyof yup.InferType<typeof schema>, string>>
  >({});

  const firebaseUser = firebaseAuth.currentUser;

  const clearFields = useCallback(() => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  }, []);

  const verifyPassword = useCallback(async () => {
    if (!user || !firebaseUser) return;

    try {
      await firebaseUser.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(user.email, currentPassword),
      );
      return true;
    } catch {
      return false;
    }
  }, [user, currentPassword, firebaseUser]);

  const handleSubmit = useCallback(async () => {
    if (!firebaseUser || loading) return;

    try {
      setLoading(true);
      setError("");
      setErrors({});

      await schema.validate(
        {currentPassword, newPassword, confirmNewPassword},
        {
          abortEarly: false,
        },
      );

      const isPasswordVerified = await verifyPassword();

      if (isPasswordVerified) {
        await firebaseUser.updatePassword(newPassword);

        sendPasswordChangedEmailOnCall();
        onClose();
        dsmToast("Your password is now changed", "success");

        // Add toast
        clearFields();
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          currentPassword: "Password is incorrect",
        }));
      }
    } catch (err) {
      if (isMaybeObject<yup.ValidationError>(err) && Array.isArray(err.inner)) {
        setErrors(
          err.inner.reduce<typeof errors>((acc, curr) => {
            if (
              isMaybeObject<yup.ValidationError["inner"][number]>(curr) &&
              isChangePasswordField(curr.path) &&
              isNonEmptyString(curr.message)
            ) {
              acc[curr.path] = curr.message;
            }

            return acc;
          }, {}),
        );
      } else if (isHasMessage(err)) {
        setError(err.message);
      } else {
        setError("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  }, [
    loading,
    firebaseUser,
    newPassword,
    confirmNewPassword,
    currentPassword,
    verifyPassword,
    clearFields,
    onClose,
  ]);

  const handleClose = useCallback(() => {
    if (loading) return;

    onClose();
  }, [loading, onClose]);

  useEffect(() => {
    clearFields();
    setError("");
    setErrors({});
  }, [isOpen, clearFields]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent p="8" minWidth="464px">
        <Flex justifyContent="space-between" alignItems="center" mb="8">
          <ModalHeader flexGrow="1" p="0" color="gray.900">
            Change password
          </ModalHeader>
          <IconButton
            background="transparent"
            borderRadius="full"
            aria-label="close modal"
            onClick={onClose}
          >
            <IoMdClose fontSize="24px" />
          </IconButton>
        </Flex>
        <ModalBody p="0">
          <form onSubmit={(e) => e.preventDefault()}>
            <PasswordInput
              label="Current password"
              value={currentPassword}
              fieldProps={{
                isError: !!errors.currentPassword,
                helpText: errors.currentPassword,
              }}
              saveValue={setCurrentPassword}
            />
            <PasswordInput
              label="New password"
              value={newPassword}
              fieldProps={{isError: !!errors.newPassword, helpText: errors.newPassword}}
              saveValue={setNewPassword}
            />
            <PasswordInput
              label="Confirm new password"
              value={confirmNewPassword}
              fieldProps={{
                isError: !!errors.confirmNewPassword,
                helpText: errors.confirmNewPassword,
              }}
              saveValue={setConfirmNewPassword}
            />
          </form>
          <Flex justifyContent="center" mb="4">
            {error && (
              <Text fontWeight="bold" color="red.500">
                {error}
              </Text>
            )}
          </Flex>

          <Flex justifyContent="flex-end" alignItems="center" gap="4">
            <DsmButton onClick={handleClose} size="sm" disabled={loading}>
              Cancel
            </DsmButton>
            <DsmSolidButton
              size="sm"
              isLoading={loading}
              disabled={loading}
              onClick={handleSubmit}
            >
              Change password
            </DsmSolidButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
