import {objectTypeGuard} from "@workspace/type-utils";

/** The number of milliseconds since the @link{https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_ecmascript_epoch_and_timestamps|ECMAScript epoch}.*/
export type Time = number;
export const isTime = (arg: unknown): arg is Time => typeof arg === "number" && arg >= 0;

type Now = () => Time;
export const now: Now = () => new Date().getTime();

export type TimeDuration = number;
export const isTimeDuration = (arg: unknown): arg is TimeDuration =>
  typeof arg === "number" && arg >= 0;

export const delay = async (timeout: TimeDuration) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

export type CreationTime = {whenCreated: Time};
export const createdNow = (): CreationTime => ({whenCreated: now()});
export const isCreationTime = objectTypeGuard<CreationTime>(({whenCreated}) =>
  isTime(whenCreated),
);

export type UpdateTime = {whenUpdated: Time};
export const updatedNow = (): UpdateTime => ({whenUpdated: now()});
export const isUpdateTime = objectTypeGuard<UpdateTime>(({whenUpdated}) =>
  isTime(whenUpdated),
);

export type DeletionTime = {whenDeleted: Time};
export const deletedNow = (): DeletionTime => ({whenDeleted: now()});
export const isDeletionTime = objectTypeGuard<DeletionTime>(({whenDeleted}) =>
  isTime(whenDeleted),
);

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = 24 * ONE_HOUR;

/**
 * Time durations.
 */
export const TIME = {
  ONE_SECOND,
  TWO_SECONDS: ONE_SECOND * 2,
  THREE_SECONDS: ONE_SECOND * 3,
  FIVE_SECONDS: ONE_SECOND * 5,
  TEN_SECONDS: ONE_SECOND * 10,
  FIFTEEN_SECONDS: ONE_SECOND * 15,
  HALF_A_MINUTE: ONE_SECOND * 30,
  ONE_MINUTE,
  HALF_AN_HOUR: ONE_MINUTE * 30,
  ONE_HOUR,
  ONE_DAY,
} as const;
