import { FetchUserLeadsFilters } from "@workspace/firebase-datamodel";
import {LastPostDate, Lead} from "@workspace/models";
import {sub} from "date-fns";
import Fuse from 'fuse.js'

export const filterLeads = (leads: Lead[], filters: FetchUserLeadsFilters) => {
  let validLeads = leads;

  if(filters.followers){
    if(filters.followers.min){
      validLeads = validLeads.filter(lead => lead.followers >= filters.followers!.min!);
    }

    if(filters.followers.max){
      validLeads = validLeads.filter(lead => lead.followers <= filters.followers!.max!);
    }
  }

  if(filters.following){
    if(filters.following.min){
      validLeads = validLeads.filter(lead => lead.following >= filters.following!.min!);
    }

    if(filters.following.max){
      validLeads = validLeads.filter(lead => lead.following <= filters.following!.max!);
    }
  }

  if(filters.posts){
    if(filters.posts.min){
      validLeads = validLeads.filter(lead => lead.posts >= filters.posts!.min!);
    }

    if(filters.posts.max){
      validLeads = validLeads.filter(lead => lead.posts <= filters.posts!.max!);
    }
  }

  if(filters.lastPostDate){
    const date = getLastPostDate(filters.lastPostDate);

    if(date){
      validLeads = validLeads.filter(lead => lead.lastPostDate && new Date(lead.lastPostDate) >= date);
    }
  }

  if(filters.keyword){
    const fuse = new Fuse(validLeads, {
      keys: ['keywordString'],
      includeMatches: true,
      threshold: 0.75
    })


    const result = fuse.search(filters.keyword)

    validLeads = result.map(r => r.item);
  }

  return validLeads;
}


const getLastPostDate = (lastPostDate: LastPostDate) => {
  const daysBehind: Partial<Record<LastPostDate, number>> = {
    'last14': 14,
    'last30': 30,
    'last90': 90,
    '1year': 365,
    '5years': 365 * 5,
  }

  const daysNumeric = daysBehind[lastPostDate];

  if(!daysNumeric) return undefined;

  return sub(new Date(), {
    days: daysNumeric
  });
}
