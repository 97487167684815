"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
exports.__esModule = true;
exports.LEAD_PROCESSING_INTERVAL_IN_MINUTES = exports.LEADS_PROCESSED_AT_ONCE = exports.lastPostDate = exports.lastPostDates = void 0;
exports.lastPostDates = [
    'last14',
    'last30',
    'last90',
    '1year',
    '5years',
    'unlimited'
];
exports.lastPostDate = exports.lastPostDates.reduce(function (acc, key) {
    var _a;
    return (__assign(__assign({}, acc), (_a = {}, _a[key] = key, _a)));
}, {});
/**
 * Number of leads processed per processing run
 * */
exports.LEADS_PROCESSED_AT_ONCE = 20;
/**
 * Time between processing runs
 * */
exports.LEAD_PROCESSING_INTERVAL_IN_MINUTES = 20;
