import {Button, ButtonProps, chakra, Flex, shouldForwardProp} from "@chakra-ui/react";
import {isValidMotionProp, motion} from "framer-motion";
import {FC} from "react";
import {Icon} from "./icons";

type Props = Pick<ButtonProps, "onClick"> & {
  isOpen: boolean;
};

const ExtensionSlideToggleIconContainer = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const ExtensionSlideToggle: FC<Props> = ({isOpen, onClick}) => {
  return (
    <Button
      p="2"
      w="68px"
      minW="42px"
      maxW="42px"
      display="block"
      top="56px"
      left="-42px"
      overflowX="hidden"
      position="absolute"
      colorScheme="primary"
      borderRightRadius="none"
      onClick={onClick}
      _focus={{outline: "none"}}
      backgroundColor='primary.400'
    >
      <Flex flexDirection="column" alignItems="center" gap="2">
        {/*
           Logo.
         */}
        <svg width="24" height="28" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.7764 22.7893C25.1061 17.9485 25.5777 16.3997 21.9047 20.7054C21.2768 21.4284 20.6489 22.1939 19.9373 22.9169C19.2675 23.5973 18.5977 24.1502 17.928 24.533C16.5465 25.341 15.6256 25.0008 15.1233 24.5755C13.3361 23.1698 14.7834 19.7093 15.4163 17.941C16.5694 14.8657 18.0617 11.8008 18.7652 8.58462C19.063 7.29861 19.26 5.45239 18.1373 4.50185C16.3724 3.12258 13.5006 5.79503 12.3186 6.84094C10.1 8.75474 8.13251 11.0938 6.24878 13.9858C4.99295 15.9847 3.52782 18.5364 3.52782 20.7054C3.67078 22.1578 4.8207 21.0914 5.70459 20.4927C7.25344 19.4295 8.50926 17.941 9.68136 16.495C10.7279 15.2191 11.5917 14.1668 12.429 13.0186C12.9731 12.253 14.0903 11.9341 14.8302 12.5398C15.5071 13.0939 15.5981 14.003 15.054 14.7686C14.1749 16.0019 13.3233 17.3881 12.2349 18.7065C10.9372 20.3226 9.47206 21.9812 7.58832 23.3422C6.5418 24.0652 5.3697 24.7881 3.82085 24.8732C2.56502 24.9157 0.471981 24.4904 0.178955 20.9605C0.178955 17.7463 1.76574 14.8229 3.4441 12.1996C5.49528 8.92486 7.71391 6.37312 10.1837 4.24667C11.7325 2.92828 13.4488 1.56735 15.4582 1.01447C17.2582 0.504127 18.9326 0.80183 20.1884 1.78C21.5698 2.88575 22.3652 4.5869 22.3652 6.71335C22.3652 7.47887 22.2815 8.32945 22.0722 9.35015C21.3552 12.7934 19.6889 15.8429 18.5559 19.1318C18.4721 19.3019 18.4303 19.472 18.3466 19.6421C18.6814 19.2594 19.0163 18.8766 19.3512 18.4939C20.6908 16.8778 22.114 15.2617 23.9978 14.0709C25.5466 13.1352 26.2044 13.4755 26.7486 13.7732C27.2509 14.0283 28.13 14.7513 28.1718 16.3674C28.2556 18.8341 27.5858 21.3858 26.9579 23.6824C26.178 26.3234 23.0707 25.4183 23.7764 22.7893Z" fill="white"/>
        </svg>

        {/*
           Animated icon.
         */}
        <ExtensionSlideToggleIconContainer
          alignItems="center"
          animate={{
            rotate: isOpen ? 0 : 180,
            transition: {delay: 0.2},
          }}
          justifyContent="center"
        >
          <Icon size="20px" name="chevronRight" />
        </ExtensionSlideToggleIconContainer>
      </Flex>
    </Button>
  );
};
