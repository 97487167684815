import {Box, Text} from "@chakra-ui/react";
import {FC} from "react";
import {StepSectionSubtext, StepTitle} from "../styled";
import {ProspectsStepProps} from "./types";
import ProfileSearchDropdown from "../ProfileSearchDropdown/ProfileSearchDropdown";

export const MINIMUM_TOP_USERS_PROFILE_URLS = 3;

export const ProspectsStep: FC<ProspectsStepProps> = ({ onChange, profiles}) => {
  return (
    <Box>
      <StepTitle mb="4">
        Which Instagram accounts attract your customers?
      </StepTitle>
      <Text mb="10" bg="orange.50" fontSize="14px" color="#170E02" px="4" py="3">
        Add at least 3 Instagram accounts. Make sure they have enough posts and engagement, and that they are publicly accessible.
      </Text>
      <StepSectionSubtext>
        Select people
      </StepSectionSubtext>
      <ProfileSearchDropdown
        profiles={profiles}
        onChange={onChange}
      />
    </Box>
  );
};
