import {Box, Icon, Text} from "@chakra-ui/react";
import {PricingPlanName, PRICING_PLAN_FEATURES} from "@workspace/firebase-datamodel";
import {FC} from "react";
import {IoCheckmarkSharp} from "react-icons/io5";
import {FEATURES_RENDER_ORDER, FEATURE_NAME_LABEL} from "./consts";

export const PLAN_FEATURES = [
  "Send automated bulk messages on Instagram and Twitter",
  "Advanced filters to find the right targets",
  "Analytics dashboard",
  "Upload .csv files for bulk messages",
];

export const PricingPlanFeatures: FC<{
  planName: PricingPlanName;
  messagesPerDay: number;
}> = ({planName, messagesPerDay}) => {
  return (
    <Box p="8" background="white" borderRadius="16px" height="100%" fontFamily="Inter">
      <Text fontWeight="bold" fontSize="lg" mb="6">
        What’s included
      </Text>
      <Box display="flex" flexDirection="column" gap="2">
        <Box display="flex">
          <Box pt="1px" mr="2">
            <Icon size="20" color="primary.400" as={IoCheckmarkSharp} />
          </Box>
          <Text fontSize="14px">{messagesPerDay} DMs /day</Text>
        </Box>
        {FEATURES_RENDER_ORDER.map((featureName, i) => {
          const isFeatureEnabled = PRICING_PLAN_FEATURES[planName].includes(featureName);

          return (
            <Box key={i} display="flex">
              <Box pt="1px" mr="2">
                <Icon
                  size="20"
                  color={isFeatureEnabled ? "primary.400" : "gray.900"}
                  opacity={!isFeatureEnabled ? "0.4" : undefined}
                  as={IoCheckmarkSharp}
                />
              </Box>
              <Text
                fontSize="14px"
                opacity={!isFeatureEnabled ? "0.4" : undefined}
                as={!isFeatureEnabled ? "s" : undefined}
              >
                {FEATURE_NAME_LABEL[featureName]}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
