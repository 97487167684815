export const PROFILE_URL_REGEX =
  /^https:\/\/(www.)?instagram.com\/([a-zA-Z0-9._]+)(\/)?$/;

export const isUniqueOrFirstOccurringProfileUrl = (
  arr: string[],
  pivot: string,
  index: number,
) => {
  const username = pivot.match(PROFILE_URL_REGEX)?.[2] ?? "";

  const usernames = arr.map((profileUrl) =>
    PROFILE_URL_REGEX.test(profileUrl)
      ? profileUrl.match(PROFILE_URL_REGEX)?.[2] ?? ""
      : profileUrl,
  );
  const firstIndex = usernames.indexOf(username);
  const lastIndex = usernames.lastIndexOf(username);

  return firstIndex === lastIndex || firstIndex === index;
};
