/**
 * Use `isMaybeObject` as a *type guard* helper.
 *
 * @example
 *```ts
 *type Foo = { bar: boolean };

 *const isFoo = (arg: unknown): arg is Foo => {
 *  if (isMaybeObject<Foo>(arg)) return false;
 *  const { bar } = arg;
 *  return typeof bar === "boolean";
 *}
 *```
 */
export const isMaybeObject = <T extends object>(
  arg: unknown,
): arg is {
  [K in keyof T]: unknown;
} => typeof arg === "object" && arg !== null && !Array.isArray(arg);

/**
 * Use `objectTypeGuard` as a *type guard* helper to reduce boilerplate.
 *
 * @example
 * ```ts
 * type Foo = { bar: boolean };
 *
 * const isFoo = objectTypeGuard<Foo>(({ bar }) => typeof bar === "boolean");
 * ```
 */
export const objectTypeGuard =
  <T extends object>(check: (obj: {[K in keyof T]: unknown}) => boolean) =>
  (arg: unknown): arg is T =>
    isMaybeObject<T>(arg) && check(arg);

export type RequiredByKeys<T extends object, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: T[P];
};

export type PartialByKeys<T extends object, K extends keyof T> = Omit<T, K> & {
  [P in K]?: T[P];
};
