import {Operation} from "@workspace/models";
import {isLiteralType, objectTypeGuard} from "@workspace/type-utils";

export const analyticsEvents = [
  'Campaign Created',
  'Campaign Cancelled',
  'Campaign Finished',
  'Campaign with Follow Up Created',
  'Message Template Edited',
  'Message Template Created',
  'Generated Magic DM',
  'List Upload',
  'Collected Targets',
  'Skip Previous Conversations Enabled',
  'Triggered Options Menu (/)',
  'Custom Message Created (Edited Template)',
  'Message Sent',
  'Created Integration',
  'Edited Integration',
  'Downgraded Subscription',
  'Upgraded Subscription',
  'Cancelled Subscription',
  'Started Free Trial',
  'Created Account',
  'DM Replied'
] as const;

export type AnalyticsEvent = (typeof analyticsEvents)[number];

const isAnalyticsEvent = isLiteralType<AnalyticsEvent>(analyticsEvents);

export type TrackAnalyticEvent = Operation<
  { event: AnalyticsEvent, properties?: Record<string, any> },
  void
>;

export const isTrackAnalyticsEventInput = objectTypeGuard<TrackAnalyticEvent["in"]>(
  ({event}) => isAnalyticsEvent(event)
);
