import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  defineStyleConfig,
} from "@chakra-ui/react";
import {FC} from "react";
import {IconBaseProps} from "react-icons";
import {colorHex} from "../colors";
import {Icon, IconKey} from "./icons";

export const buttonStyleConfig = defineStyleConfig({
  baseStyle: {
    fontWeight: "semibold",
    fontSize: "14px",
    borderRadius: 8,
    _disabled: {
      opacity: 0.3,
    },
  },
  sizes: {
    sm: (props) => {
      let paddingStyle: Record<string, number> = {
        px: 3,
        py: 2,
      };

      if (props["data-icon-button"]) {
        paddingStyle = {p: 1.5, height: 8, width: 8};
      }

      return {
        fontSize: "14px",
        lineHeight: "20px",
        height: "unset",
        ...paddingStyle,
      };
    },
    md: (props) => {
      let paddingStyle: Record<string, number> = {
        px: 4,
        py: 2,
      };

      if (props["data-icon-button"]) {
        paddingStyle = {p: 2, height: 9, width: 9};
      }

      return {
        fontSize: "14px",
        lineHeight: "20px",
        height: "unset",
        ...paddingStyle,
      };
    },
    lg: (props) => {
      let paddingStyle: Record<string, number | string> = {
        px: 4,
        py: 3,
      };

      if (props["data-icon-button"]) {
        paddingStyle = {p: 4, height: "44px", width: "44px"};
      }

      return {
        fontSize: "14px",
        lineHeight: "20px",
        height: "unset",
        ...paddingStyle,
      };
    },
  },
  variants: {
    primary: {
      backgroundColor: colorHex.primary400,
      color: colorHex.white,
      _hover: {
        backgroundColor: colorHex.primary300,
        _disabled: {
          backgroundColor: colorHex.primary300,
        },
      },
      _disabled: {
        backgroundColor: colorHex.primary300,
      },
    },
    secondary: {
      backgroundColor: colorHex.primary50,
      color: colorHex.primary400,
      _hover: {
        backgroundColor: "#F2F1FD",
        _disabled: {
          backgroundColor: "#DDDBFA",
        },
      },
      _disabled: {
        backgroundColor: "#DDDBFA",
      },
    },
    ghost: {
      backgroundColor: "transparent",
      color: "#2621E3",
      _hover: {
        color: "#4E4AE8",
        backgroundColor: "transparent",
        _disabled: {
          color: colorHex.primary500,
          backgroundColor: "transparent",
        },
      },
      _disabled: {
        color: colorHex.primary500,
        backgroundColor: "transparent",
      },
    },
    danger: {
      backgroundColor: colorHex.red400,
      color: colorHex.white,
      _hover: {
        backgroundColor: "#D43226",
        _disabled: {
          backgroundColor: "#B12A20",
        },
      },
      _disabled: {
        backgroundColor: "#B12A20",
      },
    },
    outline: {
      border: "none",
      color: colorHex.primary400,
      _hover: {
        backgroundColor: colorHex.primary50,
        color: colorHex.primary500,
        _disabled: {
          color: colorHex.primary300,
        },
      },
      _disabled: {
        color: colorHex.primary300,
      },
    },
    bordered: {
      borderColor: colorHex.gray100,
      borderWidth: "1px",
      color: colorHex.gray900,
      _hover: {
        backgroundColor: colorHex.primary50,
        _disabled: {
          color: colorHex.gray700,
        },
      },
      _disabled: {
        color: colorHex.gray700,
      },
    },
  },
});

export type ButtonProps = Omit<ChakraButtonProps, "variant"> & {
  variant?: "primary" | "secondary" | "ghost" | "danger" | "outline" | "bordered";
  size?: "sm" | "md" | "lg";
};

export const Button: FC<ButtonProps> = ({variant = "primary", size = "md", ...props}) => {
  return (
    <ChakraButton
      {...props}
      isDisabled={props.disabled || false}
      variant={variant}
      size={size}
    />
  );
};

export type IconButtonProps = ButtonProps & {
  iconName: IconKey;
  iconProps?: IconBaseProps;
};

export const IconButton: FC<IconButtonProps> = ({
  iconName,
  iconProps = {},
  variant = "primary",
  size = "md",
  ...props
}) => {
  return (
    <ChakraButton data-icon-button="true" {...props} variant={variant} size={size}>
      <Icon {...iconProps} name={iconName} />
    </ChakraButton>
  );
};
